<template>
    <div class="footer-max-component footer-component default_page_style">
        <ul class="footer-max-component-content-list">
            <li class="nav_section">
                <img :src="require('../../images/backgroundImg/logo_icon.svg')" alt="">
                <p>国家文物局</p>
                <p>考古研究中心</p>
            </li>
            <li class="footer-max-component-list-subsets">
                <h3>科技考古信息平台</h3>
                <ul>
                    <li v-for="(item,index) in catalogue" :key="index" class="platform-show-list"
                        @click="watchNavigationHandle(item)">{{ item.name }}
                    </li>
                </ul>
            </li>
            <li class="footer-max-component-list-subsets-team">
                <h3>科研团队</h3>
                <ul>
                    <li v-for="(item,index) in teamList" :key="index" class="team-show-list">{{ item.name }}</li>
                </ul>
            </li>
            <li class="footer-max-component-list-subsets-unit">
                <h3>合作单位</h3>
                <ul>
                    <li v-for="(item,i) in unitList" :key="i">
                        <a :href="item.path" target="_blank">{{ item.name }}</a>
                    </li>
                </ul>
            </li>
            <li class="qr_code">
                <img :src="require('../../images/backgroundImg/qrCode.png')" alt="">
                <p>考古中国公众号</p>
            </li>
        </ul>
<!--        <ul class="footer-max-bottom">-->
<!--            <li v-for="(item,i) in copyrightLis" :key="i">-->
<!--                <span v-text="item.name+'：'"></span>-->
<!--                <a :class="{'active':item.url}" :href="item.url?item.url:'javascript:;'" target="_blank"-->
<!--                   v-text="item.value"></a>-->
<!--            </li>-->
<!--        </ul>-->
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import {useRouter} from 'vue-router'

export default defineComponent({
    name: "footer-max-component",
    props: {
        catalogue: {
            type: Array,
            default: null,
        }
    },
    setup() {
        const copyrightLis = ref([
            {name: "版权所有", value: "国家文物局考古研究中心", url: ""},
            {name: "技术支持", value: "西安云图信息技术有限公司", url: "//www.geoyt.cn"},
        ]);
        const teamList = ref([
            {name: "北京大学考古文博学院 “考古年代学与动植物考古”联合实验室"},
            {name: "中国科学院古脊椎动物与古人类研究所 “分子古生物学”联合实验室"},
            {name: "中国科学院青藏高原所研究所 “环境考古”联合实验室"},
        ])
        const unitList = ref([
            {name: "中国社会科学网", path: "http://kaogu.cssn.cn/"},
            {name: "国家基因组科学数据中心", path: "https://ngdc.cncb.ac.cn/"},
            {name: "山东大学考古实验教学中心", path: "https://www.history.sdu.edu.cn/lab/index.htm"},
            {name: "中国植物物种信息数据库", path: "http://db.kib.ac.cn/"},
            {name: "中国动物主题数据库", path: "http://www.zoology.csdb.cn/"},
        ])
        // 路由跳转
        let router = useRouter();

        // 监听导航事件
        const watchNavigationHandle = (item) => {
            router.push({name: item.path});
        }

        return {
            copyrightLis,
            teamList,
            unitList,
            watchNavigationHandle,
        }
    }
})
</script>

<style lang="scss">
@import "./footer-component";
</style>