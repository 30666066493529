<template>
    <div v-if="visibleLogin" class="login_component" ref="loginMain">
        <div class="login_container">
            <h3>用户登录</h3>
            <i class="iconfont icon-shanchu2" @click="shutLogin"/>
            <el-form ref="loginFormRef" :model="loginValidateForm" :rules="rulesForm" size="large"
                     @keydown.enter="submitForm">
                <template v-if="phoneLogin">
                    <el-form-item prop="mobile">
                        <el-input v-model.trim="loginValidateForm.mobile"
                                  placeholder="手机号">
                            <template #prepend>
                                <el-icon>
                                    <Iphone/>
                                </el-icon>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code" class="mobile-code-item">
                        <el-input v-model.trim="loginValidateForm.code" maxlength="20"
                                  minlength="2"
                                  placeholder="验证码"
                                  type="password">
                            <template #prepend>
                                <el-icon>
                                    <Message/>
                                </el-icon>
                            </template>
                            <template #append>
                                <span class="send-code-text" @click="sendCodeEvent">{{ sendText }}</span>
                            </template>
                        </el-input>
                    </el-form-item>
                </template>
                <template v-else>
                    <el-form-item prop="username">
                        <el-input v-model.trim="loginValidateForm.username"
                                  maxlength="16"
                                  minlength="2"
                                  placeholder="用户名">
                            <template #prepend>
                                <el-icon>
                                    <user/>
                                </el-icon>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model.trim="loginValidateForm.password" maxlength="20"
                                  minlength="2"
                                  placeholder="密码"
                                  show-password
                                  type="password">
                            <template #prepend>
                                <el-icon>
                                    <lock/>
                                </el-icon>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-row :gutter="10" class="login_verification_code_box">
                        <el-col :span="17">
                            <el-form-item prop="validCode">
                                <el-input v-model.trim="loginValidateForm.validCode" placeholder="验证码">
                                    <template #prepend>
                                        <el-icon>
                                            <setting/>
                                        </el-icon>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <img v-if="refreshCode"
                                 :src="`${codeUrl}/api-uaa/validata/code/${codeUUID}?t=${new Date().getTime()}`"
                                 alt="验证码"
                                 class="code_img"
                                 @click="refreshCodeEvent">
                        </el-col>
                    </el-row>
                </template>
                <el-form-item>
                    <div class="login-btn-box">
                        <el-button class="submit_verify" @click="phoneLogin = !phoneLogin">
                            {{ phoneLogin ? "用户名登录" : "手机号登录" }}
                        </el-button>
                        <el-button class="submit_login" type="primary" @click="submitForm">登录</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import {Lock, Setting, User, Message, Iphone} from '@element-plus/icons-vue'
import {nextTick, onMounted, reactive, ref, watch, watchEffect} from "vue";
import requestInterface from '../../js/request-interface'
import commonMethods from "../../js/common-methods";
import userApi from "../../api/user-api";
import {ElMessage} from "element-plus";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "login_component",
    components: {
        Lock, User, Setting, Message, Iphone
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    emits: ['closeEvent'],
    setup(props, {emit}) {
        let route = useRoute();
        let router = useRouter();
        let loginMain = ref(null);
        // 显示隐藏验证码
        let refreshCode = ref(true);
        let codeUrl = ref(null);
        // 数据库标识
        let dataIndex = ref(requestInterface.index);
        // let visibleLogin = ref(commonMethods.getCookie("safer"));
        let visibleLogin = ref(false);
        let codeUUID = ref(commonMethods.randomUUID())
        let loginFormRef = ref(null);
        let rulesForm = reactive({
            username: [{required: true, message: '请填写用户名', trigger: 'blur'}],
            password: [{required: true, message: '请填写密码', trigger: 'blur'}],
            validCode: [{required: true, message: '请填写验证码', trigger: 'blur'}],
            mobile: [{
                required: true, validator: function (rule, value, callback) {
                    const re = new RegExp("^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\\d{8}$")
                    if (value) {
                        return re.test(value) ? callback() : callback(new Error("请输入正确的手机号"));
                    } else {
                        return callback(new Error("请输入手机号"));
                    }
                }, trigger: 'blur'
            }],
        })

        let loginValidateForm = reactive({
            username: "",
            password: "",
            validCode: "",
            mobile: "",
            code: "",
        })

        let phoneLogin = ref(false);
        let sendCode = ref(false);
        let sendText = ref("获取验证码");
        let num = ref(60);
        let interval;

        // 刷新验证码
        const refreshCodeEvent = function () {
            refreshCode.value = false;
            nextTick(() => {
                codeUUID.value = commonMethods.randomUUID();
                refreshCode.value = true;
            })
        }

        watch(num, function (val) {
            if (val < 60) {
                sendText.value = `${val}s后重新获取`;
            } else {
                sendText.value = "获取验证码";
            }
        });
        // 发送验证码
        const sendCodeEvent = function () {
            if (!loginValidateForm.mobile) return ElMessage.warning("请输入手机号")
            // loginFormRef.value.validateField("mobile").then(() => {
            if (sendCode.value) return;
            userApi.getSendCode(loginValidateForm.mobile).then(res => {
                if (res['resp_code'] === 0) {
                    interval = setInterval(function () {
                        num.value = num.value - 1;
                        if (num.value === 0) {
                            clearInterval(interval);
                            sendCode.value = false;
                            num.value = 60;
                        }
                    }, 1000);
                } else {
                    ElMessage.error(res['resp_msg']);
                }
            });
            // });
        }

        // 提交登录
        const submitForm = function () {
            loginFormRef.value.validate(async valid => {
                if (valid) {
                    let params
                    if (phoneLogin.value) {
                        params = {
                            code: loginValidateForm.code,
                            mobile: loginValidateForm.mobile,
                            grant_type: "mobile_code"
                        }
                    } else {
                        params = {
                            ...loginValidateForm,
                            deviceId: codeUUID.value,
                            grant_type: "password_code"
                        }
                    }
                    setTimeout(function () {
                        refreshCodeEvent();
                    }, 1500)
                    let result = await userApi.login(params);
                    const {token_type, access_token, refresh_token} = result;
                    let safer = commonMethods.initialToUpperCase(token_type) + " " + access_token;
                    commonMethods.setCookies({safer, refreshWand: refresh_token});
                    if (phoneLogin.value) {
                        let mobileResult = await userApi.getInfoByMobile(loginValidateForm.mobile, safer);
                        commonMethods.setCookies({uname: mobileResult.username});
                    } else {
                        commonMethods.setCookies({uname: loginValidateForm.username});
                    }
                    router.replace({name: route.name, query: {...route.query, active: 1}});
                    ElMessage({
                        message: '登录成功',
                        type: 'success',
                        duration: 1000,
                        onClose: () => {
                            location.reload();
                        }
                    })
                }
            })
        }

        watchEffect(function () {
            let visible = props.visible;
            if (visible) {
                requestInterface.getBaseUrl().then(url => {
                    codeUrl.value = url;
                    visibleLogin.value = visible
                });
            }
        })

        // 关闭登录弹窗
        const shutLogin = function () {
            visibleLogin.value = false;
            emit("closeEvent");
        }

        onMounted(function () {
        })

        return {
            loginMain,
            refreshCode,
            codeUUID,
            loginFormRef,
            loginValidateForm,
            visibleLogin,
            dataIndex,
            codeUrl,
            refreshCodeEvent,
            submitForm,
            rulesForm,
            shutLogin,

            phoneLogin,
            sendText,
            sendCodeEvent
        }
    }
}
</script>

<style lang="scss">
@import "login-component";
</style>
