<template>
    <div class="big-file-upload-component">
        <uploader ref="bigUploaderRef"
                  :auto-start="autoStart"
                  :file-status-text="statusText"
                  :options="options"
                  class="uploader-example"
                  @file-complete="fileComplete"
                  @file-added="fileAddedEvent"
                  @file-success="fileSuccessEvent">
            <uploader-un-support></uploader-un-support>
            <uploader-drop>
                <uploader-btn :attrs="attrs" :single="multiple">选择文件</uploader-btn>
                <!--                <uploader-btn :attrs="attrs">选择图片</uploader-btn>-->
                <uploader-btn v-if="directory" :directory="directory">选择文件夹</uploader-btn>
                <p>拖拽文件在这里上传</p>
            </uploader-drop>
            <uploader-list></uploader-list>
        </uploader>
    </div>
</template>

<script>
import {Uploader, UploaderBtn, UploaderDrop, UploaderList, UploaderUnSupport} from 'big-file-upload-vue3/src'
import {reactive, ref} from "vue";
import requestInterface from "../../js/request-interface";
import {ElMessage} from "element-plus";

export default {
    name: "big-file-upload-component",
    components: {
        Uploader,
        UploaderUnSupport,
        UploaderDrop,
        UploaderBtn,
        UploaderList
    },
    props: {
        // 上传多文件
        multiple: {
            type: Boolean,
            default: true
        },
        // 上传文件夹
        directory: {
            type: Boolean,
            default: false
        },
        // 上传文件类型
        accept: {
            type: String,
            default: "*"
        },
        // 切片大小
        sliceSize: {
            type: Number,
            default: 5
        },
        // 自动上传
        autoStart: {
            type: Boolean,
            default: false
        },
        // 上传地址
        uploadUrl: {
            required: true,
            type: String,
            default: null
        },
        bucketName: {
            required: true,
            type: String,
            default: 'test'
        },
        addData:{
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
        let bigUploaderRef = ref(null);
        let options = reactive({
            // target:props.uploadUrl,

            // 目标上传 URL，可以是字符串也可以是函数，如果是函数的话，则会传入 Uploader.File 实例、
            // 当前块 Uploader.Chunk 以及是否是测试模式，默认值为 '/'
            target: function (file, chunkFile) {
                const key = 'chunk_' + chunkFile.offset;
                return file['chunkUrlData'][key];
            },
            // 为每个块向服务器发出 GET 请求，以查看它是否已经存在。如果在服务器端实现，
            // 这将允许在浏览器崩溃甚至计算机重新启动后继续上传。(默认: true)
            testChunks: false,
            // 分块时按照该值来分。最后一个上传块的大小是可能是大于等于1倍的这个值但是小于两倍的这个值大小，
            // 可见这个 Issue #51，默认 1*1024*1024。
            chunkSize: props.sliceSize * 1024 * 1024,
            // 强制所有块小于或等于 chunkSize。否则，最后一个块将大于或等于chunkSize。(默认: false)
            forceChunkSize: true,
            // 包含在带有数据的多部分 POST 中的额外参数。这可以是一个对象或一个函数。如果是一个函数，
            // 它将被传递一个 Uploader.File、一个 Uploader.Chunk 对象和一个 isTest 布尔值（默认值{}：）
            query: function (file, chunkFile) {
                return {'partNumber': chunkFile.offset + 1};
            },
            uploadMethod: 'PUT',
            //  当上传的时候所使用的是方式，可选 multipart、octet，默认 multipart，参考 multipart vs octet。
            // MiniO 的分片不能使用表单
            method: 'octet',
            //  处理请求参数，默认 function (params) {return params}，一般用于修改参数名字或者删除参数。0.5.2版本后，
            //  processParams一定要设置为空对象（此处针对minion上传，否则参数过多，亚马逊上传接口认为恶意攻击，上传失败）
            processParams: function () {
                return {};
            }
        })
        let attrs = reactive({
            accept: props.accept
        })
        let statusText = reactive({
            success: '成功了',
            error: '出错了',
            uploading: '上传中',
            paused: '暂停中',
            waiting: '等待中'
        })
        const fileComplete = function () {
            context.emit("fileComplete", arguments);
            // ElMessage.success("文件上传成功");
        }
        // 触发手动上传 外部使用ref方式调用
        const submitUploadEvent = function () {
            bigUploaderRef.value.uploader.resume();
        }

        // 添加文件事件处理
        const fileAddedEvent = function (file) {
            getChunkUploadUrl(file);
        }
        // 获取分片上传url
        const getChunkUploadUrl = async function (file) {
            file.chunkUrlData = await requestInterface.requestGet("api-file/createMultipartUpload", {
                fileName: file.name,
                chunkSize: file.chunks.length,
                bucketName: props.bucketName,
                contentType: file.contentType
            });
        }
        // 上传单个文件成功-合并分片
        const fileSuccessEvent = function (rootFile, file) {
            requestInterface.requestGet("api-file/completeMultipartUpload", {
                objectName: file.name,
                uploadId: file.chunkUrlData.uploadId,
                bucketName: props.bucketName
            }).then(function (res) {
                saveInfoToDatabase(file);
                return res
            });
        }
        // 保存信息到数据库
        const saveInfoToDatabase = async function (file) {
            const formData = new FormData();
            formData.append('fileName', file.name);
            formData.append('contentType', file.fileType);
            formData.append('size', file.size);
            formData.append('bucketName', props.bucketName);
            let res = await requestInterface.requestUploadFile("api-file/saveFileInfo", formData);
            //保存文件在对应数据
            if (res) {
                context.emit("getBigFileInfo", {...res})
                let params = {
                    fileId : res.id,
                    fileName : res.name,
                    fileNature : res.source,
                    filePath : res.path,
                    fileType : res.contentType,
                    bucketName : props.bucketName,
                    fileSize: Number(res.size),
                }
                if (props.addData){
                    let file = await requestInterface.bigfileUpload(params)
                    if (file['resp_code'] === 0){
                        ElMessage({
                            message: file['resp_msg'],
                            type: 'success',
                        })
                    }else {
                        ElMessage({
                            message: file['resp_msg'],
                            type: 'error',
                            duration:"30000",
                            showClose: true,
                        })
                    }
                }
            }
        }

        return {
            options,
            attrs,
            statusText,
            bigUploaderRef,
            fileComplete,
            submitUploadEvent,
            fileAddedEvent,
            fileSuccessEvent
        }
    }
}
</script>

<style lang="scss">
.big-file-upload-component {
    width: 100%;

    .uploader-drop {
        padding: 40px;
    }
}
</style>
