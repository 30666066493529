<template>
    <div class="pagination-component">
        <el-pagination background v-if="!pageSizes"
                       hide-on-single-page
                       :total="total"
                       :page-size="pageSize"
                       :pager-count="pagerCount"
                       layout="total, prev, pager, next"
                       @current-change="currentChange"
        />
        <el-pagination background
                       v-else
                       hide-on-single-page
                       :total="total"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="pageSize"
                       :pager-count="pagerCount"
                       layout="total, prev, pager, next,sizes"
                       @current-change="currentChange"
                       @size-change="sizesChange"
        />
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "pagination-component",
    props: {
        total: {
            type: Number,
            default: 100
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageSizes: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        let pagerCount = ref(5);
        const currentChange = size => {
            context.emit("currentChange", size);
        }
        const sizesChange = (val) => {
            context.emit("sizesChange", val);
        }
        return {
            pagerCount,
            currentChange,
            sizesChange
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.pagination-component {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .el-pagination {
        &.is-background {
            button,
            .el-pager li {
                min-width: pxToRem(60);
            }

            .el-pagination__total {
                @include color($fontColorTwo)
            }

            button {
                margin: 0;
                box-sizing: border-box;
                @include bgColor(white);
                border-radius: pxToRem(4);

                &.btn-prev {
                    border: pxToRem(1) solid rgba(153, 153, 153, 0.6);
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: 0;
                }

                &.btn-next {
                    border: pxToRem(1) solid rgba(153, 153, 153, 0.6);
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            .el-pager {

                li {
                    margin: 0;
                    border-radius: 0;
                    box-sizing: border-box;
                    border: pxToRem(1) solid rgba(153, 153, 153, 0.6);
                    border-right: 0;
                    //@include bgColor(white);

                    &.is-active {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
</style>
