import commonMethods from "archaeometry-common-components/src/js/common-methods";
import {ElMessage} from "element-plus";
import {plantDetailsApi} from "../../../../api/data-linkage-details-api";

const commonMethodsPlant = {
    // 搜索获取下拉列表内容
    fetchSuggestions(val, dataList) {
        let list
        val = val === "null" ? false : val;
        if (val) {
            list = this.editQuery(dataList, val)
        } else {
            list = dataList;
        }
        return list
    },
    editQuery(dataList, val) {
        let resultList = [];
        for (const item of dataList) {
            if (item.ruinsName || item.name || item.latinName) {
                let data = item.ruinsName || item.name || item.latinName;
                if (data.split(val).length > 1) {
                    resultList.push(item);
                }
            }
        }
        return resultList;
    },
    // 判断需不需要添加
    filtrateArr(val, list) {
        let stage = false;
        for (const key of list) {
            if (key.name === val || key.latinName === val) {
                stage = true
            }
        }
        return stage
    },
    // 获取全部参数列表
    async getSystemParamsAll() {
        let result = await plantDetailsApi.getSystemParamsAll();
        return result["datas"]
    },
    // 获取系统参数pid
    getSystemParamsPid(name, list) {
        let pid;
        for (const item of list) {
            if (item.name === name) {
                pid = item['id'];
            }
        }
        return pid
    },
    // 基本信息四大类删除事件
    async deleteHandle(id, type, cb,literature) {
        await commonMethods.deleteDialog();
        let result
        if (literature) {
           result = await plantDetailsApi.deleteLiteratureMeasureData(id, type.lType);
        } else {
            result = await plantDetailsApi.deleteCurrentData(id, type.type);
        }
        if (result["resp_code"] === 0) {
            ElMessage.success(`${type.name}信息删除成功`);
            await cb();
        } else {
            ElMessage.error(result["resp_msg"])
        }
    },
    // 图像信息删除事件
    async deleteImageData(id, cb, type) {
        let result
        if (type) {
            result = await plantDetailsApi.deleteThreeData(id);
        } else {
            result = await plantDetailsApi.deleteImageData(id);
        }
        if (result["resp_code"] === 0) {
            ElMessage.success(`${type ? "三维" : "图像"}信息删除成功`);
            await cb();
        } else {
            ElMessage.error(result["resp_msg"])
        }
    },
    // 获取背景信息类型
    getBgInfoType(type) {
        let bgType;
        let list = [  {name: '单位信息', value: 'ruinsInfo', param: "unitId", backgroundType: "1", key: '遗迹单位'},
            {name: '剖面信息', value: 'profile', param: "profileId", backgroundType: "2", key: "剖面采集"},];
        for (const item of list) {
            if (item.backgroundType === type) {
                bgType = item
            }
        }
        return bgType
    },
    getBasicType(type) {
        let basicType;
        let list = [
                {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", imgType: "carbonized", sampleType: "1"},
                {name: '植硅体', value: 'phytolith', type: "phytolith", imgType: "phytolith", sampleType: "2"},
                {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", imgType: "starch", sampleType: "3"},
                {name: '木炭', value: 'charcoal', type: "charcoal", imgType: "charcoal", sampleType: "4"},
            ]
        for (const item of list) {
            if (item.name === type) {
                basicType = item
            }
        }
        return basicType;
    },
    // 获取导航栏url参数
    getUrlBgParams(query) {
        let {ruinsId, bgType, unitId, profileId, residueId, bgId} = query;
        return {
            ruinsId, backgroundType: bgType, backgroundId: unitId || profileId || residueId || bgId
        }
    },
    getImageCurrentNav(nav) {
        let current;
        let list = [
            {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", imgType: "carbonized", sampleType: "1"},
            {name: '植硅体', value: 'phytolith', type: "phytolith", imgType: "phytolith", sampleType: "2"},
            {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", imgType: "starch", sampleType: "3"},
            {name: '木炭', value: 'charcoal', type: "charcoal", imgType: "charcoal", sampleType: "4"},
        ];
        for (const item of list) {
            if (item.value === nav) {
                current = item
            }
        }
        return current
    },
    async findByIdRuins(id, list,) {
        let result = await plantDetailsApi.getIdFindRuins(id);
        result.administrative = commonMethods.getAdministrativeDivision(result)

        for (const form of list) {
            for (const item in result) {
                if (form.key === item) {
                    form.value = result[item]
                }
            }
        }
        return list
    },
    // 获取文献信息
    async getLiteratureValue(id, info) {
        let result = await plantDetailsApi.getResourceDataList({id}, "documentId");
        for (const item of info) {
            for (const key in result) {
                if (item.key === key) {
                    item.value = result[key]
                }
            }
        }
    },
    // 文献下样本删除
    async deleteLiteratureSample(id, type, cb) {
        let stage = true
        if (type.sampleType === "2") {
            stage = false
        }
        await commonMethods.deleteDialog();
        let result = await plantDetailsApi.deleteLiteratureSample(id, stage);
        if (result["resp_code"] === 0) {
            ElMessage.success(`${type.name}信息删除成功`);
            await cb();
        } else {
            ElMessage.error(result["resp_msg"])
        }

    },
    getCurrentSampleType(val) {
        let list = [
            {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", imgType: "carbonized", sampleType: "1"},
            {name: '植硅体', value: 'phytolith', type: "phytolith", imgType: "phytolith", sampleType: "2"},
            {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", imgType: "starch", sampleType: "3"},
            {name: '木炭', value: 'charcoal', type: "charcoal", imgType: "charcoal", sampleType: "4"},
        ];
        for (const key of list) {
            if (key.sampleType === val) {
                return key;
            }
        }
    },
    getBgInfo(query) {
        const {type, ruinsId, unitId, profileId, residueId} = query;
        let bgInfo = {ruinsId};
        if (type === "unit" && unitId) {
            bgInfo.backgroundType = "1";
            bgInfo.backgroundId = unitId
        } else if (type === "profile" && profileId) {
            bgInfo.backgroundType = "2";
            bgInfo.backgroundId = profileId
        } else if (type === "residue" && residueId) {
            bgInfo.backgroundType = "3";
            bgInfo.backgroundId = residueId
        }
        return bgInfo
    }
}

export default commonMethodsPlant;
