import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "animate.css"
/* 引入公共组件模块*/
import ArchCommonComponents from "archaeometry-common-components/src"
import "archaeometry-common-components/src/scss/common.scss"

const app = createApp(App);
app.use(router);
app.use(store);
app.use(ElementPlus, {locale: zhCn});
app.use(ArchCommonComponents);
app.mount('#cloudMap')

router.beforeEach((to, from, next) => {
    document.title = process.env.VUE_APP_PROJECT_NAME_ZH + "-" + to.meta.title
    // control-panel.log(to)
    // control-panel.log(from)
    // control-panel.log(next)
    let main_Box = document.querySelector(".main_container")
    main_Box.scrollTo(0, 0)
    next();
})
