<template>
    <div class="preview-file-module">
        <div class="preview_head" v-if="visibleHead">
            <h3>{{ filename }}</h3>
            <div class="toolbar_section">
                <span @click="downloadEvent">
                    <el-icon><Download/></el-icon>
                </span>
            </div>
        </div>
        <template v-if="component">
            <component class="preview_wrapper" :is="component" :file-url="url" :filename="filename"/>
        </template>
        <div v-else class="preview_wrapper no_preview">
            <div class="preview_container">
                <el-empty description="暂不支持该文件格式在线浏览"/>
            </div>
        </div>
    </div>
</template>

<script>
import {useRoute} from "vue-router";
import {defineComponent, onMounted, ref} from "vue";
import {Download} from '@element-plus/icons-vue'
import PreviewFileMethod from "./preview-file-method";
import commonMethods from "@acc/js/common-methods";
import PreviewFilePdf from "./preview-file-pdf";

export default defineComponent({
    name: "preview-file-module",
    components: {PreviewFilePdf},
    setup() {
        const route = useRoute();
        const {fileUrl, type} = route.query;
        const component = ref(null);
        const url = ref(null);
        const filename = ref(null);
        const method = new PreviewFileMethod();
        const visibleHead = ref(false);
        const downloadEvent = () => {
            commonMethods.downloadFiles(url.value, filename.value)
        }
        onMounted(() => {
            const encodeUrl = atob(fileUrl);
            url.value = decodeURIComponent(encodeUrl);
            component.value = method.getSuffixByComponent(type);
            const split = url.value.split("/");
            filename.value = split[split.length - 1];
            visibleHead.value = type !== "pdf";
            const element = document.querySelector(".preview-file-module");
            if (element) {
                element.style.setProperty("--office-color", method.previewColor(type));
            }
        });

        return {
            visibleHead,
            component,
            url,
            filename,
            Download,
            downloadEvent,
        }
    }
})


</script>

<style lang="scss">
@import "./preview-file-module";
</style>
