<template>
    <div class="tab-head-component default_page_style">
        <slot/>
        <div class="to-back-component" v-if="showBack" @click="backHandle">
            <span>返回</span>
            <el-icon>
                <arrow-left-bold/>
            </el-icon>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import {useRouter, useRoute} from 'vue-router'
import {ArrowLeftBold} from '@element-plus/icons-vue'

export default defineComponent({
    name: "tab-head-component",
    props: {
        showBack: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: null
        }
    },
    components: {ArrowLeftBold},
    setup(props) {
        let router = useRouter();
        let route = useRoute();
        // 返回上一步
        const backHandle = () => {
            let query = {};
            for (const match of route.matched) {
                if (match.name===props.name) {
                    query = match.query
                }
            }
            router.push({name: props.name, query})
        }

        return {
            backHandle
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.tab-head-component {
    @include bgColor(white);
    height: pxToRem(70);
    display: flex;
    align-items: center;
    margin-bottom: pxToRem(20);
    box-sizing: border-box;

    //> div {
    //    margin-left: pxToRem(40);
    //}

}
</style>