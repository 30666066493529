<template>
    <div class="carbon-details-component">
        <breadcrumb-component max showBack/>
        <div class="default_page_style default_wrapper">
            <!-- 背景信息-->
            <div class="background-info default_space_style">
                <module-head-component title="背景信息"/>
                <info-text-ul-component :data="backgroundData" :span="6"/>
            </div>
            <!-- 实验数据-->
            <div class="experiment-data default_space_style">
                <module-head-component title="实验数据"/>
                <div class="protogenesis-table-custom-style">
                    <table v-show="relevancyData.length > 0" align="center" width="100%">
                        <tr v-for="(item, i) in relevancyData" :key="i" class="basic-content-table-tr">
                            <template v-for="(item2, i) in item" :key="i">
                                <td class="basic-content-table-td-title">
                                    <span>{{ item2.title }}</span></td>
                                <td class="basic-content-table-td-body">
                                    <div v-html="item2.value"></div>
                                </td>
                            </template>
                        </tr>

                        <tr class="basic-content-table-tr" style="width: 100%;">
                            <td class="basic-content-table-td-title">
                                <span>备注：</span></td>
                            <td class="basic-content-table-td-body" colspan="5">
                                <div v-html="sampleRemark"></div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref,} from "vue";
import InfoTextUlComponent from "./component/info-text-ul-component";
import {useRoute} from "vue-router";
import commonMethods from "../../../js/common-methods";
import {carbonDetailsApi} from "../../../api/data-linkage-details-api";

export default defineComponent({
    name: "carbon-details-component",
    components: {InfoTextUlComponent},
    setup() {
        let route = useRoute();
        let backgroundData = ref([
            {name: 'ruinsName', title: '遗址名称', value: null},
            {name: 'division', title: '行政区划', value: null},
            {name: 'ruinsUnitNumber', title: '遗址单位号', value: null},
            {name: 'longitudeStr', title: '经度', value: null},
            {name: 'latitudeStr', title: '纬度', value: null},
            {name: 'altitude', title: '海拔', value: null},
            {name: 'ruinsSubarea', title: '遗址分区', value: null},
            {name: 'culturalPeriod', title: '文化分期', value: null},
            {name: 'digYear', title: '发掘年份', value: null},
            {name: 'exploreSquareNumber', title: '探方号', value: null},
            {name: 'stratumGradation', title: '层位', value: null},
            {name: 'relicNumber', title: '遗迹单位号', value: null},
            {name: 'relicGradation', title: '堆积号', value: null},
            {name: "sampleCode", title: '送检号', value: null},
            {name: 'unitCode', title: '出土单位号', value: null},
            {name: 'remark', title: '备注', value: null},
            {name: 'literatureList', title: '样本信息来源', value: null},
        ]);
        // 实验数据
        let relevancyData = ref([])

        let dataList = ref([
            {name: "labCode", title: '实验室编号：', value: null},
            {name: "labName", title: '检测机构：', value: null},
            {name: "orgSampleCode", title: '样本原编号：', value: null},
            {name: "sampleTypeName", title: '样品类型：', value: null},
            {name: "sampleMaterial", title: '样品物质：', value: null},
            {name: "collectTime", title: '样品采集时间：', value: null},
            {name: "carbonFourteenDatingMethodName", title: '碳十四测年方法：', value: null},
            {name: "carbonYear", title: '碳十四年代（BP）：', value: null},
            {name: "error", title: '误差（±）：', value: null},
            {name: "carbonThirteenCheckStatus", title: '是否碳十三校正：', value: null},
            {name: "carbonThirteenCheckMethodName", title: '碳十三校正方法：', value: null},
            {name: "independentMeasure", title: '碳十三测量数据：', value: null},
            {name: "halfTimeName", title: '半衰期：', value: null},
            {name: "calendarYearRangeOne", title: '日历年代校正范围(1σ)：', value: null},
            {name: "calendarYearRangeTwo", title: '日历年代校正范围(2σ)：', value: null},
            {name: "correctCurveVersion", title: '校正曲线版本：', value: null},
            {name: "correctSoftVersion", title: '校正软件及版本：', value: null},
            {name: "sampleAmountName", title: '样品量：', value: null},
        ])
        // 获取样本信息
        let sampleRemark = ref(null);
        const getSampleInfo = async function () {
            let result = await carbonDetailsApi.getSampleInfo({id: route.query.sampleId ? route.query.sampleId : route.query.id});
            if (result["resp_code"] === 0) {
                const {ruins, ruinsInfo, sampleLiterature, sample} = result["datas"];
                // 添加背景信息
                ruins.division = commonMethods.getAdministrativeDivision(ruins);
                if (sampleLiterature.length > 0 && sample.dataSources === '1') {
                    let literature = [];
                    for (const item in sampleLiterature) {
                        literature.push(sampleLiterature[item].documentName);
                    }
                    ruins.literatureList = literature.join(",");
                } else {
                    ruins.literatureList = sample.dataSources === '0' ? '原始数据' : '文献采集'
                }
                let bgData = backgroundData.value;
                for (const bg of bgData) {
                    for (const key in ruinsInfo) {
                        if (bg.name === key) {
                            bg.value = ruinsInfo[key]
                        }
                    }
                    for (const key in ruins) {
                        if (bg.name === key) {
                            bg.value = ruins[key]
                        }
                    }

                    if (bg.name === 'sampleCode') {
                        for (const key in sample) {
                            if (key === bg.name) {
                                bg.value = sample[key]
                            }
                        }
                    }
                }

                //     添加实验信息
                let relevancyList = dataList.value
                sample.collectTime = sample.collectTime ? commonMethods.momentFormat(sample.collectTime) : null;
                for (const list of relevancyList) {
                    for (const key in sample) {
                        if (list.name === key) {
                            list.value = sample[key]
                            if (list.name === 'calendarYearRangeOne' || list.name === 'calendarYearRangeTwo'){
                                list.value = commonMethods.filterInfo(sample[key])
                            }
                        }
                        // 碳十三校正
                        if (key === "carbonThirteenCheckStatus" && sample[key] && list.name === "carbonThirteenCheckStatus") {
                            list.value = sample[key] === "1" ? "是" : "否"
                        }
                    }
                }
                sampleRemark.value = commonMethods.filterInfo(sample.remark);
                relevancyData.value = commonMethods.arraySlice(relevancyList, 3);
            }
        }

        onMounted(() => {
            let matched = route.matched;
            matched[matched.length - 1].meta.title = route.query.title;
            getSampleInfo()
        })


        return {
            backgroundData,
            relevancyData,
            sampleRemark
        }
    }
})
</script>

<style lang="scss">
@import "detail";
</style>