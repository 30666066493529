import requestInterface from "../js/request-interface";

const literatureManageApi = {
    /**
     * 获取文献数据列表
     * @param params
     * @returns {*}
     */
    getDataList(params) {
        return requestInterface.requestGet("literature-center/document/page", params,false)
    },
    /**
     * 发布文献
     * @param formData
     * @returns {*}
     */
    publishLiterature(formData) {
        return requestInterface.requestUploadFile("literature-center/document/document/save", formData)
    },
    /**
     * 修改文献
     * @param formData
     * @returns {*}
     */
    editLiterature(formData) {
        return requestInterface.requestUploadFile("literature-center/document/document/update", formData)
    },
    /**
     * 获取文献详情
     * @param params
     * @returns {*}
     */
    getLiteratureDetail(params) {
        return requestInterface.requestGet("literature-center/document/documentId", params);
    },
    /**
     * 删除文献
     * @param id
     */
    deleteLiterature(id) {
        return requestInterface.requestDelete(`literature-center/document/document/${id}`);
    },

    // 获取文件查看地址
    getFilePath(){
        return requestInterface.requestGet("plant-arch/minio/url")
    }
}
export default literatureManageApi;
