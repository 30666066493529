import { createStore } from 'vuex'
import {dataCenterApi} from "@/api/platform-management-api";
import {collectionApi} from "@/api/sample-manage";

export default createStore({
  state: {
    //根code列表
    codeList: {},
  },
  getters: {},
  mutations: {
    changeCodeList(state, value) {
      state.codeList = value
    }
  },
  actions: {
    visibleBaseMapHandle(context, payload) {
      context.commit('visibleBaseMapHandle', payload);
    },
    async setCodeList(content) {
      let result = await dataCenterApi.getCodeList();
      content.commit("changeCodeList", result)
    },
    async setPlantCodeList(content) {
      let result = await collectionApi.getCodeList();
      content.commit("changeCodeList", result)
    }
  },
  modules: {}
})
