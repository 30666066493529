<template>
    <el-space :size="size" :spacer="spacer">
        <slot/>
    </el-space>
</template>

<script>
import {defineComponent, h} from "vue";
import {ElDivider} from "element-plus";

export default defineComponent({
    name: "common-space-vertical",
    props: {
        size: {
            type: Number,
            default: 5
        }
    },
    setup() {
        const spacer = h(ElDivider, {direction: 'vertical'})
        return {
            spacer
        }
    }
})
</script>

<style scoped>

</style>
