<template>
    <div class="data-sharing-dialog-component">
        <dialog-component :visible="visible"
                          title="数据共享"
                          @confirm-handle="confirmDialog"
                          @cancel-handle="cancelDialog">

            <el-form size="large">
                <el-form-item label="数据分享给：">
                    <el-select v-model="userId" clearable>
                        <el-option v-for="item in tableData"
                                   :key="item.id"
                                   :label="`${item.nickname} (${item.username})`"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </dialog-component>
    </div>
</template>

<script>
import {defineComponent, ref, watchEffect} from "vue";
import {userManageApi} from "archaeometry-common-components/src/api/database-devops-api";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "data-sharing-dialog-component",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        loginId: {
            type: String,
            default: null
        }
    },
    setup(props, context) {
        // 数据共享弹窗确定事件处理
        const confirmDialog = async function () {
            if (userId.value) {
                context.emit("sharedConfirmHandle", userId.value);
            } else {
                ElMessage({
                    message: '请先选择需要共享的用户',
                    type: 'warning',
                })
            }
        }

        // 数据共享弹窗取消事件处理
        const cancelDialog = () => {
            context.emit("cancelHandle");
        }
        // 用户列表
        let tableData = ref([]);
        let userId = ref(null)

        // 获取用户列表
        const getUserList = async function () {
            let result = await userManageApi.getUserList(null);
            let data = result.data;
            for (let i = 0; i < data.length; i++) {
                if (data[i].id === Number(props.loginId)) {
                    data.splice(i, 1)
                }
            }
            tableData.value = data;
        }

        watchEffect(function () {
            let visible = props.visible
            if (visible) {
                getUserList()
            }
        })

        return {
            confirmDialog,
            cancelDialog,
            userId,
            tableData
        }
    }
})
</script>

<style scoped>

</style>