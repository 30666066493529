<template>
    <div :class="plantClass ? plantClass : 'attribute-query-component'">
        <h3>属性查询</h3>
        <div class="attr_query">
            <slot slot="attr-query"/>
            <div class="query_btn_group">
                <el-button @click="resetEvent">重置</el-button>
                <el-button type="primary" @click="queryEvent">查询</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {inject, ref, watchEffect} from "vue";

export default {
    name: "attribute-query-component",
    setup() {
        let attrQueryAnimalRef = ref(null);
        // let attrQueryForm = inject("attrQueryForm");
        let attrResetForm = inject("attrResetForm");
        let queryEventDataList = inject("queryEvent");

        // 查询
        const queryEvent = function () {
            if (process.env.VUE_APP_MARKING === "2") {
                queryEventDataList("plant");
            } else {
                queryEventDataList();
            }
        }
        // 重置
        const resetEvent = function () {
            attrResetForm();
            queryEvent();
        }
        let plantClass = ref(null)

        watchEffect(function () {
            if (process.env.VUE_APP_MARKING === '2' || process.env.VUE_APP_MARKING === '1'){
                plantClass.value = 'plant-query-component'
            }
        })
        return {
            attrQueryAnimalRef,
            queryEvent,
            resetEvent,
            plantClass
        }
    }
}
</script>

<style lang="scss">
@import "~@acc/scss/commonFunc";

.attribute-query-component {
    @include bgColor(white);
    overflow-y: scroll;
    .attr_query {
        padding: pxToRem(15);
        .query_btn_group {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: pxToRem(1366))  {
        height: pxToRem(340);
        overflow-y: scroll;
    }
}
.plant-query-component {
    @include bgColor(white);
    height: pxToRem(520);
    overflow-y: scroll;
    .attr_query {
        padding: pxToRem(15);
        .query_btn_group {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    @media screen and (max-width: pxToRem(1366))  {
        height: pxToRem(450);
        overflow-y: scroll;
    }
}
</style>
