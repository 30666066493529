import VueCookies from 'vue-cookies'
import {ElMessage, ElMessageBox} from "element-plus";
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'
import moment from "moment";

const {get, set, keys, isKey} = VueCookies;

const commonMethods = {
    options: {
        gaoDeKey: "43208269b0e20cb94e662979be5d85a8",
        noPicture: require("../images/noPicture.jpg"),
    },
    // 获取cookie
    getCookie(key) {
        return get(key);
    },
    // 获取全部cookie
    getAllCookie() {
        let keyObj = {};
        for (const key of keys()) {
            keyObj[key] = this.getCookie(key);
        }
        return keyObj;
    },
    /**
     * 设置cookie
     * 对象格式  key:value
     * @param obj
     */
    setCookies(obj = {}) {
        for (const objKey in obj) {
            set(objKey, obj[objKey]);
        }
    },
    // 删除cookie
    removeCookie(key) {
        if (isKey(key)) {
            VueCookies['remove'](key);
        }
    },
    // 删除所有cookie
    removeAllCookie() {
        for (const key of keys()) {
            this.removeCookie(key);
        }
    },
    // RSA 加密
    RSAEncrypt(val) {
        // let result = await requestInterface.requestGet("carbon-arch/rsa/pwdencrypt", {password: val});
        // return result["resp_msg"]
        return RSAUtils.encryptedString(RSAUtils.publicKey, val)
    },
    /**
     * 格式化时间
     * @param time
     * @param format
     */
    momentFormat(time, format = "YYYY-MM-DD") {
        return moment(time).format(format)
    },
    /**
     * 获取数组最后一项
     * @param arr
     */
    getArrayLast(arr) {
        return arr.slice(-1)[0];
    },
    /**
     * 获取图片路径
     * @param item
     * @param type
     */
    getImgUrl(item, type) {
        if (type) {
            // 高清图
            return this.getCookie("moUrl") + "/" + item["imageUrl"]
        } else {
            // 缩略图
            return this.getCookie("moUrl") + "/" + (item["thumPath"] || item["filePath"] || item["thumUrl"] || item)
        }
    },
    /**
     * 过滤信息
     * @param content
     * @param size
     */
    filterInfo(content, size) {
        let html = '';
        if (content) {
            let infoArr = content.split(/[\n\r]/g);
            for (let i = 0; i < infoArr.length; i++) {
                // let text = filterArr[i].replace(/\s+/g, '');
                let text = infoArr[i];
                if (size && infoArr.length > size) {
                    if (i < size) {
                        if (text) {
                            html += `<p>${text}${(i + 1 === size ? '...' : '')}</p>`;
                        }
                    }
                } else {
                    if (text) {
                        html += `<p>${text}</p>`;
                    }
                }
            }
        }
        return html;
    },
    /**
     * 字符串截取
     * @param info
     * @param num
     * @returns {string|*}
     */
    fontSplit(info, num) {
        if (info.length > num) {
            return info.substr(0, num) + '...';
        } else {
            return info;
        }
    },
    /**
     * 切割数组，把一个数据分割成多个组
     * @param array
     * @param subGroupLength
     * @returns {[]}
     */
    arraySlice(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while (index < array.length) {
            newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
    },
    getObjectURL(file) {
        let url = null;
        if (window.createObjectURL !== undefined) { // basic
            url = window.createObjectURL(file);
        } else if (window.URL !== undefined) { // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL !== undefined) { // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        return url;
    },
    /**
     * 生成UUID
     * @returns {string}
     */
    randomUUID() {
        let s = [];
        let hexDigits = '0123456789aBcDeF';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
    },
    // 删除弹窗
    deleteDialog(params = {}) {
        params = {
            title: "删除",
            text: "确定删除这条数据吗?",
            type: "error",
            ...params
        }
        return new Promise(resolve => {
            ElMessageBox.confirm(params.text, params.title, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: params.type,
                customClass: "custom_delete_message_box"
            }).then(() => {
                // if (/删除/.test(params.title)) {
                // } else {
                //     resolve()
                // }
                resolve()
            }).catch(() => ElMessage.info({message: "您取消了操作"}))
        })
    },
    /**
     * 设置历史参数到localStorage
     * @param name
     * @param query
     */
    setHashQueryToLocalStorage({name, query}) {
        localStorage.setItem(name, JSON.stringify(query || {}));
    },
    /**
     * 根据name获取历史参数
     * @param {string} key
     */
    getNameByHashQuery(key) {
        let query = localStorage.getItem(key);
        return JSON.parse(query)
    },
    //	查看单个大图
    showSingleMaxImg(e) {
        const src = e.target.src;
        const obj = [{
            name: src,
            url: src,
            thumb: src
        }];
        this.viewerPicture(obj);
    },
    //	查看大图列表
    showMaxImgList(imgList, index = 0) {
        let obj = [];
        for (const img of imgList) {
            obj.push({
                name: img,
                url: img,
                thumb: img
            })
        }
        this.viewerPicture(obj, index);
    },
    /**
     * 查看大图-文件列表预览
     * @param fileList
     * @param index
     */
    showMaxImgListToFile(fileList, index) {
        let obj = [];
        fileList.map(file => {
            let src = this.getObjectURL(file);
            obj.push({
                name: file.name,
                url: src,
                thumb: src
            });
        });
        this.viewerPicture(obj, index);
    },
    viewerPicture(imgList, index = 0) {
        let element = document.createElement("div");
        let elementUl = document.createElement("ul");
        for (const img of imgList) {
            let elementLi = document.createElement("li");
            let elementImg = document.createElement("img");
            elementImg.setAttribute("data-original", img.url);
            elementImg.setAttribute("src", img.thumb);
            elementImg.setAttribute("alt", img.name);
            elementLi.append(elementImg);
            elementUl.append(elementLi);
        }
        element.append(elementUl);
        document.body.append(element);
        const viewer = new Viewer(element, {
            url: "data-original",
            zIndex: 99999,
            fullscreen: false,
            hide() {
                viewer.destroy();
                element.remove();
            }
        });
        if (index) {
            viewer.index = index;
            viewer.show();
        } else {
            viewer.show();
        }
    },
    /**
     * 首字母转大写
     * @param str
     */
    initialToUpperCase(str) {
        let splits = str.split(" ");
        for (let i = 0; i < splits.length; i++) {
            splits[i] = splits[i].charAt(0).toUpperCase() + splits[i].slice(1);
            str = splits.join(" ");
        }
        return str;
    },
    /**
     * 数组去重
     * @param arr
     * @returns {*}
     */
    uniq(arr) {
        let obj = {};
        for (const data of arr) {
            obj[data.id] = data;
        }
        return Object.values(obj);
    },
    /**
     * 文件数组去重
     * @returns {*}
     * @param fileList
     */
    uniqFile(fileList) {
        let fileObj = {};
        for (const file of fileList) {
            fileObj[`${file.name}-${file.size}`] = file;
        }
        return Object.values(fileObj);
    },
    /**
     * 下载文件
     * 默认会打开  而不是下载，下载需要配合后端
     * @param href
     * @param filename
     * @param suffix
     */
    downloadFile(href, filename, suffix = "pdf") {
        let element = document.createElement("a");
        element.href = href;
        element.target = "_blank";
        element.download = (filename || String(new Date().getTime())) + "." + suffix;
        document.body.append(element);
        element.click();
        element.remove();
    },
    /**
     * 结果信息提示
     * @param result
     * @param callback
     * @param text
     * @param defaultText
     */
    message(result, callback, text = "删除", defaultText) {
        let msg = result['resp_msg'];
        msg = msg ? msg.trim() : msg;
        if (result['resp_code'] === 0) {
            ElMessage.success(text + (defaultText ? defaultText : "成功"));
            if (callback) callback();
        } else {
            ElMessage.error(text + (defaultText ? defaultText : "失败") + (msg ? "，" + msg : null));
        }
    },
    /**
     * 坐标转度分秒
     * 小数点前的是度°，组要转换的是小数点后面的。
     * 转分 小数后面的乘以60，取整就是分
     * 转秒 用转分的小数乘以60，小数部分就是秒
     * @param coordinates
     * @param unit
     * @returns {string}
     */
    coordinatesConvertDegreeMinuteSecond(coordinates, unit = "en") {
        let unitOpt = {
            zh: {
                degree: "度",
                minute: "分",
                second: "秒"
            },
            en: {
                degree: "°",
                minute: "′",
                second: "″"
            }
        }
        // 坐标字符串转数组
        const coords = String(coordinates).split('.');

        // 度  获取数组第一位
        const degree = coords[0];

        // 通过数组第二位进行计算生成数组获取分
        const mArr = String((Number(String(0 + '.' + coords[1])) * 60)).split('.');
        // 分 获取数组第一位
        const minute = mArr[0];
        // 秒 通过获取数组第二位进行计算生成秒  截取小数点后两位
        const second = (Number(String(0 + '.' + mArr[1])) * 60).toFixed(2);

        // 结果
        const result = [
            degree + unitOpt[unit].degree,
            minute + unitOpt[unit].minute,
            second + unitOpt[unit].second
        ];

        return result.join('');
    },
    /**
     * 度分秒转坐标
     * 计算公式：度=° + 分=′ / 60 + 秒=″ / 60 / 60
     * @param degree
     * @param minute
     * @param second
     * @param decimal
     */
    degreeMinuteSecondConvertCoordinates(degree, minute, second, decimal = 6) {
        return (degree + (minute / 60) + (second / 60 / 60)).toFixed(decimal);
    },
    /**
     * 度分秒转坐标
     * 计算公式：度=° + 分=′ / 60 + 秒=″ / 60 / 60
     * @param coordinates
     * @param decimal
     */
    degreeMinuteSecondConvertCoordinatesToSplit(coordinates, decimal = 6) {
        const {degree, minute, second} = commonMethods.splitDegree(coordinates);
        return (degree + (minute / 60) + (second / 60 / 60)).toFixed(decimal);
    },
    /**
     * 切割度分秒
     * @param degree
     * @returns {{degree: *, minute: *, second: *}}
     */
    splitDegree(degree) {
        let all = degree.replaceAll(/[^\d.]/g, " ").trim();
        all = all.split(" ");
        return {
            degree: Number(all[0]),
            minute: Number(all[1]),
            second: Number(all[2])
        }
    },
    // 根据数组key获取value
    getObjKeyByValue(objList, key) {
        for (const obj in objList) {
            if (obj === key) return objList[obj];
        }
    },
    // 根据数组value获取key
    getObjValueByKey(objList, value) {
        for (const obj in objList) {
            if (objList[obj] === value) return obj;
        }
    },
    // 构造树状结构
    constructTreeStructure(dataList) {
        // 检查pid是否存在
        function checkPId(dataList, pId) {
            let result = false;
            for (const data of dataList) {
                if (data.id === pId) {
                    result = true;
                    break;
                }
            }
            return result;
        }

        // 递归遍历数据
        const recursionLoop = (dataList, id) => {
            let arr = [];
            for (const data of dataList) {
                if (data.pid === id && data.label) {
                    data.children = this.uniq(recursionLoop(dataList, data.id));
                    arr.push(data)
                } else if (data.pid === id && data.name) {
                    data.children = this.uniq(recursionLoop(dataList, data.id));
                    arr.push(data)
                }
            }
            return arr;
        }

        let resultArr = [];
        for (const data of dataList) {
            let pId = checkPId(dataList, data.pid);
            if (!pId) {
                let loop1 = recursionLoop(dataList, data.pid);
                resultArr.push(...loop1);
            }
        }
        // new Set()去重并返回结果
        return this.uniq(resultArr);
    },
    // 树状结构转普通Json
    treeStructureConstructJson(dataList) {
        let dataArr = [];

        function loop(dataList, arr) {
            for (const data of dataList) {
                dataArr.push(data);
                if (data.children) {
                    loop(data.children, arr);
                }
            }
        }

        loop(dataList, dataArr)
        return dataArr;
    },
    // 根据pId获取父级
    getPIdByParents(dataList, pId) {
        let dataArr = [];

        function loop(dataList, pId) {
            for (const data of dataList) {
                if (data.id === pId) {
                    dataArr.unshift(data);
                    loop(dataList, data.pid);
                }
            }
        }

        loop(dataList, pId);
        return dataArr;
    },
    // 获取数据库类型
    getDatabaseType(dataIndex) {
        let database = "";
        let name = "";
        switch (dataIndex) {
            case "0":
                database = "";
                name = "平台";
                break;
            case "1":
                database = "animal-arch";
                name = "动物";
                break;
            case "2":
                database = "plant-arch";
                name = "植物";
                break;
            case "3":
                database = "dna-arch";
                name = "东亚古DNA";
                break;
            case "4":
                database = "carbon-arch";
                name = "碳十四测年";
                break;
        }
        return {database, name};
    },
    // 获取数据库名称（平台个人中心使用）
    getDatabaseName(dataIndex) {
        let database = "";
        let name = "";
        switch (dataIndex) {
            case "0":
                database = "";
                name = "信息平台";
                break;
            case "1":
                database = "animal-arch";
                name = "动物考古数据库";
                break;
            case "2":
                database = "plant-arch";
                name = "植物考古数据库";
                break;
            case "3":
                database = "dna-arch";
                name = "东亚古DNA数据库";
                break;
            case "4":
                database = "carbon-arch";
                name = "碳十四年代数据库";
                break;
        }
        return {database, name};
    },
    // 获取数据库标识
    getDatabaseMarking() {
        return this.getDatabaseType(process.env.VUE_APP_MARKING).database;
    },
    isEmptyObj(obj) {
        return Object.keys(obj).length === 0
    },
    // 行政区划处理
    getAdministrativeDivision(result = {}) {
        let administrative = []
        if (result["province"]) administrative.push(result.province);
        if (result["city"]) administrative.push(result.city);
        if (result["county"]) administrative.push(result.county);
        administrative = administrative.join("/")
        return administrative
    },
    // 处理坐标经纬度
    setCoordinate(str) {
        if (str) {
            if (str[0] > str[1]) {
                return [str[1], str[0]]
            } else {
                return str
            }
        }
        // if (str[0] > str[1]) {
        //     return [str[1], str[0]]
        // } else {
        //     return str
        // }
    },
    // 构造树形结构目录树
    getTreeData(list) {
        let province = []
        //构造一级目录
        for (const key of list) {
            province.push({label: key.province, children: []})
        }
        province = unique(province)
        for (const item in province) {
            // 构造二级目录
            for (const key of list) {
                if (province[item].label === key.province) {
                    province[item].children.push({label: key.city, children: []})
                }
            }
            province[item].children = unique(province[item].children)

            let two = province[item].children
            // 构造三级目录
            for (const item2 in two) {
                for (const key of list) {
                    if (two[item2].label === key.city) {
                        two[item2].children.push({label: key.county})
                    }
                }
                two[item2].children = unique(two[item2].children)
            }
        }

        function unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.label) && res.set(arr.label, 1))
        }

        return province
    },

    // 构造树形结构目录树
    //这个构造树结构的方法，如果数据量过大会导致系统卡死状态
    constructionTreeData(arr) {
        let list = []
        for (const data of arr) {
            for (const dataKey in data) {
                if (dataKey === "province") {
                    list.push({
                        label: data.province,
                        id: data.province,
                        type: "province",
                        pid: 0,
                    })
                }
                if (dataKey === "city") {
                    list.push({
                        label: data.city,
                        id: data.city,
                        type: "city",
                        pid: data.province
                    })
                }
                if (dataKey === "county") {
                    list.push({
                        label: data.county,
                        id: data.county,
                        type: "county",
                        pid: data.city,
                        province: data.province,
                    })
                }
            }
        }
        return this.constructTreeStructure(list);
    },

    //数字转汉字
    sectionToChinese(section) {
        let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
        let unit = ["十", "百", "千", "万"];
        let num = section % 10;
        let chart = ''
        if (section <= 9) {
            return changeNum[section]
        } else {
            if (num === 0) {
                chart = section === 10 ? unit[0] : `${changeNum[num]}${unit[0]}`
            } else {
                chart = `${unit[0]}${changeNum[num]}`
            }
        }
        return chart
    },
    handleSelectionChange(val, stage) {
        let ids = []
        for (const key of val) {
            if (key.dataBelong === "1") {
                // ElMessage.info("当前选中项中存在他人共享数据，不可进行操作")
                stage = true;
            } else {
                ids.push(key.id)
            }
        }
        return ids
    },
    // 数据库标识(必传参数):动物 1，植物 2，DNA 3，碳十四 4
    getMarkingByName() {
        let marking;
        switch (process.env.VUE_APP_MARKING) {
            case '1':
                marking = "animal"
                break;
            case '2':
                marking = "plant"
                break;
            case '3':
                marking = "dna"
                break;
            case '4':
                marking = "carbon"
                break;
        }
        return marking;
    },

    /**
     * 添加文件到formData中
     * @returns {[]}
     * @param formData formData
     * @param fileList 文件列表
     * @param name 自定义名称
     */
    appendFileToFormData(formData, fileList, name = null) {
        for (const file of fileList) {
            if (name) {
                formData.append(name, file);
            } else {
                formData.append('file', file);
            }
        }
    },
    /**
     * 下载文件
     * @param url
     * @param filename
     */
    downloadFiles(url, filename) {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = (filename || String(+new Date()));
        a.click();
    },
    // 获取文件后缀
    getFileNameSuffix(filename) {
        if (!filename) return;
        return filename.split(".").slice(-1)[0].toLowerCase();
    },
    // 过滤详细信息
    filterDetailInfo(content) {
        let html = '';
        if (content) {
            let infoArr = content.split(/[\n\r]/g);
            for (let i = 0; i < infoArr.length; i++) {
                let text = infoArr[i].replace(/\s+/g, '');
                if (text) {
                    html += `<p>${text}</p>`;
                }
            }
        }
        return html;
    },
    // 支持各种文件预览
    previewPlus(url, path) {
        const suffix = this.getFileNameSuffix(path || url);
        if (["png", "jpg", "jpeg", "gif", "webp"].includes(suffix)) {
            this.showMaxImgList([url]);
        } else {
            window.open(`${location.origin + location.pathname}#/preview-view?fileUrl=${btoa(encodeURIComponent(url))}&type=${suffix}`);
        }
    }
}

export default commonMethods;
