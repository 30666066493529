<template>
    <el-form ref="formRef"
             :label-width="120"
             :model="publishForm"
             :rules="formRules"
             label-position="right"
             size="large">
        <el-row :gutter="60">
            <el-col :span="12">
                <el-form-item label="文献名称：" prop="documentName">
                    <el-input v-model="publishForm.documentName"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="作者：" prop="author">
                    <el-input v-model="publishForm.author"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="出版社：" prop="press">
                    <el-input v-model="publishForm.press" placeholder="例：xxx出版社"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="发表时间：" props="releaseYear">
                    <!--                    <el-date-picker-->
                    <!--                        v-model="publishForm.releaseYear"-->
                    <!--                        label="Pick a date"-->
                    <!--                        placeholder="请选择发布时间"-->
                    <!--                        style="width: 100%"-->
                    <!--                        type="date"-->
                    <!--                        value-format="YYYY-MM-DD"-->
                    <!--                    />-->
                    <el-input v-model="publishForm.releaseYear" placeholder="例：2018年"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="期刊/出版物：" prop="publication">
                    <el-input v-model="publishForm.publication" placeholder="请填写期刊/出版物名称"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="所涉遗址：" prop="involvedRuins">
                    <el-input v-model="publishForm.involvedRuins" placeholder="例：三星堆遗址"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="期：" prop="stage">
                    <el-input v-model="publishForm.stage" placeholder="例：第x期"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="卷：" prop="volume">
                    <el-input v-model="publishForm.volume"
                              placeholder="例：第x卷"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="页码：" prop="pageNumber">
                    <el-input v-model="publishForm.pageNumber" placeholder="例：445-447"/>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="上传类型：">
                    <el-radio-group v-model="visibleUpload">
                        <el-radio :label="true">文件</el-radio>
                        <el-radio :label="false">链接</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col v-if="!visibleUpload" :span="12">
                <el-form-item label="文献链接：" prop="documentLink">
                    <el-input v-model="publishForm.documentLink" placeholder="https://xxxx.pdf"/>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="备注：" prop="remark">
                    <el-input v-model="publishForm.remark" class="remark" type="textarea"/>
                </el-form-item>
            </el-col>
            <el-col v-if="visibleUpload" :span="24">
                <el-form-item class="upload_file" label="上传文件：" prop="file">
                    <regular-file-upload-component
                        ref="uploadRef"
                        :deleteFile="true"
                        accept=".pdf"
                        upload-tip="注：上传文件格式.pdf"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-form-item class="literature_publish_btn_group" label-width="0">
            <el-space :size="30">
                <el-button type="primary" @click="submitEvent">{{ isEdit ? '更新' : '发布' }}</el-button>
                <el-button :disabled="isEdit" @click="resetForm">重置</el-button>
            </el-space>
        </el-form-item>
    </el-form>
</template>

<script>
import {reactive, ref} from "vue";
import literatureManageApi from "../../api/literature-manage-api";
import {RegularFileUploadComponent} from '../../index'
import commonMethods from "../../js/common-methods";

export default {
    name: "literature-publish-form-component",
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: null
        },
        publishCallbackEvent: {
            type: Function,
            default: function () {

            }
        }
    },
    components: {
        RegularFileUploadComponent
    },
    setup(props) {
        let formRef = ref(null);
        // 表单数据
        let publishForm = reactive({
            documentName: null,
            author: null,
            press: null,
            releaseYear: null,
            publication: null,
            volume: null,
            pageNumber: null,
            involvedRuins: null,
            remark: null,
            documentLink: null,
            stage:null,
        });
        // 表单验证规则
        let formRules = ref({
            documentName: [{required: true, message: "请填写文献名称", trigger: 'blur'}],
            documentLink: [{
                required: true, validator: function (rule, value, callback) {
                    if (!/^(http|https):\/\//.test(value)) {
                        return callback(new Error("请填写文献链接"))
                    }
                    return callback();
                }, trigger: 'blur'
            }],
            file: [{
                required: true, validator: function (rule, value, callback) {
                    if (uploadRef.value.fileList.length < 1 && !props.isEdit) {
                        return callback(new Error("请上传文件"));
                    }
                    return callback();
                }
            }]
        });
        // 操作文件组件
        let uploadRef = ref(null);
        // 判断是否为文件上传
        let visibleUpload = ref(true);
        // 提交事件
        const submitEvent = function () {
            formRef.value.validate(async valid => {
                if (valid) {
                    let formData = getFormData();
                    let result;
                    if (props.isEdit) {
                        result = await literatureManageApi.editLiterature(formData);
                    } else {
                        result = await literatureManageApi.publishLiterature(formData);
                    }
                    props.publishCallbackEvent(result);
                }
            })
        }
        // 获取formData数据
        const getFormData = function () {
            let formData = new FormData();
            if (visibleUpload.value) {
                formData.append("file", uploadRef.value.fileList[0]);
            }
            for (const form in publishForm) {
                formData.append(form, publishForm[form]);
            }
            formData.append('publisher',commonMethods.getCookie('real'))
            if (props.isEdit) formData.append("id", props.id);
            return formData;
        }

        // 重置表单
        const resetForm = function () {
            formRef.value.resetFields();
        }

        return {
            publishForm,
            formRules,
            formRef,
            uploadRef,
            visibleUpload,
            submitEvent,
            resetForm
        }
    }
}
</script>

<style lang="scss">
.literature_publish_btn_group {
    text-align: center;
    .el-form-item__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
