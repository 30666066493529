import requestInterface from "../js/request-interface";

// 动物查看详情接口公共组件接口API
const animalDetailsApi = {
    /**
     * 查询样本详情信息
     * @returns {*}
     * @param params
     */
    getSampleInfo(params) {
        return requestInterface.requestGet('animal-arch/sample/find/detailed', params);
    },
    // 获取牙齿磨蚀参数列表
    getToothParamsList() {
        return requestInterface.requestGet("animal-arch/toothParam/list");
    },
    // 获取列表
    getList(type, params) {
        return requestInterface.requestGet(`animal-arch/measure/${type}/list`, params);
    },
    /**
     * 获取牙齿信息列表
     * @returns {*}
     */
    async getTeethInfoList(toothPosition) {
        let result = await requestInterface.requestGet("animal-arch/toothInfo/findPosition", {toothPosition});
        let milkToothUpper = [];    // 左乳齿上排
        let milkToothUnder = [];    // 左乳齿下排
        let permanentToothUpper = [];    // 左恒齿上排
        let permanentToothUnder = [];    // 左恒齿下排
        for (const res of result) {
            let toothUpper = res.milkToothUpper;
            let toothUnder = res.milkToothUnder;
            let under = res.permanentToothUnder;
            let upper = res.permanentToothUpper;
            if (toothUpper) milkToothUpper.push({
                name: substr(toothUpper),
                originName: toothUpper,
                sub: substr(toothUpper, true)
            });
            if (toothUnder) milkToothUnder.push({
                name: substr(toothUnder),
                originName: toothUnder,
                sub: substr(toothUnder, true)
            });
            if (upper) permanentToothUpper.push({
                name: substr(upper),
                originName: upper,
                sub: substr(upper, true)
            });
            if (under) permanentToothUnder.push({
                name: substr(under),
                originName: under,
                sub: substr(under, true)
            });
        }

        // 截取下标
        function substr(name, status) {
            let split = name.split("");
            let numArr = [];
            for (const str of split) {
                if (status) {
                    if (!isNaN(Number(str))) {
                        numArr.push(str);
                    }
                } else {
                    if (isNaN(Number(str))) {
                        numArr.push(str);
                    }
                }
            }
            return numArr.length > 0 ? numArr.join("") : null;
        }

        return {milkToothUpper, milkToothUnder, permanentToothUpper, permanentToothUnder}
    },


};

// 植物查看详情接口公共组件接口API
const plantDetailsApi = {
    /**
     * 查询碳氮同位素检测列表
     * @param params
     * @param type
     * @returns {*}
     */
    getDetectionList(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/list`, params);
    },
    /**
     * 查询图像信息分页列表
     * @returns {*}
     * @param params
     */
    getImageDataList(params) {
        return requestInterface.requestGet("plant-arch/photoInfo/page", params)
    },
    /**
     * 查询基本信息四大类分页列表
     * @returns {*}
     * @param params
     * @param type
     */
    getCurrentDataList(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/page`, params)
    },
    /**
     * 查询三维信息分页列表
     * @returns {*}
     * @param params
     */
    getThreeDataList(params) {
        return requestInterface.requestGet("plant-arch/threeDimensionInfo/page", params)
    },
    /**
     * 查询全部参数
     */
    getSystemParamsAll() {
        return requestInterface.requestGet("plant-arch/dict/list", {pid: 0});
    },
    /**
     * 删除基本信息四大类信息
     * @returns {*}
     * @param id
     * @param type
     */
    deleteLiteratureMeasureData(id, type) {
        return requestInterface.requestGet(`plant-arch/literature/${type}/measure/delete`, {id})
    },
    /**
     * 删除基本信息四大类信息
     * @returns {*}
     * @param id
     * @param type
     */
    deleteCurrentData(id, type) {
        return requestInterface.requestGet(`plant-arch/${type}/delete`, {id})
    },
    /**
     * 删除 三维信息
     * @returns {*}
     * @param id
     */
    deleteThreeData(id) {
        return requestInterface.requestDelete(`plant-arch/threeDimensionInfo/ThreeDimensionInfo/${id}`)
    },
    /**
     * 删除 图像信息
     * @returns {*}
     * @param id
     */
    deleteImageData(id) {
        return requestInterface.requestDelete(`plant-arch/photoInfo/photoInfo/${id}`)
    },
    /**
     * 样本统计删除
     * @returns {*}
     * @param id
     * @param type
     */
    deleteLiteratureSample(id, type,) {
        return requestInterface.requestGet(`plant-arch/literature/${type ? "sample" : "phytolith"}/delete`, {id})
    },
    getIdFindRuins(id) {
        return requestInterface.requestGet(`ruins-arch/ruins/findById`, {id});
    },
    /**
     * 献资源数据分页列表
     * @returns {*}
     * @param params
     * @param type
     */
    getResourceDataList(params, type) {
        return requestInterface.requestGet(`literature-center/document/${type}`, params);
    },
    /**
     * 根据id查询详情
     * @param type
     * @param id
     * @returns {*}
     */
    findByIdGetInfo(type, id) {
        return requestInterface.requestGet(`plant-arch/${type}/find`, {id});
    },
    /**
     * 查询基本信息四大类详细信息
     * @returns {*}
     * @param id
     * @param type
     */
    getCurrentDataInfo(id, type) {
        return requestInterface.requestGet(`plant-arch/${type}/find`, {id})
    },
};
    // dna查看详情接口公共组件接口API
const dnaDetailsApi = {
    /*
     * 根据项目id查询项目DNA数据
     * @param params
    * */
    getDataInfo(id) {
        return requestInterface.requestGet("dna-arch/data/find", {id})
    },
    /*
* 根据id查看遗址信息
* */
    getRuinsInfo(id) {
        return requestInterface.requestGet("ruins-arch/ruins/findById", {id})
    },
    /*
 * 根据id查看遗迹详情信息
 * */
    getRuinsInfoDetails(id) {
        return requestInterface.requestGet("ruins-arch/ruinsInfo/findById", {id})
    },
    //获取大文件列表
    getFilesListData(dataId){
        return requestInterface.requestGet("dna-arch/file/find", {dataId})
    },
};

// 植物查看详情接口公共组件接口API
const carbonDetailsApi = {
    /**
     * 查询样本详情
     * @param params
     * @returns {*}
     */
    getSampleInfo(params) {
        return requestInterface.requestGet("carbon-arch/sample/find", params);
    },
};

const queryListApi = {
    // 获取发掘年份
    getNumberList(type,ruinsName){
        return requestInterface.requestPost(`map-center/ruins/${type}`, ruinsName)
    },
    // 获取发掘年份
    getDigYearList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/digYear", ruinsName)
    },
    // 获取探方号
    getExploreSquareNumberList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/exploreSquareNumber", ruinsName)
    },
    // 获取堆积号
    getRelicGradationList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/relicGradation", ruinsName)
    },
    // 获取遗迹单位号
    getRelicNumberList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/relicNumber", ruinsName)
    },
    // 获取遗址分区号
    getRuinsSubareaList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/ruinsSubarea", ruinsName)
    },
    // 获取遗址单位号
    getRuinsUnitNumberList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/ruinsUnitNumber", ruinsName)
    },
    // 获取层位
    getStratumGradationList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/stratumGradation", ruinsName)
    },
    // 获取文化分期
    getCultureList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/stratumGradation", ruinsName)
    },
    // 获取出土单位号
    getUnitCodeList(ruinsName){
        return requestInterface.requestPost("map-center/ruins/unitCode", ruinsName)
    },
}

export {
    animalDetailsApi,
    plantDetailsApi,
    dnaDetailsApi,
    queryListApi,
    carbonDetailsApi
}