<template>
    <div class="sample-manage-sample-mode-details">
        <breadcrumb-component max showBack/>
        <div class="default_page_style default_wrapper">
            <div class="background-info default_space_style">
                <div class="background-info-left">
                    <carousel-component v-if="imgList.length > 0" :img-list="imgList"/>
                    <div v-else class="image">
                        <img :src="noPicture" alt="">
                    </div>
                </div>
                <div class="background-info-right">
                    <module-head-component title="背景信息"/>
                    <div class="background-content">
                        <info-text-ul-component :data="backgroundData" :span="12"/>
                    </div>
                </div>
            </div>
            <div class="experiment-basic-relevancy-info default_space_style sample-info-style">
                <div class="experiment-info">
                    <module-head-component title="样本信息"/>
                    <div class="info-details-list">
                        <module-panel-component title="基本信息">
                            <basic-info :sampleInfo="sampleInfo" :submitRecordInfo="submitRecordInfo"/>
                        </module-panel-component>
                        <module-panel-component title="骨骼信息">
                            <skeleton-info :sampleInfo="sampleInfo" :submitRecordInfo="submitRecordInfo"/>
                        </module-panel-component>
                        <other-info/>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-backtop :bottom="100" :right="100" target=".main_container"/>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute} from "vue-router";
import InfoTextUlComponent from "./component/sample-mode-details-info/info-text-ul-component";
import commonMethods from "../../../js/common-methods";
import {animalDetailsApi} from "../../../api/data-linkage-details-api";
import OtherInfo from "./details/other-info";
import BasicInfo from "./details/basic-info";
import SkeletonInfo from "./details/skeleton-info";

export default defineComponent({
    name: "animal-details-component",
    components: {InfoTextUlComponent, OtherInfo, BasicInfo, SkeletonInfo},

    setup() {
        let route = useRoute()
        let imgList = ref([]);
        let noPicture = ref(commonMethods.options.noPicture);
        /*背景信息*/
        const backgroundData = ref([
            {name: 'ruinsName', title: '遗址名称', value: null},
            {name: 'division', title: '行政区划', value: null},
            {name: 'ruinsUnitNumber', title: '遗址单位号', value: null},
            {name: 'longitudeStr', title: '经度', value: null},
            {name: 'latitudeStr', title: '纬度', value: null},
            {name: 'altitude', title: '海拔(米)', value: null},
            {name: 'ruinsSubarea', title: '遗址分区', value: null},
            {name: 'culturalPeriod', title: '文化分期', value: null},
            {name: 'digYear', title: '发掘年份', value: null},
            {name: 'exploreSquareNumber', title: '探方号', value: null},
            {name: 'stratumGradation', title: '层位', value: null},
            {name: 'relicNumber', title: '遗迹单位号', value: null},
            {name: 'relicGradation', title: '堆积号', value: null},
            // {name: 'relicType', title: '出土遗迹类型', value: null},
            {name: 'unitCode', title: '出土单位号', value: null},
            {name: 'remark', title: '备注', value: null},
            {name: 'literatureIds', title: '样本信息来源', value: null},
        ])

        // 回到顶部
        const toTop = function () {
            document.documentElement.scrollTop = 0;
            window.scrollTo(0, 0)
        }

        // 样本信息
        let sampleInfo = reactive({});
        // 送检信息
        let submitRecordInfo = ref(null);
        const getSampleInfo = async function () {
            const {id} = route.query
            let result = await animalDetailsApi.getSampleInfo({id});
            if (result["resp_code"] === 0) {
                const {ruins, ruinsInfo, sampleLiterature, photo, sample, submitRecord} = result["datas"]
                for (const key in sample) {
                    sampleInfo[key] = sample[key]
                }
                let recordInfo = []
                for (const item of submitRecord) {
                    recordInfo.push(item['isotopeInfoName'])
                }
                // 送检信息
                submitRecordInfo.value = recordInfo.join(",")

                let bgData = backgroundData.value
                // 背景信息
                for (const form in bgData) {
                    if (bgData[form].name === "division") {
                        bgData[form].value = ruins.province + '/' + ruins.city + '/' + ruins.county
                    }
                    if (bgData[form].name === "literatureIds") {
                        let literatureIds = []
                        if (sampleLiterature.length > 0) {
                            for (const name in sampleLiterature) {
                                literatureIds.push(sampleLiterature[name].documentName)
                            }
                        } else {
                            literatureIds.push(bgData[form].value ? '文献采集' : '原始数据')
                        }
                        bgData[form].value = literatureIds.join(",");
                    }
                    for (const info in ruins) {
                        if (bgData[form].name === info) {
                            bgData[form].value = ruins[info]
                        }
                    }
                    for (const key in ruinsInfo) {
                        if (bgData[form].name === key) {
                            bgData[form].value = ruinsInfo[key]
                        }
                    }
                }

                //    照片
                let fileList = []
                let imageList = []
                for (const img in photo) {
                    fileList.push(photo[img]["fileInfoList"][0])
                }
                for (const file in fileList) {
                    imageList.push(`${commonMethods.getCookie('moUrl')}/${fileList[file].thumPath}`)
                }
                imgList.value = imageList;

            }
        }
        onMounted(function () {
            let matched = route.matched;
            matched[matched.length - 1].meta.title = route.query.title;
            getSampleInfo()
        })
        return {
            imgList,
            backgroundData,
            sampleInfo,
            submitRecordInfo,
            noPicture,
            toTop
        }
    }
})
</script>

<style lang="scss">
@import "details";
</style>