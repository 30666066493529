<template>
    <div class="ruins-mode-component">
        <el-form :model="queryForm" class="filter_section" size="large">
            <el-form-item label="遗址：">
                <el-input v-model="queryForm.ruinsName" placeholder="遗址名称"/>
            </el-form-item>
            <el-form-item label="行政区划：">
                <three-level-linkage ref="districtRef" clearable/>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="queryHandle">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="content_section">
            <div class="default_table_style table_bg">
                <el-table :data="tableData" size="large" stripe>
                    <el-table-column label="序号" type="index" width="100"></el-table-column>
                    <el-table-column label="遗址名称" prop="ruinsName"></el-table-column>
                    <el-table-column label="遗址单位号" prop="ruinsUnitNumber"></el-table-column>
                    <el-table-column label="行政区划" prop="administrative"></el-table-column>
                    <el-table-column label="经度" prop="longitudeStr"></el-table-column>
                    <el-table-column label="纬度" prop="latitudeStr"></el-table-column>
                    <el-table-column label="海拔（米）" prop="altitude"></el-table-column>
                    <slot/>
                </el-table>
            </div>
            <pagination-component :page-size="pageSize" :total="total" pageSizes
                                  @current-change="currentChange"
                                  @sizes-change="handleSizeChange"/>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {ruinsModeApi} from "../../api/sample-manage-common-api";
import commonMethods from "../../js/common-methods";

export default defineComponent({
    name: "ruins-mode-component",
    props: {
        dataIndex: {
            type: String,
            default: "1",
        },
        audit:{
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);
        let districtRef = ref(null);
        let queryForm = reactive({
            ruinsName: null
        });
        let tableData = ref([]);
        // 查询按钮事件处理
        let queryHandle = () => {
            currentChange(1);
        }
        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getRuinsList();
        }
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }

        // 获取遗址列表
        const getRuinsList = async function () {
            const {area, city, province} = districtRef.value;
            let params = {
                province, city, county: area,
                ...queryForm,
                page: pageNumber.value,
                pageNum: pageNumber.value,
                limit: pageSize.value,
                belong: 1,
                submitStatus:1,
                userId: commonMethods.getCookie('uId')
            }
            let result
            if (props.audit){
                result = await ruinsModeApi.auditRuinsList(params);
            }else {
                result = await ruinsModeApi.getRuinsList(params, "page", props.dataIndex);
            }

            for (const list of result.data) {
                list.administrative = commonMethods.getAdministrativeDivision(list)
            }
            tableData.value = result.data;
            total.value = result.count;
        }

        onMounted(function () {
            getRuinsList()
        })
        return {
            pageSize,
            total,
            currentChange,
            handleSizeChange,

            tableData,
            districtRef,
            queryForm,
            queryHandle,
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.ruins-mode-component {
    display: flex;
    flex-direction: column;
    height: 100%;

    .filter_section {
        padding: pxToRem(30) pxToRem(40) pxToRem(5);
        @include bgColor(white);
        border-radius: pxToRem(2);
        display: flex;

        .el-form-item {
            margin-right: pxToRem(15);

            &:first-of-type {
                margin-right: pxToRem(30);
            }

            &:last-of-type {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .content_section {
        margin-top: pxToRem(20);
        padding: pxToRem(30) pxToRem(40);
        @include bgColor(white);
        height: 100%;

        .default_table_style {
            margin-bottom: pxToRem(30);
        }
    }
}
</style>