// 数据库运维接口API
import requestInterface from "../js/request-interface";

// 角色管理接口API
const roleManageApi = {
    // 获取角色列表
    async getRoleList(params) {
        return requestInterface.requestGet("api-user/roles", params);
    },
    /**
     * 添加、更新角色
     * @param params
     */
    saveOrUpdateRole(params) {
        return requestInterface.requestPost("api-user/roles/saveOrUpdate", params, null, null, true);
    },
    /**
     * 删除角色
     * @param id
     */
    deleteRole(id) {
        return requestInterface.requestDelete(`api-user/roles/${id}`);
    },
};

// 用户管理接口API
const userManageApi = {
    // 获取用户列表
    async getUserList(params) {
        return requestInterface.requestGet("api-user/userVos", params);
    },
    // 获取当前用户审核人列表
    async getCurrentUserAuditList(params) {
        return requestInterface.requestGet("api-user/data/admin/user", params);
    },
    // 获取所有角色列表
    async getAllRoleList(params) {
        return requestInterface.requestGet("api-user/allRoles", params);
    },
    /**
     * 添加、更新用户
     * @param params
     */
    saveOrUpdateUser(params) {
        return requestInterface.requestPost("api-user/users/saveOrUpdate", params, null, null, true);
    },
    /**
     * 添加用户
     * @param params
     */
    saveUser(params) {
        return requestInterface.requestPost("api-user/admin/saveUser", params,);
    },
    /**
     * 审核用户
     * @param params
     */
    auditUser(params) {
        return requestInterface.requestPost("api-user/user/check/updateCheckStatus", params,);
    },
    /**
     * 重置密码
     * @param id
     */
    resetPwd(id) {
        return requestInterface.requestPost(`api-user/users/${id}/password`);
    },
    /**
     * 修改用户状态
     * @param params
     */
    editUserStage(params) {
        return requestInterface.requestGet("api-user/users/updateEnabled", params);
    },
    /**
     * 分配角色
     * @param params
     */
    setRoleToUser(params) {
        return requestInterface.requestPost("api-user/users/setRoles", params);
    },
    /**
     * 删除用户
     * @param params
     */
    deleteUser(params) {
        return requestInterface.requestPost("api-user/admin/delete", params);
    },
};

// 系统日志接口API
const systemLogApi = {
    // 获取系统日志列表
    async getSystemLogList(params) {
        return requestInterface.requestGet("api-log/page", params);
    },
}

// 菜单管理接口API
const menuManageApi = {
    // 获取用户列表
    getAllMenuList(params) {
        return requestInterface.requestGet("api-user/menus/find/alls", params);
    },
    /**
     * 添加菜单
     * @param params
     */
    saveOrUpdateMenu(params) {
        return requestInterface.requestPost("api-user/menus/saveOrUpdateMenu", params,);
    },
    /**
     * 添加菜单
     * @param id
     */
    deleteMenu(id) {
        return requestInterface.requestGet(`api-user/menus/delete/${id}`);
    },
    /**
     * 根据角色id获取当前权限
     * @param roleId
     * @param dataIndex
     */
    getCurrentRoleMenu(roleId, dataIndex) {
        return requestInterface.requestGet(`api-user/menus/${roleId}/${dataIndex}/menus`);
    },
    /**
     * 给角色分配权限
     * @param params
     */
    roleAllotMenu(params) {
        return requestInterface.requestPost("api-user/menus/granted", params, null, null, true);
    },
    /**
     * 获取当前用户权限
     * @param dataIndex
     */
    getCurrentUserMenu(dataIndex) {
        return requestInterface.requestGet(`api-user/menus/current/${dataIndex}`);
    },
}

export {
    roleManageApi,
    userManageApi,
    systemLogApi,
    menuManageApi
}
