<template>
    <div class="role-manage-list">
        <el-form class="filter_section default_space_style" size="large">
            <el-row :gutter="60">
                <el-col :span="10">
                    <el-form-item label="角色名称：">
                        <el-input v-model="roleName" clearable @clear="queryRoleList" placeholder="请输入查询角色名称"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-button type="primary" @click="queryRoleList">查询</el-button>
        </el-form>
        <div class="container_content_box default_space_style">
            <el-button type="primary" class="add_role" @click="addRoleHandle">添加角色</el-button>
            <div class="default_table_style table_bg">
                <el-table size="large" stripe :data="tableData">
                    <el-table-column label="序号" type="index" width="100"></el-table-column>
                    <el-table-column label="角色名称" prop="name"></el-table-column>
                    <el-table-column label="编码" prop="code"></el-table-column>
                    <el-table-column label="权限" align="center">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="primary" :disabled="scope.row.code === 'admin'" @click="privilegesHandle(scope.row)">权限分配</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="primary" :disabled="scope.row.code === 'admin'" @click="editHandle(scope.row)">编辑</el-link>
                                <el-link type="info" :disabled="scope.row.code === 'admin'" @click="deleteHandle(scope.row)">删除</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination-component :total="roleListTotal"
                                  :limit="roleListPageSize"/>
        </div>
        <dialog-component :visible="visibleDialog"
                          :title="dialogTitle"
                          @confirm-handle="confirmDialog"
                          @cancel-handle="cancelDialog">
            <el-form size="large" ref="addRoleFormRef"
                     :model="addRoleForm"
                     :rules="addRoleFormRules">
                <el-form-item label="角色名称：" prop="name">
                    <el-input v-model="addRoleForm.name"/>
                </el-form-item>
                <el-form-item label="角色编码：" prop="code">
                    <el-input v-model="addRoleForm.code" :disabled="disabled"/>
                </el-form-item>
            </el-form>
        </dialog-component>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import commonMethods from "../../../../js/common-methods";
import {roleManageApi} from "../../../../api/database-devops-api";
import {DialogComponent, PaginationComponent, CommonSpaceVertical} from '../../../../index'

export default defineComponent({
    name: "role-manage-list-module",
    components: {
        DialogComponent,
        PaginationComponent,
        CommonSpaceVertical
    },
    props: {
        dataIndex: {
            type: String,
            default: null,
        }
    },
    setup(props, context) {
        let router = useRouter();
        let disabled = ref(false)
        // 检索表单数据
        let roleListPageNumber = ref(1);
        let roleListPageSize = ref(10);
        let roleListTotal = ref(1);
        // 角色名称
        let roleName = ref(null);
        // 角色添加的form
        let addRoleForm = reactive({
            name: '',
            code: '',
        });

        // 验证添加参数
        let addRoleFormRules = ref({
            name: [{required: true, message: '请填写角色名称', trigger: 'blur'}],
            code: [{required: true, message: '请填写角色编码', trigger: 'blur'}],
        });
        // 添加编辑角色弹框表单的ref
        let addRoleFormRef = ref(null);
        // 控制弹窗显示隐藏
        let visibleDialog = ref(false);
        // 弹窗标题
        let dialogTitle = ref(null);

        // 表格数据
        let tableData = ref([]);

        // 获取角色列表
        const getRoleList = async function () {
            let data = {
                name: roleName.value,
                page: roleListPageNumber.value,
                limit: roleListPageSize.value,
                nature: props.dataIndex,
            }
            let result = await roleManageApi.getRoleList(data);
            tableData.value = result.data;
            roleListTotal.value = result.count;
        }

        // 查询按钮
        const queryRoleList = () => {
            roleListPageNumber.value = 1;
            getRoleList();
        }
        // 权限事件处理
        const privilegesHandle = item => {
            // if (props.dataIndex === "0") {
            //     context.emit("privilegesHandle", item);
            // } else {
            //
            // }
            router.push({name: "roleManagePrivileges", query: {id: item.id, name: item.name}});
        }
        // 添加角色事件处理
        const addRoleHandle = () => {
            dialogTitle.value = "添加角色";
            visibleDialog.value = true;

        }
        let editRole = ref(null);
        // 编辑事件处理
        const editHandle = item => {
            dialogTitle.value = "编辑角色";
            editRole.value = item
            for (const form in addRoleForm) {
                addRoleForm[form] = item[form];
            }
            visibleDialog.value = true;
            disabled.value = true
        }

        // 删除事件处理
        const deleteHandle = async item => {
            await commonMethods.deleteDialog();
            await commonParamsInterface(item.id, "删除参数");
        }

        // 添加、编辑参数
        const saveOrUpdateRole = function () {
            let params = {
                ...addRoleForm,
                nature: props.dataIndex
            };
            if (editRole.value) {
                params.id = editRole.value.id;
            }
            commonParamsInterface(params, `${editRole.value ? "编辑" : "添加"}参数`);
        }
        // 公共参数接口
        const commonParamsInterface = async function (params, tip) {
            let result
            if (tip === "删除参数") {
                result = await roleManageApi.deleteRole(params);
            } else {
                result = await roleManageApi.saveOrUpdateRole(params);
            }
            commonMethods.message(result, () => {
                getRoleList();
                visibleDialog.value = false;
            }, tip);
        }

        // 弹窗确定事件处理
        const confirmDialog = () => {
            addRoleFormRef.value.validate(valid => {
                if (valid) {
                    saveOrUpdateRole();
                }
            })
        }

        // 弹窗关闭事件处理
        const cancelDialog = () => {
            visibleDialog.value = false;
            addRoleForm.name = null;
            addRoleForm.code = null;
            disabled.value = false
        }

        onMounted(function () {
            getRoleList();
        })

        return {
            roleListPageNumber,
            roleListPageSize,
            roleListTotal,
            roleName,
            tableData,
            visibleDialog,
            dialogTitle,
            addRoleForm,
            addRoleFormRef,
            addRoleFormRules,
            editHandle,
            deleteHandle,
            privilegesHandle,
            confirmDialog,
            addRoleHandle,
            cancelDialog,
            queryRoleList,

            disabled
        }
    }
})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.role-manage-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .filter_section {
        display: flex;

        .el-row {
            flex: 1;
            width: 0;

            .el-form-item {
                margin-bottom: 0;
            }
        }

        .el-button {
            margin-left: auto;
        }
    }

    .container_content_box {
        @include bgColor(white);
        margin-top: pxToRem(20);
        height: 100%;
        .add_role {
            width: max-content;
            margin-bottom: pxToRem(20);
        }

        .default_table_style {
            margin-bottom: pxToRem(30);
        }
    }
}
</style>
