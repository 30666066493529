import commonMethods from "../js/common-methods";

const globalMixin = {
    methods: {
        // 显示默认图片
        errorImage(e) {
            e.currentTarget.src = commonMethods.options.noPicture;
            e.value = null;
        }
    }
}
export default globalMixin;
