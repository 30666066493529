// 系统管理API
import requestInterface from "archaeometry-common-components/src/js/request-interface";

const platformApi = {
    /**
     * 平台简介添加和编辑
     * type 区分接口
     * @param params
     */
    introductionComponent(type, params) {
        return requestInterface.requestPost(`science-arch-portal/portalIntroduce/${type ? "update" : "save"}`, params, null, null, true);
    },

    /**
     * 平台简介详情获取
     */
    introductionList() {
        return requestInterface.requestGet(`science-arch-portal/portalIntroduce/list`);
    },

    /**
     * 共建单位添加和编辑
     * type 区分接口
     * @param params
     */
    addBuildUnit(type, params) {
        return requestInterface.requestPost(`science-arch-portal/constructUnit/${type ? "update" : "save"}`, params, null, null, true);
    },

    /*
    * 获取共建单位列表
    * */
    buildUnitList() {
        return requestInterface.requestGet("science-arch-portal/constructUnit/list")
    },

    /*
    * 删除共建单位
    * @param params
    * */
    deleteBuildUnit(params) {
        return requestInterface.requestGet("science-arch-portal/constructUnit/delete", params)
    },

    /*
     * 科研团队添加
     * @param params
     * */
    addResearchTeam(type, params) {
        return requestInterface.requestPost(`science-arch-portal/scienceTeam/${type ? "update" : "save"}`, params, null, null, true)
    },

    /*
     * 获取科研团队列表
     * @param params
     * */
    getResearchTeam() {
        return requestInterface.requestGet(`science-arch-portal/scienceTeam/list`)
    },
    /*
   * 删除共建单位
   * @param params
   * */
    deleteResearchTeam(params) {
        return requestInterface.requestGet("science-arch-portal/scienceTeam/delete", params)
    },
    /*
    * 获取学术前沿版块列表
    * */
    getSectionList() {
        return requestInterface.requestGet("science-arch-portal/field/list")
    },
    /*
   * 根据id查询学术前沿
   * */
    getSectionId(id) {
        return requestInterface.requestGet("science-arch-portal/researchInfo/findById", id)
    },
    /*
     * 学术前沿图片上传
     * @param params
     * */
    uploadImgList(params) {
        return requestInterface.requestUploadFile(`science-arch-portal/file/save`, params)
    },
    /*
      * 学术前沿图片删除
      * @param params
    * */
    deleteImgList(ids) {
        return requestInterface.requestDelete(`science-arch-portal/tradeStandard/TradeStandard/${ids}`)
    },
    /*
    * 学术前沿添加
    * @param params
    * */
    addAcademic(type, params) {
        return requestInterface.requestPost(`science-arch-portal/researchInfo/${type ? "update" : "save"}`, params, null, null, true)
    },
    /*
    * 获取学术前沿列表
    *@param params
    * */
    getAcademic(params) {
        return requestInterface.requestGet("science-arch-portal/researchInfo/page", params)
    },
    /*
  * 删除学术前沿
  * @param params
  * */
    deleteAcademic(id) {
        return requestInterface.requestGet(`science-arch-portal/researchInfo/delete/`, id, null, null, false)
    },
    /*
    * 行业标准添加/更新
    * @param params
    * @type  true/false
    * */
    addStandard(type, params) {
        return requestInterface.requestUploadFile(`science-arch-portal/tradeStandard/TradeStandard/${type ? 'update' : 'save'}`, params)
    },
    /**
     * 获取行业标准列表
     * @param params
     * */
    getStandardList(params) {
        return requestInterface.requestGet("science-arch-portal/tradeStandard/page", params)
    },
    /*
  * 删除行业标准
  * @param params
  * */
    deleteStandard(id) {
        return requestInterface.requestDelete(`science-arch-portal/tradeStandard/TradeStandard/${id}`)
    },
    /*
    * 添加单位信息
    * */
    addUnitList(params) {
        return requestInterface.requestPost(`api-user/workunits/saveOrUpdate`, params, null, null, true)
    },
    /*
    * 获取单位数据
    * */
    getUnitList(params) {
        return requestInterface.requestGet(`api-user/workunits`, params)
    },
    /*
    * 删除单位数据
    * @param params
    * */
    deleteUnitList(id) {
        return requestInterface.requestDelete(`api-user/workunits/${id}`)
    },
}
//数据中心api
const dataCenterApi = {
    /*
    * 动物/C14/DNA样本数据列表
    * @params
    * type 区分接口
    * */
    animalDataList(type, params) {
        return requestInterface.requestPost(`map-center/${type === 0 ? 'animal' : type === 1 ? 'carbon' : 'dna'}/page`, params)
    },
    /*
    * 获取遗迹列表
    * @params
    * */
    getRuinsList(params) {
        return requestInterface.requestGet("dna-arch/ruins/allFind",params);
    },
    /*
    * 获取DNA数据列表
    * @params
    * */
    getDnaDataList(params){
        return requestInterface.requestPost("dna-arch/data/page",params);
    },
    /* getRuinsInfo   遗址信息
    *  getDataInfo   dna数据信息
    *  getRuinsInfoDetails  遗址详情信息
    * 查询dna数据详情
    * */
    getRuinsInfo(id) {
        return requestInterface.requestGet("dna-arch/ruins/findById", {id})
    },
    getDataInfo(id) {
        return requestInterface.requestGet("dna-arch/data/find", {id})
    },
    getRuinsInfoDetails(id) {
        return requestInterface.requestGet("ruins-arch/ruinsInfo/findById", {id})
    },
    //获取大文件列表
    getFilesListData(dataId){
        return requestInterface.requestGet("dna-arch/file/find", {dataId})
    },
    // 获取列表
    getListOne(type, params) {
        return requestInterface.requestGet(`animal-arch/measure/${type}/list`, params);
    },

    /*
    * 获取动物样本数据列表
    * */
    getAnimalData(params){
        return requestInterface.requestPost("animal-arch/sample/page",params)
    },
    /**
     * 查询样本详情信息(动物)
     * @returns {*}
     * @param params
     */
    getSampleInfo(params) {
        return requestInterface.requestGet('animal-arch/sample/find/detailed', params);
    },
    // 获取牙齿磨蚀参数列表
    getToothParamsList() {
        return requestInterface.requestGet("animal-arch/toothParam/list");
    },
    /**
     * 获取牙齿信息列表
     * @returns {*}
     */
    async getTeethInfoList(toothPosition) {
        let result = await requestInterface.requestGet("animal-arch/toothInfo/findPosition", {toothPosition});
        let milkToothUpper = [];    // 乳齿上排
        let milkToothUnder = [];    // 乳齿下排
        let permanentToothUpper = [];    // 恒齿上排
        let permanentToothUnder = [];    // 恒齿下排
        for (const res of result) {
            let toothUpper = res.milkToothUpper;
            let toothUnder = res.milkToothUnder;
            let upper = res.permanentToothUpper;
            let under = res.permanentToothUnder;

            if (toothUpper) milkToothUpper.push({
                name: substr(toothUpper),
                originName: toothUpper,
                sub: substr(toothUpper, true)
            });
            if (toothUnder) milkToothUnder.push({
                name: substr(toothUnder),
                originName: toothUnder,
                sub: substr(toothUnder, true)
            });
            if (upper) permanentToothUpper.push({
                name: substr(upper),
                originName: upper,
                sub: substr(upper, true)
            });
            if (under) permanentToothUnder.push({
                name: substr(under),
                originName: under,
                sub: substr(under, true)
            });
        }

        // 截取下标
        function substr(name, status) {
            let split = name.split("");
            let numArr = [];
            for (const str of split) {
                if (status) {
                    if (!isNaN(Number(str))) {
                        numArr.push(str);
                    }
                } else {
                    if (isNaN(Number(str))) {
                        numArr.push(str);
                    }
                }
            }
            return numArr.length > 0 ? numArr.join("") : null;
        }

        return {milkToothUpper, milkToothUnder, permanentToothUpper, permanentToothUnder}
    },

    /*
    * 获取样本数据（C14）
    * */
    getCarbonData(params){
        return requestInterface.requestPost("carbon-arch/sample/page",params)
    },
    /**
     * 查询样本详情(C14)
     * @param params
     * @returns {*}
     */
    getCarbonInfo(params) {
        return requestInterface.requestGet("carbon-arch/sample/find", params);
    },
    // 根据code获取子级列表
    async getCodeByChildList(code) {
        const result = await requestInterface.requestGet("carbon-arch/dict/sonlist", {code});
        return result['datas'];
    },

    // 获取code列表
    async getCodeList() {
        const result = await requestInterface.requestGet("carbon-arch/dict/codelist");
        let objList = {};
        for (const obj of result['datas']) {
            objList[obj.key] = obj.value;
        }
        return objList;
    },
    // 植物样本接口
    /*
    * 获取碳化植物数据列表
    * */
    getCarbonPlantData(params) {
        return requestInterface.requestGet("plant-arch/carbonPlant/page", params);
    },
    /*
    * 获取植物(植硅体)数据列表
    * */
    getPhytolithData(params) {
        return requestInterface.requestGet("plant-arch/phytolithMeasure/page", params);
    },
    /*
    * 获取植物(淀粉粒)数据列表
    * */
    getStarchGranuleMeasureData(params) {
        return requestInterface.requestGet("plant-arch/starchGranule/page", params);
    },
    /*
    * 获取植物(木炭)数据列表
    * */
    getCharcoalData(params) {
        return requestInterface.requestGet("plant-arch/charcoal/page", params);
    },

}

export {
    platformApi,
    dataCenterApi
}