<template>
    <div class="space-query-component">
        <h3>空间查询<span class="iconfont clear_draw icon-shanchu31" @click="clearDraw(true)"></span></h3>
        <div class="space_query">
            <ul>
                <li v-for="(item,i) in spaceList" :key="i" @click="drawHandle(item)">
                    <span class="icon iconfont" :class="item.icon"></span>
                    <p v-text="item.name"></p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {inject, ref} from "vue";
import {ElMessage} from "element-plus";

export default {
    name: "space-query-component",
    setup() {
        let geoJson = inject("geoJson");
        let mapTool = inject("mapTool");
        let spaceList = ref([
            {name: "矩形查询", type: "rectangle", icon: "icon-shape-rectangle"},
            {name: "多边形查询", type: "polygon", icon: "icon-shape-polygon"}
        ]);
        let currentSpace = ref(null);
        let draw;
        // 绘制事件处理
        const drawHandle = function (item) {
            clearDraw();
            mapTool.map.doubleClickZoom.disable();
            switch (item.type) {
                case 'rectangle':
                    draw = mapTool.map.editTools.startRectangle(null);
                    break;
                case 'polygon':
                    draw = mapTool.map.editTools.startPolygon(null);
                    break;
            }
            draw.on('editable:drawing:end', function (e) {
                let geo = e.layer.toGeoJSON().geometry;
                if (geo?.coordinates[0][0]) {
                    geoJson.value = e.layer.toGeoJSON().geometry;
                }
                draw.disableEdit();
            })
        }

        // 清除绘制
        const clearDraw = function (status) {
            mapTool.map.doubleClickZoom.enable();
            if (draw) {
                draw.disableEdit();
                draw.remove();
                draw = null;
            }
            if (status) {
                geoJson.value = null;
                ElMessage.success("绘制清除成功");
            }
        }
        return {
            spaceList,
            currentSpace,
            clearDraw,
            drawHandle
        }
    }
}
</script>

<style lang="scss">
@import "~@acc/scss/commonFunc";

.space-query-component {
    @include bgColor(white);

    .space_query {
        padding: pxToRem(15);

        ul {
            display: flex;
            align-items: center;
            justify-content: space-around;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;

                span {
                    @include widthHeight(60);
                    @include bgColor;
                    @include displayFlex;
                    border-radius: 50%;
                    @include fontSize(30);
                    @include color(#666);
                    cursor: pointer;
                    transition: .1s linear;

                    &:hover {
                        @include color($defaultColor);
                    }
                }

                p {
                    margin-top: pxToRem(10);
                    @include fontSize(14);
                }
            }
        }
    }
}
</style>
