<template>
    <div class="system_entrance portal-website">
        <header-component v-if="headerShow"/>
        <div class="main_container">
            <router-view/>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref, watchEffect} from "vue";
import HeaderComponent from "@/components/header-component";
import {useRoute} from "vue-router";

export default defineComponent({
    components: {HeaderComponent},
    setup() {
        const route = useRoute();
        let headerShow = ref(true);
        watchEffect(function () {
            headerShow.value = !["register", "portalWebsite"].includes(route.meta["mainNavigation"]);
        })

        return {
            headerShow
        }
    }
})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.portal-website {
    .main_container {
        //background-image: url("~@/assets/images/homepage/page_twot_bg.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
</style>
