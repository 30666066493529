<template>
    <map-viewer-component>
        <slot name="attr-query"/>
    </map-viewer-component>
</template>

<script>
import {defineComponent} from "vue";

import MapViewerComponent from "../../../components/map-viewer-component/map-viewer-component";

export default defineComponent({
    name: "sample-center-list",
    components: {MapViewerComponent},
})
</script>

<style lang="scss">

</style>
