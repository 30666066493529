<template>
    <div class="database-content-component default_space_style">
        <div class="add-handle-box">
            <slot name="title"/>
            <el-button type="primary" class="add_role" @click="addHandle" v-if="title">{{ title }}</el-button>
        </div>
        <slot/>
        <pagination-component :total="total" :page-size="limit" :page-sizes="pageSizes"
                              @current-change="currentChange"
                              @sizes-change="handleSizeChange"/>
    </div>
</template>

<script>

import {defineComponent} from "vue"
import {PaginationComponent} from "../../../index"

export default defineComponent({
    name: "database-content-component",
    props: {
        title: {
            type: String,
            default: "",
        },
        total: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 10
        },
        pageSizes: {
            type: Boolean,
            default: false
        }
    },
    components: {
        PaginationComponent
    },
    setup(props, context) {
        const addHandle = () => {
            context.emit("addHandle");
        }
        const currentChange = size => {
            context.emit("currentChange", size);
        }
        const handleSizeChange = size => {
            context.emit("sizesChange", size);
        }
        return {
            addHandle,
            currentChange,
            handleSizeChange
        }
    }
})
</script>

<style lang="scss">
@import "../../../scss/commonFunc";

.database-content-component {
    @include bgColor(white);
    //margin-top: pxToRem(20);
    flex: 1;

    .add-handle-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: pxToRem(20);

        .add_role {
            //flex: 1;
            //margin-bottom: pxToRem(20);
            width: max-content;
        }
    }

    .default_table_style {
        margin-bottom: pxToRem(30);
    }
}
</style>
