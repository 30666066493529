<template>
    <div :class="auditBtn ? 'audit-btn-group-component' : 'btn-group-component'">
        <el-button-group>
            <el-button v-for="(item,i) in btnList" v-if="!auditBtn"
                       :key="i"
                       :type="item.value===defaultBtn?'primary':''"
                       :disabled="item.count && (item.count === '0')"
                       @click="btnHandle(item,i)">
                {{ item.name }}
            </el-button>
            <el-button v-for="(item,i) in btnList" v-if="auditBtn"
                       :key="i"
                       :type="item.value===defaultBtn?'primary':''"
                       @click="btnHandle(item,i)">
                <p>{{ item.name }}</p>
                <p>{{ item.vice }}</p>
            </el-button>
        </el-button-group>
    </div>
</template>

<script>

export default {
    name: "btn-group-component",
    props: {
        btnList: {
            type: Array,
            default: () => []
        },
        defaultBtn: {
            type: String,
            default: null
        },
        auditBtn: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        let btnHandle = (item, i) => {
            context.emit("btnHandle", item, i);
        }
        return {
            btnHandle
        }
    }
}
</script>

<style scoped>

</style>
