// 导出组件
import {default as BaseMapComponent} from "./components/base-map-component/base-map-component";
import {default as BreadcrumbComponent} from "./components/breadcrumb-component/breadcrumb-component";
import {default as FooterMaxComponent} from "./components/footer-component/footer-max-component";
import {default as FooterMinComponent} from "./components/footer-component/footer-min-component";
import {default as MainNavigationComponent} from "./components/main-navigation-component/main-navigation-component";
import {default as MapToolComponent} from "./components/map-tool-component/map-tool-component";
import {default as ModuleHeadComponent} from "./components/module-head-component/module-head-component";
import {default as ModulePanelComponent} from "./components/module-panel-component/module-panel-component";
import {default as PaginationComponent} from "./components/pagination-component/pagination-component";
import {default as DialogComponent} from "./components/dialog-component/dialog-component";
import {default as BtnGroupComponent} from "./components/btn-group-component/btn-group-component";
import {
    default as FunctionBtnGroupComponent
} from "./components/function-btn-group-component/function-btn-group-component";
import {default as ThreeLevelLinkage} from "./components/three-level-linkage/three-level-linkage";
import {default as CommonSpaceVertical} from "./components/common-space-vertical/common-space-vertical";
import {default as TabHeadComponent} from "./components/tab-head-component/tab-head-component";
import {default as CarouselComponent} from './components/carousel-component/carousel-component'
import {default as BigFileUploadComponent} from "./components/big-file-upload-component/big-file-upload-component";
import {default as BasicInfoTextComponent} from "./components/basic-info-text-component/basic-info-text-component";
import {
    default as RegularFileUploadComponent
} from "./components/regular-file-upload-component/regular-file-upload-component";
import {
    default as CoordinatesSelectMapComponent
} from "./components/coordinates-select-map-component/coordinates-select-map-component";
import {
    default as LiteraturePublishFormComponent
} from "./components/literature-publish-form-component/literature-publish-form-component";
import {default as ImageListComponent} from "./components/image-list-component/image-list-component";
import {
    default as DataSharingDialogComponent
} from "./components/data-sharing-dialog-component/data-sharing-dialog-component"
import {default as RuinsModeComponent} from "./components/ruins-mode-component/ruins-mode-component"
import {
    default as SampleBasicOrMapComponent
} from "./components/sample-basic-or-map-component/sample-basic-or-map-component"
import {default as ControlPanelComponent} from "./components/control-panel-component/control-panel-component"
import {default as ThreePreviewComponent} from "./components/three-preview-component/three-preview-component"
import {default as SampleImportComponent} from "./components/sample-import-component/sample-import-component"
import {
    default as TransitionRouterViewComponent
} from "./components/transition-router-view-component/transition-router-view-component";
import {default as LoginComponent} from "./components/login-component/login-component";
import {
    default as SubmitAuditDialogComponent
} from "./components/submit-audit-dialog-component/submit-audit-dialog-component"
import {
    default as RelevancyLiteratureDialogComponent
} from "./components/relevancy-literature-dialog-component/relevancy-literature-dialog-component"

// 导出模块
import {default as LiteratureManageListModule} from './modules/literature-manage/list/list'
import {default as LiteratureManagePublishModule} from './modules/literature-manage/publish/publish'
import {default as PreviewFileModule} from './modules/preview-file-module/preview-file-module'
import {default as RoleManageListModule} from './modules/database-devops/role-manage/list/list'
import {default as RoleManagePrivilegesModule} from './modules/database-devops/role-manage/privileges/privileges'
import {default as UserManageListModule} from './modules/database-devops/user-manage/list/list'
import {default as DatabaseDevopsModule} from './modules/database-devops/database-devops'
import {
    default as DatabaseContentComponent
} from './modules/database-devops/database-content-component/database-content-component'
import {
    default as DatabaseHeadComponent
} from './modules/database-devops/database-head-component/database-head-component'
import {default as SystemLogModule} from './modules/database-devops/system-log/system-log'
import MenuManageModule from './modules/database-devops/menu-manage/menu-manage'
import {default as SampleCenterListModule} from "./modules/sample-center/list/list"
import {default as AnimalDatabaseComponent} from "./components/data-linkage-component/animal-database"
import {default as PlantDatabaseComponent} from "./components/data-linkage-component/plant-database"
import {default as DnaDatabaseComponent} from "./components/data-linkage-component/dna-database"
import {default as CarbonDatabaseComponent} from "./components/data-linkage-component/carbon-database"
import {
    default as AnimalDetailsComponent
} from "./components/data-linkage-details-component/animal-details-component/animal-details-component"
import {
    default as DnaDetailsComponent
} from "./components/data-linkage-details-component/dna-detais-component/dna-details-component"
import {
    default as CarbonDetailsComponent
} from "./components/data-linkage-details-component/carbon-details-component/carbon-details-component"
import {default as QueryFromComponent} from "./components/data-linkage-component/query-from-component";
import {
    default as PlantDetailsComponent
} from "./components/data-linkage-details-component/plant-details-component/plant-details-component"
import {default as RuinsComponent} from "./modules/sample-center/ruins-modules/ruins-component"
import {default as PlantSampleListComponent} from "./modules/sample-center/sample-modules/plant-sample-list-component"

export {
    BaseMapComponent,
    BreadcrumbComponent,
    FooterMaxComponent,
    FooterMinComponent,
    MainNavigationComponent,
    MapToolComponent,
    ModuleHeadComponent,
    ModulePanelComponent,
    PaginationComponent,
    DialogComponent,
    BtnGroupComponent,
    FunctionBtnGroupComponent,
    ThreeLevelLinkage,
    CommonSpaceVertical,
    TabHeadComponent,
    CarouselComponent,
    BigFileUploadComponent,
    BasicInfoTextComponent,
    RegularFileUploadComponent,
    CoordinatesSelectMapComponent,
    LiteraturePublishFormComponent,
    ImageListComponent,
    DataSharingDialogComponent,
    RuinsModeComponent,
    SampleBasicOrMapComponent,
    ControlPanelComponent,
    ThreePreviewComponent,
    SampleImportComponent,

    TransitionRouterViewComponent,

    LiteratureManageListModule,
    LiteratureManagePublishModule,
    PreviewFileModule,
    RoleManageListModule,
    RoleManagePrivilegesModule,
    UserManageListModule,
    DatabaseDevopsModule,
    DatabaseContentComponent,
    DatabaseHeadComponent,
    SystemLogModule,
    MenuManageModule,
    SampleCenterListModule,
    LoginComponent,
    SubmitAuditDialogComponent,
    RelevancyLiteratureDialogComponent,
    AnimalDatabaseComponent,
    PlantDatabaseComponent,
    DnaDatabaseComponent,
    CarbonDatabaseComponent,
    AnimalDetailsComponent,
    DnaDetailsComponent,
    CarbonDetailsComponent,
    QueryFromComponent,
    PlantDetailsComponent,
    RuinsComponent,
    PlantSampleListComponent
}
// 组件列表
const components = [
    BaseMapComponent,
    BreadcrumbComponent,
    FooterMinComponent,
    FooterMaxComponent,
    MainNavigationComponent,
    MapToolComponent,
    ModuleHeadComponent,
    ModulePanelComponent,
    PaginationComponent,
    DialogComponent,
    BtnGroupComponent,
    FunctionBtnGroupComponent,
    ThreeLevelLinkage,
    CommonSpaceVertical,
    TabHeadComponent,
    CarouselComponent,
    BigFileUploadComponent,
    BasicInfoTextComponent,
    RegularFileUploadComponent,
    CoordinatesSelectMapComponent,
    LiteraturePublishFormComponent,
    ImageListComponent,
    DataSharingDialogComponent,
    RuinsModeComponent,
    SampleBasicOrMapComponent,
    ControlPanelComponent,
    ThreePreviewComponent,
    SampleImportComponent,

    TransitionRouterViewComponent,

    LiteratureManageListModule,
    LiteratureManagePublishModule,
    PreviewFileModule,
    RoleManageListModule,
    RoleManagePrivilegesModule,
    UserManageListModule,
    DatabaseDevopsModule,
    DatabaseContentComponent,
    DatabaseHeadComponent,
    SystemLogModule,
    MenuManageModule,
    SampleCenterListModule,
    LoginComponent,
    SubmitAuditDialogComponent,
    RelevancyLiteratureDialogComponent,
    AnimalDatabaseComponent,
    PlantDatabaseComponent,
    DnaDatabaseComponent,
    CarbonDatabaseComponent,

    AnimalDetailsComponent,
    DnaDetailsComponent,
    CarbonDetailsComponent,
    QueryFromComponent,
    PlantDetailsComponent,
    RuinsComponent,
    PlantSampleListComponent
]

export default {
    install(Vue) {
        components.forEach(component => Vue.component(component.name, component))
    },
    ...components
}
