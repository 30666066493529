<template>
    <breadcrumb-component max showBack/>
    <div class="sample-center-list default_page_style default_wrapper">
        <div class="right_content_section">
            <div class="ruins-mode-component">
                <btn-group-component :btn-list="plantTypeList" :default-btn="defaultPlantType.value"
                                     @btn-handle="BgSwitchHandle"/>
                <el-form ref="carbidePlantFormRef"
                         :model="carbidePlantForm"
                         class="filter_section"
                         label-position="right"
                         label-width="140px"
                         size="large">
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <el-form-item label="遗址名称：" prop="name">
                                <el-input v-model="carbidePlantForm.ruinsName" placeholder="遗址名称"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="遗址分区：" prop="name">
                                <el-input v-model="carbidePlantForm.ruinsSubarea" placeholder="遗址分区"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="文化分期：" prop="name">
                                <el-input v-model="carbidePlantForm.culturalPeriod" placeholder="文化分期"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="探方号：" prop="name">
                                <el-input v-model="carbidePlantForm.exploreSquareNumber" placeholder="探方号"/>
                            </el-form-item>
                        </el-col>
                        <template v-if="defaultPlantType.value==='carbidePlant'">
                            <el-col :span="8">
                                <el-form-item label="科拉丁名：" prop="familyId">
                                    <el-select v-model="carbidePlantForm.familyId" placeholder="请选择科拉丁名">
                                        <el-option v-for="(item,i) in familyIdList" :key="i" :label="item"
                                                   :value="item"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="属拉丁名：" prop="spGenus">
                                    <el-select v-model="carbidePlantForm.genusId" placeholder="请选择属拉丁名">
                                        <el-option v-for="(item,i) in genusList" :key="i" :label="item"
                                                   :value="item"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="种属：" prop="name">
                                    <el-input v-model="carbidePlantForm.name" placeholder="例：小麦"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="保存状况：" prop="saveStatus">
                                    <el-select v-model="carbidePlantForm.saveStatus" placeholder="请选择保存状况">
                                        <el-option v-for="(item,i) in saveStatusList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="保存部位：" prop="preservationSite">
                                    <el-select v-model="carbidePlantForm.preservationSite" placeholder="请选择保存部位">
                                        <el-option v-for="(item,i) in preservationSiteList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="成熟度：" prop="maturity">
                                    <el-select v-model="carbidePlantForm.maturity" placeholder="请选择成熟度">
                                        <el-option v-for="(item,i) in maturityList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </template>
                        <template v-if="defaultPlantType.value==='phytolith'">
                            <el-col :span="8">
                                <el-form-item label="植硅体类型：" prop="phytolithType">
                                    <el-select v-model="carbidePlantForm.phytolithType"
                                               placeholder="请选择植硅体类型">
                                        <el-option v-for="(item,i) in phytolithTypeList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </template>
                        <template v-if="defaultPlantType.value==='starchGrain'">
                            <el-col :span="8">
                                <el-form-item label="淀粉粒种类：" prop="name">
                                    <el-input v-model="carbidePlantForm.name" placeholder="例：松属"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="保存状况：" prop="saveStatus">
                                    <el-select v-model="carbidePlantForm.saveStatus" placeholder="请选择保存状况">
                                        <el-option v-for="(item,i) in DFLSaveStatusList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </template>
                        <template v-if="defaultPlantType.value==='charcoal'">
                            <el-col :span="8">
                                <el-form-item label="科拉丁名：" prop="familyId">
                                    <el-select v-model="carbidePlantForm.familyId" placeholder="请选择科拉丁名">
                                        <el-option v-for="(item,i) in familyIdList" :key="i" :label="item"
                                                   :value="item"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="属拉丁名：" prop="spGenus">
                                    <el-select v-model="carbidePlantForm.genusId" placeholder="请选择属拉丁名">
                                        <el-option v-for="(item,i) in genusList" :key="i" :label="item"
                                                   :value="item"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="种属：" prop="name">
                                    <el-input v-model="carbidePlantForm.name" placeholder="例：小麦"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="保存状况：" prop="saveStatus">
                                    <el-select v-model="carbidePlantForm.saveStatus" placeholder="请选择保存状况">
                                        <el-option v-for="(item,i) in MTSaveStatusList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="样品来源：" prop="sourceType">
                                    <el-select v-model="carbidePlantForm.sourceType" placeholder="请选择来源分类">
                                        <el-option v-for="(item,i) in sourceTypeList" :key="i" :label="item.name"
                                                   :value="item.id"/>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="取样部位：" prop="familyId">
                                    <el-input v-model="carbidePlantForm.samplePlace" placeholder="请输入取样部位"/>
                                </el-form-item>
                            </el-col>
                        </template>
                        <el-col
                            :span="defaultPlantType.value==='charcoal' ? 16 : defaultPlantType.value==='starchGrain' ? 24 : defaultPlantType.value==='carbidePlant' ? 16 : 8">
                            <div class="pull-right">
                                <el-button type="primary" @click="queryHandle">查询</el-button>
                                <el-button @click="resetHandle">重置</el-button>
                                <el-button type="success" @click="viewerMap">进入地图</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
                <div class="content_section sample-center-list-table">
                    <div class="default_table_style table_bg">
                        <el-table :data="dataTable" size="large" stripe @selection-change="handleSelectionChange">
                            <el-table-column label="序号" type="index" width="100"></el-table-column>
                            <el-table-column label="遗址名称" prop="ruinsName"></el-table-column>
                            <el-table-column label="文化分期" prop="culturalPeriod"></el-table-column>
                            <el-table-column label="出土单位号" prop="belongName"></el-table-column>
                            <template v-if="defaultPlantType.value==='carbidePlant'">
                                <el-table-column label="种属" prop="name"></el-table-column>
                                <el-table-column label="保存部位">
                                    <template #default="scope">
                                        <span>{{ scope.row["preservationSiteName"] || scope.row.savePart }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="成熟度">
                                    <template #default="scope">
                                        <span>{{ scope.row["maturityName"] || scope.row.maturity }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="保存状况">
                                    <template #default="scope">
                                        <span>{{ scope.row["saveStatusName"] || scope.row.saveStatus }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量" prop="quantity"></el-table-column>
                            </template>
                            <template v-if="defaultPlantType.value==='phytolith'">
                                <el-table-column label="植硅体类型">
                                    <template #default="scope">
                                        <span>{{ scope.row["phytolithTypeName"] || scope.row.phytolithType }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量" prop="quantity"></el-table-column>
                            </template>
                            <template v-if="defaultPlantType.value==='starchGrain'">
                                <el-table-column label="样品来源">
                                    <template #default="scope">
                                        <span>{{ scope.row["utensilType"] }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="淀粉粒种类" prop="name"></el-table-column>
                                <el-table-column label="数量" prop="quantity"></el-table-column>
                                <el-table-column label="保存状况">
                                    <template #default="scope">
                                        <span>{{ scope.row["saveStatusName"] || scope.row.saveStatus }}</span>
                                    </template>
                                </el-table-column>
                            </template>
                            <template v-if="defaultPlantType.value==='charcoal'">
                                <el-table-column label="取样部位" prop="samplePlace"></el-table-column>
                                <el-table-column label="种属" prop="name"></el-table-column>
                                <el-table-column label="数量" prop="quantity"></el-table-column>
                            </template>
                            <el-table-column align="center" label="操作">
                                <template #default="scope">
                                    <common-space-vertical>
                                        <el-link type="warning" @click="lookHandle(scope.row)">详情</el-link>
                                    </common-space-vertical>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <pagination-component :page-size="pageSize" :total="total" pageSizes @current-change="currentChange"
                                          @sizes-change="handleSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import commonMethods from "../../../js/common-methods";
import {ruinsModeApi} from "../../../api/sample-manage-common-api";
import userApi from "../../../api/user-api";

export default defineComponent({
    name: "plant-sample-list-component",
    setup() {
        let store = useStore();
        let router = useRouter();
        let carbidePlantFormRef = ref(null)

        let plantTypeList = ref([
            {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", imgType: "carbonized", sampleType: "1"},
            {name: '植硅体', value: 'phytolith', type: "phytolith", imgType: "phytolith", sampleType: "2"},
            {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", imgType: "starch", sampleType: "3"},
            {name: '木炭', value: 'charcoal', type: "charcoal", imgType: "charcoal", sampleType: "4"},
        ])
        // 科拉丁名列表
        let familyIdList = ref([]);
        // 属拉丁名列表
        let genusList = ref([]);
        // 实验室名称列表
        let labNameIdList = ref([]);
        // 样品来源列表
        let sampleSourceList = ref([]);
        // 浮选方法列表
        let flotationMethodList = ref([]);
        // 成熟度列表
        let maturityList = ref([]);
        // 保存部位列表
        let preservationSiteList = ref([]);
        // 保存状况列表
        let saveStatusList = ref([]);
        // 植硅体类型列表
        let phytolithTypeList = ref([]);
        // 植硅体-参考物列表
        // let referenceList = ref([]);
        // 植硅体-重液类型列表
        let heavyLiquidTypeList = ref([]);
        // 植硅体-重液类型列表
        let extractionMethodList = ref([]);
        // 淀粉粒-重液类型列表
        let DFLHeavyLiquidTypeList = ref([]);
        // 淀粉粒-保存状况列表
        let DFLSaveStatusList = ref([]);
        // 木炭-保存状况列表
        let MTSaveStatusList = ref([]);
        // 木炭-来源分类列表
        let sourceTypeList = ref([]);

        const carbidePlantForm = reactive({});
        const getCodeList = async function () {
            await store.dispatch("setCodeList")
            await getSelectList("炭化植物-样品来源", sampleSourceList);
            await getSelectList("炭化植物-浮选方法", flotationMethodList);
            await getSelectList("炭化植物-成熟度", maturityList);
            await getSelectList("炭化植物-保存部位", preservationSiteList);
            await getSelectList("炭化植物-保存状况", saveStatusList);

            await getSelectList("植硅体-提取方法", extractionMethodList);
            await getSelectList("植硅体-重液类型", heavyLiquidTypeList);
            // await getSelectList("植硅体-参考物", referenceList);
            await getSelectList("植硅体类型", phytolithTypeList);

            await getSelectList("淀粉粒-重液类型", DFLHeavyLiquidTypeList);
            await getSelectList("淀粉粒-保存状况", DFLSaveStatusList);

            await getSelectList("木炭-保存状况", MTSaveStatusList);
            await getSelectList("木炭-来源分类", sourceTypeList);
        }
        // 获取下拉列表
        const getSelectList = async function (name, data) {
            let code = commonMethods.getObjValueByKey(store.state.codeList, name);
            data.value = await ruinsModeApi.getCodeByChildList(code);
        }
        // 获取种属拉丁名列表
        const getSpeciesList = async function () {
            let result = await ruinsModeApi.getLatinName(null, "family");
            familyIdList.value = result["datas"];
        }
        const getGenusList = async function () {
            let result = await ruinsModeApi.getLatinName(null, "genus");
            genusList.value = result["datas"];
        }

        let defaultPlantType = ref(plantTypeList.value[0])
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);
        // 目录数据


        // 查询按钮事件处理
        let queryHandle = () => {
            currentChange(1);
        }
        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getDataList();
        }

        // 获取样本数据
        let dataTable = ref(null)
        const getDataList = async function () {
            let param = {
                page: pageNumber.value,
                limit: pageSize.value,
                releaseStatus: 1,
                ...carbidePlantForm,
            }
            let result = await ruinsModeApi.getCurrentDataList(param, defaultPlantType.value.type);
            dataTable.value = result.data;
            total.value = result.count;
        }

        const handleSizeChange = function (val) {
            pageSize.value = val;
            getDataList();
        }
        //导出按钮操作事件
        const navigationHandle = async function () {
            let ids
            if (defaultPlantType.value.sampleType === '1') {
                ids = {
                    carbon: tableCurrentList.value ? tableCurrentList.value.join(',') : null,
                    phytolith: null,
                    starch: null,
                    charcoal: null
                }
            } else if (defaultPlantType.value.sampleType === '2') {
                ids = {
                    carbon: null,
                    phytolith: tableCurrentList.value ? tableCurrentList.value.join(',') : null,
                    starch: null,
                    charcoal: null
                }
            } else if (defaultPlantType.value.sampleType === '3') {
                ids = {
                    carbon: null,
                    phytolith: null,
                    starch: tableCurrentList.value ? tableCurrentList.value.join(',') : null,
                    charcoal: null
                }
            } else {
                ids = {
                    carbon: null,
                    phytolith: null,
                    starch: null,
                    charcoal: tableCurrentList.value ? tableCurrentList.value.join(',') : null,
                }
            }
            let idList = {
                sampleIds: JSON.stringify(ids)
            }
            // 生成样本id序列号
            let res = await ruinsModeApi.batchSampleId(idList)

            let params = {
                exportIdsCode: res['resp_msg'] ? res['resp_msg'] : '',
                ...carbidePlantForm,
                releaseStatus: 1,
            }
            let checkedList = {
                carbon: "",
                phytolith: "",
                starch: "",
                charcoal: ""
            };
            let column = {
                column: JSON.stringify(checkedList)
            }
            let result = await ruinsModeApi.sampleExportGenerate(column);
            params.exportCode = result["resp_msg"];
            let path = "";
            for (const key in params) {
                path += `${key}=${params[key]}&`
            }
            let url = commonMethods.getCookie("proUrl").url + `/plant-arch/excel/exportRuinsInfoSampleData?` + path;
            commonMethods.downloadFile(url, `单位样本数据`, "xlsx");
        }

        const lookHandle = function (val) {
            let query = {
                ruinsId: val.ruinsId,
                title: val.name,
                sampleId: val.id,
                sampleType: defaultPlantType.value.sampleType,
                bgType: val.backgroundType,
                bgId: val.backgroundId
            }
            let resolve = router.resolve({
                name: "mapModeRelicDetail",
                query: {title: val.ruinsName, id: val.id, ...query}
            });
            window.location.href = resolve.href;
            // window.open(resolve.href);
        }

        const resetHandle = () => {
            carbidePlantFormRef.value ? carbidePlantFormRef.value.resetFields() : null
            carbidePlantForm.userId = ''
            for (const key in carbidePlantForm) {
                carbidePlantForm[key] = null
            }
            getDataList();
        }
        let userJudge = ref(false)
        const getUserRoles = async function () {
            let res = await userApi.getCurrentUser()
            let roles = res['datas'].roles
            for (const role of roles) {
                if (role.nature === '2' && role.code === 'admin-plant') {
                    userJudge.value = true
                } else {
                    userJudge.value = false
                }
            }
        }
        // 表格选中项事件
        let tableCurrentList = ref([]);
        const handleSelectionChange = (val) => {
            let ids = []
            for (const key of val) {
                ids.push(key.id);
            }
            tableCurrentList.value = ids
        }
        const BgSwitchHandle = async function (val) {
            defaultPlantType.value = val;
            getDataList();
        }

        const viewerMap = function () {
            router.push({name: 'sampleCenterList'})
        }

        onMounted(function () {
            getSpeciesList()
            getGenusList()
            getUserRoles()
            getDataList();
            getCodeList()
        })
        return {
            pageSize,
            total,
            queryHandle,
            currentChange,

            plantTypeList,
            defaultPlantType,
            navigationHandle,
            handleSizeChange,
            dataTable,
            lookHandle,
            carbidePlantForm,
            // 科拉丁名列表
            familyIdList,
            // 属拉丁名列表
            genusList,
            // 实验室名称列表
            labNameIdList,
            // 样品来源列表
            sampleSourceList,
            // 浮选方法列表
            flotationMethodList,
            // 成熟度列表
            maturityList,
            // 保存部位列表
            preservationSiteList,
            // 保存状况列表
            saveStatusList,
            // 植硅体类型列表
            phytolithTypeList,
            // 植硅体-参考物列表
            // referenceList,
            // 植硅体-重液类型列表
            heavyLiquidTypeList,
            // 植硅体-重液类型列表
            extractionMethodList,
            // 淀粉粒-重液类型列表
            DFLHeavyLiquidTypeList,
            // 淀粉粒-保存状况列表
            DFLSaveStatusList,
            // 木炭-保存状况列表
            MTSaveStatusList,
            // 木炭-来源分类列表
            sourceTypeList,
            carbidePlantFormRef,
            resetHandle,
            userJudge,
            handleSelectionChange,
            BgSwitchHandle,
            viewerMap
        }
    }
})
</script>

<style lang="scss">
@import "../../sample-center/ruins-modules/list";

.breadcrumb-component {
    min-height: pxToRem(58);
}
</style>