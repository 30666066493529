<template>
    <div class="user-manage-list">
        <el-form :model="filterForm" class="filter_section default_space_style" size="large">
            <el-row :gutter="60">
                <el-col :span="10">
                    <el-form-item label="账号：">
                        <el-input v-model="filterForm.username" clearable placeholder="请输入查询账号"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                    <el-form-item label="角色：">
                        <el-select v-model="filterForm.roleId" clearable placeholder="请选择查询角色">
                            <el-option v-for="item in allRoleList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-button type="primary" @click="getUserList">查询</el-button>
        </el-form>
        <div class="container_content_box default_space_style">
            <el-button class="add_role" type="primary" @click="addHandle">添加用户</el-button>
            <div class="default_table_style table_bg">
                <el-table :data="tableData" size="large" stripe style="width: 100%">
                    <el-table-column label="序号" type="index" width="65" align="center"/>
                    <el-table-column v-for="(item,i) in tableColumns" :key="i" :align="item.align||'left'"
                                     :label="item.label"
                                     :prop="item.type"
                                     :width="item.width"
                                     show-overflow-tooltip>
                        <template #default="scope">
                            {{ scope.row[item.type] || '不详' }}
                        </template>
                    </el-table-column>
                    <!--                    <el-table-column label="账号" prop="username"></el-table-column>-->
                    <!--                    <el-table-column label="手机号" prop="mobile" width="130"></el-table-column>-->
                    <!--                    <el-table-column label="真实姓名" prop="nickname" width="100"></el-table-column>-->
                    <!--                    <el-table-column label="单位" prop="unitName"></el-table-column>-->
                    <!--                    <el-table-column label="部门" prop="departName" width="100"></el-table-column>-->
                    <el-table-column label="角色" prop="roles">
                        <template #default="scope">
                            <span>{{ getRolesName(scope.row['roles']) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态" prop="audit" width="100">
                        <template #default="scope">
                            <span v-if="scope.row['checkStatus'] === '1'" class="pass">通过</span>
                            <span v-else-if="scope.row['checkStatus'] === '2'" class="no-pass">不通过</span>
                            <span v-else class="await">未审核</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核时间" min-width="180" v-if="timeShow !== '0'">
                        <template #default="scope">
                            <p>{{ scope.row.checkTime ? formatData(scope.row.checkTime) : null }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请时间" min-width="180" v-if="timeShow !== '0'">
                        <template #default="scope">
                            <p>{{ scope.row.createTime ? formatData(scope.row.createTime) : null }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="340">
                        <template #default="scope">
                            <common-space-vertical v-if="dataIndex === '0'">
                                <el-link type="success" @click="lookHandle(scope.row, '用户审核')">查看</el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="danger"
                                         @click="auditHandle(scope.row, '角色分配')">角色分配
                                </el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="custom-baby-blue"
                                         @click="resetPwdHandle(scope.row)">重置密码
                                </el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="info"
                                         @click="deleteHandle(scope.row)">注销
                                </el-link>
                            </common-space-vertical>
                            <common-space-vertical v-else>
                                <el-link type="success" @click="lookHandle(scope.row, '用户审核')">查看</el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="custom-blue"
                                         @click="auditHandle(scope.row, '用户审核')">审核
                                </el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="danger"
                                         @click="auditHandle(scope.row, '角色分配')">角色分配
                                </el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="custom-baby-blue"
                                         @click="resetPwdHandle(scope.row)">重置密码
                                </el-link>
                                <el-link :disabled="rolesShow(scope.row)" type="info"
                                         @click="deleteHandle(scope.row)">注销
                                </el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!--            <pagination-component :limit="userListPageSize" :total="userListTotal" @current-change="currentChange"/>-->
            <pagination-component :page-size="userListPageSize" :total="userListTotal" pageSizes
                                  @current-change="currentChange"
                                  @sizes-change="handleSizeChange"/>
        </div>
        <!--添加/修改用户-->
        <dialog-component :title="dialogTitle"
                          :visible="visibleDialog"
                          @cancel-handle="dialogCloseHandle"
                          @confirm-handle="watchConfirmHandle">
            <el-form ref="userRef" :model="addUserForm" :rules="addUserFormRules" label-width="110px" size="large">
                <el-form-item label="账号：" prop="username">
                    <el-input v-model="addUserForm.username" placeholder="请填写账号"/>
                </el-form-item>
                <el-form-item label="手机号：" prop="mobile">
                    <el-input v-model="addUserForm.mobile" placeholder="请填写手机号"/>
                </el-form-item>
                <el-form-item label="真实姓名：" prop="nickname">
                    <el-input v-model="addUserForm.nickname" placeholder="请填写真实姓名"/>
                </el-form-item>
                <el-form-item label="单位：" prop="unitName">
                    <el-input v-model="addUserForm.unitName" placeholder="请填写真实姓名"/>
                </el-form-item>
                <el-form-item label="部门：" prop="departName">
                    <el-input v-model="addUserForm.departName" placeholder="请填写真实姓名"/>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model="addUserForm.password" placeholder="请填写密码"/>
                </el-form-item>
                <!--                <el-form-item label="角色：" prop="roleId">-->
                <!--                    <el-select v-model="addUserForm.roleId">-->
                <!--                        <el-option v-for="item in allRoleList"-->
                <!--                                   :key="item.id"-->
                <!--                                   :label="item.name"-->
                <!--                                   :value="item.id"></el-option>-->
                <!--                    </el-select>-->
                <!--                </el-form-item>-->
            </el-form>
        </dialog-component>
        <!--审核/角色分配用户-->
        <dialog-component :title="auditDialogTitle" :visible="auditVisibleDialog"
                          @cancel-handle="auditVisibleDialog=false"
                          @confirm-handle="auditConfirmHandle">
            <el-form :model="auditForm" label-width="110px" size="large">
                <el-form-item label="账号：">
                    <el-input v-model="auditForm.checkUserName"/>
                </el-form-item>
                <el-form-item v-if="auditDialogTitle==='用户审核'" label="审核：">
                    <el-select v-model="auditForm.checkStatus">
                        <el-option label="通过" value="1">通过</el-option>
                        <el-option label="不通过" value="2">不通过</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="auditDialogTitle==='角色分配'" label="角色：">
                    <el-select v-model="auditForm.roleIds">
                        <el-option v-for="item in allotRoleList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </dialog-component>
        <!--查看用户详情弹窗-->
        <dialog-component :visible="userLookDialog"
                          title="用户详情"
                          width="800px"
                          @cancel-handle="userLookDialog=false"
                          @confirm-handle="userLookDialog=false">
            <div class="default_table_style table_bg">
                <el-table :data="userInfoData" size="large" stripe>
                    <el-table-column label="账号" prop="username"></el-table-column>
                    <el-table-column label="手机号" prop="mobile"></el-table-column>
                    <el-table-column label="真实姓名" prop="nickname"></el-table-column>
                    <el-table-column label="单位" prop="unitName"></el-table-column>
                    <el-table-column label="部门" prop="departName"></el-table-column>
                </el-table>
            </div>
        </dialog-component>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, unref, watchEffect} from "vue";
import commonMethods from "../../../../js/common-methods";
import {userManageApi} from "../../../../api/database-devops-api";
import {CommonSpaceVertical, DialogComponent, PaginationComponent} from '../../../../index'

export default defineComponent({
    name: "user-manage-list-module",
    components: {
        DialogComponent,
        CommonSpaceVertical,
        PaginationComponent
    },
    props: {
        dataIndex: {
            type: String,
            default: null,
        },

        roleCode: {
            type: String,
            default: 'admin-animal',
        }
    },
    setup(props) {
        const userRef = ref(null)
        let dialogTitle = ref(null);
        let visibleDialog = ref(false);
        let auditDialogTitle = ref(null);
        let auditVisibleDialog = ref(false);
        // 检索表单数据
        let userListPageNumber = ref(1);
        let userListPageSize = ref(10);
        let userListTotal = ref(0);
        let timeShow = ref(process.env.VUE_APP_MARKING);
        // 条件检索
        let filterForm = reactive({
            username: null,
            roleId: null
        });
        // 添加用户弹窗
        let addUserForm = reactive({
            mobile: null,
            nickname: null,
            username: null,
            password: null
        });
        // 表单验证
        let addUserFormRules = reactive({
            username: [{required: true, message: "请填写账号", trigger: 'blur'}],
            mobile: [
                {required: true, message: "请填写手机号", trigger: 'blur'},
                {pattern: /^1[3456789]\d{9}$/, message: '手机号码格式不正确', trigger: 'blur'}],
            nickname: [{required: true, message: "请填写真实姓名", trigger: 'blur'}],
            password: [{required: true, message: "请填写密码", trigger: 'blur'}],
            unitName: [{required: true, message: "请填写单位名称", trigger: 'blur'}],
            departName: [{required: true, message: "请填写部门名称", trigger: 'blur'}],
        })
        // 审核和分配的弹框
        let auditForm = reactive({});
        // 编辑时存储信息
        let editUserForm = ref(null);
        // 表格数据
        const tableColumns = ref([
            {label: "账号", type: "username", width: 110},
            {label: "手机号", type: "mobile", align: "center", width: 130,},
            {label: "真实姓名", type: "nickname", align: "center", width: 100,},
            {label: "单位", type: "unitName", align: "center"},
            {label: "部门", type: "departName", align: "center"},
        ]);
        let tableData = ref([]);
        let allRoleList = ref([]);
        // 分配角色列表
        let allotRoleList = ref([])
        // 获取用户列表
        const getUserList = async function () {
            let data = {
                page: userListPageNumber.value,
                limit: userListPageSize.value,
                ...filterForm,
            }
            let result = await userManageApi.getUserList(data);
            tableData.value = result.data;
            userListTotal.value = result.count;
        }
        const currentChange = function (num) {
            userListPageNumber.value = num;
            getUserList();
        };
        const handleSizeChange = function (val) {
            userListPageSize.value = val;
            currentChange(1);
        }
        // 获取所有角色列表
        const getAllRoleList = async function () {
            let data = {
                page: userListPageNumber.value,
                limit: userListPageSize.value,
                nature: props.dataIndex,
            }
            let result = await userManageApi.getAllRoleList(data);
            allRoleList.value = result["datas"];
            if (timeShow.value === "0") {
                allotRoleList.value = result["datas"];
            } else {
                allotRoleList.value = result["datas"].filter(item => {
                    return !/(管理员|游客)/.test(item.name)
                })
            }
        }

        // 启用状态事件处理
        const changeSwitchHandle = item => {
            let params = {
                id: item.id,
                enabled: item.enabled
            }
            commonParamsInterface(params, "状态更新");
        }
        // 添加用户事件处理
        const addHandle = () => {
            dialogTitle.value = "添加用户";
            visibleDialog.value = true;
        }

        // 添加用户信息弹窗确定事件处理
        const watchConfirmHandle = async () => {
            const form = unref(userRef)
            if (!form) return
            try {
                await form.validate()
                commonParamsInterface({...addUserForm, checkUserId: commonMethods.getCookie("uId")}, "添加用户");
                visibleDialog.value = false;
            } catch (error) {
                return error
                // ElMessage.error('必填项不能为空')
            }
            if (editUserForm.value) {
                // commonParamsInterface({...addUserForm, id: editUserForm.value.id}, "编辑用户");
            } else {

            }
        }
        // 添加用户弹出框关闭事件
        const dialogCloseHandle = () => {
            visibleDialog.value = false
            editUserForm.value = null
            for (const form in addUserForm) {
                addUserForm[form] = null;
            }
        }

        const auditConfirmHandle = async function () {
            let params = {
                ...auditForm,
            }
            let stage = auditDialogTitle.value === "用户审核"
            let result
            if (stage) {
                params.userId = auditUserId.value
                params.checkUserId = commonMethods.getCookie("uId")
                result = await userManageApi.auditUser(params)
            } else {
                params.id = auditUserId.value
                result = await userManageApi.setRoleToUser(params)
            }
            commonMethods.message(result, () => {
                getUserList();
                auditVisibleDialog.value = false
            }, stage ? "用户审核" : "权限分配");
        }

        // 编辑事件处理
        const editHandle = (item) => {
            editUserForm.value = item
            for (const form in addUserForm) {
                addUserForm[form] = item[form];
            }
            addUserForm.roleId = item.roles.length > 0 ? item.roles[0].id : ''

            dialogTitle.value = "修改用户";
            visibleDialog.value = true;
        }
        // 用户审核 / 角色分配事件处理
        let auditUserId = ref(null)
        const auditHandle = (item, title) => {
            Object.keys(auditForm).map(key => delete auditForm[key]);
            auditUserId.value = item.id
            auditForm["checkUserName"] = item.username;
            auditVisibleDialog.value = true
            auditDialogTitle.value = title
        }

        // 删除事件处理
        const deleteHandle = async item => {
            await commonMethods.deleteDialog();
            await commonParamsInterface({id: item.id}, "删除用户");
        }

        const resetPwdHandle = async item => {
            await commonMethods.deleteDialog({
                text: "确认重置密码为： 123456",
                type: null
            });
            await commonParamsInterface(item.id, "重置密码");
        }

        // 获取角色名称
        const getRolesName = function (list) {
            let name = ""
            for (const item of list) {
                if (props.dataIndex === item.nature) {
                    name = item.name
                }
            }
            return name;
        }

        // 公共参数接口
        const commonParamsInterface = async function (params, tip) {
            let result
            if (tip === "重置密码") {
                // 重置密码接口是put请求
                result = await userManageApi.resetPwd(params);
            } else if (tip === "删除用户") {
                result = await userManageApi.deleteUser(params);
            } else if (tip === "状态更新") {
                result = await userManageApi.editUserStage(params)
            } else {
                // result = await userManageApi.saveOrUpdateUser(params)
                result = await userManageApi.saveUser(params)
            }
            commonMethods.message(result, () => {
                getUserList();
                dialogCloseHandle();
            }, tip);
        }

        //查看用户详情
        let userLookDialog = ref(false);
        let userInfoData = ref(null)
        const lookHandle = function (row) {
            userInfoData.value = [row]
            userLookDialog.value = true;
        }
        watchEffect(function () {
            if (userLookDialog.value === false) {
                userInfoData.value = null
            }
        })
        const rolesAllocation = async function (val) {
            getUserList();
            return val
            // if (val) {
            //     let data = {
            //         code: val,
            //     }
            //     let result = await roleManageApi.getRoleList(data);
            //     filterForm.roleId = result['data'][0].id
            //     getUserList();
            // } else {
            //     getUserList();
            // }
        }


        const rolesShow = function (val) {
            if (val.roles.length > 0 && val.roles[0].code === 'admin')
                return true
        }

        let userPower = ref(false)
        // const getUserRoles = async function () {
        //     let res = await userApi.getCurrentUser()
        //     let roles = res['datas'].roles
        //     for (const role of roles) {
        //         if (role.nature === '1' && role.code === 'admin') {
        //             return (userPower.value = false , rolesAllocation('admin-animal'))
        //         } else if (role.nature === '2' && role.code === 'admin') {
        //             return (userPower.value = false, rolesAllocation('admin-plant'))
        //         } else if (role.nature === '3' && role.code === 'admin') {
        //             return (userPower.value = false , rolesAllocation('admin-dna'))
        //         } else if (role.nature === '4' && role.code === 'admin') {
        //             return (userPower.value = false , rolesAllocation('admin-carbon'))
        //         } else {
        //             return (userPower.value = true , rolesAllocation(null))
        //         }
        //     }
        // }

        // 时间格式化
        const formatData = function (data) {
            return commonMethods.momentFormat(data, "YYYY-MM-DD HH:mm:ss")
        }

        onMounted(function () {
            getAllRoleList();
            // getUserRoles();
            getUserList();
            // rolesAllocation();
        })

        return {
            userRef,
            userListPageNumber,
            userListPageSize,
            userListTotal,
            filterForm,
            addUserForm,
            addUserFormRules,
            auditForm,
            tableData,
            tableColumns,
            allRoleList,
            allotRoleList,
            visibleDialog,
            dialogTitle,
            auditDialogTitle,
            auditVisibleDialog,
            userLookDialog,
            userInfoData,
            editHandle,
            auditHandle,
            deleteHandle,
            addHandle,
            changeSwitchHandle,
            watchConfirmHandle,
            auditConfirmHandle,
            dialogCloseHandle,
            resetPwdHandle,
            getUserList,
            getRolesName,
            currentChange,
            handleSizeChange,
            lookHandle,
            rolesAllocation,

            rolesShow,
            userPower,
            formatData,
            timeShow
        }
    }
})
</script>


<style lang="scss">
@import "../../../../../src/scss/commonFunc";

.user-manage-list {
    display: flex;
    flex-direction: column;

    .filter_section {
        display: flex;
        //width: 100%;

        .el-row {
            flex: 1;
            width: 0;

            .el-form-item {
                margin-bottom: 0;
            }
        }

        .el-button {
            margin-left: auto;
        }
    }

    .container_content_box {
        @include bgColor(white);
        margin-top: pxToRem(20);

        .add_role {
            margin-bottom: pxToRem(20);
            width: max-content;
        }

        .default_table_style {
            margin-bottom: pxToRem(30);

            .pass {
                @include color($defaultColor);
            }

            .no-pass {
                @include color(#D10000);
            }

            .await {
                @include color(#999999);
            }
        }

        .pagination-component {
            display: flex;
            justify-content: flex-end;
            align-content: center;
            padding: 0;
        }
    }
}
</style>
