<template>
    <div class="sample-center-list default_page_style default_wrapper">
        <div class="left_catalog_section">
            <el-tree ref="treeRef" :data="treeData" :props="defaultProps" show-checkbox
                     @check-change="handleCheckChange"/>
        </div>
        <div class="right_content_section">
            <div class="ruins-mode-component">
                <el-form :model="queryForm" class="filter_section" size="large">
                    <el-form-item label="遗址：" style="min-width: 300px">
                        <el-input v-model="queryForm.ruinsName" placeholder="遗址名称" clearable @clear="clearForm"/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryHandle">查询</el-button>
                        <el-button type="warning" @click="viewerSample" v-if="index === '2'">样本列表</el-button>
                        <el-button type="success" @click="viewerMap">进入地图</el-button>
                    </el-form-item>
                </el-form>
                <div class="content_section sample-center-list-table">
                    <div class="default_table_style table_bg">
                        <el-table :data="tableData" size="large" stripe>
                            <el-table-column label="序号" type="index" width="100"></el-table-column>
                            <el-table-column label="遗址名称" prop="ruinsName"></el-table-column>
                            <el-table-column label="遗址单位号" prop="ruinsUnitNumber"></el-table-column>
                            <el-table-column label="行政区划" prop="administrative"></el-table-column>
                            <el-table-column label="经度" prop="longitudeStr"></el-table-column>
                            <el-table-column label="纬度" prop="latitudeStr"></el-table-column>
                            <el-table-column label="海拔（米）" prop="altitude"></el-table-column>
                            <el-table-column align="center" label="操作">
                                <template #default="scope">
                                    <div class="table_operate_group">
                                        <span @click="viewerHandle(scope.row)">进入遗址</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <pagination-component :page-size="pageSize" :total="total" pageSizes
                                          @current-change="currentChange"
                                          @sizes-change="handleSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import commonMethods from "../../../js/common-methods";
import {ruinsModeApi} from "../../../api/sample-manage-common-api";

export default defineComponent({
    name: "ruins-component",
    setup() {
        let router = useRouter();
        let index = ref(process.env.VUE_APP_MARKING)
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);
        // 目录数据
        let tableData = ref([]);
        let treeRef = ref(null);
        let treeData = ref([]);
        let queryForm = reactive({
            ruinsName: null
        });

        // 进入遗址查看事件处理
        const viewerHandle = item => {
            router.push({name: "sampleCenterDetail", query: {ruinsId: item.id, title: item.ruinsName}})
        }
        // 查询按钮事件处理
        let currentTree = ref([])
        let queryHandle = () => {
            currentChange(1);
        }
        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getRuinsList();
        }
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }

        // 获取全部遗址数据
        const getAllRuinsList = async function () {
            // let result = await ruinsModeApi.getAllRuinsList(props.dataIndex);
            // treeData.value = commonMethods.constructionTreeData(result["data"]);
            let result
            let params = {
                pageNum: pageNumber.value,
                limit: pageSize.value,
            }
            let dataIndex = process.env.VUE_APP_MARKING
            if (process.env.VUE_APP_MARKING === '2') {
                params.examineStatus = '1'
                result = await ruinsModeApi.getPlantRuinsList(params, "pages", dataIndex);
            } else {
                result = await ruinsModeApi.getRuinsList(params, "pages", dataIndex);
            }
            // let result = await ruinsModeApi.getRuinsList(params, "pages", props.dataIndex);
            treeData.value = commonMethods.constructionTreeData(result["data"]);
        }
        // 获取遗址列表
        const getRuinsList = async function () {
            let params = {
                ...queryForm,
                areas: currentTree.value.length > 0 ? encodeURIComponent(JSON.stringify(currentTree.value)) : "",
                pageNum: pageNumber.value,
                limit: pageSize.value,

            }
            let dataIndex = process.env.VUE_APP_MARKING
            let result
            if (process.env.VUE_APP_MARKING === '2') {
                params.examineStatus = '1'
                result = await ruinsModeApi.getPlantRuinsList(params, "pages", dataIndex);
            } else {
                result = await ruinsModeApi.getRuinsList(params, "pages", dataIndex);
            }

            for (const list of result.data) {
                list.administrative = commonMethods.getAdministrativeDivision(list)
            }
            tableData.value = result.data;
            total.value = result.count;
        }

        const defaultProps = reactive({
            children: 'children',
            label: "label"
        })
        // 树结构选择
        const handleCheckChange = function () {
            getTreeData();
        }
        // 获取左侧目录树选中的数据
        const getTreeData = function () {
            let list = treeRef.value.getCheckedNodes()
            let data = [];
            for (const item of list) {
                if (item.type === "county") {
                    data.push({province: item.province, city: item.pid, county: item.label})
                }
            }
            currentTree.value = data
        }

        // 进入地图点击事件
        const viewerMap = function () {
            router.push({name: 'sampleCenterList'})
        }
        // 样本列表点击事件
        const viewerSample = function () {
            router.push({name: 'sampleCenterSampleList'})
        }

        const clearForm = function () {
            getRuinsList();
        }


        onMounted(function () {
            getAllRuinsList();
            getRuinsList();
        })
        return {
            pageSize,
            total,
            treeData,
            treeRef,
            defaultProps,
            queryForm,
            tableData,
            queryHandle,
            currentChange,
            handleSizeChange,
            viewerHandle,
            handleCheckChange,
            viewerMap,
            clearForm,
            index,
            viewerSample
        }
    }
})
</script>

<style lang="scss">
@import "./list";
</style>