<template>
    <div class="literature-manage-publish">
        <breadcrumb-component max/>
        <div class="container_content_box default_page_style default_wrapper">
            <literature-publish-form-component
                ref="publishRef"
                :is-edit="isEdit"
                :id="id"
                :publish-callback-event="publishEvent"/>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {useRoute, useRouter} from 'vue-router'
import {LiteraturePublishFormComponent, BreadcrumbComponent} from "../../../index";
import literatureManageApi from "../../../api/literature-manage-api";
import commonMethods from "../../../js/common-methods";

export default defineComponent({
    name: "literature-manage-publish-module",
    components: {LiteraturePublishFormComponent, BreadcrumbComponent},
    props: {
        detailUrl: {
            type: String,
            default: null
        },
        publishUrl: {
            type: String,
            default: null
        },
        listName: {
            type: String,
            default: "literatureList"
        }
    },
    setup(props) {
        let publishRef = ref(null);
        let route = useRoute();
        let router = useRouter();
        let id = ref(route.query.id);
        // 是否为编辑状态
        let isEdit = ref(id.value ? true : false);
        // 获取详情数据
        const getDetailData = function () {
            literatureManageApi.getLiteratureDetail({id: id.value}).then(function (msg) {
                for (const form in publishRef.value.publishForm) {
                    publishRef.value.publishForm[form] = msg[form] === "null" ? null : msg[form];
                    if (msg.documentFileList.length > 0){
                        publishRef.value.uploadRef.fileList = msg.documentFileList
                    }
                }
                // publishRef.value.visibleUpload.value = msg['documentFileList'].length > 0;
            })
        }
        // 发布回调
        const publishEvent = function (result) {
            commonMethods.message(result, () => {
                router.push({name: props.listName});
            }, isEdit.value ? '编辑' : '发布')
        }

        onMounted(() => {
            if (isEdit.value) {
                getDetailData();
            }
        })

        return {
            publishRef,
            isEdit,
            id,
            publishEvent
        }
    }
})
</script>

<style lang="scss">
@import "../../../scss/commonFunc";

.literature-manage-publish {
    flex: 1;
    display: flex;
    flex-direction: column;

    .container_content_box {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .el-form {
        flex: 1;
        display: flex;
        flex-direction: column;
        @include bgColor(white);
        padding: pxToRem(20) pxToRem(40);

        .upload_file {
            .el-form-item__content {
                min-height: pxToRem(138);
            }
        }
    }

    .remark {
        .el-textarea__inner {
            min-height: pxToRem(85) !important;
        }
    }

    .literature_publish_btn_group {
        margin-top: auto;
        margin-bottom: 0;

        .el-form-item__content {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: pxToRem(35);

            .el-space__item {
                &:last-of-type {
                    margin-right: 0 !important;
                }
            }
        }
    }
}
</style>
