import requestInterface from "archaeometry-common-components/src/js/request-interface";

// 样本采集api
const collectionApi = {
    // 公共部分
    // 获取code列表
    async getCodeList() {
        const result = await requestInterface.requestGet("plant-arch/dict/codelist");
        let objList = {};
        for (const obj of result['datas']) {
            objList[obj.key] = obj.value;
        }
        return objList;
    },
    // 根据code获取子级列表
    async getCodeByChildList(code) {
        const result = await requestInterface.requestGet("plant-arch/dict/sonlist", {code});
        return result['datas'];
    },
    //   ------------------------------遗址信息start
    /**
     * 查询所有遗址名称 allFind / 根据id查询 findById
     * @returns {*}
     * @param params
     * @param type
     */
    getRuinsName(type, params) {
        return requestInterface.requestGet(`plant-arch/ruins/${type}`, params);
    },
    /**
     * 新增遗址
     * @returns {*}
     * @param params
     */
    addRuins(params) {
        return requestInterface.requestPost("plant-arch/ruins/save", params)
    },
    //   ------------------------------遗址信息end
    // 单位信息采集
    /**
     * 查询遗址分区列表/查询遗迹单位列表
     * @returns {*}
     * @param type
     * @param params
     */
    async getUnitCommonList(type, params) {
        let result = await requestInterface.requestGet(`plant-arch/ruinsInfo/list/${type}`, params)
        let objList = [];
        for (const obj of result['datas']) {
            objList.push({name: obj})
        }
        return objList;
    },
    /**
     * 新增和编辑单位信息
     * @returns {*}
     * @param type
     * @param params
     */
    addOrUpdateUnitInfo(params, type) {
        return requestInterface.requestPost(`plant-arch/ruinsInfo/${type ? "update" : "save"}`, params)
    },
    /**
     * 根据id查询单位采集信息
     * @returns {*}
     * @param id
     */
    getUnitInfo(id) {
        return requestInterface.requestGet("plant-arch/ruinsInfo/find", {id})
    },

    // 剖面信息采集
    /**
     * 新增和编辑剖面信息采集
     * @returns {*}
     * @param type
     * @param params
     */
    addOrUpdateProfileInfo(params, type) {
        return requestInterface.requestPost(`plant-arch/profile/${type ? "update" : "save"}`, params, null, null, true)
    },
    /**
     * 根据id查询剖面和残留物信息
     * @returns {*}
     * @param id
     * @param type
     */
    getProfileOrResidueInfo(id, type) {
        return requestInterface.requestGet(`plant-arch/${type}/find`, {id})
    },
    // 残留物信息采集
    /**
     * 新增和编辑残留物信息采集
     * @returns {*}
     * @param type
     * @param params
     */
    addOrUpdateResidueInfo(params, type) {
        return requestInterface.requestPost(`plant-arch/residue/${type ? "update" : "save"}`, params, null, null, true)
    },
    // 标本管理-----------start
    /**
     * 获取拉丁名
     * @returns {*}
     * @param type
     * @param params
     */
    getLatinName(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/list`, params)
    },
    // 基本信息采集 ---------------start
    /**
     * 新增和编辑 基本信息四大类 采集
     * @returns {*}
     * @param params
     * @param type
     * @param stage
     */
    addOrUpdateData(params, type, stage) {
        return requestInterface.requestPost(`plant-arch/${type}/${stage ? "update" : "save"}`, params, null, null, true)
    },
    /**
     * 查询基本信息四大类分页列表
     * @returns {*}
     * @param params
     * @param type
     */
    getCurrentDataList(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/page`, params)
    },
    /**
     * 删除基本信息四大类信息
     * @returns {*}
     * @param id
     * @param type
     */
    deleteCurrentData(id, type) {
        return requestInterface.requestGet(`plant-arch/${type}/delete`, {id})
    },
    /**
     * 查询基本信息四大类详细信息
     * @returns {*}
     * @param id
     * @param type
     */
    getCurrentDataInfo(id, type) {
        return requestInterface.requestGet(`plant-arch/${type}/find`, {id})
    },
    // 基本信息采集 ---------------end

    // 图像信息采集 ---------------start
    /**
     * 新增和编辑 图像信息
     * @returns {*}
     * @param params
     * @param stage
     */
    addOrUpdateImageData(params, stage) {
        return requestInterface.requestUploadFile(`plant-arch/photoInfo/photoInfo/${stage ? "update" : "save"}`, params)
    },
    /**
     * 查询图像信息分页列表
     * @returns {*}
     * @param params
     */
    getImageDataList(params) {
        return requestInterface.requestGet("plant-arch/photoInfo/page", params)
    },
    /**
     * 删除 图像信息
     * @returns {*}
     * @param id
     */
    deleteImageData(id) {
        return requestInterface.requestDelete(`plant-arch/photoInfo/photoInfo/${id}`)
    },
    // 图像信息采集 ---------------end

    // 三维信息采集 ---------------start
    /**
     * 新增和编辑 三维信息
     * @returns {*}
     * @param params
     * @param stage
     */
    addOrUpdateThreeData(params, stage) {
        return requestInterface.requestUploadFile(`plant-arch/threeDimensionInfo/ThreeDimensionInfo/${stage ? "update" : "save"}`, params)
    },
    /**
     * 查询三维信息分页列表
     * @returns {*}
     * @param params
     */
    getThreeDataList(params) {
        return requestInterface.requestGet("plant-arch/threeDimensionInfo/page", params)
    },
    /**
     * 删除 三维信息
     * @returns {*}
     * @param id
     */
    deleteThreeData(id) {
        return requestInterface.requestDelete(`plant-arch/threeDimensionInfo/ThreeDimensionInfo/${id}`)
    },
    // 图像信息采集 ---------------end

    // 检测信息采集 ---------------start
    /**
     * 新增和编辑 检测信息(碳氮同位素、碳十四测年）
     * @returns {*}
     * @param params
     * @param type
     * @param stage
     */
    addOrUpdateDetectionData(params, type, stage) {
        return requestInterface.requestPost(`plant-arch/${type}/${stage ? "update" : "save"}`, params, null, null, true)
    },
    // 检测信息采集 ---------------end

    // 样本统计采集
    addOrUpdateSampleInfo(params, type, stage) {
        return requestInterface.requestPost(`plant-arch/literature/${type}/${stage ? "update" : "save"}`, params, null, null, true)
    },
    /**
     * 样本统计分页列表
     * @returns {*}
     * @param params
     * @param type
     * @param stage
     */
    getSampleDataList(params, type, stage) {
        return requestInterface.requestGet(`plant-arch/literature/${type ? "sample" : "phytolith"}/${stage ? "find" : "page"}`, params)
    },
    /**
     * 样本统计删除
     * @returns {*}
     * @param id
     * @param type
     */
    deleteLiteratureSample(id, type,) {
        return requestInterface.requestGet(`plant-arch/literature/${type ? "sample" : "phytolith"}/delete`, {id})
    }
}

// 原始数据列表Api
const originalDataApi = {
    /**
     * 遗址分页列表 / 全部
     * @returns {*}
     * @param params
     * @param type
     */
    getRuinsList(params, type) {
        return requestInterface.requestGet(`plant-arch/ruins/${type ? "allFind" : "page"}`, params);
    },
    /**
     * 批量共享/批量审核
     * @param type
     * @param params
     * @returns {*}
     */
    batchOperationData(type, params) {
        return requestInterface.requestPost(`plant-arch/${type}/save`, params);
    },
    /**
     * 查询剖面背景分页列表
     * @param type
     * @param params
     * @returns {*}
     */
    getBgDataList(type, params) {
        return requestInterface.requestGet(`plant-arch/${type}/page`, params);
    },
    /**
     * 根据id查询详情
     * @param type
     * @param id
     * @returns {*}
     */
    findByIdGetInfo(type, id) {
        return requestInterface.requestGet(`plant-arch/${type}/find`, {id});
    },
    // 获取植物遗址信息
    getIdFindRuins(id) {
        return requestInterface.requestGet("plant-arch/ruins/findById", {id});
    },
    // 获取植物遗迹单位信息
    getRuinsUnitInfo(id){
        return requestInterface.requestGet("plant-arch/ruinsInfo/find", {id});
    },
    /**
     * 遗址删除接口
     * @returns {*}
     * @param id
     */
    deleteRuins(id) {
        return requestInterface.requestDelete(`plant-arch/ruins/delete/${id}`);
    },
    /**
     * 查询碳氮同位素检测列表
     * @param params
     * @param type
     * @returns {*}
     */
    getDetectionList(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/list`, params);
    }
}


export {
    collectionApi,
    originalDataApi,
}