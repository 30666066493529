<template>
    <div class="carousel-component">
        <div class="img_box">
            <img :src="currentIndex" @error="errorImage($event)" @click="viewerPicture" alt="">
        </div>
        <div class="img_slider">
            <div class="swiper picture_swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(img,i) in imgList" :key="i">
                        <img :src="img" alt="" @error="errorImage($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue'
import globalMixin from '../../mixins/global-mixin'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import commonMethods from '../../js/common-methods'

export default defineComponent({
    name: "carousel-component",
    props: {
        imgList: {
            type: Array,
            default: () => []
        },
        index: {
            type: Number,
            default: 0
        }
    },
    mixins: [globalMixin],
    setup(props) {
        let currentIndex = ref(props.imgList[0]);
        let swiper = null;
        const initSwiper = (num) => {
            swiper = new Swiper(".picture_swiper", {
                initialSlide: props.index,
                spaceBetween: 10,
                slidesPerView: num,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true
                },
                on: {
                    slideChangeTransitionEnd: function () {
                        currentIndex.value = this.imagesToLoad[this.activeIndex].currentSrc;
                    }
                }
            })
        }
        // 预览图片
        const viewerPicture = () => {
            // const {imagesToLoad, activeIndex} = swiper;
            // let imgList = [];
            // for (const img of Object.values(imagesToLoad)) {
            //     imgList.push(img.currentSrc);
            // }
            // commonMethods.showMaxImgList(imgList, activeIndex);
            let current = props.imgList.indexOf(currentIndex.value);
            commonMethods.showMaxImgList(props.imgList, current);
        }

        onMounted(() => {
            let num = props.imgList.length > 4 ? 4 : props.imgList.length
            initSwiper(num);
        })

        return {
            currentIndex,
            viewerPicture
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.carousel-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .img_box {
        flex: 1;
        height: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .img_slider {
        height: pxToRem(75);
        margin-top: pxToRem(10);

        .picture_swiper {
            width: 100%;
            height: 100%;
            overflow: hidden;

            .swiper-slide {
                height: 100%;
                background-color: #7DC2BD;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 1px solid #E0E0E0;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>