<template>
    <div class="footer-min-component footer-component">
        <ul class="default_page_style">
            <li v-for="(item,i) in copyrightLis" :key="i">
                <span v-text="item.name+'：'"></span>
                <a :class="{'active':item.url}" :href="item.url?item.url:'javascript:;'" target="_blank"
                   v-text="item.value"></a>
            </li>
        </ul>
    </div>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
    name: "footer-min-component",
    setup() {
        const copyrightLis = ref([
            {name: "版权所有", value: "国家文物局考古研究中心", url: ""},
            {name: "技术支持", value: "西安云图信息技术有限公司", url: "//www.geoyt.cn"},
        ]);
        return {
            copyrightLis
        }
    }
})
</script>

<style lang="scss">
@import "./footer-component";
</style>