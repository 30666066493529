<template>
    <div class="literature-manage">
        <div class="filter_query_section">
            <el-form ref="searchRef" :model="searchForm" class="default_page_style" size="large">
                <el-row :gutter="43">
                    <el-col :span="8">
                        <el-form-item label="文献名称：" prop="documentName">
                            <el-input v-model.trim="searchForm.documentName" placeholder="文献名称"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="作者：" prop="author">
                            <el-input v-model.trim="searchForm.author" placeholder="作者"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="期刊：" prop="publication">
                            <el-input v-model.trim="searchForm.publication" placeholder="期刊"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所涉遗址：" prop="involvedRuins">
                            <el-input v-model.trim="searchForm.involvedRuins" placeholder="所涉遗址"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item class="operate_control">
                            <el-space :size="20">
                                <el-button type="primary" @click="queryForm">查询</el-button>
                                <el-button @click="resetFrom">重置</el-button>
                            </el-space>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="container_content_box default_page_style default_wrapper">
            <div class="content_section default_space_style">
                <div class="head_section">
                    <h3>文献列表</h3>
                    <el-button class="publish_literature" type="primary" @click="publishLiteratureHandle">发布文献
                    </el-button>
                </div>
                <ul class="list_container">
                    <li v-for="(item,i) in dataList" :key="i">
                        <div class="list_container-image" @click="viewerLiteratureHandle(item)">
                            <i :class="`${item.documentFileList.length > 0 ? 'iconfont icon-pdf' : 'iconfont icon-lianjie'}`"/>
                            <!--                            <img-->
                            <!--                                :src="item.documentFileList.length > 0 ? fileUrl + '/' + item.documentFileList[0].thumPath : null"-->
                            <!--                                alt=""-->
                            <!--                                @error="errorImage($event)">-->
                            <!--                            <div class="shadow_img">-->
                            <!--                                <div class="iconfont icon-chakan"/>-->
                            <!--                                <p>预览</p>-->
                            <!--                            </div>-->
                        </div>
                        <div class="center_content">
                            <h3 class="literature-title"
                                @click="viewerLiteratureHandle(item)"
                                v-text="item.documentName === 'null'  ? '' :  item.documentName"/>
                            <p>
                                <common-space-vertical :size="24">
                                    <span>作者：{{ item.author === "null" ? '' : item.author }}</span>
                                    <span>出版社：{{ item.press === "null" ? '' : item.press }}</span>
                                    <span>发表时间：{{ item.releaseYear === "null" ? '' : item.releaseYear }}</span>
                                </common-space-vertical>
                            </p>
                            <p>
                                <common-space-vertical :size="24">
                                    <span>文献来源：{{ item.publication === "null" ? '' : item.publication }}</span>
                                    <span>期：{{ item.stage === "null" ? '*' : item.stage }}</span>
                                    <span>卷：{{ item.volume === "null" ? '*' : item.volume }}</span>
                                    <span>页码：第{{ item.pageNumber === "null" ? '*' : item.pageNumber }}页</span>
                                </common-space-vertical>
                            </p>
                            <p>
                                <common-space-vertical :size="24">
                                    <span>上传者：{{ item.publisher }}</span>
                                    <span>上传时间：{{ timeFormat(item.createTime) }}</span>
                                </common-space-vertical>
                            </p>
                            <p>
                                <span style="cursor:pointer" @click="redirectUrl(item.documentLink)">文献链接：{{
                                        item.documentLink === "null" ? '' : item.documentLink
                                    }}</span>
                            </p>
                            <p>
                                <span>所涉遗址：{{ item.involvedRuins === "null" ? '' : item.involvedRuins }}</span>
                            </p>
                        </div>
                        <div class="operate_section">
                            <common-space-vertical :size="9">
                                <el-link :disabled="userId !== item.creater" type="primary"
                                         @click="editLiteratureHandle(item)">编辑
                                </el-link>
                                <el-link :disabled="userId !== item.creater" type="info"
                                         @click="deleteLiteratureHandle(item)">删除
                                </el-link>
                            </common-space-vertical>
                        </div>
                    </li>
                </ul>
                <el-empty v-if="dataList.length<1"/>
                <pagination-component :page-size="limit" :total="total" @current-change="currentChange"/>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import globalMixin from "../../../mixins/global-mixin";
import {useRouter} from "vue-router";
import literatureManageApi from "../../../api/literature-manage-api";
import {ElMessage} from "element-plus";
import commonMethods from "../../../js/common-methods";

export default defineComponent({
    name: "literature-manage-list-module",
    mixins: [globalMixin],
    props: {
        url: {
            type: String,
            default: null
        },
        publishName: {
            type: String,
            default: "literaturePublish"
        },
    },
    setup(props) {
        let searchRef = ref(null);
        let userId = commonMethods.getCookie('uId')
        let limit = ref(10);
        let page = ref(1);
        let total = ref(0);
        let visiblePdf = ref(false);
        let pdfUrl = ref(null);
        let searchForm = reactive({
            documentName: "",
            author: "",
            publication: "",
            involvedRuins: "",
        })

        // 数据列表
        const dataList = ref([])

        // 路由 跳转页面
        let router = useRouter();

        // 发布文献
        const publishLiteratureHandle = () => {
            router.push({name: props.publishName})
        }

        // 查看文献
        const viewerLiteratureHandle = (item) => {
            console.log(item)
            let fileList = item['documentFileList'];
            if (fileList.length > 0) {
                pdfUrl.value = fileUrl.value + "/" + fileList[0].filePath;
                closePdfViewer(fileList[0]);
            } else {
                if (/^(http|https):\/\//.test(item.documentLink)) {
                    pdfUrl.value = item.documentLink;
                    window.open(pdfUrl.value)
                } else {
                    ElMessage.error("该文献不能预览");
                }
            }
        }

        // 编辑文献
        const editLiteratureHandle = (item) => {
            router.push({name: props.publishName, query: {id: item.id}})
        }

        // 删除文献
        const deleteLiteratureHandle = (item) => {
            commonMethods.deleteDialog().then(async () => {
                let result = await literatureManageApi.deleteLiterature(item.id);
                commonMethods.message(result, () => getDataList());
            });
        }

        // 获取数据列表
        const getDataList = async function () {
            let result = await literatureManageApi.getDataList({
                ...searchForm,
                limit: limit.value,
                page: page.value,
                existIndex: true
            });
            dataList.value = result.data;
            total.value = result.count;
        }

        // 查询文献
        const queryForm = function () {
            page.value = 1;
            getDataList();
        }
        // 重置查询
        const resetFrom = function () {
            searchRef.value.resetFields();
            page.value = 1;
            getDataList();
        }

        // 分页事件
        const currentChange = function (num) {
            page.value = num;
            getDataList();
        }
        // 关闭pdf预览
        const closePdfViewer = function (item) {
            // commonMethods.previewPlusForSystem(item);
            let filePath = commonMethods.getCookie('moUrl') + '/' + item.filePath;
            commonMethods.previewPlus(filePath, filePath);
            // let routerUrl = router.resolve({
            //     name: "previewPdf",
            //     query: {
            //         url: pdfUrl.value
            //     }
            // })
            //  window.location.href = routerUrl.href;

        }

        // 文献封面地址
        let fileUrl = ref(null)
        const literatureCover = async function () {
            let res = await literatureManageApi.getFilePath()
            fileUrl.value = res['resp_msg']
        }
        // 文献链接条状
        const redirectUrl = function (url) {
            if (url !== "null" && url) {
                window.open(url)
            }
        }
        const timeFormat = function (time) {
            return commonMethods.momentFormat(time)
        }
        onMounted(() => {
            getDataList();
            literatureCover();
        })

        return {
            dataList,
            searchForm,
            total,
            limit,
            visiblePdf,
            searchRef,
            pdfUrl,
            fileUrl,
            publishLiteratureHandle,
            editLiteratureHandle,
            deleteLiteratureHandle,
            viewerLiteratureHandle,
            queryForm,
            resetFrom,
            currentChange,
            closePdfViewer,
            redirectUrl,
            userId,
            timeFormat
        }
    }
})
</script>

<style lang="scss">
@import "./list";
</style>
