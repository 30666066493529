<template>
    <main-navigation-component :logo="logo" :navigation-list="navigationList"
                               dataIndex
                               @userHandle="watchUserHandle"
                               @navigation-handle="watchNavigationHandle"
                               @control-handle="watchControlHandle"
                               @register-handle="watchRegisterHandle"/>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from 'vue-router'
import navigationConfig from "@/assets/config/navigation-config";
import {menuManageApi} from "archaeometry-common-components/src/api/database-devops-api";
import commonMethods from "archaeometry-common-components/src/js/common-methods";

export default defineComponent({
    name: "header-component",
    setup() {
        // logo
        const logo = ref(process.env.VUE_APP_PROJECT_NAME_ZH)
        // 导航列表
        const navigationList = ref(navigationConfig.mainNavigationList);
        // 路由跳转
        let router = useRouter();
        let token = ref(commonMethods.getCookie("safer"))

        // 监听导航事件
        const watchNavigationHandle = path => {
           if (path === 'portalWebsite'){
               router.push({name: path,query:{active:1}});
           }else {
               router.push({name: path});
           }
        }
        // 监听控制台事件
        const watchControlHandle = () => {
            router.push({name: "controlPanel"});
        }
        // 监听注册事件
        const watchRegisterHandle = function () {
            let routerUrl = router.resolve({
                name: "userRegister",
                query: {}
            })
            // window.open(routerUrl.href, "_blank")
            window.location.href = routerUrl.href;
        }
        // 监听用户个人信息点击事件
        const watchUserHandle = function () {
            router.push({name: "personalCenter"});
        }
        // 获取当前用户权限
        const getCurrentUserMenu = async function () {
            navigationList.value = await menuManageApi.getCurrentUserMenu(0);
        }
        onMounted(function () {
            if (token.value) {
                getCurrentUserMenu()
            }
        })
        return {
            logo,
            navigationList,
            watchNavigationHandle,
            watchControlHandle,
            watchRegisterHandle,
            watchUserHandle,
        }
    }
})
</script>

<style lang="scss">
.control_btn {
    display: none;
}
</style>