/**
 * @name preview-file-method
 * @description
 * @author 烟火里的尘埃
 * @Date 2023-08-15 11:27
 */
import commonMethods from "../../js/common-methods";

class PreviewFileMethod {
    constructor() {

    }

    // 预览颜色
    previewColor(suffix) {
        let color = "#53BAD9";
        switch (suffix) {
            case 'pdf':
                color = "#F25E5E";
                break;
            case 'xls':
            case 'xlsx':
            case 'csv':
                color = "#107C41";
                break;
            // case 'doc':
            case 'docx':
                color = "#185ABD";
                break;
            // case 'ppt':
            // case 'pptx':
            //     color = "#C43E1C";
            //     break;
            default:
                break;
        }
        return color;
    }

    // 根据文件后缀获取组件
    getSuffixByComponent(suffix) {
        let component;
        switch (suffix) {
            case 'pdf':
                component = "preview-file-pdf";
                break;
            case 'xls':
            case 'xlsx':
            case 'csv':
                component = "preview-file-excel";
                break;
            // case 'doc':
            case 'docx':
                component = "preview-file-word";
                break;
            // case 'ppt':
            // case 'pptx':
            //     component = "preview-file-ppt";
            //     break;
            case 'mp4':
            case 'm38u':
            case 'webm':
            case 'ogv':
            case 'flv':
            case 'rtmp':
                component = "preview-file-video";
                break;
            case 'mp3':
                component = "preview-file-audio";
                break;
            case 'txt':
            case 'json':
            case 'xml':
            case 'sql':
            case 'yaml':
            case 'js':
            case 'css':
            case 'scss':
            case 'jsx':
            case 'ts':
            case 'tsx':
            case 'html':
            case 'htm':
            case 'yml':
            case 'vue':
            case 'log':
            case 'conf':
            case 'ini':
            case 'markdown':
            case 'md':
            case 'properties':
            case 'license':
                component = "preview-file-text";
                break;
            default:
                break;
        }
        return component;
    }

    // 读取文本文件
    async readTextFile(fileUrl) {
        const suffix = commonMethods.getFileNameSuffix(fileUrl);
        const request = await fetch(fileUrl);
        const blob = await request.blob();
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsText(blob, 'utf-8');
            reader.onload = e => {
                const result = e.target.result;
                let content = result;
                if (suffix === 'json') {
                    content = JSON.stringify(JSON.parse(result), null, 4);
                }
                if (suffix === 'txt') {
                    content = commonMethods.filterDetailInfo(result)
                }
                resolve({
                    suffix,
                    content
                });
            }
        })
    }

}

export default PreviewFileMethod;
