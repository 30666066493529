// 数据库运维接口API
import requestInterface from "../js/request-interface";
import commonMethods from "@acc/js/common-methods";

// 接口标识
let marking = commonMethods.getDatabaseMarking();

const sampleMapApi = {
    /**
     * 根据遗址id查询遗址详情
     * @param params
     */
    getRuinsBasic(params) {
        return requestInterface.requestPost("map-center/ruins/id", params);
    },
    /**
     * 查询 遗址数据量-count/遗址列表-list
     * @param type
     * @param params
     */
    getRuinsList(params, type) {
        return requestInterface.requestPost(`map-center/ruins/${type ? "count" : "list"}`, params);
    },
    /**
     * 查询样本分页列表
     * @param type
     * @param params
     */
    getSamplePageList(type, params) {
        return requestInterface.requestPost(`map-center/${type}/page`, params);
    },
    /**
     * 查询样本分页列表
     * @param type
     * @param params
     */
    getPlantSamplePageList(type, params) {
        return requestInterface.requestPost(`map-center/plant/${type}`, params);
    },
    /**
     * 查询遗址的文化分期
     * @param params
     */
    getRuinsCulture(params) {
        return requestInterface.requestPost("map-center/ruins/culture", params);
    },
    /**
     * 查询遗址的文化分期
     * @param params
     */
    getSampleCount(params) {
        return requestInterface.requestPost("map-center/ruins/sampleCount", params);
    },
    // 获取遗址列表-地图
    getRuinsFind(params, stage) {
        if (marking === 'plant-arch') {
            delete params.queryType;
            // delete params.examineStatus;
            if (params.dataSourcesType === 0) {
                params.examineStatus = "1"
            }
            if (params.dataSourcesType) delete params.dataSourcesType;
            // 属性查询
            if (stage) {
                // return requestInterface.requestPost(marking + "/ruins/findMap/" + params.plantType, params);
                return requestInterface.requestGet(`${marking}/map/${params.plantType}/geom/`, params);
            } else {
                if (params.plantType) delete params.plantType;
                // return requestInterface.requestPost(marking + "/ruins/findMap", params);
                return requestInterface.requestGet(marking + "/map/ruins/list", params);
            }
        } else {
            if (params.plantType) delete params.plantType;
            if (params.dataSourcesType) delete params.dataSourcesType;
            return requestInterface.requestPost(marking + "/ruins/findMap", params);
        }
    },
    // 获取植物样本数据-地图
    getRuinsNum(params) {
        if (marking === 'plant-arch') {
            delete params.queryType
            // 属性查询
            return requestInterface.requestPost(marking + "/ruins/find/sampleQuantity", params);
        }
    },
    // 植物地图初始化时获取遗址下的数据量统计
    getPlantRuinsCount(id) {
        return requestInterface.requestGet(marking + "/map/ruins/sample/count", {ruinsId: id});
    },
    // 获取样本列表-地图
    getSampleList(params) {
        if (marking === 'plant-arch') {
            let param = params['plantType'];
            // param = param.type || "carbonPlant";
            // delete params.queryType
            return requestInterface.requestGet(marking + `/map/${param}/page`, params);
        }
        if (marking === 'dna-arch') {
            return requestInterface.requestPost(marking + "/data/page", params);
        }
        if (marking === 'carbon-arch') {
            return requestInterface.requestPost(marking + "/sample/map", params);
        }
        if (marking === 'animal-arch') {
            return requestInterface.requestPost(marking + "/sample/map", params);
        }
        return requestInterface.requestPost(marking + "/sample/page", params);
    },
    /*
    * 查询地图已发布数据
    * */
    getMapSampleList(params) {
        return requestInterface.requestPost(marking + "/sample/pageMap", params);
    }
}
export {
    sampleMapApi
}
