<template>
    <div class="map-catalog-component">
        <div class="nav_head_section">
            <!--            <div class="back" @click="viewer='list'">-->
            <div class="back" @click="enterList">
                <!--                <span class="iconfont icon-fanhui"></span>-->
                进入列表
            </div>
            <div class="query_input_section">
                <el-input v-model="queryVal" clearable placeholder="请输入遗址名称..." @clear="queryEvent"
                          @keydown.enter="queryEvent"/>
                <div class="query_icon" @click="queryEvent">
                    <el-icon>
                        <Search/>
                    </el-icon>
                </div>
            </div>
        </div>
        <ul class="nav_list">
            <li v-for="(item,i) in navList" :key="i" :class="{active:currentNav===item.type}" @click="navEvent(item)"
                v-text="item.name"></li>
        </ul>
        <div :class="{active:currentNav}" class="nav_body">
            <div :class="{active:currentNav}" class="scroll_container">
                <!--行政区划-->
                <div v-show="currentNav==='division'">
                    <h3 class="module_title">行政区划<span class="iconfont clear_draw icon-shanchu31"
                                                           @click="clearDivision(true)"></span></h3>
                    <city-select-component visible @city-event="cityEvent"/>
                </div>
                <!--空间查询-->
                <space-query-component v-show="currentNav==='space'"/>
                <!--属性查询-->
                <attribute-query-component v-show="currentNav==='attr'">
                    <slot slot="attr-query"/>
                </attribute-query-component>
                <!--   v-if="isPlant && currentNav!=='attr'"-->
                <div v-if="false" class="plant-count">
                    <ul>
                        <li><span>遗址数量：</span><span>{{ plantCount.ruins }}</span></li>
                        <li v-if="dataType === 'carbonPlant'">
                            <span>炭化植物样品数量：</span><span>{{ plantCount.carbonizedPlantCount }}</span></li>
                        <li v-if="dataType === 'phytolith'">
                            <span>植硅体样品数量：</span><span>{{ plantCount.phytolithCount }}</span></li>
                        <li v-if="dataType === 'starchGranule'">
                            <span>淀粉粒样品数量：</span><span>{{ plantCount.starchGranuleCount }}</span></li>
                        <li v-if="dataType === 'charcoal'"><span>木炭样品数量：</span><span>{{
                                plantCount.charcoalCount
                            }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {inject, onMounted, reactive, ref, watchEffect} from "vue";
import {Search} from '@element-plus/icons-vue'
import CitySelectComponent from "@acc/components/city-select-component/city-select-component";
import SpaceQueryComponent from "@acc/components/map-catalog-component/space-query-component";
import AttributeQueryComponent from "@acc/components/map-catalog-component/attribute-query-component";
import {ElMessage} from "element-plus";
import {useRouter} from "vue-router";

export default {
    name: "map-catalog-component",
    components: {
        AttributeQueryComponent,
        SpaceQueryComponent,
        CitySelectComponent,
        Search
    },
    emits: ['queryEvent', 'cityEvent'],
    props: {
        ruinsList: {
            type: Array,
            default: () => []
        },
        dataType: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        let router = useRouter();
        let viewer = inject("viewer");
        let adCode = inject("adCode");
        let navList = ref([
            {name: "政区查询", type: "division"},
            {name: "空间查询", type: "space"},
            {name: "属性查询", type: "attr"}
        ]);
        let currentNav = ref(null);
        let queryVal = inject("queryVal");

        let visiblePanel = ref(false);
        // 导航点击事件
        const navEvent = function (item) {
            currentNav.value = item.type === currentNav.value ? null : item.type;
        }
        // 查询事件
        const queryEvent = function () {
            context.emit("queryEvent",);
        }
        // 行政区选择事件
        const cityEvent = function (city) {
            context.emit("cityEvent", city);
        }
        // 清除行政区划选择
        const clearDivision = function () {
            adCode.value = null;
            ElMessage.success("政区已清空");
        }
        let isPlant = ref(false);

        let plantCount = reactive({
            ruins: 0,
            sampleCount: 0,
            carbonizedPlantCount: 0,
            phytolithCount: 0,
            starchGranuleCount: 0,
            charcoalCount: 0,
        });
        watchEffect(function () {
            for (const key in plantCount) {
                plantCount[key] = 0
            }
            for (const list of props.ruinsList) {
                plantCount.sampleCount += list.sampleCount;
                plantCount.carbonizedPlantCount += list.carbonized;
                plantCount.phytolithCount += list.phytolith;
                plantCount.starchGranuleCount += list.starch;
                plantCount.charcoalCount += list.charcoal;
                plantCount.ruins += list.ruins
            }
            if (process.env.VUE_APP_MARKING === '2') {
                // currentNav.value = 'attr'
            }
        })
        const enterList = function () {
            if (process.env.VUE_APP_MARKING === '3') {
                router.push({name: 'dataCenterSiteModeList'})
            } else {
                router.push({name: 'sampleCenterRuinsList'})
            }
        }
        onMounted(function () {
            if (process.env.VUE_APP_MARKING === '2') {
                isPlant.value = true;
            }
        })
        return {
            navList,
            queryVal,
            viewer,
            queryEvent,
            cityEvent,
            navEvent,
            visiblePanel,
            currentNav,
            clearDivision,
            isPlant,
            plantCount,
            enterList
        }
    }
}
</script>

<style lang="scss">
@import "./map-catalog-component";
</style>
