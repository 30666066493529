<template>
    <div class="skeleton-info-content">
        <table-data-list-component :data="basicInfoList">
            <tr class="basic-content-table-tr">
                <td class="basic-content-table-td-title">
                    <span v-text="'样本描述'"></span></td>
                <td :colspan="5" class="basic-content-table-td-body">
                    <span>{{ described }}</span></td>
            </tr>
            <tr class="basic-content-table-tr">
                <td class="basic-content-table-td-title">
                    <span v-text="'备注'"></span></td>
                <td :colspan="5" class="basic-content-table-td-body">
                    <span>{{ remark }}</span></td>
            </tr>
        </table-data-list-component>
        <el-row>
            <el-col :span="24" style="margin-bottom: 0" class="animal-tooth-info">
                <el-row v-show="/左|右|-$/.test(sampleInfo['boneOrientationName'])" class="skeleton-information">
                    <el-col :span="24">
                        <span class="info-titles">{{
                                `${/左|轴左|-$/.test(sampleInfo['boneOrientationName']) ? '左侧' : '右侧'}恒齿-上牙：`
                            }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUpper">
                            <el-checkbox v-for="(item, i) in permanentToothUpper" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span
                            class="info-titles">{{
                                `${/左|轴左|-$/.test(sampleInfo['boneOrientationName']) ? '左侧' : '右侧'}乳齿-上牙：`
                            }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUpper">
                            <el-checkbox v-for="(item, i) in milkToothUpper" :key="i" :label="item.originName" disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span class="info-titles">{{
                                `${/左|轴左|-$/.test(sampleInfo['boneOrientationName']) ? '左侧' : '右侧'}乳齿-上牙：`
                            }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUnder">
                            <el-checkbox v-for="(item, i) in milkToothUnder" :key="i" :label="item.originName" disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span class="info-titles">{{
                                `${/左|轴左|-$/.test(sampleInfo['boneOrientationName']) ? '左侧' : '右侧'}恒齿-下牙：`
                            }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUnder">
                            <el-checkbox v-for="(item, i) in permanentToothUnder" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col v-if="showAllTooth" :span="24">
                        <span class="info-titles">{{ `右侧恒齿-上牙：` }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUpperRight">
                            <el-checkbox v-for="(item, i) in permanentToothUpperRight" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col v-if="showAllTooth" :span="24">
                        <span class="info-titles">{{ `右侧乳齿-上牙：` }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUpperRight">
                            <el-checkbox v-for="(item, i) in milkToothUpperRight" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col v-if="showAllTooth" :span="24">
                        <span class="info-titles">{{ `右侧乳齿-下牙：` }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUnderRight">
                            <el-checkbox v-for="(item, i) in milkToothUnderRight" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col v-if="showAllTooth" :span="24">
                        <span class="info-titles">{{ `右侧恒齿-下牙：` }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUnderRight">
                            <el-checkbox v-for="(item, i) in permanentToothUnderRight" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
                <el-row v-show="/不明$/.test(sampleInfo['boneOrientationName'])" class="skeleton-information">
                    <el-col :span="24">
                        <span class="info-titles">{{ `恒齿-上牙：` }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUpper">
                            <el-checkbox v-for="(item, i) in permanentToothUpper" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span class="info-titles">{{ `乳齿-上牙：` }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUpper">
                            <el-checkbox v-for="(item, i) in milkToothUpper" :key="i" :label="item.originName" disabled>
                                {{ item.name }}
                                <span class="number-up">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span class="info-titles">{{ `乳齿-下牙：` }}</span>
                        <el-checkbox-group v-model="checkList.milkToothUnder">
                            <el-checkbox v-for="(item, i) in milkToothUnder" :key="i" :label="item.originName" disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :span="24">
                        <span class="info-titles">{{ " 恒齿-下牙：" }}</span>
                        <el-checkbox-group v-model="checkList.permanentToothUnder">
                            <el-checkbox v-for="(item, i) in permanentToothUnder" :key="i" :label="item.originName"
                                         disabled>
                                {{ item.name }}
                                <span class="number-down">{{ item.sub }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {defineComponent, onBeforeUpdate, reactive, ref, watch} from "vue";
import tableDataListComponent from "../component/sample-mode-details-info/table-data-list-component";
import {animalDetailsApi} from "../../../../api/data-linkage-details-api";

export default defineComponent({
    name: "skeleton-info",
    components: {
        tableDataListComponent
    },
    props: {
        sampleInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        submitRecordInfo: {
            type: String,
            default: null
        }
    },
    setup(props) {
        /*基本信息详情*/
        let submitRecordInfo = ref(null)
        let described = ref(null)
        let remark = ref(null)
        const basicInfoList = ref([
            {key: 'sampleCode', title: '送检号', value: null},
            {key: 'appraisalNumber', title: '鉴定编号', value: null},
            {key: 'specimenNumber', title: '标本数量', value: null},
            {key: 'speciesName', title: '种属', value: null},
            {key: 'latinName', title: '种属拉丁名', value: null},
            {key: 'sizePart', title: '动物种类', value: null},
            {key: 'boneName', title: '骨骼名称', value: null},
            {key: 'boneDetailedName', title: '骨骼部位', value: null},
            {key: 'boneOrientationName', title: '骨骼方位', value: null},
            // {key: 'described', title: '样本描述', value: null, span: 5},
            {key: 'savePartName', title: '体积完整程度', value: null},
            {key: 'saveDegreeName', title: '骨骼完整程度', value: null},
            {key: 'specimenQuality', title: '标本质量', value: null},
            {key: 'relativeAgeName', title: '相对年龄', value: null},
            {key: 'absolutelyAge', title: '绝对年龄', value: null},
            {key: 'genderName', title: '性别', value: null},
            {key: 'healNearName', title: '近端愈合程度', value: null},
            {key: 'healFarName', title: '远端愈合程度', value: null},
            {key: 'healUnionName', title: '联合部愈合程度', value: null},
            {key: 'healOtherName', title: '其他愈合程度', value: null},
            {key: 'specimenCoordinate', title: '标本坐标', value: null},
            {key: 'info', title: '送检信息', value: null, span: 5},
            // {key: 'remark', title: '备注', value: null, span: 5},
        ])
        let milkToothUpper = ref([]);    // 乳齿上排
        let milkToothUnder = ref([]);    // 乳齿下排
        let permanentToothUpper = ref([]);    // 恒齿上排
        let permanentToothUnder = ref([]);    // 恒齿下排
        let checkList = reactive({
            milkToothUpper: [],
            milkToothUnder: [],
            permanentToothUnder: [],
            permanentToothUpper: [],
            milkToothUpperRight: [],
            milkToothUnderRight: [],
            permanentToothUnderRight: [],
            permanentToothUpperRight: [],
        })

        const setSampleData = async function () {
            let infoList = basicInfoList.value
            let sampleInfo = props.sampleInfo
            described.value = sampleInfo.identDescribe;
            remark.value = sampleInfo.remark;
            for (const sample in sampleInfo) {
                for (const item of infoList) {
                    if (item.key === sample) {
                        item.value = sampleInfo[sample]
                    } else if (item.key === 'sizePart') {
                        item.value = `${sampleInfo.sizeName ? sampleInfo.sizeName : ''}${sampleInfo.phyleticName ? sampleInfo.phyleticName : ''}`
                    } else if (item.key === 'info') {
                        item.value = submitRecordInfo.value
                    }else if (item.key === 'boneName'){
                        item.value = sampleInfo.boneName ? sampleInfo.boneName : sampleInfo.bonesName
                    }else if (item.key === 'boneDetailedName'){
                        item.value = sampleInfo.boneDetailedName ? sampleInfo.boneDetailedName : sampleInfo.positionName
                    }
                }
            }
        };

        const setToothInfo = function () {
            let toothInfo = JSON.parse(decodeURIComponent(props.sampleInfo.tooth))
            for (const key in toothInfo) {
                checkList[key] = toothInfo[key]
            }
        }

        // 获取牙齿信息列表
        const getTeethInfoList = async function (id) {
            let result = await animalDetailsApi.getTeethInfoList(id);
            milkToothUpper.value = result.milkToothUpper;
            milkToothUnder.value = result.milkToothUnder;
            permanentToothUpper.value = result.permanentToothUpper;
            permanentToothUnder.value = result.permanentToothUnder;
        }

        // 获取所有牙齿信息
        let milkToothUpperRight = ref([]);    // 右乳齿上排
        let milkToothUnderRight = ref([]);    // 右乳齿下排
        let permanentToothUpperRight = ref([]);    // 右恒齿上排
        let permanentToothUnderRight = ref([]);    // 右恒齿下排
        const getAllTeethInfoList = async function () {
            let result = await animalDetailsApi.getTeethInfoList(1);
            milkToothUpper.value = result.milkToothUpper;
            milkToothUnder.value = result.milkToothUnder;
            permanentToothUpper.value = result.permanentToothUpper;
            permanentToothUnder.value = result.permanentToothUnder;
            let res = await animalDetailsApi.getTeethInfoList(2);
            milkToothUpperRight.value = res.milkToothUpper;
            milkToothUnderRight.value = res.milkToothUnder;
            permanentToothUpperRight.value = res.permanentToothUpper;
            permanentToothUnderRight.value = res.permanentToothUnder;
        }
        let showAllTooth = ref(false)
        watch(props.sampleInfo, function (sampleInfo) {
            if (sampleInfo['boneOrientationName'] === '不明' || /左|右$/.test(sampleInfo['boneOrientationName']) && sampleInfo['boneOrientationName'] !== '左+右') {
                getTeethInfoList(/左$/.test(sampleInfo['boneOrientationName']) ? 1 : 2);
                showAllTooth.value = false
            } else if (sampleInfo['boneOrientationName'] === '左+右' || sampleInfo['boneOrientationName'] === '-') {
                getAllTeethInfoList();
                showAllTooth.value = true
            }
            setToothInfo()
        })
        onBeforeUpdate(function () {
            submitRecordInfo.value = props.submitRecordInfo
            setSampleData()
        })
        return {
            basicInfoList,
            checkList,
            milkToothUpper,
            milkToothUnder,
            permanentToothUnder,
            permanentToothUpper,
            milkToothUpperRight,
            milkToothUnderRight,
            permanentToothUnderRight,
            permanentToothUpperRight,
            showAllTooth,


            described,
            remark
        }
    }
})
</script>

<style lang="scss">
@import "details-info";

.skeleton-info-content {
    .el-row {
        .el-col {
            align-items: center;

            &.el-col-6 {
                max-width: 100%;
            }

            display: flex;
            margin-bottom: pxToRem(25);
            font-size: pxToRem(14);
            font-weight: 400;

            .info-titles {
                width: pxToRem(140);
                color: #666666;
            }

            p {
                color: $fontColorOne;
            }

            .el-checkbox-group {
                display: flex;
            }

        }
    }

    .animal-tooth-info{
        .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
            border-color: #ff0000;
        }

    }
}

</style>