<template>
  <div class="row-box">
    <el-row>
      <el-col v-for="(item, i) in data" :key="i" :span="item.span ? item.span : span">
        <span class="info-titles">{{ item.title ? `${item.title}：` : ""}}</span>
        <p>{{ item.value }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "info-text-ul-component",

  props: {
    data: {
      type: String,
      default: null
    },
    span: {
      type: String,
      default: null
    }
  }

})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.row-box {
  .el-row {

    .el-col {
      align-items: center;

      &.el-col-6 {
        max-width: 100%;
      }

      display: flex;
      margin-bottom: pxToRem(25);
      font-size: pxToRem(14);
      font-weight: 400;

      .info-titles {
        width: pxToRem(140);
        color: #666666;
      }

      p {
        color: $fontColorOne;
      }

      .el-checkbox-group {
        display: flex;
      }

    }
  }
}
</style>