<template>
    <ul class="info-list-component">
        <li v-for="(item,i) in infoList" :key="i">
            <p class="info-title">{{ item.title }}</p>
            <p class="info-text">{{tableData && tableData[`${item.keyValue}`] && tableData[`${item.keyValue}`] !== "undefined" ? tableData[`${item.keyValue}`] : '无'}}</p>
        </li>
    </ul>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "info-list-component",
    props: {
        infoList: {
            type: Array,
            default: null
        },
        tableData: {
            type: Object,
            default: null
        }
    },
    setup(){

    }
})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.info-list-component {
    display: flex;
    flex-wrap: wrap;

    li {
        min-width: calc(100% / 4 );

        p {
            line-height: pxToRem(40);
            font-size: pxToRem(15);
            margin-right: pxToRem(60);
            word-break: break-all;
            &.info-title {
                @include color($fontColorTwo);
                border-bottom: 1px solid #D0D0D0;
            }
            &.info-text{
                @include color($fontColorOne);
                font-family: Arial;
            }
        }


    }
}
</style>