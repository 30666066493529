<template>
    <div class="control-panel default_page_style">
        <module-head-component :title="title"/>
        <module-panel-component title="资源管理" v-if="state.sampleState && state.literatureState">
            <ul v-if="dnaShow" class="source_section">
                <li v-if="state.sampleState" @click="navigationEvent('sampleManage')">
                    <span class="iconfont icon-shijizutaoweihu blue"></span>
                    <h3>样本管理</h3>
                    <p>Sample management</p>
                </li>
                <li v-if="state.literatureState" @click="navigationEvent('literatureManage')">
                    <span class="iconfont icon-zhuanyelingyuweihu baby_blue"></span>
                    <h3>文献管理</h3>
                    <p>Literature management</p>
                </li>
            </ul>
            <ul v-if="!dnaShow" class="source_section">
                <li v-if="state.sampleState" @click="navigationEvent('projectManagement')">
                    <span class="iconfont icon-xiangmu blue"></span>
                    <h3>项目管理</h3>
                    <p>Project management</p>
                </li>
                <li v-if="state.literatureState" @click="navigationEvent('dataManagement')">
                    <span class="iconfont icon-RectangleCopy baby_blue"></span>
                    <h3>数据管理</h3>
                    <p>Data management</p>
                </li>
                <li v-if="state.auditCenter" @click="navigationEvent('auditCenter')">
                    <span class="iconfont icon-examine_line baby_blue"></span>
                    <h3>审核中心</h3>
                    <p>Audit center</p>
                </li>
            </ul>
        </module-panel-component>
        <module-panel-component v-if="state.centerState" title="资源应用">
            <ul class="source_section">
                <li v-if="dnaShow" @click="navigationEvent('sampleCenter')">
                    <span class="iconfont icon-yangben orange"></span>
                    <h3>样本中心</h3>
                    <p>Sample center</p>
                </li>
                <li v-if="!dnaShow" @click="navigationEvent('dataCenterList')">
                    <span class="iconfont icon-yangben orange"></span>
                    <h3>数据中心</h3>
                    <p>Data center</p>
                </li>
            </ul>
        </module-panel-component>
        <module-panel-component v-if="state.maintenanceState" class="devops" title="平台运维">
            <ul class="source_section">
                <li @click="navigationEvent('databaseDevOps')">
                    <span class="iconfont icon-shuju dark_blue"></span>
                    <h3>数据库运维</h3>
                    <p>Database operation and maintenance</p>
                </li>
            </ul>
        </module-panel-component>
    </div>
    <footer-min-component/>
</template>

<script>
import {defineComponent, onMounted, reactive} from "vue";
import {useRouter} from "vue-router";
import commonMethods from "../../js/common-methods";
import {menuManageApi} from "../../api/database-devops-api";

export default defineComponent({
    name: "control-panel-component",
    props: {
        title: {
            type: String,
            default: null,
        },
        dnaShow: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        let router = useRouter();
        // 导航跳转时间
        const navigationEvent = function (item) {
            router.push({name: item})
        }
        // 返回门户平台
        const returnDoor = function (path) {
            let proUrl = commonMethods.getCookie("proUrl");
            window.location.href = proUrl.door + "/#/" + path;
        }
        let state = reactive({
            sampleState: false,
            literatureState: false,
            centerState: false,
            maintenanceState: false,
            auditCenter: false,
        });

        // 获取用户数据库权限
        const getAnimalCurrentUserMenu = async function () {
            const {VUE_APP_MARKING} = process.env;
            let dataIndex = VUE_APP_MARKING
            let res = await menuManageApi.getCurrentUserMenu(dataIndex);
            for (const re of res) {
                if (re.name === "数据库运维") {
                    state.maintenanceState = true
                } else if (re.name === "样本管理" || re.name === '项目管理') {
                    state.sampleState = true
                } else if (re.name === "文献管理" || re.name === '数据管理') {
                    state.literatureState = true
                } else if (re.name === "样本中心" || re.name === '数据中心') {
                    state.centerState = true
                } else if (re.name === '审核中心') {
                    state.auditCenter = true
                }
            }
        }
        onMounted(function () {
            getAnimalCurrentUserMenu();
        })
        return {
            navigationEvent,
            returnDoor,
            state,
        }
    }
})
</script>

<style lang="scss">
@import "control-panel-component";
</style>
