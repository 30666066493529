<template>
    <div class="ruins-mode-relic-measure-info">
        <!--        <module-head-component title="测量信息"/>-->
        <!--        <btn-group-component :btn-list="plantSampleSwitch" :default-btn="currentSwitch.value"-->
        <!--                             @btn-handle="bthHandleSwitch">-->
        <!--        </btn-group-component>-->
        <!--表格区域-->
        <div class="default_table_style detail-table-style">
            <el-table :data="tableData" size="large" stripe>
                <!--                <el-table-column label="序号" type="index" width="80"></el-table-column>-->
                <!--                <el-table-column label="科" prop="familyId" class-name="latinName"></el-table-column>-->
                <!--                <el-table-column label="属" prop="genusId" class-name="latinName"></el-table-column>-->
                <!--                <el-table-column label="种" prop="speciesId" class-name="latinName"></el-table-column>-->
                <!--                <el-table-column label="亚种" prop="subspeciesId" class-name="latinName"></el-table-column>-->
                <template v-if="currentSwitch.sampleType==='1'">
                    <el-table-column label="测量方法" prop="measureMethodName"></el-table-column>
                    <!-- <el-table-column label="测量种类" prop="measureTypeName"></el-table-column> -->
                    <el-table-column label="长(mm)" prop="length"></el-table-column>
                    <el-table-column label="宽(mm)" prop="width"></el-table-column>
                    <el-table-column label="厚(mm)" prop="thick"></el-table-column>
                </template>
                <template v-if="currentSwitch.sampleType==='2'">
                    <el-table-column label="参考类型" prop="typeName" width="150"></el-table-column>
                    <el-table-column label="测量参数" prop="measureData"></el-table-column>
                </template>
                <template v-if="currentSwitch.sampleType==='3'">
                    <el-table-column label="长径(mm)" prop="longDiameter"></el-table-column>
                    <el-table-column label="短径(mm)" prop="shortDiameter"></el-table-column>
                </template>
                <!--                <el-table-column align="center" label="操作" width="200">-->
                <!--                    <template #default="scope">-->
                <!--                        <common-space-vertical>-->
                <!--                            <el-link type="primary" @click="editHandle(scope.row)">编辑</el-link>-->
                <!--                            <el-link type="info" @click="deleteHandle(scope.row)">删除</el-link>-->
                <!--                        </common-space-vertical>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
            </el-table>
        </div>
        <pagination-component :page-size="pageSize" :total="total" pageSizes
                              @current-change="currentChange"
                              @sizes-change="handleSizeChange"/>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref, watchEffect} from "vue";
import commonMethodsPlant from "../../js/common-methods-plant";
import {useRoute, useRouter} from "vue-router";
import {plantDetailsApi} from "../../../../../api/data-linkage-details-api";

export default defineComponent({
    name: "ruins-mode-relic-measure-info",
    props: {
        navList: {
            type: Array,
            default: () => [],
        }
    },
    setup(props) {
        let route = useRoute();
        let router = useRouter();
        const plantSampleSwitch = ref([
            {
                name: '炭化植物',
                value: 'carbidePlant',
                type: "carbonizedPlantMeasure",
                lType: "carbonized",
                sampleType: "1"
            },
            {name: '植硅体', value: 'phytolith', type: "phytolithMeasure", lType: "phytolith", sampleType: "2"},
            {name: '淀粉粒', value: 'starchGrain', type: "starchGranuleMeasure", lType: "starch", sampleType: "3"},
        ])
        // 当前选中的按钮，
        let currentSwitch = ref(plantSampleSwitch.value[0]);
        const bthHandleSwitch = function (val) {
            currentSwitch.value = val;
            currentChange(1);
        }

        let sampleType = ref("1");
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);
        let tableData = ref([]);

        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getDataList();
        };
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }
        // 获取数据列表
        const getDataList = async function () {
            // let urlParams = commonMethodsPlant.getUrlBgParams(route.query)
            let param = {
                page: pageNumber.value,
                limit: pageSize.value,
                sampleId: route.query.sampleId
            }
            let result = await plantDetailsApi.getCurrentDataList(param, currentSwitch.value.type);
            if (currentSwitch.value.value === "phytolith") {
                for (const item of result.data) {
                    let paramsList = [];
                    let valuesList = [];
                    paramsList = item.measureParams.split(",")
                    valuesList = item.measureValues.split(",");
                    let measureData = [];
                    for (const key in paramsList) {
                        measureData.push(paramsList[key] + ":" + valuesList[key])
                    }
                    item.measureData = measureData.join(";")
                }
            }
            tableData.value = result.data;
            total.value = result.count;
        }

        // 表格数据删除
        const deleteHandle = function (item) {
            commonMethodsPlant.deleteHandle(item.id, currentSwitch.value, () => {
                getDataList();
            })
        }
        // 编辑
        const editHandle = (item) => {
            let {ruinsId, backgroundType, unitId, profileId, residueId} = route.query;
            let query = {
                ruinsId: ruinsId,
                type: backgroundType === "1" ? "unit" : (backgroundType === "2" ? "profile" : "residue"),
                unitId, profileId, residueId,
                isEdit: true,
                info: "measureInfo", sampleType: currentSwitch.value.value, id: item.id,
            }
            let routerUrl = router.resolve({
                name: "originalDataCollection",
                query
            })
             window.location.href = routerUrl.href;
            // window.open(routerUrl.href, "_blank")
        }
        watchEffect(function () {
            if (props.navList && props.navList.length > 0) {
                let list = []
                for (const nav of props.navList) {
                    for (const sampleList of plantSampleSwitch.value) {
                        if (nav === sampleList.name) {
                            list.push(sampleList)
                        }
                    }
                }
                plantSampleSwitch.value = list;
                currentSwitch.value = list[0];
            }
        })
        onMounted(function () {
            const {sampleType} = route.query
            for (const list of plantSampleSwitch.value) {
                if (list.sampleType === sampleType) {
                    currentSwitch.value = list;
                }
            }
            if (sampleType !== '4') getDataList();
        })
        return {
            plantSampleSwitch,
            currentSwitch,
            bthHandleSwitch,
            sampleType,
            pageSize,
            total,
            tableData,
            currentChange,
            handleSizeChange,
            editHandle,
            deleteHandle,
        }
    }
})
</script>

<style lang="scss">
@import "../../../../../scss/commonFunc";

</style>
