<template>
    <dialog-component
        :visible="visibleDialog"
        :title="currentOperate.name"
        @cancel-handle="dialogClose"
        @confirm-handle="confirmHandle">
        <el-form ref="dataOpenRef" :model="dataOpenForm" :rules="dataOpenRules" size="large">
            <el-form-item label="关联文献：" prop="literatureIds"
                          v-if="currentOperate.value==='sampleCorrelation'">
                <el-select v-model="dataOpenForm.literatureIds" clearable filterable multiple
                           placeholder="例：xxx遗址xxx文献">
                    <el-option v-for="item in literatureList"
                               :key="item.id"
                               :label="item.documentName"
                               :value="item.id"/>
                </el-select>
            </el-form-item>
            <el-form-item label="数据公开程度：" prop="dataPower" v-if="currentOperate.value==='dataOpen'">
                <el-radio-group v-model="dataOpenForm.dataPower">
                    <el-radio label="1">公开</el-radio>
                    <el-radio label="0">数据访问需向数据上传者提交申请</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
    </dialog-component>
</template>

<script>
import {defineComponent, ref, reactive, watchEffect} from "vue";
import {sampleImportCommonApi} from "@acc/api/sample-manage-common-api";
import literatureManageApi from "@acc/api/literature-manage-api";
import commonMethods from "@acc/js/common-methods";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "relevancy-literature-dialog-component",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        currentOperate: {
            type: Object,
            default: () => {
            }
        },
        checkList: {
            type: Array,
            default: () => []
        },
        // 默认是样本，植物数据库传各自的数据类型
        sampleType: {
            type: String,
            default: "sample"
        }
    },
    setup(props, context) {
        // 控制弹窗显示隐藏
        let visibleDialog = ref(props.visible);

        let database = ref("");
        let dataOpenRef = ref(null)
        let dataOpenForm = reactive({
            literatureIds: null,
            dataPower: null,
        })
        const dataOpenRules = reactive({})
        let literatureList = ref([]);

        // 关闭弹窗
        const dialogClose = (type = false) => {
            dataOpenRef.value.resetFields();
            context.emit("cancelHandle", type, "literature");
        }

        // 确定事件
        const confirmHandle = async () => {
            let ids = props.checkList.join(",")
            let result
            if (props.currentOperate.value === "sampleCorrelation") {
                // 关联文献
                const params = {sampleId: ids, literatureIds: dataOpenForm.literatureIds.join(",")}
                result = await sampleImportCommonApi.sampleRelevancyLiterature(params, database.value);
            } else {
                // 数据公开
                const params = {ids: ids, dataPower: dataOpenForm.dataPower}
                result = await sampleImportCommonApi.sampleBatchPower(params, database.value, props.sampleType);
            }
            if (result["resp_code"] === 0) {
                ElMessage.success(`数据${props.currentOperate.name}成功`);
                dialogClose(true);
            } else {
                ElMessage.warning(result["resp_msg"]);
            }
        }

        // 获取关联文献列表
        const getLiteratureList = async () => {
            let result = await literatureManageApi.getDataList();
            literatureList.value = result.data;
        }

        watchEffect(() => {
            visibleDialog.value = props.visible;
            if (visibleDialog.value) {
                let dataIndex = process.env.VUE_APP_MARKING;
                database.value = commonMethods.getDatabaseType(dataIndex).database;
                if (props.currentOperate.value === "sampleCorrelation") getLiteratureList();
            }
        });

        return {
            visibleDialog,
            dialogClose,
            confirmHandle,

            dataOpenRef,
            dataOpenForm,
            dataOpenRules,
            literatureList,
        }
    }
})
</script>

<style scoped>

</style>