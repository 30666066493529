<template>
    <div class="map_tool_module" :class="{active:position}">
        <div class="tool_item" v-for="(item,i) in toolList" :key="i" @click="toolHandle(item)">
            <span class="iconfont" :class="item.icon"></span>
            <p v-text="item.name"></p>
        </div>
    </div>
</template>

<script>
import {defineComponent, inject, ref} from "vue";

export default defineComponent({
    name: "map-tool-component",
    props: {
        position: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        // mapConfig对象
        let mapTool = inject("mapTool");
        let visibleBaseMap = inject("visibleBaseMap");


        const toolList = ref([
            {name: "全图", icon: "icon-quantu", type: "totalGraph"},
            {name: "放大", icon: "icon-fangda", type: "zoomIn"},
            {name: "缩小", icon: "icon-suoxiao", type: "zoomOut"},
            {name: "底图", icon: "icon-tuceng", type: "baseMap"}
        ])

        const toolHandle = (item) => {
            switch (item.type) {
                case 'totalGraph':
                    mapTool.totalGraph();
                    break;
                case 'zoomIn':
                    mapTool.setMapZoom(true);
                    break;
                case 'zoomOut':
                    mapTool.setMapZoom();
                    break;
                case 'baseMap':
                    visibleBaseMap.value = true;
                    break;
                default:
                    break;
            }
        }
        return {
            toolList,
            toolHandle
        }
    }
})
</script>

<style lang="scss">
@import "./map-tool-component";
</style>
