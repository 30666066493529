import requestInterface from "../js/request-interface";
import commonMethods from "@acc/js/common-methods";

// 接口标识
let marking = commonMethods.getDatabaseMarking();

const dataVisitApplyApi = {
    /**
     * 查询数据访问申请分页列表
     * @param params
     */
    getDataApplyList(params) {
        return requestInterface.requestGet(marking+"/data/view/apply/page", params);
    },
    /**
     * 验证用户有无访问权限
     * @param params
     */
    getDataApplyVerify(params) {
        return requestInterface.requestGet(marking+"/data/view/apply/verify", params);
    },
    /**
     * 申请访问受限数据/ 回复申请
     * @param params
     * @param type
     */
    dataApply(params,type) {
        return requestInterface.requestPost(marking+`/data/view/apply/${type? "update":"save"}`, params);
    },
}
export {
    dataVisitApplyApi
}
