<template>
    <div class="table-data-list-component">
        <table align="center" width="100%">
            <tr v-for="(item, i) in dataList" :key="i" class="basic-content-table-tr">
                <template v-for="(item2, i) in item" :key="i">
                    <td class="basic-content-table-td-title">
                        <span>{{ item2.title }}</span></td>
                    <td class="basic-content-table-td-body"  :colspan="item2.span">
                        <span>{{ item2.value }}</span></td>
                </template>
            </tr>
            <slot/>
        </table>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import commonMethods from "archaeometry-common-components/src/js/common-methods";

export default defineComponent({
    name: "table-data-list-component",
    props: {
        data: {
            type: String,
            default: null
        },
        cutting:{
            type: Number,
            default: 3
        }
    },
    setup(props) {
        let dataList = ref([])
        onMounted(function () {
            dataList.value = commonMethods.arraySlice(props.data, props.cutting);
        })
        return {
            dataList
        }
    }
})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.table-data-list-component {
    table {
        border-collapse: collapse;
        .basic-content-table-tr {
            @include color(#333333);
            @include fontSize;
            .basic-content-table-td-title {
                @include widthHeight(200, 50);
                line-height: pxToRem(50);
                border: 1px solid #E0E0E0;
                text-align: right;
                padding-right: pxToRem(17);
                @include bgColor;
                font-weight: 700;
            }

            .basic-content-table-td-body {
                @include widthHeight(200, 50);
                line-height: pxToRem(25);
                border: 1px solid #E0E0E0;
                padding:0 pxToRem(10) 0 pxToRem(17);
                >span {
                    white-space: normal;
                    word-break: break-word;
                }
            }
        }
    }
}
</style>