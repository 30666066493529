<template>
    <div class="module-panel-component">
        <div class="panel_header" v-if="isHead">
            <span v-text="title"></span>
        </div>
        <div class="panel-body">
            <slot/>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "module-panel-component",
    props: {
        title: {
            type: String,
            default: null
        },
        isHead: {
            type: Boolean,
            default: true
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.module-panel-component {
    display: flex;
    flex-direction: column;
    @include bgColor(white);

    .panel_header {
        @include height(63);
        display: flex;
        align-items: center;
        border-bottom: pxToRem(2) solid #F0F2F5;
        padding: 0 pxToRem(25);
        box-sizing: border-box;

        span {
            @include color;
            @include fontSize(18);
            @include lineHeight(18);
            border-left: pxToRem(5) solid $defaultColor;
            padding-left: pxToRem(10);
            display: flex;
            align-items: center;
            font-family: Microsoft YaHei;
        }
    }

    .panel-body {
        padding: pxToRem(35) pxToRem(35) pxToRem(5);
    }
}
</style>