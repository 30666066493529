<template>
    <div class="database-head-component ">
        <slot/>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "database-head-component",
    setup() {

        return {}
    }
})
</script>

<style lang="scss">
@import "../../../scss/commonFunc";

.database-head-component {
    @include bgColor(white);

    .filter_section {
        display: flex;

        .el-row {
            flex: 1;
            width: 0;

            .el-form-item {
                margin-bottom: 0;
            }
        }

        .el-button {
            margin-left: auto;
        }
    }
}
</style>
