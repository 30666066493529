<template>
    <div class="info-content">
        <table-data-list-component :data="basicInfoList"/>
    </div>
</template>

<script>
import {defineComponent, ref, watch} from "vue";
import tableDataListComponent from "../component/sample-mode-details-info/table-data-list-component";

export default defineComponent({
    name: "basic-info",
    components: {tableDataListComponent},
    props: {
        sampleInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        submitRecordInfo: {
            type: String,
            default: null
        }
    },
    setup(props) {
        /*基本信息详情*/
        const basicInfoList = ref([
            // {key: 'digYear', title: '发掘年份', value: null},
            {key: 'sampleMethodName', title: '采样方法', value: null},
            {key: 'saveLocation', title: '保存地点', value: null},
            {key: 'sampleSourceName', title: '筛选方法', value: null},
            {key: 'screenSize', title: '网筛大小', value: null},
            {key: 'appraiser', title: '鉴定人', value: null},
        ])

        const setSampleData = function () {
            let infoList = basicInfoList.value
            let sampleInfo = props.sampleInfo
            for (const sample in sampleInfo) {
                for (const item in infoList) {
                    if (infoList[item].key === sample) {
                        infoList[item].value = sampleInfo[sample] ?  sampleInfo[sample]  : '无'
                    }
                }
            }
        };

        watch(props.sampleInfo, function () {
            setSampleData()
        })
        return {
            basicInfoList,
        }
    }
})
</script>

<style lang="scss">
@import "details-info";

</style>