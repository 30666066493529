<template>
    <div class="city-select-component">
        <div class="current_city" ref="cityRef">
            <div @click="visiblePanel=!visiblePanel" :class="{active:visiblePanel}">
                <p v-text="currentCity"></p>
                <el-icon>
                    <CaretBottom/>
                </el-icon>
            </div>
        </div>
        <div class="city_panel" v-if="visiblePanel">
            <div class="city_panel_title">
                <div class="select_city">当前城市：{{ currentCity }}</div>
                <span class="close_panel" @click="visiblePanel=false">
                    <el-icon><Close/></el-icon>
                </span>
            </div>
            <ul class="city_panel_hot_city">
                <li v-for="(item,i) in cityList['cityData']['hotCitys']" :key="i" v-text="item.name"
                    @click="cityHandle(item)"></li>
            </ul>
            <div class="city_panel_query">
                <ul>
                    <li v-for="(item,i) in selectList" :key="i"
                        v-text="item.name"
                        :class="{active:item.type===currentSelect}" @click="currentSelect=item.type"></li>
                </ul>
                <div class="input_city">
                    <el-input v-model="cityVal"
                              @keydown.enter="queryEvent"
                              @input="queryEvent"
                              @clear="queryEvent"
                              clearable
                              placeholder="请输入城市"/>
                    <ul class="query_result" v-if="queryList.length>0">
                        <li v-for="(item,i) in queryList" :key="i" @click="cityHandle(item)">
                            <el-icon>
                                <Search/>
                            </el-icon>
                            <p v-html="item.html"></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="city_panel_body">
                <div class="city_letter">
                    <span v-for="(item,i) in Object.keys(cityList[currentSelect+'ByLetter'])" :key="i" v-text="item"
                          @click="letterHandle(item)"></span>
                </div>
                <div class="city_list_scroll">
                    <template v-if="currentSelect==='province'"
                              v-for="(value,key,v) in cityList[currentSelect+'ByLetter']" :key="v">
                        <template v-for="(item,i) in value" :key="i">
                            <div class="city_item" :class="'city_item_'+key">
                                <p @click="getNameByProvince(Object.keys(item)[0])" class="province_item">{{ Object.keys(item)[0] }}：</p>
                                <ul>
                                    <li v-for="(city,c) in Object.values(item)[0]" :key="c" v-text="city.name"
                                        @click="cityHandle(city)"></li>
                                </ul>
                            </div>
                        </template>
                    </template>
                    <template v-if="currentSelect==='city'" v-for="(value,key,v) in cityList[currentSelect+'ByLetter']"
                              :key="v">
                        <div class="city_item" :class="['city_item_'+key,currentSelect]">
                            <p>{{ key }}：</p>
                            <ul>
                                <li v-for="(city,c) in value" :key="c" v-text="city.name"
                                    @click="cityHandle(city)"></li>
                            </ul>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, reactive, ref, watch} from "vue";
import {CaretBottom, Close, Search} from '@element-plus/icons-vue'
import DragMethods from "../../js/drag-methods";

export default {
    name: "city-select-component",
    components: {
        CaretBottom,
        Close,
        Search
    },
    props: {
        defaultCity: {
            type: String,
            default: '全国'
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        let cityList = reactive(require("./city.json"));
        let currentCity = ref(props.defaultCity);
        let cityRef = ref(null);
        let visiblePanel = ref(props.visible);
        let selectList = ref([
            {name: "按省份", type: "province"},
            {name: "按城市", type: "city"},
        ]);
        let currentSelect = ref(selectList.value[0].type);
        let cityVal = ref(null);

        let queryList = ref([]);

        watch(currentSelect, function () {
            let city_list_scroll = document.querySelector(".city_list_scroll");
            city_list_scroll.scrollTo(0, 0);
        });

        // 点击实现自动定位功能
        const letterHandle = function (item) {
            let city_list_scroll = document.querySelector(".city_list_scroll");
            let selectorAll = document.querySelectorAll(".city_item_" + item);
            if (selectorAll.length > 0) {
                let element = selectorAll[0];
                city_list_scroll.scrollTo(0, element.offsetTop - city_list_scroll.offsetTop);
            }
        }
        // 城市点击事件
        const cityHandle = function (item) {
            currentCity.value = item.name;
            queryList.value = [];
            let city = getAdCodeByCity(item.adcode);
            if (!props.visible) {
                visiblePanel.value = false;
            }
            context.emit("cityEvent", city);
        }
        // 输入框查询
        const queryEvent = function () {
            let result = [];
            let val = cityVal.value;
            if (val) {
                let list = JSON.parse(JSON.stringify(getCityList()));
                for (let i = 0; i < list.length; i++) {
                    let city = list[i];
                    if (city.name.indexOf(val) > -1) {
                        city.html = city.name.replaceAll(val, '<strong>' + val + '</strong>') + `(${city['name_en']})`;
                        result.push(city);
                        // 限制搜索数量
                        if (result.length === 10) break;
                    }
                }
            }
            queryList.value = result;
        }

        // 获取所有城市
        const getCityList = function (isProvince) {
            let provinces = cityList['cityData']['provinces'];
            provinces = Object.values(provinces);
            provinces = JSON.parse(JSON.stringify(provinces));
            let cityLists = [];

            function loop(cityList, provinces) {
                for (const province of provinces) {
                    if (province.cities.length > 0) {
                        loop(cityList, province.cities);
                        if (isProvince) {
                            cityLists.push(province);
                            delete province.cities;
                        }
                    } else {
                        delete province.cities;
                        cityLists.push(province);
                    }
                }
            }

            loop(cityList, provinces);
            return cityLists;
        }

        // 根据adCode获取城市
        const getAdCodeByCity = function (adCode) {
            let list = getCityList();
            for (const city of list) {
                if (city.adcode === adCode) {
                    return city;
                }
            }
            return false;
        }

        const getNameByProvince =function (name) {
            let provinces = cityList['cityData']['provinces'];
            for (const value of Object.values(provinces)) {
                if(value.name === name ){
                    context.emit("cityEvent", value);
                }
            }
        }

        onMounted(function () {
            new DragMethods().startDrag(cityRef.value)

        })
        return {
            cityRef,
            currentCity,
            visiblePanel,
            cityList,
            selectList,
            currentSelect,
            cityVal,
            queryList,
            letterHandle,
            cityHandle,
            queryEvent,
            getNameByProvince
        }
    }
}
</script>

<style lang="scss">
@import "./city-select-component";
</style>
