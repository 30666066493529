<template>
    <div class="base_map_module" :class="{active:position}">
        <span class="close_basemap" @click="closeBaseMap">
            <i class="iconfont icon-cuowu"></i>
        </span>
        <ul>
            <li v-for="(item,i) in layerList" :key="i" :class="{'active' : item.active}"
                @click.stop="baseLayerHandle(item)">
                <div class="img_box"
                     :style="{ backgroundImage: `url('${require(`../../images/baseImg/${item.name}.png`)}')` }"></div>
                <p v-text="item.name"></p>
                <el-checkbox v-if="item.checkLabel" v-model="item.visibleLabel" @change="changeLabel(item)"
                             @click.stop="changeLabel(item,null)">标注</el-checkbox>
            </li>
        </ul>
    </div>
</template>

<script>
import {defineComponent, inject, onMounted, ref} from "vue";

export default defineComponent({
    name: "base-map-component",
    props: {
        id: {
            type: String,
            default: ''
        },
        position: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        // 图层列表
        let layerList = ref([]);
        // 图层选中名称
        let activeName = ref(null);
        // mapConfig对象
        let mapTool = inject("mapTool");
        let visibleBaseMap = inject("visibleBaseMap");

        // 关闭底图切换
        const closeBaseMap = () => {
            visibleBaseMap.value = false;
        }

        // 切换底图
        const baseLayerHandle = (item) => {
            mapTool.baseLayers.switchBaseLayer(mapTool.map, item.name, item.visibleLabel);
            closeBaseMap();
        }

        const changeLabel = (item, type) => {
            if (typeof type === "undefined") {
                mapTool.baseLayers.switchBaseLayer(mapTool.map, item.name, item.visibleLabel);
                getLayerListData();
            }
        }

        const getLayerListData = function () {
            layerList.value = mapTool.baseLayers.constructBaseLayerList(mapTool.map);
        }

        onMounted(() => {
            getLayerListData();
        })

        return {
            layerList,
            activeName,
            closeBaseMap,
            baseLayerHandle,
            changeLabel
        }
    }
})
</script>

<style lang="scss">
@import "./base-map-component";
</style>
