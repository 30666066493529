<template>
    <div class="sample-basic-or-map-component default_space_style">
        <div class="left_info">
            <module-head-component title="背景信息"/>
            <basic-info-text-component :data-list="relicInfoList"/>
        </div>
        <div class="right_map">
            <div id="map" class="default_map_style"></div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import MapConfig from "../../plugins/mapTool/map-config";
import {useRoute} from "vue-router";
import {ruinsModeApi} from "../../api/sample-manage-common-api";
import commonMethods from "../../js/common-methods";

export default defineComponent({
    name: "sample-basic-or-map-component",
    props: {
        dataIndex: {
            type: String,
            default: "1",
        },
    },
    setup(props,context) {
        let mapConfig = new MapConfig();
        let route = useRoute();
        // 遗址信息列表
        const relicInfoList = ref([
            {key: 'ruinsName', name: '遗址名称', value: null},
            {key: 'ruinsUnitNumber', name: '遗址单位号', value: null},
            {key: 'administrative', name: '行政区划', value: null},
            {key: 'longitudeStr', name: '经度', value: null},
            {key: 'latitudeStr', name: '纬度', value: null},
            {key: 'altitude', name: '海拔', value: null},
        ]);

        // 初始化获取基本信息
        const findByIdRuins = async function () {
            let list = relicInfoList.value;
            let result = await ruinsModeApi.getRuinsList({id: route.query.ruinsId}, "findById", props.dataIndex);
            result.administrative = commonMethods.getAdministrativeDivision(result)
            for (const form of list) {
                for (const item in result) {
                    if (form.key === item) {
                        form.value = result[item];
                    }
                }
            }
            let coordinates = commonMethods.setCoordinate(result["geomStr"] ? JSON.parse(result["geomStr"]).coordinates  : null);
            mapConfig.addMarker(mapConfig.map, coordinates);
        }


        onMounted(() => {
            mapConfig.initMap("map");
            findByIdRuins();
        })
        return {
            relicInfoList,
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";
/*基本信息*/
.sample-basic-or-map-component {
    display: flex;
    @include bgColor(white);
    margin-bottom: pxToRem(20);

    .left_info {
        flex: 1;
        width: 0;
        .basic-info-text-component {
            padding: 0;
        }

        ul {
            padding: 0 pxToRem(20);

            li {
                margin-top: pxToRem(20);
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                line-height: pxToRem(30);

                span {
                    min-width: pxToRem(90);
                    @include fontSize;
                    @include color($fontColorTwo);
                }

                p {
                    //flex: 1;
                    //width: 0;
                    @include fontSize;
                    @include color;
                    margin-right: pxToRem(50);

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        .query-from-component{
            margin-top: pxToRem(20);
        }
    }


    .right_map {
        @include widthHeight(500, 325);
        margin-left: pxToRem(24);
    }
}
</style>