<template>
    <div class="query-from-component">
        <el-form :model="queryForm" label-width="120px">
            <el-row>
                <el-col :span="span">
                    <el-form-item label="出土单位号：">
                        <el-select v-model="queryForm.unitCode" placeholder="出土单位号" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.unitCode.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="文化分期：">
                        <el-select v-model="queryForm.culturalPeriod" placeholder="文化分期" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.culture.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="发掘年份：">
                        <el-select v-model="queryForm.digYear" placeholder="发掘年份" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.digYear.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="探方号：">
                        <el-select v-model="queryForm.exploreSquareNumber" placeholder="探方号" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.exploreSquareNumber.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="遗迹单位号：">
                        <el-select v-model="queryForm.relicNumber" placeholder="遗迹单位号" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.relicNumber.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="堆积号：">
                        <el-select v-model="queryForm.relicGradation" placeholder="堆积号" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.relicGradation.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="遗址分区：">
                        <el-select v-model="queryForm.ruinsSubarea" placeholder="遗址分区" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.ruinsSubarea.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="span">
                    <el-form-item label="层位：">
                        <el-select v-model="queryForm.stratumGradation" placeholder="层位" @change="getFormData" clearable @clear="clearFormData">
                            <el-option v-for="item in numberList.stratumGradation.list"
                                       :label="item"
                                       :value="item"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "vue";
import {queryListApi} from "../../api/data-linkage-details-api";
import {useRoute} from "vue-router";

export default defineComponent({
    name: "query-from-component",
    props:{
        span:{
            type:Number,
            default:8,
        }
        
    },
    setup(props,context) {
        const queryForm = reactive({})
        const route = useRoute()
        let numberList = reactive({
            culture: {name: '文化分区', list: []},
            digYear: {name: '发掘年份', list: []},
            exploreSquareNumber: {name: '探方号', list: []},
            relicGradation: {name: '堆积号', list: []},
            relicNumber: {name: '遗迹单位号', list: []},
            ruinsSubarea: {name: '遗址分区', list: []},
            stratumGradation: {name: '层位', list: []},
            unitCode: {name: '出土单位号', list: []},
        })

        const getNumberList = async function () {
            let res
            for (const key in numberList) {
                res = await queryListApi.getNumberList(key, {ruinsName:route.query.title})
                numberList[key].list = res.datas
            }
        }
        const getFormData = function () {
            context.emit('getFormData',queryForm)
        }
        const clearFormData = function () {
            context.emit('getFormData',queryForm)
        }
        onMounted(function () {
            getNumberList()
        })

        return {
            queryForm,
            numberList,
            getFormData,
            clearFormData
        }
    }
})
</script>

<style lang="scss">
@import '../../scss/commonFunc';
.query-from-component{
    background-color: white;
    .el-input__inner{
        height: pxToRem(38);
    }
}
</style>