<template>
    <div class="other-info-detail">
        <module-panel-component title="痕迹信息">
            <el-row v-if="sampleMarkInfo.id" :gutter="60">
                <el-col :span="8">
                    <span class="info-titles">风化等级：</span>
                    <p>{{ sampleMarkInfo.weatherGrade }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">产生时间：</span>
                    <p>{{ sampleMarkInfo.produceTime }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">自然作用：</span>
                    <p>{{ sampleMarkInfo.naturalAction }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">自然作用描述：</span>
                    <p>{{ sampleMarkInfo.naturalRemark }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">动物作用：</span>
                    <p>{{ sampleMarkInfo.animalAction }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">动物作用描述：</span>
                    <p>{{ sampleMarkInfo.animalRemark }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">人工作用：</span>
                    <p>{{ sampleMarkInfo.artificialAction }}</p>
                </el-col>
                <el-col :span="14">
                    <span class="info-titles">其他描述：</span>
                    <p>{{ sampleMarkInfo.otherRemark }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">火烧：</span>
                    <p>{{ sampleMarkInfo.burn }}</p>
                </el-col>
                <el-col :span="8">
                    <span class="info-titles">病变：</span>
                    <p>{{ sampleMarkInfo.illnessChange }}</p>
                </el-col>
                <el-col :span="24">
                    <span class="info-titles">备注：</span>
                    <p>{{ sampleMarkInfo.remark }}</p>
                </el-col>
            </el-row>
            <el-empty v-else/>
        </module-panel-component>
        <module-panel-component title="牙齿腐蚀">
            <div v-if="toothAbrasionInfo.id" class="tooth-info">
                <div class="info-select">
                    <el-form :label-width="100" label-position="right" size="large">
                        <el-form-item label="参考图例：">
                            <el-select v-model="selectValue" disabled>
                                <el-option v-for="(item,i) in toothLegendList"
                                           :key="i"
                                           :label="item.paramName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="select-details">
                    <div class="img-box">
                        <img :src="currentImage" alt="" @click="previewImage($event)" @error="errorImage($event)">
                    </div>
                    <div class="info-content">
                        <el-row :gutter="60">
                            <el-col :span="12">
                                <span class="info-titles">dp4：</span>
                                <p>{{ toothAbrasionInfo.dp4 }}</p>
                            </el-col>
                            <el-col :span="12">
                                <span class="info-titles">p4：</span>
                                <p>{{ toothAbrasionInfo.p4 }}</p>
                            </el-col>
                            <el-col :span="12">
                                <span class="info-titles">M1：</span>
                                <p>{{ toothAbrasionInfo.m1 }}</p>
                            </el-col>
                            <el-col :span="12">
                                <span class="info-titles">M2：</span>
                                <p>{{ toothAbrasionInfo.m2 }}</p>
                            </el-col>
                            <el-col :span="12">
                                <span class="info-titles">M3：</span>
                                <p>{{ toothAbrasionInfo.m3 }}</p>
                            </el-col>
                            <el-col :span="12">
                                <span class="info-titles">M1/M2：</span>
                                <p>{{ toothAbrasionInfo.m1M2 }}</p>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <el-empty v-else/>
        </module-panel-component>
        <module-panel-component title="测量信息">
            <div v-if="measureInfo.id" class="detail-measurement-info">
                <div class="measurement-info-left">
                    <el-tabs
                        v-model="activeLegend"
                        class="measurement-demo-tabs"
                        type="card">
                        <el-tab-pane
                            v-for="item in legendList"
                            :key="item.id"
                            :label="item.name"
                            :name="item.id"
                        >
                        </el-tab-pane>
                    </el-tabs>

                    <div class="content-left-img">
                        <img :src="legendPicture" alt="" @click="previewImage($event)" @error="errorImage($event)">
                    </div>
                    <div v-if="measureList.length>0" class="content-left-describe">
                        <div class="title">
                            <div class="left">参考文献：</div>
                            <div class="right" @click="explainEvent">
                                <div class="iconfont icon-qm"/>
                                测量说明
                            </div>
                        </div>
                        <div class="content">
                            <template v-for="(item,i) in referencesList" :key="i">
                                <p v-html="filterReferencesInfo(item.literatureDesc)"></p>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="measurement-info-right">
                    <div class="measurement-name">
                        <el-row>
                            <el-col :span="8">
                                <span>种类：</span>
                                <span>{{ measureInfo["animalTypeName"] }}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>骨骼：</span>
                                <span>{{ measureInfo["measureBoneName"] }}</span>
                            </el-col>
                            <el-col :span="8">
                                <span>部位：</span>
                                <span>{{ measureInfo["measurePartName"] }}</span>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="measurement-table">
                        <div v-for="(item, i) in measureInfo.paramsList" :key="i"
                             :class="measureInfo.paramsList.length > 1 ? 'width50' : 'width1'"
                             class="default_table_style table_bg">
                            <el-table :data="item" size="large" stripe>
                                <el-table-column label="测量项" prop="params"></el-table-column>
                                <el-table-column label="测量值" prop="value"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
            <el-empty v-else/>
        </module-panel-component>
        <module-panel-component title="几何形态测量">
            <div v-if="geometryMeasureInfo.length > 0" class="default_table_style table_bg geometry-measure-info">
                <el-table :data="geometryMeasureInfo" size="large" stripe>
                    <el-table-column label="文件名称" prop="fileName"></el-table-column>
                    <el-table-column label="文件类型" prop="fileType"></el-table-column>
                    <el-table-column label="备注" prop="remark">
                        <template #default="scope">
                            <p>{{ scope.row.remark === "null" || !scope.row.remark ? "" : scope.row.remark }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="warning" @click="filePreview(scope.row)">查看</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-empty v-else/>
        </module-panel-component>
        <module-panel-component title="三维">
            <image-list-component :data-list="threeDInfo"
                                  :operation="false"
                                  preview
                                  @preview-handle="previewHandle"/>
        </module-panel-component>
        <module-panel-component title="文件">
            <div v-if="fileInfo.length > 0" class="default_table_style table_bg geometry-measure-info">
                <el-table :data="fileInfo" size="large" stripe>
                    <el-table-column label="文件名称" prop="fileName"></el-table-column>
                    <el-table-column label="文件类型" prop="fileType"></el-table-column>
                    <el-table-column label="备注" prop="remark">
                        <template #default="scope">
                            <p>{{ scope.row.remark === "null" || !scope.row.remark ? "" : scope.row.remark }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="warning" @click="filePreview(scope.row)">查看</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-empty v-else/>
        </module-panel-component>
        <!-- 测量说明-->
        <dialog-component :visible="explainVisibleDialog"
                          title="测量说明"
                          width="1000px"
                          @confirm-handle="explainVisibleDialog = false"
                          @cancel-handle="explainVisibleDialog = false">
            <ul class="measurement-explain-dialog">
                <li v-for="item in measureList" :key="item">
                    <span>{{ item.paramName + '：' }}</span>
                    <p>{{ item.paramDesc }}</p>
                </li>
            </ul>
        </dialog-component>

        <!--  三维预览的弹框-->
        <three-preview-component :previewInfo="previewInfo"
                                 :visibleDialog="previewDialog"
                                 @cancel-handle="previewCancelHandle"/>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watch} from "vue";
import commonMethods from "../../../../js/common-methods";
import globalMixin from "../../../../mixins/global-mixin";
import {useRoute, useRouter} from "vue-router";
import {animalDetailsApi} from "../../../../api/data-linkage-details-api";

export default defineComponent({
    name: "other-info",
    mixins: [globalMixin],
    setup: function () {
        let route = useRoute();
        let router = useRouter();
        // 牙齿下拉表单选择项
        const selectValue = ref("");
        // 显示的图片
        const currentImage = ref("");

        // 牙齿磨蚀图例列表
        let toothLegendList = ref([]);
        const getToothParamsList = async function (id) {
            let result = await animalDetailsApi.getToothParamsList();
            let data = result['datas']
            toothLegendList.value = data
            for (const toothLegend of data) {
                let legendList = toothLegend['toothLegendList'];
                if (id === toothLegend['id']) {
                    currentImage.value = legendList.length > 0 ? legendList[0].imageUrl : commonMethods.options.noPicture;
                }
            }
        };

        // 测量信息相关   start
        // 选中的图例
        let activeLegend = ref(null);
        // 图例列表
        let legendList = ref([]);
        // 获取图例列表
        const getLegendList = async function (params) {
            let result = await animalDetailsApi.getList("legend", params);
            if (result['resp_code'] !== 0) return;
            legendList.value = result['datas'];
            activeLegend.value = legendList.value[0] ? legendList.value[0].id : '';
        }
        // 图例图片
        let legendPicture = ref(commonMethods.options.noPicture);
        // 根据id获取图例图片
        const getIdByLegendPicture = function () {
            for (const legend of legendList.value) {
                if (legend['id'] === activeLegend.value) {
                    return legend;
                }
            }
        }
        // 监听图例切换变化
        watch(activeLegend, function () {
            let picture = getIdByLegendPicture();
            if (picture) {
                legendPicture.value = commonMethods.getCookie('moUrl') + '/' + picture['thumPath'];
            }

        })
        // 测量项列表
        let measureList = ref([]);
        // 获取测量项列表
        const getMeasureList = async function (params) {
            let result = await animalDetailsApi.getList("param", params);
            measureList.value = result["datas"].reverse();
        }
        // 测量说明
        let explainVisibleDialog = ref(false)
        const explainEvent = function () {
            explainVisibleDialog.value = true
        }
        // 参考文献列表
        let referencesList = ref([]);
        // 获取参考文献
        const getReferencesList = async function (val) {
            let result = await animalDetailsApi.getList("literature", {
                animalTypeId: val.animalTypeId
            });
            referencesList.value = result['datas'];
        }
        // 过滤参考文献信息
        const filterReferencesInfo = function (content) {
            return commonMethods.filterInfo(content);
        }
        // 测量信息相关   end

        // 三维预览弹窗
        let previewDialog = ref(false);
        let previewInfo = ref(null);
        // 三维文件预览
        const previewHandle = function (item) {
            previewInfo.value = item["fileInfoList"][0];
            previewDialog.value = true;
        }
        // 三维弹框关闭
        const previewCancelHandle = function () {
            previewDialog.value = false;
            previewInfo.value = null;
        }

        // 图片预览点击事件
        const previewImage = (e) => {
            commonMethods.showSingleMaxImg(e)
        };

        // 文件预览
        const filePreview = function (item) {
            // if (item["fileType"] === "image/jpeg") { jpg|png|jpeg|gif
            if (['jpg', 'png', 'jpeg', 'gif'].includes(item["fileType"])) {
                commonMethods.showMaxImgList([item.thumPath])
                // } else if (item["fileType"] === "pdf") {
            } else {
                // let routerUrl = router.resolve({
                //     name: "preview" + commonMethods.initialToUpperCase(item["fileType"]),
                //     query: {url: commonMethods.getCookie('moUrl') + '/' + item.thumPath}
                // })
                //  window.location.href = routerUrl.href;
                let filePath = commonMethods.getCookie('moUrl') + '/' + item.thumPath;
                commonMethods.previewPlus(filePath, filePath);
            }
        }
        // 痕迹信息
        let sampleMarkInfo = reactive({});
        // 牙齿信息
        let toothAbrasionInfo = reactive({});
        // 测量信息
        let measureInfo = reactive({
            paramsList: []
        });
        // 几何
        let geometryMeasureInfo = ref([]);
        // 文件
        let fileInfo = ref([]);
        // 三维
        let threeDInfo = ref([]);

        const getSampleInfo = async function () {
            const {id} = route.query
            let result = await animalDetailsApi.getSampleInfo({id});
            if (result["resp_code"] === 0) {
                const {
                    sampleMark,
                    toothAbrasion,
                    sampleMeasure,
                    geometryMeasure,
                    annexInfo,
                    threeD
                } = result["datas"]

                // 处理痕迹信息
                if (sampleMark) {
                    for (const key in sampleMark) {
                        sampleMarkInfo[key] = sampleMark[key]
                    }
                }

                // 处理牙齿磨蚀信息
                if (toothAbrasion) {
                    await getToothParamsList(toothAbrasion.toothLegendId)
                    selectValue.value = toothAbrasion.toothLegendId
                    for (const key in toothAbrasion) {
                        toothAbrasionInfo[key] = toothAbrasion[key]
                    }
                }

                // 处理测量信息
                if (sampleMeasure) {
                    const {animalTypeId, measureBoneId, measurePartId, measureParams, measureValues} = sampleMeasure
                    await getLegendList({animalTypeId, measureBoneId, measurePartId});
                    await getMeasureList({animalTypeId, measureBoneId, measurePartId});
                    await getReferencesList({animalTypeId, measureBoneId, measurePartId});
                    for (const key in sampleMeasure) {
                        measureInfo[key] = sampleMeasure[key]
                    }
                    let measure = measureList.value
                    let paramsList = [];
                    let valuesList = [];
                    if (measureParams) paramsList = measureParams.split(",");
                    if (measureValues) valuesList = measureValues.split(",");

                    let measureParamsList = []

                    for (const item in measure) {
                        measureParamsList[item] = {params: measure[item].paramName, value: 0}
                    }
                    for (const params in paramsList) {
                        for (const key in measureParamsList) {
                            if (paramsList[params] === measureParamsList[key].params) {
                                measureParamsList[key].value = valuesList[params]
                            }
                        }
                    }

                    if (measureParamsList.length > 25) {
                        measureInfo.paramsList = commonMethods.arraySlice(measureParamsList, 25)
                    } else {
                        measureInfo.paramsList = [measureParamsList]
                    }
                }

                // 处理几何信息
                for (const item of geometryMeasure) {
                    for (const file of item["fileInfoList"]) {
                        for (const fileKey in file) {
                            item[fileKey] = file[fileKey]
                        }
                    }
                    item["fileType"] = item["fileName"].split(".")[1];
                }
                geometryMeasureInfo.value = geometryMeasure

                // 处理三维信息
                for (let i = 0; i < threeD.length; i++) {
                    let fileInfoList = threeD[i]["fileInfoList"]
                    for (const file of fileInfoList) {
                        if (file["fileNature"] === "0") {
                            threeD[i].imgUrl = file.thumPath
                        }
                    }
                }
                threeDInfo.value = threeD
                // 处理文件信息
                for (const item of annexInfo) {
                    for (const file of item["fileInfoList"]) {
                        for (const fileKey in file) {
                            item[fileKey] = file[fileKey];
                        }
                    }
                    item["fileType"] = item["fileName"].split(".")[1];
                }
                fileInfo.value = annexInfo
            }
        }

        onMounted(function () {
            getSampleInfo()
        })

        return {
            sampleMarkInfo,

            toothAbrasionInfo,
            selectValue,
            toothLegendList,
            currentImage,
            previewImage,

            activeLegend,
            legendList,
            legendPicture,
            measureList,
            explainEvent,
            explainVisibleDialog,
            referencesList,
            filterReferencesInfo,

            measureInfo,

            geometryMeasureInfo,
            fileInfo,

            threeDInfo,
            filePreview,

            previewDialog,
            previewInfo,
            previewHandle,
            previewCancelHandle,
        }
    }
})
</script>

<style lang="scss">
@import "./details-info";
</style>