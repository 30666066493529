import JSZip from "jszip";
import moment from "moment";
import {ElMessage} from "element-plus";

const exportUtils = {
    blobType: {
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        csv: 'text/csv',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pdf: 'application/pdf',
        ppt: 'application/vnd.ms-powerpoint',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        png: 'image/png',
        gif: 'image/gif',
        jpeg: 'image/jpeg',
        jpg: 'image/jpeg',
        mp3: 'audio/mpeg',
        aac: 'audio/aac',
        html: 'text/html',
        css: 'text/css',
        js: 'text/javascript',
        json: 'application/json',
        abw: 'application/x-abiword',
        arc: 'application/x-freearc',
        avi: 'video/x-msvideo',
        azw: 'application/vnd.amazon.ebook',
        bin: 'application/octet-stream',
        bmp: 'image/bmp',
        bz: 'application/x-bzip',
        bz2: 'application/x-bzip2',
        csh: 'application/x-csh',
        eot: 'application/vnd.ms-fontobject',
        epub: 'application/epub+zip',
        htm: 'text/html',
        ico: 'image/vnd.microsoft.icon',
        ics: 'text/calendar',
        jar: 'application/java-archive',
        jsonld: 'application/ld+json',
        mid: 'audio/midi audio/x-midi',
        midi: 'audio/midi audio/x-midi',
        mjs: 'text/javascript',
        mpeg: 'video/mpeg',
        mpkg: 'application/vnd.apple.installer+xml',
        odp: 'application/vnd.oasis.opendocument.presentation',
        ods: 'application/vnd.oasis.opendocument.spreadsheet',
        odt: 'application/vnd.oasis.opendocument.text',
        oga: 'audio/ogg',
        ogv: 'video/ogg',
        ogx: 'application/ogg',
        otf: 'font/otf',
        rar: 'application/x-rar-compressed',
        rtf: 'application/rtf',
        sh: 'application/x-sh',
        svg: 'image/svg+xml',
        swf: 'application/x-shockwave-flash',
        tar: 'application/x-tar',
        tif: 'image/tiff',
        tiff: 'image/tiff',
        ttf: 'font/ttf',
        txt: 'text/plain',
        vsd: 'application/vnd.visio',
        wav: 'audio/wav',
        weba: 'audio/webm',
        webm: 'video/webm',
        webp: 'image/webp',
        woff: 'font/woff',
        woff2: 'font/woff2',
        xhtml: 'application/xhtml+xml',
        xml: 'text/xml',
        xul: 'application/vnd.mozilla.xul+xml',
        zip: 'application/zip',
    },
    blobSuffix: {
        "application/vnd.ms-excel": "xls",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
        "text/csv": "csv",
        "application/msword": "doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
        "application/pdf": "pdf",
        "application/vnd.ms-powerpoint": "ppt",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
        "image/png": "png",
        "image/gif": "gif",
        "image/jpeg": "jpg",
        "audio/mpeg": "mp3",
        "audio/aac": "aac",
        "text/html": "htm",
        "text/css": "css",
        "text/javascript": "mjs",
        "application/json": "json",
        "application/x-abiword": "abw",
        "application/x-freearc": "arc",
        "video/x-msvideo": "avi",
        "application/vnd.amazon.ebook": "azw",
        "application/octet-stream": "bin",
        "image/bmp": "bmp",
        "application/x-bzip": "bz",
        "application/x-bzip2": "bz2",
        "application/x-csh": "csh",
        "application/vnd.ms-fontobject": "eot",
        "application/epub+zip": "epub",
        "image/vnd.microsoft.icon": "ico",
        "text/calendar": "ics",
        "application/java-archive": "jar",
        "application/ld+json": "jsonld",
        "audio/midi audio/x-midi": "midi",
        "video/mpeg": "mpeg",
        "application/vnd.apple.installer+xml": "mpkg",
        "application/vnd.oasis.opendocument.presentation": "odp",
        "application/vnd.oasis.opendocument.spreadsheet": "ods",
        "application/vnd.oasis.opendocument.text": "odt",
        "audio/ogg": "oga",
        "video/ogg": "ogv",
        "application/ogg": "ogx",
        "font/otf": "otf",
        "application/x-rar-compressed": "rar",
        "application/rtf": "rtf",
        "application/x-sh": "sh",
        "image/svg+xml": "svg",
        "application/x-shockwave-flash": "swf",
        "application/x-tar": "tar",
        "image/tiff": "tiff",
        "font/ttf": "ttf",
        "text/plain": "txt",
        "application/vnd.visio": "vsd",
        "audio/wav": "wav",
        "audio/webm": "weba",
        "video/webm": "webm",
        "image/webp": "webp",
        "font/woff": "woff",
        "font/woff2": "woff2",
        "application/xhtml+xml": "xhtml",
        "text/xml": "xml",
        "application/vnd.mozilla.xul+xml": "xul",
        "application/zip": "zip"
    },
    /**
     * 导出普通json格式数据为Excel
     * @param dataList
     * @param filename
     * @param returnBlob
     */
    exportGeneralJSONDataExcel(dataList = [], filename = +new Date(), returnBlob = false) {
        return new Promise(resolve => {
            let newData = JSON.parse(JSON.stringify(dataList));
            newData = newData.map(item => {
                delete item.leaflet_id;
                return item;
            })
            if (newData.length > 0) {
                let head = newData[0];
                let newObj = [];
                for (const h in head) {
                    newObj[h] = h
                }
                newData.unshift(newObj);
            }
            let data = newData.map(item => Object.values(item).join("\t")).join("\n");
            let blob = new Blob([data], {type: this.blobType.xlsx});
            if (returnBlob) {
                resolve({
                    // base64: base64.replace(/^data:image\/(png|jpg|jpeg|webp);base64,/, ""),
                    filename,
                    suffix: this.getBlobTypeBySuffix(blob),
                    blob
                });
            } else {
                this.downloadBlob(blob, filename);
            }
        })
    },
    /**
     * 导出json数据
     * @param dataList
     * @param filename
     */
    exportJSONData(dataList, filename = +new Date()) {
        this.downloadBlob(new Blob([JSON.stringify(dataList, null, 4)]), filename + ".json");
    },
    /**
     * 下载流文件
     * @param blob
     * @param filename
     * @param suffix
     */
    downloadBlob(blob, filename, suffix = "jpg") {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = URL.createObjectURL(blob);
        a.download = filename || String(+new Date()) + "." + suffix;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
    /**
     * 下载文件
     * @param href
     * @param filename
     * @param callbackBlob
     */
    downloadFile(href, filename = +new Date(), callbackBlob) {
        fetch(href).then(res => {
            // response.setHeader("Access-Control-Expose-Headers","Content-Disposition");   // 后端设置
            // 想要获取文件名  后端必须设置请求头，否则无法获取请求头文件名
            // const fileNameEncode = res.headers.get('content-disposition');
            // res.headers.forEach(h=>{
            //     console.log(h)
            // })
            // console.log(fileNameEncode);
            return res.blob();
        }).then(blob => {
            if (callbackBlob) {
                callbackBlob(blob);
            } else {
                this.downloadBlob(blob, filename + this.getBlobTypeBySuffix(blob));
            }
        });
    },
    /**
     * 链接地址转blob
     * @param href
     * @param format
     */
    urlToBlob(href, format) {
        return new Promise(resolve => {
            fetch(href).then(res => {
                return res.blob()
            }).then(blob => {
                resolve({
                    // base64: base64.replace(/^data:image\/(png|jpg|jpeg|webp);base64,/, ""),
                    suffix: this.getBlobTypeBySuffix(blob),
                    blob
                })
            });
        })
    },
    // 根据blob类型获取文件后缀
    getBlobTypeBySuffix(blob) {
        let type = blob.type;
        type = type.split("/");
        type = type[type.length - 1];
        return "." + this.blobSuffix[blob.type] || type;
    },
    /**
     * 下载zip
     * @param blobList
     * @param zipName
     */
    downloadZip(blobList, zipName = "HGIS_" + moment().format("yyyyMMDDHHmmss")) {
        ElMessage.loading({content: "数据正在打包，请耐心等待...", duration: 0, key: 'download'}).then();
        let zip = new JSZip();
        // let folder = zip.folder("舆图");

        for (const list of blobList) {
            zip.file(list.filename + list.suffix, list.blob);
        }
        zip.generateAsync({type: "blob"}).then(b => {
            message.destroy("download");
            message.success("数据打包完成，马上下载^_^").then();
            exportUtils.downloadBlob(b, zipName);
        });
    }
}

export default exportUtils;