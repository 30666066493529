<template>
    <div class="regular-file-upload-component" ref="uploadRef">
        <el-upload
            class="upload_container"
            drag
            action=""
            :accept="accept"
            :multiple="multiple"
            :before-upload="beforeUpload">
            <el-icon class="el-icon--upload">
                <upload-filled/>
            </el-icon>
            <div class="el-upload__text">将文件拖放到此处或<em>点击上传</em></div>
            <template #tip>
                <div class="el-upload__tip">{{ uploadTip }}</div>
            </template>
        </el-upload>
        <ul class="file_list_section" v-if="showFileList">
            <li v-for="(item,i) in fileList" :key="i">
                <p v-text="item.name || item.fileName"></p>
                <span @click="removeFile(i,item)">
                    <el-icon><close/></el-icon>
                </span>
            </li>
        </ul>
    </div>
</template>

<script>
import {UploadFilled, Close} from '@element-plus/icons-vue'
import {onMounted, ref} from "vue";
import {useRoute} from 'vue-router'
import commonMethods from "../../js/common-methods";
import literatureManageApi from "../../api/literature-manage-api";

export default {
    name: "regular-file-upload-component",
    components: {UploadFilled, Close},
    props: {
        // 上传多文件
        multiple: {
            type: Boolean,
            default: false
        },
        // 上传文件夹
        directory: {
            type: Boolean,
            default: false
        },
        // 上传文件类型
        accept: {
            type: String,
            default: "*"
        },
        // 上传提示语
        uploadTip: {
            type: String,
            default: ""
        },
        // 显示上传列表
        showFileList: {
            type: Boolean,
            default: true
        },
        deleteFile:{
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        let uploadRef = ref(null);
        let route = useRoute();
        // 文件列表
        let fileList = ref([]);

        // 上传文件之前的钩子
        const beforeUpload = file => {
            fileList.value = props.multiple ? commonMethods.uniqFile([file, ...fileList.value]) : [file];
            return false;
        }

        // 删除文件
        const removeFile = async (index, item) => {
            if (props.deleteFile) {
                fileList.value.splice(index, 1);
                let formData = new FormData()
                formData.append('id',route.query.id)
                formData.append('delFileIds',item.id)
                let res = await literatureManageApi.editLiterature(formData)
                return res
            } else {
                fileList.value.splice(index, 1);
            }
        }

        onMounted(() => {
            let selector = uploadRef.value.querySelector(".el-upload__input");
            if (selector && props.directory) {
                // 设置可以上传文件夹
                selector.setAttribute("webkitdirectory", true);
            }
        });

        return {
            uploadRef,
            fileList,
            beforeUpload,
            removeFile
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.regular-file-upload-component {
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;

    .upload_container,
    .el-upload,
    .el-upload-dragger {
        width: 100%;
        min-height: 100%;
    }

    .el-upload-dragger {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .el-icon--upload {
            margin: 0;
        }
    }

    .el-upload__tip {
        margin-top: 0;
        @include color($redColor);
        @include fontSize;
    }

    .file_list_section {
        display: flex;
        flex-direction: column;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include bgColor($bgOpacityColor);
            padding: 0 pxToRem(10);
            margin-bottom: pxToRem(5);

            &:hover {
                @include bgColor(rgba(55, 119, 86, 0.4));
            }

            p {
                flex: 1;
                width: 0;
                @include fontSize;
                @include color;
            }

            span {
                @include widthHeight(pxToRem(35));
                @include displayFlex;
                @include color;
                @include fontSize(18);
                cursor: pointer;

                &:hover {
                    @include color($defaultColor);
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
