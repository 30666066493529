<template>
    <!-- 使用动态过渡名称 -->
<!--    <router-view v-slot="{ Component }">-->
<!--        <transition name="fade">-->
<!--            <component :is="Component"/>-->
<!--        </transition>-->
<!--    </router-view>-->
    <router-view/>
</template>

<script>
export default {
    name: "transition-router-view-component"
}
</script>

<style>
.fade-leave-active,
.fade-enter-active {
    transition: opacity .1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
