<template>
    <div class="role-manage-privileges">
        <breadcrumb-component/>
        <div class="container_content_box default_space_style">
            <div class="privileges-title">
                <span>当前角色：{{ roleName }}</span>
                <div class="custom_btn_group_right">
                    <el-button type="primary" @click="submitMenuEvent">保存</el-button>
                </div>
            </div>
            <div class="container-box">
                <div class="menu-list">
                    <module-head-component title="菜单列表"/>
                    <div class="checkbox_list">
                        <el-checkbox-group v-model="menuCheckedList">
                            <el-checkbox v-for="(nav,i) in menuList" :key="i"
                                         :label="nav.id">{{ nav.name }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="function-list-tree">
                    <module-head-component title="功能列表"/>
                    <el-tree ref="treeRef" :data="treeList" :default-checked-keys="defaultTreeCheckedList" :props="defaultProps"
                             check-strictly
                             node-key="id"
                             show-checkbox
                             @check-change="handleNodeClick"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref, watchEffect} from "vue";
import {menuManageApi} from "../../../../api/database-devops-api";
import {BreadcrumbComponent, ModuleHeadComponent} from "../../../../index"
import {useRoute} from "vue-router";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "role-manage-privileges-module",
    props: {
        dataIndex: {
            type: String,
            default: "1"
        }
    },
    components: {
        BreadcrumbComponent,
        ModuleHeadComponent
    },
    setup(props) {
        const route = useRoute();
        let roleName = ref(null);
        // 菜单栏
        let menuList = ref([]);
        // 菜单栏选中
        let menuCheckedList = ref([]);
        // 目录树
        let treeRef = ref(null);
        let treeList = ref([]);
        const defaultProps = reactive({
            children: 'subMenus',
            label: "name"
        })
        let defaultTreeCheckedList = ref([]);
        // 获取全部菜单列表
        const getAllMenu = async function () {
            let result = await menuManageApi.getAllMenuList();
            let menu = [];
            for (const data of result.data) {
                if (data.type === 1 && data.parentId === -1) {
                    menu.push(data);
                    data.checked = false;
                }
            }
            menuList.value = menu;
            treeList.value = result.data;
        }

        const handleNodeClick = () => {
        }

        const submitMenuEvent = async function () {
            let list = treeRef.value.getCheckedNodes();
            let ids = [...menuCheckedList.value,]
            for (const key of list) {
                ids.push(key.id);
            }
            ids = new Set(ids);
            let menuIds = [...ids];
            let params = {roleId: route.query.id, menuIds}
            let result = await menuManageApi.roleAllotMenu(params);
            if (result["resp_code"] === 0) {
                await getCurrentRoleMenu();
                ElMessage.success("权限分配成功");
                location.reload();
            }
        }

        // 获取当前角色权限
        const getCurrentRoleMenu = async function () {
            let result = await menuManageApi.getCurrentRoleMenu(route.query.id, props.dataIndex);
            let menuChecked = [];
            let treeChecked = []
            for (const item of result) {
                if (item.checked) {
                    if (item["pId"] === -1) {
                        menuChecked.push(item.id);
                    } else {
                        treeChecked.push(item.id);
                    }
                }
            }
            menuCheckedList.value = menuChecked;
            defaultTreeCheckedList.value = treeChecked;
        }

        watchEffect(function () {
            let id = route.query.id
            if (id && props.dataIndex === "0") {
                getCurrentRoleMenu();
            }
        })

        onMounted(function () {
            getAllMenu();
            roleName.value = route.query.name;
            if (props.dataIndex !== "0") {
                getCurrentRoleMenu();
            }
        })
        return {
            roleName,
            menuList,
            menuCheckedList,

            treeRef,
            treeList,
            defaultProps,
            handleNodeClick,
            defaultTreeCheckedList,
            submitMenuEvent
        }
    }
})
</script>

<style lang="scss">
@import "../../../../scss/commonFunc";

.role-manage-privileges {
    display: flex;
    flex-direction: column;
    flex: 1;

    .container_content_box {
        margin-top: pxToRem(20);
        @include bgColor(white);
        flex: 1;

        .privileges-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .custom_btn_group_right {
            }

            margin-bottom: pxToRem(20);
        }

        .container-box {
            display: flex;

            .menu-list {
                @include width(300);
                @include height(500);
                margin-right: pxToRem(20);
                border: 1px solid #ddd;
            }

            .function-list-tree {
                border: 1px solid #ddd;
                @include width(0);
                flex: 1;
                padding-bottom: pxToRem(20);

                .el-tree {
                    margin: pxToRem(28) 0 0 pxToRem(10);
                    @include height(400);
                    overflow: auto;

                    > .el-tree-node {
                        > .el-tree-node__content {
                            .el-checkbox {
                                display: none;
                            }
                        }
                    }

                    .el-tree-node__content {
                        margin-bottom: pxToRem(8) !important;
                    }
                }
            }
        }

    }

    .checkbox_list {
        padding: pxToRem(25) pxToRem(23) pxToRem(10);

        .el-checkbox {
            margin-right: pxToRem(130);
            margin-bottom: pxToRem(15);
        }
    }


}
</style>
