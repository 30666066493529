/*基础底图*/
import {control, featureGroup, layerGroup, tileLayer} from 'leaflet';

const subdomainsChrome = ['0', '1', '2', '3'];

const subdomainsGaoDe = ['1', '2', '3', '4'];

const subdomainsTianDiTu = ['0', '1', '2', '3', '4', '5', '6', '7'];
const tianDiTuToken = 'afa188939c93ab9b4b7026d69813acad';
const googleLanURL = 'http://www.srhgis.com';

class BaseLayers {
    constructor() {
        this.options = {
            /*谷歌底图*/
            chromeTileLayer: {
                vec: tileLayer('http://mt{s}.google.cn/vt/lyrs=m@207000000&hl=zh-CN&gl=CN&src=app&x={x}&y={y}&z={z}&s=Galile', {
                    subdomains: subdomainsChrome
                }),
                image: layerGroup([
                    tileLayer(googleLanURL + '/shanxi13/{z}/{y}/{x}.jpg'),
                    tileLayer(googleLanURL + '/shanxi14/{z}/{y}/{x}.jpg'),
                    tileLayer(googleLanURL + '/shanxi15/{z}/{y}/{x}.jpg'),
                    tileLayer(googleLanURL + '/shanxi16/{z}/{y}/{x}.jpg')
                ]),
                terrain: tileLayer('https://mt{s}.google.cn/vt/lyrs=t@128,r@176000000&hl=zh-CN&gl=cn&src=app&x={x}&y={y}&z={z}&s=Galil', {
                    subdomains: subdomainsChrome
                })
            },
            /*天地图底图*/
            tianDiTuTileLayer: {
                vec: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=vec_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cva_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu,
                        visibleLabel: true
                    })
                ]),
                image: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=img_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cia_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu,
                        visibleLabel: true
                    })
                ]),
                terrain: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=ter_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cta_w&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu,
                        visibleLabel: true
                    })
                ]),
                vec_CGcs: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=vec_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cva_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    })
                ]),
                image_CGcs: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=img_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cia_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    })
                ]),
                terrain_CGcs: layerGroup([
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=ter_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    }),
                    tileLayer('https://t{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=' + tianDiTuToken, {
                        subdomains: subdomainsTianDiTu
                    })
                ])
            },
            /*高德地图*/
            gaoDeTileLayer: {
                vec: layerGroup([
                    tileLayer('https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}', {
                        subdomains: subdomainsGaoDe
                    })
                ]),
                image: layerGroup([
                    tileLayer('https://webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}', {
                        subdomains: subdomainsGaoDe
                    }),
                    tileLayer('https://webst0{s}.is.autonavi.com/appmaptile?style=8&x={x}&y={y}&z={z}', {
                        subdomains: subdomainsGaoDe,
                        visibleLabel: true
                    })
                ])
            },
            /*智图*/
            GeoQTileLayer: {
                'GeoQ': tileLayer('http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineCommunity/MapServer/tile/{z}/{y}/{x}'),
                'GeoQPurplishBlue': tileLayer('http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}'),
                'GeoQGrey': tileLayer('http://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetGray/MapServer/tile/{z}/{y}/{x}')
            },
            openToPoMap: tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
                attribution: ''
            }),
            openStreetMap: {
                MapNik: tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: ''
                }),
                DE: tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
                    maxZoom: 18,
                    attribution: ''
                })
            },
            stamen: {
                terrain: tileLayer('https://stamen-tiles-{s}.a.ssfastly.net/terrain/{z}/{x}/{y}{r}.{ext}', {
                    attribution: '',
                    subdomains: 'abcd',
                    minZoom: 0,
                    maxZoom: 18,
                    ext: 'png'
                }),
                terrainBackground: tileLayer('https://stamen-tiles-{s}.a.ssfastly.net/terrain-background/{z}/{x}/{y}{r}.{ext}', {
                    attribution: '',
                    subdomains: 'abcd',
                    minZoom: 0,
                    maxZoom: 18,
                    ext: 'png'
                }),
                tonerMap: tileLayer('http://tile.stamen.com/toner/{z}/{x}/{y}.png', {
                    attribution: ''
                }),
                watercolorMap: tileLayer('http://tile.stamen.com/watercolor/{z}/{x}/{y}.jpg', {
                    attribution: ''
                })
            },
            ESri: {
                natGeoWorldMap: tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}', {
                    attribution: '',
                    maxZoom: 16
                }),
                worldPhysical: tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}', {
                    attribution: '',
                    maxZoom: 8
                })
            },
            Tencent: {
                vec: layerGroup([
                    tileLayer('https://rt{s}.map.gtimg.com/realtimerender?z={z}&x={x}&y={y}&type=vector&style=0', {
                        subdomains: '0123',
                        tms: true
                    })
                ])
            }
        }
    }

    /**
     * 设置图层组
     */
    settingLayerGroup() {
        return {
            '\u5929\u5730\u56FE\u5730\u5F62': this.options.tianDiTuTileLayer.terrain,
            '\u5929\u5730\u56FE\u77E2\u91CF': this.options.tianDiTuTileLayer.vec,
            '\u5929\u5730\u56FE\u5F71\u50CF': this.options.tianDiTuTileLayer.image,
            // '\u5929\u5730\u56FE\u77E2\u91CF_CGCS2000': this.options.tianDiTuTileLayer.vec_CGcs,
            // '\u5929\u5730\u56FE\u5F71\u50CF_CGCS2000': this.options.tianDiTuTileLayer.image_CGcs,
            // '\u5929\u5730\u56FE\u5730\u5F62_CGCS2000': this.options.tianDiTuTileLayer.terrain_CGcs,
            '\u9AD8\u5FB7\u77E2\u91CF': this.options.gaoDeTileLayer.vec,
            '\u9AD8\u5FB7\u5F71\u50CF': this.options.gaoDeTileLayer.image,
            // 'Google\u77E2\u91CF': this.options.chromeTileLayer.vec,
            // 'Google\u5F71\u50CF': this.options.chromeTileLayer.image,
            // 'Google\u5730\u5F62': this.options.chromeTileLayer.terrain,
            // '\u667A\u56FE': this.options.GeoQTileLayer.GeoQ,
            // '\u667A\u56FE\u85CF\u84DD': this.options.GeoQTileLayer.GeoQPurplishBlue,
            // '\u667A\u56FE\u7070': this.options.GeoQTileLayer.GeoQGrey,
            '腾讯矢量': this.options.Tencent.vec
            // "OpenToPoMap": this.options.openToPoMap,
            // "Stamen.Toner": this.options.stamen.tonerMap,
            // "Stamen.Watercolor": this.options.stamen.watercolorMap,
            // "Stamen.Terrain": this.options.stamen.terrain,
            // "Stamen.TerrainBackground": this.options.stamen.terrainBackground,
            // "OpenStreetMap.MapNik": this.options.openStreetMap.MapNik,
            // "OpenStreetMap.DE": this.options.openStreetMap.DE,
            // "ESri.NatGeoWorldMap": this.options.ESri.natGeoWorldMap,
            // "ESri.WorldPhysical": this.options.ESri.worldPhysical,
        };
    }

    /*加载底图*/
    loadTileLayers(map) {
        return control.layers(this.settingLayerGroup(), null, {
            position: 'topright'
        }).addTo(map);
    }

    // 加载基础底图
    loadBaseLayer(map, defaultLayer = "高德影像", visibleLabel = true) {
        let baseMapLayer = this.getBaseLayers(map, "baseLayer");
        if (!baseMapLayer) baseMapLayer = new featureGroup(null, {id: 'baseLayer'}).addTo(map);
        let tingLayerGroup = this.settingLayerGroup();
        for (const group in tingLayerGroup) {
            tingLayerGroup[group].options.name = group;
            tingLayerGroup[group].options.active = group === defaultLayer;
            let baseLayer = tingLayerGroup[group];
            for (const layer of baseLayer.getLayers()) {
                tingLayerGroup[group].options.checkLabel = 'visibleLabel' in layer.options;
                if (group === defaultLayer) {
                    if ('visibleLabel' in layer.options) {
                        layer.setOpacity(layer.options.visibleLabel && visibleLabel ? 1 : 0);
                    } else {
                        layer.setOpacity(1);
                    }
                    tingLayerGroup[group].options.visibleLabel = visibleLabel;
                } else {
                    layer.setOpacity(0);
                    tingLayerGroup[group].options.visibleLabel = true;
                }
            }
            baseMapLayer.addLayer(baseLayer)
        }
        return baseMapLayer;
    }

    // 切换底图
    switchBaseLayer(map, layerName, visibleLabel) {
        let layer = this.getBaseLayers(map, "baseLayer");
        if (!layer) return;
        this.visibleBaseLayer(layer.getLayers(), layerName, visibleLabel);
    }

    // 显示隐藏底图
    visibleBaseLayer(baseLayerGroup, layerName, visibleLabel) {
        for (const baseLayer of baseLayerGroup) {
            for (const layer of baseLayer.getLayers()) {
                if (baseLayer.options.name === layerName) {
                    if ('visibleLabel' in layer.options) {
                        layer.setOpacity(visibleLabel ? 1 : 0);
                    } else {
                        layer.setOpacity(1);
                    }
                    baseLayer.options.active = true;
                    baseLayer.options.visibleLabel = visibleLabel;
                } else {
                    baseLayer.options.active = false;
                    layer.setOpacity(0);
                }
            }
        }
    }

    // 获取基础图层
    getBaseLayers(map, id) {
        for (const layer of Object.values(map._layers)) {
            if (layer.options.id === id) {
                return layer;
            }
        }
        return null;
    }

    // 构造基础图层列表-用户地图切换展示
    constructBaseLayerList(map) {
        let baseLayer = this.getBaseLayers(map, "baseLayer");
        let baseLayerList = [];
        if (baseLayer) {
            for (const layer of baseLayer.getLayers()) {
                baseLayerList.push(layer.options);
            }
        }
        return baseLayerList;
    }
}

export default BaseLayers;
