const navigationConfig = {
    // 主导航
    mainNavigationList: [
        {name: "首页", path: 'portalWebsite'},
        {name: "平台简介", path: 'platformIntro'},
        {name: "学术动态", path: 'academicFrontier'},
        {name: "行业标准", path: 'professionalStandard'},
        {name: "数据中心", path: 'dataCenter'},
        // {name: "系统管理", path: 'websiteManagement'},
    ],
    // 测量参数类型
    measureNavigationList: [
        {name: '种类', value: 'animalType', type: "param"},
        {name: '骨骼', value: 'name', type: "bone"},
        {name: '部位', value: 'detailed', type: "part"},
    ],
    // 背景信息类型
    backgroundTypeList: [
        {name: '单位信息', value: 'ruinsInfo', param: "unitId", backgroundType: "1", key: '遗迹单位'},
        {name: '剖面信息', value: 'profile', param: "profileId", backgroundType: "2", key: "剖面采集"},
        {name: '残留物信息', value: 'residue', param: "residueId", backgroundType: "3", key: "残留物采集"},
    ],
    // 植物基本信息样本切换
    plantSampleList: [
        {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", sampleType: "1"},
        {name: '植硅体', value: 'phytolith', type: "phytolith", sampleType: "2"},
        {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", sampleType: "3"},
        {name: '木炭', value: 'charcoal', type: "charcoal", sampleType: "4"},
    ],
}
export default navigationConfig;