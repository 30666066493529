<template>
    <div :class="{'default_page_style':max}" class="breadcrumb-component">
        <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item v-for="(item,i) in matched" :key="i" @click="routerQuery(item)">
                <!--                :to="{name:item.name,query: getNameByHashQuery(item)}"-->
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
        <div v-if="showBack" class="to-back-component" @click="backHandle">
            <span>返回</span>
            <el-icon>
                <arrow-left-bold/>
            </el-icon>
        </div>
    </div>
</template>

<script>
import {defineComponent, nextTick, ref, watchEffect} from "vue";
import {useRoute, useRouter} from "vue-router";
import {ArrowLeftBold, ArrowRight} from '@element-plus/icons-vue'
import commonMethods from "archaeometry-common-components/src/js/common-methods";

export default defineComponent({
    name: "breadcrumb-component",
    components: {ArrowLeftBold},
    props: {
        max: {
            type: Boolean,
            default: false
        },
        showBack: {
            type: Boolean,
            default: false
        },
    },
    setup() {
        let route = useRoute();
        let router = useRouter();
        let matched = ref([]);
        // 监听路由变化
        watchEffect(() => {
            let matchedArr = route.matched;
            nextTick(() => {
                matched.value = matchedArr;
            })
        });

        // 根据name获取历史参数
        const getNameByHashQuery = ({name}) => {
            return commonMethods.getNameByHashQuery(name);
        }
        // 返回上一步
        const backHandle = () => {
            router.back();
        }
        const routerQuery = function (item) {
            router.replace({name: item.name, query: {...route.query}})
        }
        return {
            matched,
            ArrowRight,
            getNameByHashQuery,
            backHandle,

            routerQuery
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.breadcrumb-component {
    height: pxToRem(58);
    align-items: center;
    display: flex;
    @include bgColor(white);
    //margin-bottom: pxToRem(20);

    .el-breadcrumb {
        margin-left: pxToRem(40);
        @include fontSize(16);
        @include color($fontColorOne);

        .is-link {
            font-weight: normal;
        }
    }

}
</style>