<template>
    <table cellspacing="0" class="info-table-component">
        <tbody>
        <tr v-for="(item,i) in tableList " :key="i" class="info-box">
            <td class="info-table-name">{{ item.name }}</td>
            <td class="info-table-content">{{
                    dataInfo && dataInfo[`${item.key}`] ? dataInfo[`${item.key}`] : '无'
                }}
            </td>
        </tr>
        <slot/>
        </tbody>
    </table>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "info-table-component",
    props: {
        tableList: {
            type: Array,
            default: null,
        },
        dataInfo: {
            type: Object,
            default: null,
        }

    },

    setup() {

        return {}
    }

})
</script>

<style lang="scss">
@import "archaeometry-common-components/src/scss/commonFunc";

.info-table-component {
    width: 100%;
    margin-top: pxToRem(-1);

    tbody {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        border-collapse: collapse;

        .info-box {
            flex: 0 0 50%;
            margin:0 pxToRem(-1) pxToRem(-1) 0;
            display: flex;
            td {
                padding: 0 pxToRem(20);
                border: pxToRem(1) solid #E0E0E0;
                align-items: center;
                padding: 0 pxToRem(30);
                font-size: pxToRem(15);
            }

            .info-table-name {
                min-width: pxToRem(240);
                min-height: pxToRem(48);
                background: rgba(238, 238, 238, 0.5);
                display: flex;
                align-items: center;
                @include color($fontColorTwo);
            }

            .info-table-content {
                width: 100%;
                word-break: break-all;
                display: flex;
                align-items: center;
                @include color($fontColorOne);
                font-weight: 500;
            }
        }
    }
}
</style>