<template>
    <div class="data-details">
        <breadcrumb-component :max="true" showBack/>
        <div class="project-basic-info default_page_style">
            <div v-for="(item,i) in infoList" :key="i" class="project-basic-info-content">
                <module-head-component :title="item.name"/>
                <info-list-component :infoList="item.infoList" :tableData="dataInfo"/>
            </div>
            <div class="project-basic-info-content">
                <module-head-component title="元数据 Meta Data"/>
                <info-table-component :dataInfo="dataInfo" :tableList="tableList">
                    <tr class="info-box">
                        <td class="info-table-name">*群体名称 Group ID</td>
                        <td class="info-table-content">{{ groupName ? groupName : "无" }}</td>
                    </tr>
                </info-table-component>
            </div>
            <div class="default_table_style table_bg file-table">
                <el-table :data="tableData" size="large" stripe>
                    <el-table-column prop="fileName">
                        <template #header>
                            <p class="name-zh">文件名称</p>
                            <p class="name-en">Files Name</p>
                        </template>
                    </el-table-column>
                    <el-table-column :formatter="dateFormat" prop="createTime">
                        <template #header>
                            <p class="name-zh">文件上传时间</p>
                            <p class="name-en">Uploading Date</p>
                        </template>
                    </el-table-column>
                    <el-table-column width="200">
                        <template #header>
                            <p class="name-zh">操作</p>
                            <p class="name-en">Operation</p>
                        </template>
                        <template #default="scope">
                            <div class="table_operate_group" style="justify-content: flex-start">
                            <span class="text check" @click="viewDownload(scope.row)">
                                <p>下载</p>
                                <p>Download</p>
                            </span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import infoListComponent from "./component/info-list-component";
import InfoTableComponent from "./component/info-table-component"
import {defineComponent, onMounted, ref} from "vue";
import commonMethods from "../../../js/common-methods";
import {useRoute} from "vue-router";
import {dnaDetailsApi} from "../../../api/data-linkage-details-api";

export default defineComponent({
    name: "dna-details-component",
    components: {infoListComponent, InfoTableComponent},
    setup() {
        let route = useRoute();
        let dataInfo = ref(null);
        let groupName = ref(null);
        const infoList = ref([
            {
                name: '数据信息 DNA Data Information', infoList: [
                    {title: '数据发布时间  Release Date', keyValue: 'releaseTime'},
                    {title: '数据公开程度 Data Accessbility', keyValue: 'dataPower'},
                    {title: '项目编号  Project  Number', keyValue: 'projectNumber'},
                    {title: '数据编号  Data Accession Number', keyValue: 'dataNumber'}]
            },
            {
                name: '背景信息 Background', infoList: [
                    {title: '遗址名称  Site Name', keyValue: 'ruinsName'},
                    {title: '行政区划  Area', keyValue: 'area'},
                    {title: '遗址单位号  Tomb', keyValue: 'ruinsUnitNumber'},
                    {title: '经度  Longtitude', keyValue: 'longitudeStr'},
                    {title: '纬度  Latitude', keyValue: 'latitudeStr'},
                    {title: '海拔  Altitude', keyValue: 'altitude'},
                    {title: '发掘年份 Year Of Excavation', keyValue: 'digYear'},
                    {title: '遗址分区 Site Zone', keyValue: 'ruinsSubarea'},
                    {title: '文化分期 Cultural Periodization', keyValue: 'culturalPeriod'},
                    {title: '探方号 Unit', keyValue: 'exploreSquareNumber'},
                    {title: '层位 Horizon', keyValue: 'stratumGradation'},
                    {title: '遗迹单位号 Heritage Unit No', keyValue: 'relicNumber'},
                    {title: '堆积号 Stacking No', keyValue: 'relicGradation'},
                    // {title: '出土遗迹类型 Cultural Traces Horizon', keyValue: 'relicType'},
                    {title: '出土单位号 Tomb Number', keyValue: 'unitCode'},
                    {title: '备注 Note', keyValue: 'remark'},
                ]
            },
        ]);
        const tableList = ref([
            {name: '*送检号 Inspection No', key: 'sampleCode'},
            {name: '*物种 Species', key: 'speciesName'},
            {name: '样本原始编号 Original Number', key: 'originalNumber'},
            {name: '*样本名称 Sample Name', key: 'sampleName'},
            {name: '*性别 Gender', key: 'genderName'},
            {name: '*古代样本分类 Sample Type', key: 'sampleTypeName'},
            {name: '*样本归属单位 Biomaterial Provider', key: 'biomaterialProvider'},
            {name: '*取样部位 Tissue', key: 'tissueName'},
            {name: '*测年信息 Date', key: 'datingInfo'},
            {name: '*测年方法 Dating Strategy', key: 'datingMethodName'},
            {name: '*是否校正 Calibration', key: 'calibrationName'},
            {name: '*文章发行年份 Publishing Year', key: 'publishingYear'},
            {name: '*文章名称 Paper Title', key: 'paperTitle'},
            {name: '*文章链接或 DOI Link or DOI', key: 'linkDoi'},
            {name: '*实验名称 Experiment Title', key: 'experimentTitle'},
            {name: '*测序平台 Platform', key: 'platfromName'},
            {name: '*文库名称  Library Name', key: 'libraryName'},
            {name: '*文库类型 Librsry Style', key: 'libraryStyleName'},
            {name: '*UDG类型 UDG Style', key: 'udgStyleName'},
            {name: '*实验策略 Library Strategy', key: 'libraryStrategyName'},
            {name: '*数据类型 Data Type', key: 'dataTypeName'},
            {name: '*文件名称 File Name', key: 'fileName'},
            {name: '*文件MD5值  File MD5', key: 'fileMd5'},
            {name: '*覆盖度 Coverage', key: 'coverage'},
            {name: '*污染程度 Contamination', key: 'contamination'},
        ])

        let tableData = ref(null)
        const getDataInfo = async function (id) {
            let res = await dnaDetailsApi.getDataInfo(id);
            let ruins = await dnaDetailsApi.getRuinsInfo(res.datas.ruinsId);
            let ruinsInfo = await dnaDetailsApi.getRuinsInfoDetails(res.datas.ruinsInfoId);
            dataInfo.value = {
                ...res.datas,
                releaseTime: commonMethods.momentFormat(res.datas.releaseTime),
                dataPower: res.datas.dataPower === "0" ? '受限' : '公开',
                ...ruins,
                area: ruins.province + ruins.city + ruins.county,
                ...ruinsInfo
            };
            groupName.value = res.datas.groupName;
        }
        // 获取文件列表
        const getFilesListData = async function (dataId) {
            let res = await dnaDetailsApi.getFilesListData(dataId)
            tableData.value = res.datas
        }
        // 表格时间格式过滤
        const dateFormat = function (row) {
            return commonMethods.momentFormat(row.createTime)
        }
        const viewDownload = function (row) {
            let url = `${commonMethods.getCookie("proUrl").url}/api-file/downlaod?bucketName=${row.bucketName}&id=${row.fileId}&fileName=${row.fileName}`
            commonMethods.downloadFile(url, null, 'fasta');
        }
        onMounted(function () {
            let id = route.query.id
            getDataInfo(id)
            let dataId = id
            getFilesListData(dataId)
        })
        return {
            infoList,
            tableList,
            dataInfo,
            groupName,
            tableData,
            dateFormat,
            viewDownload
        }
    }
})
</script>

<style lang="scss">
@import "../../../scss/commonFunc";
.data-details{
    .project-basic-info {
        margin: pxToRem(20) 0;

        .project-basic-info-content {
            background-color: white;
            padding: 0 pxToRem(40);

            &:first-child {
                padding-top: pxToRem(30);
            }

            .info-list-component {
                padding: pxToRem(20) pxToRem(30);
            }

            .info-table-component {
                padding: pxToRem(30) pxToRem(40);
            }
        }

        .file-table {
            padding: 0 pxToRem(80) pxToRem(20);
            background-color: white;
        }
    }

    .project-basic-sample-table {
        margin: pxToRem(20) 0;
        padding: pxToRem(20) pxToRem(40) 0;
        background-color: white;

        .module-head-component {
            margin-bottom: pxToRem(20);
        }
    }
}
</style>