<template>
    <div class="system-log right-component-style">
        <database-head-component>
            <el-form v-model="filterForm" class="filter_section default_space_style" size="large">
                <el-row :gutter="60">
                    <!--                    <el-col v-if="userJudge" :span="10">-->
                    <!--                        <el-form-item label="账号：">-->
                    <!--                            <el-input v-model="filterForm.userName" clearable placeholder="请输入查询操作账号"/>-->
                    <!--                        </el-form-item>-->
                    <!--                    </el-col>-->
                    <el-col :span="10">
                        <el-form-item label="时间：">
                            <el-date-picker
                                v-model="filterForm.time"
                                end-placeholder="结束时间"
                                start-placeholder="开始时间"
                                type="daterange"
                            />
                        </el-form-item>
                        <!--                        :default-value="[new Date(2022, 4, 1), new Date(2022, 10, 1)]"-->
                    </el-col>
                </el-row>
                <el-button type="primary" @click="queryEvent">查询</el-button>
            </el-form>
        </database-head-component>
        <database-content-component>
            <div class="default_table_style table_bg">
                <el-table :data="tableData" size="large" stripe>
                    <el-table-column label="序号" type="index" width="100"></el-table-column>
                    <el-table-column label="操作" prop="operation"></el-table-column>
                    <el-table-column label="操作账户" prop="userName"></el-table-column>
                    <el-table-column label="操作时间" prop="timestamp">
                        <template #default="scope">
                            <p>{{ timeFormat(scope.row.timestamp) }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination-component :page-size="pageSize" :total="total" pageSizes
                                  @current-change="currentChange"
                                  @sizes-change="handleSizeChange"/>
        </database-content-component>
    </div>
</template>

<script>
import {defineComponent, reactive, ref, onMounted} from "vue";
import DatabaseHeadComponent from "../database-head-component/database-head-component";
import DatabaseContentComponent from "../database-content-component/database-content-component";
import {systemLogApi} from "../../../api/database-devops-api";
import commonMethods from "../../../js/common-methods";
import userApi from "../../../api/user-api";

export default defineComponent({
    name: "system-log-module",
    components: {
        DatabaseContentComponent,
        DatabaseHeadComponent
    },
    props: {
        applicationName: {
            type: String,
            default: '',
        },
    },
    setup(props) {

        // 表格数据
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);
        let tableData = ref([]);
        let filterForm = reactive({
            // userName: null,// 用户账号
            time: null
        })

        const queryEvent = function () {
            currentChange(1);
        }
        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getDataList();
        }
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }
        // 获取日志列表
        const getDataList = async function () {
            let data = {
                page: pageNumber.value,
                limit: pageSize.value,
                userName: commonMethods.getCookie('uname'),
                applicationName: props.applicationName
            }
            if (filterForm.time) {
                data.startTime = commonMethods.momentFormat(filterForm.time[0])
                data.endTime = commonMethods.momentFormat(filterForm.time[1])
            }
            let result = await systemLogApi.getSystemLogList(data);
            tableData.value = result.data;
            total.value = result.count;
        }
        let userJudge = ref(true)
        const getUserRoles = async function () {
            let res = await userApi.getCurrentUser()
            let roles = res['datas'].roles;
            for (const role of roles) {
                if (["admin-animal", "admin-plant", "admin-dna", "admin-carbon", "admin"].includes(role.code)) {
                    userJudge.value = true;
                    return getDataList(true);
                }
            }
            userJudge.value = false;
            return getDataList(false);
        }

        //时间格式化
        const timeFormat = function (time) {
            return commonMethods.momentFormat(time, "YYYY-MM-DD HH:mm:ss")
        }

        onMounted(function () {
            getDataList();
            // getUserRoles();
        })

        // onBeforeUpdate(function () {
        //     getDataList();
        // })

        return {
            pageSize,
            total,
            tableData,
            currentChange,
            handleSizeChange,

            filterForm,
            queryEvent,
            userJudge,
            timeFormat,
        }
    }
})
</script>

<style lang="scss">
.system-log {
    display: flex;
    flex-direction: column;
    height: 100%;

    .el-range-input {
        background-color: transparent;
    }

    .database-content-component {
        height: 100%;
    }
}
</style>
