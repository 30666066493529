import requestInterface from "../js/request-interface";
import {ElMessage} from "element-plus";
import commonMethods from "../js/common-methods";
// 接口标识
let marking = commonMethods.getDatabaseMarking();

const userApi = {
    /**
     * 用户登录服务接口
     * @param params
     * @returns {Promise<unknown>}
     */
    login(params) {
        return new Promise((resolve, reject) => {
            requestInterface.requestPost("api-uaa/oauth/token", params, null, 'Basic ' + window.btoa("webApp:webApp")).then(msg => {
                msg = msg['datas'];
                if (msg) {
                    // ElMessage.success("登录成功");
                    resolve(msg);
                } else {
                    ElMessage.error("登录失败");
                    reject();
                }
            })
        })
    },
    // 退出登录服务接口
    logout() {
        if (!commonMethods.getCookie("safer")) return;
        commonMethods.removeAllCookie();
        ElMessage.success("退出成功");
        window.location.href = window.location.origin + "/portal/#/"
        location.reload();
    },
    // 获取验证码
    getSendCode(mobile) {
        return requestInterface.requestGet(`api-uaa/validata/smsCode/${mobile}?isNewVersion=true`);
    },
    //
    getInfoByMobile(mobile, safer) {
        return requestInterface.requestGet("api-user/users-anon/mobile", {mobile}, null, safer);
    },
    // 根据access_token获取当前登录用户
    getCurrentUser() {
        return requestInterface.requestGet("api-user/users/current");
    },
    // 根据access_token获取当前登录用户与数据库关联
    getCurrentDataIndex() {
        return requestInterface.requestGet("api-user/user/check/findLoginUser");
    },
    // 获取Minio地址
    getMinioUrl() {
        return requestInterface.requestGet("plant-arch/minio/url");
    },
    //获取三位文件查看地址
    getThreeMinioUrl() {
        return requestInterface.requestGet("plant-arch/tomcat/url");
    },
    // RSA加密
    RSAEncrypt(params) {
        return requestInterface.requestGet("carbon-arch/rsa/pwdencrypt", {password: params});
    },
    // 用户注册
    userRegister(params) {
        return requestInterface.requestUploadFile("api-user/register", params);
    },
    // 申请数据库权限
    checkApply(params) {
        return requestInterface.requestPost("api-user/user/check/apply", params);
    },
    // 获取用户详细信息
    getUserDetailsInfo() {
        return requestInterface.requestGet("api-user/user/loginUser");
    },
    // 修改密码
    editPassword(params) {
        return requestInterface.requestPost("api-user/users/password", params, null, null, true);
    },
    //获取用户操作权限
    async getUserAccess(dataIndex) {
        return await requestInterface.requestGet(`api-user/menus/current/${dataIndex}`);
    },
    // 获取消息列表
    getMessageList(params) {
        return requestInterface.requestGet(marking + "/message/page", params);
    },
    //消息提醒查看详情
    getMsgDetails(id) {
        return requestInterface.requestGet(marking + `/message/find`, {id});
    },
    //消息变更为已读
    changeMsgStage(id) {
        return requestInterface.requestPost(marking + "/message/update", {id});
    },
    // 注销用户
    bannedUser() {
        try {
            return requestInterface.requestGet("api-user/logoff");
        } catch (e) {
            return e.response.data;
        }
    }
}
export default userApi;
