<template>
    <div class="plant-database-component">
        <module-panel-component v-if="headBg" title="植物数据库关联数据">
            <btn-group-component :btn-list="plantList" :default-btn="currentPlant"
                                 class="plant-btn-group-component"
                                 @btn-handle="val=>currentHandle(val)"/>
            <div class="default_table_style table_bg">
                <el-table :data="tableData" size="large" stripe>
                    <el-table-column v-for="(item,key) in tableHeader" :key="key" :label="item.label"
                                     :prop="item.prop">
                        <template v-if="key === 0" #default="scope">
                            <p>{{ scope.row[item.prop] }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="primary" @click="lookHandle(scope.row)">查看</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination-component :page-size="pageSize" :total="total" pageSizes
                                  @current-change="currentChange"
                                  @sizes-change="handleSizeChange"/>
        </module-panel-component>
        <module-head-component v-else title="植物数据库关联数据">
            <btn-group-component :btn-list="plantList" :default-btn="currentPlant"
                                 class="plant-btn-group-component"
                                 @btn-handle="val=>currentHandle(val)"/>
            <div class="default_table_style table_bg">
                <el-table :data="tableData" size="large" stripe>
                    <el-table-column v-for="(item,key) in tableHeader" :key="key" :label="item.label"
                                     :prop="item.prop">
                        <template v-if="key === 0" #default="scope">
                            <p>{{ scope.row[item.prop] }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="primary" @click="lookHandle(scope.row)">查看</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <pagination-component :page-size="pageSize" :total="total" pageSizes
                                  @current-change="currentChange"
                                  @sizes-change="handleSizeChange"/>
        </module-head-component>
    </div>
</template>

<script>
import {defineComponent, reactive, ref, watchEffect} from "vue";
import {linkageApi} from "../../api/database-linkage-api";
import {useRoute, useRouter} from "vue-router";
import userApi from "../../api/user-api";
import commonMethods from "../../js/common-methods";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "plant-database-component",
    props: {
        headBg: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Array,
            default: null
        },
        queryShow: {
            type: Boolean,
            default: false,
        },
        routerName: {
            type: String,
            default: null,
        }
    },
    setup(props) {
        let route = useRoute();
        let router = useRouter();
        let tableData = ref([]);
        let pageNumber = ref(1);
        let pageSize = ref(10);
        let total = ref(0);

        let plantList = ref([
            {name: '炭化植物', text: '炭化植物', value: 'carbonized', index: 1},
            {name: '植硅体', text: '植硅体', value: 'phytolith', index: 2},
            {name: '淀粉粒', text: '淀粉粒', value: 'starch', index: 3},
            {name: '木炭', text: '木炭', value: 'charcoal', index: 4},
        ])

        let currentPlant = ref(plantList.value[0].value)
        let sampleType = ref(plantList.value[0].index)
        const tableList = reactive({
            plant: {
                carbonized: [
                    {label: '遗址名称', prop: 'ruinsName'},
                    {label: '文化分期', prop: 'culturalPeriod'},
                    {label: '出土单位号', prop: 'unitCode'},
                    {label: '种属', prop: 'name'},
                    {label: '种拉丁名', prop: 'speciesId'},
                    // {label: '保存部位', prop: 'preservationSiteName'},
                    // {label: '成熟度', prop: 'maturityName'},
                    // {label: '保存状况', prop: 'saveStatusName'},
                ],
                phytolith: [
                    {label: '遗址名称', prop: 'ruinsName'},
                    {label: '文化分期', prop: 'culturalPeriod'},
                    {label: '出土单位号', prop: 'unitCode'},
                    {label: '植硅体类型', prop: 'phytolithTypeName'},
                    // {label: '数量', prop: 'quantity'},
                ],
                starch: [
                    {label: '遗址名称', prop: 'ruinsName'},
                    {label: '文化分期', prop: 'culturalPeriod'},
                    {label: '出土单位号', prop: 'unitCode'},
                    {label: '样品来源', prop: 'utensilType'},
                    {label: '淀粉粒种类', prop: 'name'},
                    // {label: '数量', prop: 'quantity'},
                    // {label: '保存状况', prop: 'saveStatusName'},
                ],
                charcoal: [
                    {label: '遗址名称', prop: 'ruinsName'},
                    {label: '文化分期', prop: 'culturalPeriod'},
                    {label: '出土单位号', prop: 'unitCode'},
                    {label: '取样部位', prop: 'samplePlace'},
                    {label: '种属', prop: 'name'},
                    // {label: '数量', prop: 'quantity'},
                ],
            }
        })
        let tableHeader = ref(tableList.plant.carbonized)
        const getDataList = async function () {
            let params
            if (props.queryShow) {
                params = {
                    page: pageNumber.value,
                    limit: pageSize.value,
                    ruinsName: route.query.title ? route.query.title : null,
                    ...props.form
                }
            } else {
                params = {
                    page: pageNumber.value,
                    limit: pageSize.value,
                    unitCode: route.query.unitCode ? route.query.unitCode : null,
                    ruinsName: route.query.title ? route.query.title : null,
                    culturalPeriod: route.query.culturalPeriod ? route.query.culturalPeriod : null,
                    sampleCode: route.query.sampleCode ? route.query.sampleCode : null,
                    ...props.form
                }
            }
            let res = await linkageApi.getPlantDataList(currentPlant.value, params);
            tableData.value = res.data;
            total.value = res.count;
        }

        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getDataList();
        }
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }

        const currentHandle = (val) => {
            currentPlant.value = val.value
            tableHeader.value = tableList.plant[val.value]
            sampleType.value = val.index
            getDataList();
        }

        const getCurrentDataIndex = async function (val) {
            let stage = false;
            let result = await userApi.getCurrentDataIndex();
            if (result["resp_code"] === 0) {
                for (const item of result["datas"]) {
                    if (item.dataIndex === val) {
                        if (item.checkStatus === '1') {
                            stage = true
                        }
                    }
                }
            }

            return stage
        }
        const centerData = async function (index, path, row) {
            let stage = await getCurrentDataIndex(index)
            if (stage) {
                if (route.query.dataPower === '1') {
                    await commonMethods.deleteDialog({
                        title: "访问数据库",
                        text: '您已拥有访问该数据库的权限，是否前往该数据库查看更多详情？',
                        type: "info",
                    });
                    let proUrl = commonMethods.getCookie("proUrl");
                    window.location.href = proUrl[path] + `/#/sampleCenter/look?title=${route.query.title}&id=${route.query.id}&unitCode=${route.query.unitCode}&culturalPeriod=${route.query.culturalPeriod}&ruinsId=${row.ruinsId}`
                } else {
                    await commonMethods.deleteDialog({
                        title: "申请数据权限",
                        text: '您访问的该数据是受限数据，是否前往该数据库申请查看更多数据详情？',
                        type: "info",
                    });
                    let proUrl = commonMethods.getCookie("proUrl");
                    window.location.href = proUrl[path] + `/#/sampleCenter/detail?ruinsId=${row.ruinsId}&title=${route.query.title}`
                }
            } else {
                // 跳转个人中心
                await commonMethods.deleteDialog({
                    title: "植物考古数据库权限申请",
                    text: '暂无该数据库权限,是否前往个人中心发起申请？',
                    type: "info",
                });
                router.push({name: 'personalCenterDatabase'});
            }
        }
        const applyData = function (row) {
            let token = commonMethods.getCookie('safer')
            if (token) {
                centerData('2', 'plant', row);
            } else {
                ElMessage.warning('请先登录自己个人账号，获取相关访问权限')
            }
        }

        const lookHandle = function (row) {
            if (row.dataPower === '1') {
                router.push({
                    name: props.routerName,
                    query: {
                        title: row.ruinsName,
                        id: row.id,
                        ruinsId: row.ruinsId,
                        bgId: row.backgroundId,
                        bgType: row.backgroundType,
                        sampleType: sampleType.value,
                        sampleId: row.id
                    }
                })
            } else {
                applyData(row)
            }
        }

        watchEffect(function () {
            getDataList()
        })


        return {
            pageSize,
            total,
            tableData,
            currentChange,
            handleSizeChange,
            plantList,
            currentPlant,
            currentHandle,
            tableList,
            tableHeader,
            lookHandle

        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.plant-database-component {
    padding-bottom: pxToRem(20);

    .module-head-component {
        margin-bottom: pxToRem(20);
    }
}
</style>