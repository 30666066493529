<template>
    <div class="database-devops default_page_style default_wrapper">
        <ul class="left_navigation">
            <li v-for="(nav,i) in newFunctionList" :key="i"
                :class="{'active':nav.path===currentNavigation}"
                @click="navigationHandle(nav)" v-text="nav.name"></li>
        </ul>
        <div class="right_container">
            <transition-router-view-component/>
        </div>
    </div>
    <slot/>
</template>

<script>
import {defineComponent, onMounted, ref, watchEffect} from "vue";
import {useRoute, useRouter} from "vue-router";
import {TransitionRouterViewComponent} from "../../index";
import userApi from "../../api/user-api";

export default defineComponent({
    name: "database-devops-module",
    components: {TransitionRouterViewComponent},
    props: {
        navigation: {
            required: true,
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        // 导航列表
        let navigationList = ref(props.navigation);
        // 当前导航
        let currentNavigation = ref(navigationList.value[0].path);

        let router = useRouter();
        let route = useRoute();

        // 导航事件处理
        const navigationHandle = nav => {
            router.push({name: nav.path})
        }
        let dataIndex = ref(process.env.VUE_APP_MARKING)
        // 监听路由变化
        watchEffect(() => {
            currentNavigation.value = route.meta['leftNavigation'];
        })
        // 获取用户操作权限
        let newFunctionList = ref([]) // 根据权限获取新的表格列表

        const getOperating = async function () {
            let result = await userApi.getUserAccess(dataIndex.value) //获取用户所有权限
            let arr = []
            // 根据路由判断具体模块权限
            for (const item of result) {
                if (item.path === route.meta.mainNavigation) {
                    arr = item.subMenus
                }
            }
            // 子模块功能需要控制放开
            // let obj = []
            // // 根据路由获取模块操作权限
            // for (const item of arr) {
            //     if (item.name === route.meta.title) {
            //         obj = item.subMenus
            //     }
            // }
            // 与初始化权限做比对，得到新的操作按钮列表
            for (const item of arr) {
                for (const itemElement of navigationList.value) {
                    if (item.name === itemElement.name) {
                        newFunctionList.value.push(itemElement)
                    }
                }
            }
            currentNavigation.value = newFunctionList.value[0].path
            if (route.query.show){
                // 这个只针对于dna数据库
                navigationHandle( {name: "审核/发布申请",path: 'auditOrPublishApply'})
            }else {
                navigationHandle(newFunctionList.value[0])
            }
        }
        onMounted(function () {
            getOperating()
        })
        return {
            navigationList,
            currentNavigation,
            navigationHandle,
            newFunctionList
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.database-devops {
    flex: 1;
    display: flex;

    .left_navigation {
        @include width(203);
        @include bgColor(white);

        li {
            padding-left: pxToRem(47);
            @include fontSize(16);
            @include color;
            height: pxToRem(60);
            cursor: pointer;
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;

            &:hover {
                @include color($defaultColor);
            }

            &.active {
                @include bgColor($bgOpacityColor);
                @include color($defaultColor);

                &:before {
                    content: "";
                    @include position;
                    @include trbl(pxToRem(16), 0, 0, pxToRem(-12));
                    @include widthHeight(25);
                    box-sizing: border-box;
                    border: pxToRem(1) solid $defaultColor;
                    transform: rotate(45deg);
                }

                &:after {
                    content: "";
                    @include position;
                    @include trbl(pxToRem(18), 0, 0, pxToRem(-13));
                    @include widthHeight(21);
                    @include bgColor($defaultColor);
                    transform: rotate(45deg);
                }
            }
        }
    }

    .right_container {
        margin-left: pxToRem(20);
        flex: 1;
        width: 0;

        .filter_section {
            @include bgColor(white);
            box-sizing: border-box;
        }
    }
}
</style>
