<template>
    <ul v-if="dataList.length > 0" class="image-list-component">
        <li v-for="(item, i) in dataList" :key="item.id">
            <div class="image-box">
                <img :src='filterImageEvent(item)' alt="" @error="errorImage($event)">
                <div class="shadow_img">
                    <span @click="previewImage(item, i)">
                        <i class="iconfont icon-chakan"/>
                        <p>查看</p>
                    </span>
                    <span @click="deleteImage(item)" v-if="deleteIcon">
                        <i class="iconfont icon-shanchu"/>
                        <p>删除</p>
                    </span>
                </div>
            </div>
            <div v-show="item.name" class="content">
                <div class="describe">
                    <div class="title">{{ item.name }}</div>
                    <div v-show="operation" class="operation">
                        <div class="iconfont icon-bianji" @click="editHandle(item)"/>
                        <div class="iconfont icon-shanchu" @click="deleteHandle(item)"/>
                    </div>
                </div>

                <div class="describe" v-if="describe">
                    <div v-show="item.shoter" class="personnel">
                        <span>拍摄人：</span>
                        <span>{{ item.shoter }}</span>
                    </div>
                    <div v-show="item.shotTime" class="personnel">
                        <span>拍摄时间：</span>
                        <span>{{ momentFormat(item.shotTime) }}</span>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <el-empty v-else/>
</template>

<script>
import {defineComponent, onMounted} from "vue";
import globalMixin from "../../mixins/global-mixin";
import commonMethods from "../../js/common-methods";
import userApi from "../../api/user-api";

export default defineComponent({
    name: "image-list-component",
    mixins: [globalMixin],
    props: {
        dataList: {
            type: Array,
            default: () => {
                return []
            }
        },
        preview: {
            type: Boolean,
            default: false,
        },
        operation: {
            type: Boolean,
            default: true,
        },
        deleteIcon: {
            type: Boolean,
            default: false,
        },
        describe: {
            type: Boolean,
            default: true,
        }
    },
    setup(props, context) {
        // 编辑
        const editHandle = item => {
            context.emit("editHandle", item);
        }
        // 删除
        const deleteHandle = async item => {
            await commonMethods.deleteDialog();
            await context.emit("deleteHandle", item);
        }

        // 图片预览
        const previewImage = async (item, index) => {
            if (props.preview) {
                context.emit("previewHandle", item);
            } else {
                const {dataList} = props
                let list = []
                for (let i = 0; i < dataList.length; i++) {
                    let imageUrl
                    if (dataList[i].photoFileList) {
                        imageUrl = dataList[i].photoFileList[0].filePath
                    } else if (dataList[i].fileInfoList) {
                        imageUrl = dataList[i].fileInfoList[0].filePath
                    } else {
                        imageUrl = item.imgUrl
                    }
                    let res = await userApi.getMinioUrl();//获取查看文件minio地址
                    let url = res['resp_msg'] + '/' + imageUrl;
                    list.push(url)
                }
                commonMethods.showMaxImgList(list, index)
            }
        };
        //图片删除
        const deleteImage = async function (item) {
            await context.emit("deleteImage", item);
        }
        // 过滤图片信息
        const filterImageEvent = function (item) {
            let url
            if (item.imgUrl) {
                url = commonMethods.getImgUrl(item.imgUrl)
                return url;
            } else {
                let file = [];
                if (item.threeDimensionFile) file = item.threeDimensionFile[0]
                if (item.fileInfoList) file = item.fileInfoList[0]
                url = commonMethods.getImgUrl(file)
                return url;
            }
        }

        const momentFormat = function (time) {
            return commonMethods.momentFormat(time)
        }
        onMounted(() => {

        })
        return {
            editHandle,
            deleteHandle,
            previewImage,
            filterImageEvent,
            momentFormat,
            deleteImage
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.image-list-component {
    display: flex;
    //justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: calc(100% / 3 - 17px);
        @include bgColor;
        margin-right: pxToRem(25);
        margin-bottom: pxToRem(20);
        border-radius: pxToRem(6) pxToRem(6) 0 0;

        &:nth-child(3n) {
            margin-right: 0;
        }

        .image-box {
            width: 100%;
            @include height(0);
            padding-top: 75%;
            @include position(relative);
            border-radius: pxToRem(6) pxToRem(6) 0 0;
            overflow: hidden;

            > img {
                @include position;
                @include trbl(0, 0, 0, 0);
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:hover {
                .shadow_img {
                    top: 0;
                }
            }

            .shadow_img {
                @include position;
                @include trbl(-100%, 0, "", 0);
                height: 100%;
                @include bgColor(rgba(0, 0, 0, .3));
                @include color(white);
                transition: .5s linear;
                display: flex;
                //flex-direction: column;
                justify-content: center;
                align-items: center;

                span {
                    display: flex;
                    flex-direction: column;
                    margin-left: pxToRem(20);
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    .iconfont {
                        @include fontSize(24);
                    }

                    p {
                        @include fontSize(15)
                    }
                }
            }
        }

        .content {
            border-top: 1px solid #cccccc;
            padding: pxToRem(10) pxToRem(20);

            .describe {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                @include fontSize(14);

                .title {
                    @include color(#333333);
                    font-weight: 700;
                }

                .operation {
                    display: flex;

                    .iconfont {
                        margin-left: pxToRem(20);
                    }
                }

                .personnel {
                    @include color(#666666);

                    &:nth-child(1) {
                        margin-right: pxToRem(20);
                    }
                }

                &:nth-child(1) {
                    margin-bottom: pxToRem(15);
                }
            }

        }

    }
}
</style>
