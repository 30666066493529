<template>
    <div class="basic-info-text-component">
        <ul>
            <li v-for="(item,i) in dataList" :key="i" :style="{marginRight:gutter}">
                <span>{{ item.name }}{{ colon ? ' ：' : '' }}</span>
                <p>{{ item.value }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "basic-info-text-component",
    props: {
        dataList: {
            type: Array,
            default: () => []
        },
        gutter: {
            type: String,
            default: "13%"
        },
        colon: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.basic-info-text-component {
    padding: pxToRem(10) pxToRem(30) pxToRem(23);
    @include bgColor(white);
    margin-bottom: pxToRem(22);

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            display: flex;
            margin: pxToRem(8) 13% pxToRem(7) 0;
            line-height: pxToRem(30);
            @include color;
            @include fontSize;

            p {
                flex: 1;
            }
        }
    }
}
</style>
