<template>
    <dialog-component :visible="visibleDialog"
                      width="1200px"
                      title="三维预览"
                      :visibleFooter="false"
                      @cancel-handle="cancelHandle">
        <div class="three-preview-component-style" id="ThreeCamera">
            <span id='info' class="threeInfo"/>
        </div>
    </dialog-component>
</template>

<script>
import {defineComponent, nextTick, ref, watchEffect} from "vue";
// import commonMethods from "../../js/common-methods";
import * as THREE from '../../js/jsm/three.module'
import {MTLLoader} from '../../js/jsm/loaders/MTLLoader'
import {OBJLoader} from "../../js/jsm/loaders/OBJLoader"
import {DDSLoader} from "../../js/jsm/loaders/DDSLoader"
import {OrbitControls} from "../../js/jsm/controls/OrbitControls"
import userApi from "../../api/user-api";

let scene;

export default defineComponent({
    name: "three-preview-component",
    props: {
        visibleDialog: {
            type: Boolean,
            default: false
        },
        previewInfo: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        // 三维数据
        let mtlPath = ref(null);
        let objPath = ref(null);

        let objLoader = ref(null);
        let mtlLoader = ref(null);
        let offset_x = ref(null);
        let offset_y = ref(null);
        let offset_z = ref(null);

        let camera = ref(null);
        // var scene = ref(null);
        let renderer = ref(null);

        let windowHalfX = ref(0);
        let windowHalfY = ref(0);
        let mouseX = ref(0);
        let mouseY = ref(0);
        let scale = ref(2);

        // 获取三维文件mtl和obj文件路径
        const info = async function (item) {
            let res = await userApi.getThreeMinioUrl();//获取查看文件minio地址
            mtlPath.value = res['resp_msg'] + '/' + item.mtlPath;
            objPath.value = res['resp_msg'] + '/' + item.objPath;
            init();
            animate();
        }

        /*初始化 设置三维*/
        const init = function () {
            document.querySelector('#info').style.display = '';
            let container = document.createElement('div');
            let ThreeCamera = document.getElementById('ThreeCamera');

            ThreeCamera.appendChild(container);
            windowHalfX.value = ThreeCamera.clientWidth;
            windowHalfY.value = ThreeCamera.clientHeight;
            // 设置摄像机：（摄像机角度 长宽比 最近能看到的画面 最远能看到的画面）  一般设置成0.1,和2000
            camera.value = new THREE.PerspectiveCamera(45, windowHalfX.value / windowHalfY.value, 0.1, 2000);
            camera.value.position.z = 10;

            // 创建一个场景
            scene = new THREE.Scene();
            scene.add(camera.value);
            // // 背景颜色
            scene.background = new THREE.Color(0x333333);

            // 创建环境光
            let ambientLight = new THREE.AmbientLight(0xcccccc, 1);
            // 将环境光添加到场景中
            scene.add(ambientLight);

            // 创建地面光
            // let spotLight = new THREE.SpotLight("#ffffff", 0.7);
            // spotLight.position.set(1600,1660,100);
            // // 阴影
            // spotLight.castShadow = true;
            // scene.add(spotLight)

            // 点光源1
            let pointLight1 = new THREE.PointLight(0xffffff, 0.1);
            //设置点光源1位置
            pointLight1.position.set(80, 100, 50);
            // 将光源1添加到场景中
            scene.add(pointLight1);

            // 点光源2位置和点光源1关于原定对称
            let pointLight2 = new THREE.PointLight(0xffffff, 0.3);
            //设置点光源2位置
            pointLight1.position.set(-80, -100, -50);
            scene.add(pointLight2);

            // 加载进度
            let onProgress = function (xhr) {
                if (xhr.lengthComputable && document.querySelector('#info')) {
                    let percentComplete = xhr.loaded / xhr.total * 100;
                    if (Math.round(percentComplete, 2) >= 100) {
                        document.querySelector('#info').style.display = 'none';
                    } else {
                        document.querySelector('#info').innerHTML = '正在加载模型 ' + Math.round(percentComplete, 2) + ' %';
                    }
                }
            };
            // 加载失败执行
            let onError = function (xhr) {
            };

            let manager = new THREE.LoadingManager();
            // manager.addHandler(/\.dds$/i, new DDSLoader());
            manager.addHandler(/\.tga$/i, new DDSLoader());
            manager.onProgress = function (item, loaded, total) {
            };
            //目前加载obj方法会加载贴图  此处不在单独加载纹理贴图， 如此处在添加纹理贴图会造成原模型贴图不显示，只显示黑色的模型
            // let texture = new THREE.Texture();  // 详细注释在下面有解释

            // obj模型数据
            mtlLoader.value = new MTLLoader(manager)
            mtlLoader.value.load(mtlPath.value, function (materials) {
                // mtlLoader.value
                //     // 本地加载方式
                //     .setPath('./M70-1/')
                //     .load("M70-1.mtl", function (materials) {
                materials.preload();
                objLoader.value = new OBJLoader(manager)
                objLoader.value
                    .setMaterials(materials)
                    // .setPath('./M70-1/')
                    // .load('M70-1.obj', function (object) {
                    .load(objPath.value, function (object) {

                        let box3 = new THREE.Box3()
                        // 计算层级模型group的包围盒
                        // 模型group是加载一个三维模型返回的对象，包含多个网格模型
                        box3.expandByObject(object)
                        // 计算一个层级模型对应包围盒的几何体中心在世界坐标中的位置
                        let center = new THREE.Vector3()
                        box3.getCenter(center);

                        object.position.x = (object.position.x - center.x);
                        object.position.y = (object.position.y - center.y);
                        object.position.z = (object.position.z - center.z);
                        scene.add(object);

                        object.traverse(function (child) {
                            if (child instanceof THREE.Mesh) {
                                // 设置颜色纹理贴图：texture对象作为材质map属性的属性值
                                //颜色贴图默认为null， 纹理贴图颜色由漫反射颜色.color调节
                                child.material.map = texture; // 设置颜色贴图的属性值
                            }
                        });
                        if (scale.value) {
                            object.scale.x = object.scale.y = object.scale.z = this.scale;
                        }

                        if (offset_x.value) {
                            object.position.x += offset_x.value;
                        }
                        if (offset_y.value) {
                            object.position.y += offset_y.value;
                        }
                        if (offset_z.value) {
                            object.position.z += offset_z.value;
                        }
                        scene.add(object);
                    }, onProgress, onError);
            })

            scene.position.y = scene.position.y - 20;
            renderer.value = new THREE.WebGLRenderer();
            renderer.value.setPixelRatio(window.devicePixelRatio);
            // 设置输出canvas画面的大小
            renderer.value.setSize(windowHalfX.value, windowHalfY.value);
            container.appendChild(renderer.value.domElement);
            window.renderer = renderer.value;

            let controls = new OrbitControls(camera.value, renderer.value.domElement);
            // an animation loop is required when either damping or auto-rotation are enabled
            controls.enableDamping = true;
            controls.dampingFactor = 1;
            controls.screenSpacePanning = true;
            controls.minDistance = 0;
            controls.maxDistance = 500;
            controls.target.set(0, 0, 0);
            controls.update();
            // document.addEventListener("pointermove", function () {
            //     onDocumentMouseMove()
            // }, false);
            window.addEventListener('resize', function () {
                onWindowResize()
            }, false);
        };

        // 指针改变坐标时触发
        // const onDocumentMouseMove = function (event) {
        // mouseX.value = (event.clientX - windowHalfX.value) / 2;
        // mouseY.value = (event.clientY - windowHalfY.value) / 2;
        // }
        //浏览器窗口调整到新的高度或宽度时，会触发resize事件
        const onWindowResize = function () {
            if (camera.value) {
                camera.value.aspect = windowHalfX.value / windowHalfX.value;
                camera.value.updateProjectionMatrix();
                renderer.value.setSize(windowHalfX.value, windowHalfX.value);
            }
        };
        const animate = function () {
            //不会卡塞，专门针对图形渲染刷新的方法
            requestAnimationFrame(animate);
            render();
        };
        const render = function () {
            // 让相机指向原点
            camera.value.lookAt(scene.position);
            renderer.value.render(scene, camera.value);
        };

        watchEffect(function () {
            let previewInfo = props.previewInfo;
            if (previewInfo) {
                nextTick(() => {
                    info(previewInfo)
                })
            }
        })

        // 弹框关闭事件
        const cancelHandle = function () {
            context.emit("cancelHandle");
        }
        return {
            cancelHandle
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.three-preview-component-style {
    @include height(600);
    border: none;

    .threeInfo {
        @include position;
        @include trbl(50%, "", "", 44%);
        @include color(white);
    }
}
</style>
