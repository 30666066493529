<template>
    <div class="ruins-mode-relic-detection-info">
        <div class="detail-table-style default_table_style">
            <el-table size="large" :data="tableData">
                <el-table-column label="种属" prop="testSubstance"></el-table-column>
                <el-table-column label="数量" prop="testNumber"></el-table-column>
                <el-table-column label="检测时间" prop="testTime">
                    <template #default="scope">
                        <span>{{ scope.row.testTime ? momentFormat(scope.row.testTime) : null }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="检测单位" prop="testCompany"></el-table-column>
                <el-table-column label="%C" prop="carbonContent"></el-table-column>
                <el-table-column label="%N" prop="nitrogenContent"></el-table-column>
                <el-table-column prop="nitrogenContent">
                    <template #header>
                        <div class="carbon-isotope-label table">δ&nbsp;&nbsp;&nbsp;<span data-attr="13">C</span></div>
                    </template>
                </el-table-column>
                <el-table-column prop="nitrogenIsotope">
                    <template #header>
                        <div class="carbon-isotope-label table">δ&nbsp;&nbsp;&nbsp;<span data-attr="15">N</span></div>
                    </template>
                </el-table-column>
                <el-table-column label="碳氮摩尔比值" prop="cnMolar"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import {defineComponent, ref, reactive, onMounted} from "vue";
import {useRoute} from "vue-router";
import commonMethods from "../../../../../js/common-methods";
import {plantDetailsApi} from "../../../../../api/data-linkage-details-api";

export default defineComponent({
    name: "ruins-mode-relic-detection-info",
    setup() {
        let route = useRoute();
        const plantSampleSwitch = reactive({name: '碳氮稳定同位素', value: 'carbonAndOxygenIsotopes', type: "cnIsotope"},)
        // 当前选中的按钮，
        let currentSwitch = ref(plantSampleSwitch[0]);
        const tableData = ref([])

        // 获取数据列表
        const getDataList = async function () {
            // let urlParams = commonMethodsPlant.getUrlBgParams(route.query)
            let param = {
                // ...urlParams,
                sampleId: route.query.sampleId
            }
            let result = await plantDetailsApi.getDetectionList(param, currentSwitch.value.type);
            tableData.value = result["data"];
        }
        const momentFormat = function (val) {
            return commonMethods.momentFormat(val)
        }
        onMounted(function () {
            if (route.query.sampleType === '1') getDataList();
        })
        return {
            plantSampleSwitch,
            currentSwitch,
            tableData,
            momentFormat,
        }
    }
})
</script>

<style lang="scss">
@import "../../../../../scss/commonFunc";
.ruins-mode-relic-detection-info {
    .detail-table-style {
        padding-bottom: pxToRem(20);
    }
}
</style>
