import requestInterface from "../js/request-interface";
import commonMethods from "../js/common-methods";
// 样本管理公共接口
const ruinsModeApi = {
    /**
     * 遗迹分页列表 / 根据id查询遗迹
     * @param params
     * @param type  //? "findById" : "pages"
     * @param dataIndex
     * @returns {*}
     */
    getRuinsList(params, type, dataIndex) {
        return requestInterface.requestGet(`${commonMethods.getDatabaseType(dataIndex).database}/ruins/${type}`, params);
    },
    getPlantRuinsList(params) {
        return requestInterface.requestGet(`plant-arch/ruins/plant/page`, params);
    },
    //植物审核样本数据
    auditRuinsList(params) {
        return requestInterface.requestGet("plant-arch/ruins/page/status", params);
    },
    /**
     * 查询所有遗址名称
     * @param dataIndex
     * @returns {*}
     */
    getAllRuinsList(dataIndex) {
        return requestInterface.requestGet(`${commonMethods.getDatabaseType(dataIndex).database}/ruins/find`);
    },
    /**
     * 查询基本信息四大类分页列表
     * @returns {*}
     * @param params
     * @param type
     */
    getCurrentDataList(params, type) {
        return requestInterface.requestGet(`plant-arch/${type}/page`, params)
    },
    // 公共部分
    // 获取code列表
    async getCodeList() {
        const result = await requestInterface.requestGet("plant-arch/dict/codelist");
        let objList = {};
        for (const obj of result['datas']) {
            objList[obj.key] = obj.value;
        }
        return objList;
    },
    // 根据code获取子级列表
    async getCodeByChildList(code) {
        const result = await requestInterface.requestGet("plant-arch/dict/sonlist", {code});
        return result['datas'];
    },
    // 查询实验室列表
    async getLabNameList(code) {
        const result = await requestInterface.requestGet("plant-arch/dict/labname", {code});
        let list = [];
        for (const data of result['datas']) {
            list.push({name: data})
        }
        return list;
    },
    /**
     * 获取拉丁名
     * @returns {*}
     * @param type
     * @param params
     */
    getLatinName(params, type) {
        return requestInterface.requestGet(`plant-arch/dict/${type}`, params)
    },
    // * 批量导出样本id序列号生成
    // * */
    batchSampleId(ids) {
        return requestInterface.requestPost("plant-arch/excel/generate/ids", ids);
    },
    /**
     * 生成导出序列号
     * @param params
     * @returns {*}
     */
    sampleExportGenerate(params) {
        return requestInterface.requestPost("plant-arch/excel/generate", params);
    },
}

// 样本导入公共接口
export const sampleImportCommonApi = {
    /**
     * 下载样本模板
     * @param dataIndex
     * @returns {*}
     */
    downloadSampleTemplate(dataIndex) {
        return requestInterface.requestGet(`${commonMethods.getDatabaseType(dataIndex).database}/sampleExcel/exportModel`);
    },
    /**
     * 导入样本
     * @param dataIndex
     * @param params
     * @param path
     * @returns {*}
     */
    importSampleExcel(dataIndex, params, path) {
        return requestInterface.requestUploadFile(`${commonMethods.getDatabaseType(dataIndex).database}/${path}/importExcel`, params);
    },
    /**
     * 植物导入样本
     * @param type
     * @param params
     * @returns {*}
     */
    plantImportSampleExcel(type, params) {
        return requestInterface.requestUploadFile(`plant-arch/excel/${type}`, params);
    },
    /**
     * 植物导入样本新
     * @param bgType
     * @param type
     * @param params
     * @returns {*}
     */
    importPlantSampleExcel(bgType, type, params) {
        return requestInterface.requestUploadFile(`plant-arch/excel/import/${bgType}/${type}/data`, params);
    },
    /**
     * 文献采集导入样本
     * @param type
     * @param params
     * @returns {*}
     */
    literatureImportSampleExcel(params, type) {
        return requestInterface.requestUploadFile(`plant-arch/literature/${type ? "phytolith" : "sample"}/importExcel`, params);
    },
    // 文献资源数据导入
    literatureDataImportSampleExcel(params) {
        return requestInterface.requestUploadFile(`plant-arch/excel/importLiteratureSample`, params);
    },
    //消息提醒查看详情
    messageDetails(type, id) {
        return requestInterface.requestGet(`${type}/message/find`, id);
    },
    //查询消息列表
    messageList(type, readStatus) {
        return requestInterface.requestGet(`${type}/message/list`, readStatus)
    },
    /**
     * 批量审核
     * @param params
     * @param type
     * @returns {*}
     */
    batchAuditData(params, type) {
        return requestInterface.requestPost(`${type}/submitExamine/save`, params);
    },
    //  样本关联文献
    sampleRelevancyLiterature(params, type) {
        return requestInterface.requestPost(`${type}/sampleLiterature/save`, params);
    },
    //  批量更新公开状态
    sampleBatchPower(params, type, sampleType) {
        return requestInterface.requestPost(`${type}/${sampleType}/update/data/power`, params);
    },
}

export {
    ruinsModeApi
}
