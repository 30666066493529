<template>
    <div class="function-btn-group-component">
        <p v-text="title"></p>
        <div :class="dnaBtn ? 'dna_custom_btn_group' : 'custom_btn_group'">
            <span v-for="(item,i) in functionList"  v-if="!dnaBtn" :key="i" @click="functionHandle(item)" v-text="item.name"></span>
<!--            dna样式-->
            <span v-for="(item,i) in functionList" v-if="dnaBtn" :key="i" @click="functionHandle(item)">
                <P>{{ item.name }}</P>
                <P>{{ item.vice }}</P>
            </span>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";

export default defineComponent({
    name: "function-btn-group-component",
    props: {
        functionList: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: null
        },
        dnaBtn: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        let functionHandle = item => {
            context.emit("functionHandle", item);
        }
        return {
            functionHandle
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.function-btn-group-component {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: pxToRem(20);

    p {
        @include fontSize(18);
        @include color;
    }

    .custom_btn_group {
        @include displayFlex;

        span {
            @include bgColor(#f5f5f5);
            border-radius: pxToRem(4);
            border: pxToRem(1) solid rgba(36, 134, 213, 0.6);
            padding: 0 pxToRem(18);
            height: pxToRem(34);
            box-sizing: border-box;
            margin-left: pxToRem(30);
            @include color($defaultColor);
            @include fontSize;
            cursor: pointer;
            @include displayFlex;
            transition: .1s linear;

            &:first-of-type {
                margin-left: 0;
            }

            &:hover {
                //border-color: $defaultColor;
                @include bgColor($defaultColor);
                @include color(white)
            }
        }
    }

    .dna_custom_btn_group {
        @include displayFlex;

        span {
            @include bgColor($bgOpacityColor);
            border-radius: pxToRem(4);
            border: pxToRem(1) solid rgba(55, 119, 86, 0.6);
            padding: 0 pxToRem(18);
            box-sizing: border-box;
            margin-left: pxToRem(30);
            cursor: pointer;
            @include displayFlex;
            transition: .1s linear;
            flex-direction: column;

            p {
                @include color($defaultColor);
                @include fontSize;
                padding: pxToRem(2) pxToRem(5);
            }

            &:first-of-type {
                margin-left: 0;
            }

            &:hover {
                //border-color: $defaultColor;
                @include bgColor($defaultColor);

                p {
                    @include color(white)
                }
            }
        }
    }
}
</style>