<template>
    <div class="original-data-sample-import">
        <breadcrumb-component max/>
        <div class="default_page_style default_wrapper sample-import-box">
            <div class="sample-collection-content default_space_style">
                <div v-if="importShow" class="download-upload">
                    <div class="download-template">
                        <div class="left-icon">
                            <div class="iconfont icon-xiazai"></div>
                        </div>
                        <div class="right-content">
                            <div class="title">下载导入模板</div>
                            <div class="describe">请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除</div>
                            <span v-if="dataIndex !== '2'" class="download-btn" @click="downloadEvent">下载模板</span>
                            <div v-if="dataIndex === '2' && !plantResource" class="download-box">
                                <span class="download-btn" @click="downloadPlantEvent('1')">下载遗迹单位模板</span>
                                <span class="download-btn" @click="downloadPlantEvent('2')">下载剖面采集模板</span>
                                <!--                                <span class="download-btn" @click="downloadPlantEvent('3')">下载残留物采集模板</span>-->
                            </div>
                            <div v-if="dataIndex === '2' && plantResource" class="download-box">
                                <span class="download-btn" @click="downloadLiteratureEvent('1')">下载文献资源数据导入模板</span>
                                <!--                                <span class="download-btn" @click="downloadLiteratureEvent('1')">下载炭化植物模板</span>-->
                                <!--                                <span class="download-btn" @click="downloadLiteratureEvent('0')">下载植硅体模板</span>-->
                                <!--                                <span class="download-btn" @click="downloadLiteratureEvent('2')">下载淀粉粒模板</span>-->
                                <!--                                <span class="download-btn" @click="downloadLiteratureEvent('3')">下载木炭模板</span>-->
                            </div>
                        </div>
                    </div>
                    <div class="download-template upload-content">
                        <div class="left-icon">
                            <div class="iconfont icon-shangchuan"></div>
                        </div>
                        <div class="right-content">
                            <div class="title">请上传填好的样本数据表</div>
                            <el-form ref="uploadFormRef" :model="uploadForm" :rules="uploadRules"
                                     class="upload-form" size="large">
                                <el-form-item v-if="dataIndex === '2' && !plantResource" label="上传文件：">
                                    <el-radio-group v-model="uploadForm.exportType">
                                        <el-radio label="1">遗迹单位</el-radio>
                                        <el-radio label="2">剖面采集</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <!--                                <el-form-item v-if="dataIndex === '2' && plantResource" label="上传文件：">-->
                                <!--                                    <el-radio-group v-model="uploadForm.literatureType">-->
                                <!--                                        <el-radio label="1">炭化植物</el-radio>-->
                                <!--                                        <el-radio label="2">植硅体</el-radio>-->
                                <!--                                        <el-radio label="3">淀粉粒</el-radio>-->
                                <!--                                        <el-radio label="4">木炭</el-radio>-->
                                <!--                                    </el-radio-group>-->
                                <!--                                </el-form-item>-->
                                <el-form-item label="上传文件：" prop="file">
                                    <regular-file-upload-component
                                        ref="uploadRef"
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                        uploadTip="文件后缀名必须为xlsx（即Excel格式）"/>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div>
                    <el-button class="next-step" type="primary" @click="nextStepEvent">下一步</el-button>
                </div>
                <div v-else class="underway">
                    <template v-if="plantLiterature">
                        <div class="iconfont icon-shalou underway-icon"></div>
                        <div class="underway-title">正在进行中......</div>
                        <div class="underway-describe">批量导入正在进行中，请稍后刷新数据列表查看</div>
                        <el-button type="primary" @click="btnHandle(true)" class="return-btn">返回</el-button>
                    </template>
                    <template v-else>
                        <div class="default_table_style table_bg import_table">
                            <el-table :data="messageList">
                                <el-table-column label="明细" prop="title"></el-table-column>
                                <el-table-column label="操作" width="300">
                                    <template #default="scope">
                                        <common-space-vertical>
                                            <el-link type="success" @click="lookDetail(scope.row)">
                                                查看
                                            </el-link>
                                            <el-link type="info" @click="downloadDetail(scope.row)">
                                                下载
                                            </el-link>
                                        </common-space-vertical>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <el-button class="return-btn" type="primary" @click="btnHandle">返回
                        </el-button>
                    </template>


                </div>
            </div>
        </div>
    </div>
    <dialog-component
        :visible="visibleBackspacing"
        title="明细详情"
        @cancel-handle="visibleBackspacing=false"
        @confirm-handle="visibleBackspacing=false">
        <p style="max-height: 400px;overflow-y: scroll" v-text="messageContent"></p>
    </dialog-component>
</template>

<script>
import {defineComponent, onBeforeUnmount, onMounted, reactive, ref, watchEffect} from "vue"
import commonMethods from "../../js/common-methods";
import {sampleImportCommonApi} from "../../api/sample-manage-common-api";
import {useRoute, useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import ReconnectingWebSocket from "reconnecting-websocket";
import exportUtils from "../../plugins/export-utils";

export default defineComponent({
    name: "sample-import-component",
    props: {
        dataIndex: {
            type: String,
            default: "1",
        }
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        let visibleBackspacing = ref(false)
        let plantLiterature = ref(false);
        let apiType = ref(null)
        const importShow = ref(true)
        const uploadRef = ref(null);
        // 上传的Form表单
        const uploadFormRef = ref(null);
        const uploadForm = reactive({
            exportType: "1",
            literatureType: "1",
            file: ''
        });
        const uploadRules = reactive({
            file: [{
                required: true, validator: function (rule, value, callback) {
                    if (uploadRef.value.fileList.length > 0) {
                        return callback();
                    } else {
                        return callback(new Error("请上传文件"));
                    }
                }, trigger: 'blur'
            }],
        })

        const btnHandle = () => {
            router.push({name: "sampleManage"})
        }

        let resultMsg = ref(null);
        // 下一步
        const nextStepEvent = function () {
            uploadFormRef.value.validate(async valid => {
                if (valid) {
                    let fileList = uploadRef.value.fileList;
                    let formData = new FormData();
                    commonMethods.appendFileToFormData(formData, fileList)
                    let result
                    if (props.dataIndex === "1") {
                        result = await sampleImportCommonApi.importSampleExcel(props.dataIndex, formData, "animalInfoDataExcel");
                    } else if (props.dataIndex === "2") {
                        if (plantResource.value) {
                            // 文献采集导入
                            // let currentType = getLiteratureType(uploadForm.literatureType);
                            if (uploadForm.literatureType !== "2") {
                                formData.append('sampleType', uploadForm.literatureType);
                                formData.append('userId', commonMethods.getCookie('uId'))
                            }
                            // result = await sampleImportCommonApi.literatureImportSampleExcel(formData, currentType.type === "phytolith",);
                            result = await sampleImportCommonApi.literatureDataImportSampleExcel(formData);
                        } else {
                            // 原始数据导入
                            let currentType = getPlantType(uploadForm.exportType);
                            result = await sampleImportCommonApi.plantImportSampleExcel(currentType.importType, formData);
                        }
                    } else {
                        result = await sampleImportCommonApi.importSampleExcel(props.dataIndex, formData, "sampleExcel");
                    }
                    if (result["resp_code"] === 0) {
                        importShow.value = false;
                        resultMsg.value = result["resp_msg"];
                        messageDataList()
                    } else {
                        ElMessage.error(result['resp_msg'])
                    }
                }
            })
        }

        // 下载模板
        const downloadEvent = async function () {
            let path;
            if (props.dataIndex === "1") {
                path = "animalInfoDataExcel"
            } else {
                path = "sampleExcel"
            }
            let url = `${commonMethods.getCookie("proUrl").url}/${commonMethods.getDatabaseType(props.dataIndex).database}/${path}/exportModel`
            // commonMethods.downloadFile(url, `${commonMethods.getDatabaseType(props.dataIndex).name}样本数据`, "xlsx");
            exportUtils.downloadFile(url, `${commonMethods.getDatabaseType(props.dataIndex).name}样本数据`);
        }
        // 下载植物模板
        const downloadPlantEvent = async function (val) {
            let currentType = getPlantType(val)
            let url = `${commonMethods.getCookie("proUrl").url}/${commonMethods.getDatabaseType(props.dataIndex).database}/excel/${currentType.downType}`
            // commonMethods.downloadFile(url, `${currentType.name}样本数据`, "xlsx");
            exportUtils.downloadFile(url, `${currentType.name}样本数据`);
        }

        // 获取植物模板类型
        const getPlantType = function (val) {
            let currentType = {}
            if (val === "2") {
                //剖面采集
                currentType.downType = "exportProfileInfoSampleModel";
                currentType.importType = "importProfileSample";
                currentType.name = "剖面采集"
            } else if (val === "3") {
                //残留物采集
                currentType.downType = "exportResidueInfoSampleModel"
                currentType.importType = "importResidueSample";
                currentType.name = "残留物采集"
            } else {
                // 遗迹单位
                currentType.downType = "exportRuinsInfoSampleModel"
                currentType.importType = "importRuinsInfoSample";
                currentType.name = "遗迹单位"
            }
            return currentType
        }

        // 下载文献采集模板
        const downloadLiteratureEvent = function () {
            let url = `${commonMethods.getCookie("proUrl").url}/plant-arch/excel/exportLiteratureSampleModel`
            // let currentType = getLiteratureType(val)
            // let url
            // if (val === "0") {
            //     url = `${commonMethods.getCookie("proUrl").url}/plant-arch/literature/${currentType.type}/exportModel`
            // } else {
            //     url = `${commonMethods.getCookie("proUrl").url}/plant-arch/literature/${currentType.type}/exportModel?sampleType=${val}`
            // }
            // commonMethods.downloadFile(url, `${currentType.name}样本数据`, "xlsx");
            commonMethods.downloadFile(url, `植物文献资源导入数据表`, "xlsx");
        }

        // 获取植物文献模板类型
        const getLiteratureType = function (val) {
            let currentType = {}
            if (val === "1") {
                //剖面采集
                currentType.type = "sample";
                currentType.name = "炭化植物"
            } else if (val === "0") {
                currentType.type = "phytolith"
                currentType.name = "植硅体"
            } else if (val === "2") {
                currentType.type = "sample"
                currentType.name = "淀粉粒"
            } else {
                currentType.type = "sample"
                currentType.name = "木炭"
            }
            return currentType
        }

        let plantResource = ref(false);
        watchEffect(function () {
            if (route.name === "resourceDataSampleImport") {
                plantResource.value = true;
            }
        })
        let rws; //消息提醒
        let messageList = ref([]) //消息列表
        //获取消息列表
        const messageDataList = async function () {
            let res = await sampleImportCommonApi.messageList(apiType.value, {readStatus: 0})
            messageList.value = res['datas']
        }
        // 查看导入数据失败明细
        let messageContent = ref(null)
        const lookDetail = async function (row) {
            let res = await sampleImportCommonApi.messageDetails(apiType.value, {id: row.id})
            messageContent.value = res.datas.content
            visibleBackspacing.value = true
            messageDataList()
        }
        //下载导入数据失败明细
        const downloadDetail = function (row) {
            let url = `${commonMethods.getCookie("proUrl").url}/${apiType.value}/message/download?id=${row.id}`
            commonMethods.downloadFile(url);
            messageDataList()
        }

        //根据数据库标识区分接口类型
        const apiTypeDifferent = function () {
            let index = process.env.VUE_APP_MARKING
            let name
            if (index === '1') {
                name = 'animal-arch'
            } else if (index === '2') {
                name = 'plant-arch'
            } else if (index === '4') {
                name = 'carbon-arch'
            }
            apiType.value = name
        }

        onMounted(function () {
            apiTypeDifferent()
            if (route.meta.plantLiterature) {
                plantLiterature.value = true;
            }
            rws = new ReconnectingWebSocket('ws://' + `192.168.0.203:9901/${apiType.value}` + '/ws/' + commonMethods.getCookie('uId'));
            rws.addEventListener('open', () => {
                rws.send('西安云图信息技术有限公司');
            })
            rws.addEventListener('message', (e) => {
                let data = JSON.parse(e.data);
                return data
                // messageList.value = data.msg
            })
        })
        onBeforeUnmount(function () {
            rws.close();
        })
        return {
            importShow,
            resultMsg,
            plantResource,
            plantLiterature,

            uploadRef,
            uploadFormRef,
            uploadForm,
            uploadRules,
            btnHandle,
            nextStepEvent,
            downloadEvent,
            downloadPlantEvent,
            downloadLiteratureEvent,

            messageList,
            lookDetail,
            downloadDetail,
            visibleBackspacing,
            messageContent,
            messageDataList
        }
    }
})
</script>

<style lang="scss">
@import "sample-import";
</style>
