<template>
    <div class="user-manage-list">
        <el-form class="filter_section default_space_style" :model="filterForm" size="large">
            <el-row :gutter="60">
                <el-col :span="10">
                    <el-form-item label="账号：">
                        <el-input v-model="filterForm.username" clearable placeholder="请输入查询账号"/>
                    </el-form-item>
                </el-col>
                <el-col :span="10">
                </el-col>
            </el-row>
            <el-button type="primary" @click="getUserList">查询</el-button>
        </el-form>
        <div class="container_content_box default_space_style">
            <el-button type="primary" class="add_role" @click="addHandle">添加菜单</el-button>
            <div class="default_table_style table_bg">
                <el-table size="large" stripe :data="tableData"
                          row-key="id"
                          :tree-props="{ children: 'subMenus', }"
                          height="480">
                    <!--  <el-table-column label="序号" type="index" width="100"></el-table-column>-->
                    <el-table-column label="菜单名称" prop="name" width="200"></el-table-column>
                    <el-table-column label="菜单url" prop="url"></el-table-column>
                    <el-table-column label="菜单path" prop="path"></el-table-column>
                    <el-table-column label="是否隐藏" prop="hidden">
                        <template #default="scope">
                            <span>{{ scope.row.hidden ? "是" : "否" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否为菜单" prop="type">
                        <template #default="scope">
                            <span>{{ scope.row.type === 1 ? "是" : "否" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="排序号" prop="sort"></el-table-column>
                    <el-table-column label="上级菜单" prop="parentId"></el-table-column>
                    <el-table-column label="操作" align="center" width="140">
                        <template #default="scope">
                            <common-space-vertical>
                                <el-link type="primary" @click="editHandle(scope.row)">编辑</el-link>
                                <el-link type="info" @click="deleteHandle(scope.row)">删除</el-link>
                            </common-space-vertical>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!--添加/修改用户-->
        <dialog-component :visible="visibleDialog" :title="dialogTitle"
                          @cancel-handle="dialogCloseHandle"
                          @confirm-handle="watchConfirmHandle">
            <el-form size="large" label-width="120px" :model="addMenuForm" :rules="addMenuFormRules">
                <el-form-item label="菜单名称：">
                    <el-input v-model="addMenuForm.name" placeholder="请填写菜单名称"/>
                </el-form-item>
                <el-form-item label="菜单url：">
                    <el-input v-model="addMenuForm.url" placeholder="请填写菜单url"/>
                </el-form-item>
                <el-form-item label="菜单path：">
                    <el-input v-model="addMenuForm.path" placeholder="请填写菜单path"/>
                </el-form-item>
                <el-form-item label="是否隐藏：">
                    <el-radio-group v-model="addMenuForm.hidden">
                        <el-radio :label="1" size="large">是</el-radio>
                        <el-radio :label="0" size="large">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否为菜单：">
                    <el-radio-group v-model="addMenuForm.type">
                        <el-radio :label="1" size="large">是</el-radio>
                        <el-radio :label="2" size="large">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序号：">
                    <el-input v-model="addMenuForm.sort" placeholder="请填写排序号"/>
                </el-form-item>
                <el-form-item label="上级菜单：">
                    <el-select v-model="addMenuForm.parentId" clearable placeholder="不选择则为根节点">
                        <el-option v-for="item in menuList"
                                   :key="item.id"
                                   :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </dialog-component>
    </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "vue";
import {menuManageApi} from "../../../api/database-devops-api";
import commonMethods from "../../../js/common-methods";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "menu-manage-module",
    components: {},
    setup() {
        let dialogTitle = ref(null);
        let visibleDialog = ref(false);
        // 条件检索
        let filterForm = reactive({
            name: null,
        });
        let menuList = ref([]);
        // 添加菜单弹窗
        let addMenuForm = reactive({
            name: null,// 菜单名称
            url: null,// 菜单url
            path: null,// 菜单path
            hidden: 0,// 是否隐藏
            type: 2,// 是否为菜单
            sort: null,// 排序号
            parentId: null,// 上级菜单
        });
        // 表单验证
        let addMenuFormRules = reactive({})
        // 编辑时存储信息
        let editUserForm = ref(null);
        // 表格数据
        let tableData = ref([]);
        let allRoleList = ref([])
        // 获取用户列表
        const getUserList = async function () {
            let result = await menuManageApi.getAllMenuList();
            tableData.value = result.data;
        }
        // 添加菜单事件处理
        const addHandle = () => {
            dialogTitle.value = "添加菜单";
            visibleDialog.value = true;
        }

        // 添加菜单信息弹窗确定事件处理
        const watchConfirmHandle = () => {
            if (!addMenuForm.parentId) {
                addMenuForm.parentId = -1
            }
            if (editUserForm.value) {
                commonParamsInterface({...addMenuForm, id: editUserForm.value.id}, "编辑菜单");
            } else {
                commonParamsInterface({...addMenuForm}, "添加菜单");
            }
            visibleDialog.value = false;
        }
        // 添加菜单弹出框关闭事件
        const dialogCloseHandle = () => {
            visibleDialog.value = false
            editUserForm.value = null
            for (const form in addMenuForm) {
                addMenuForm[form] = null;
            }
        }

        // 编辑事件处理
        const editHandle = (item) => {
            editUserForm.value = item
            for (const form in addMenuForm) {
                addMenuForm[form] = item[form];
            }
            dialogTitle.value = "修改用户";
            visibleDialog.value = true;
        }

        // 删除事件处理
        const deleteHandle = async item => {
            await commonMethods.deleteDialog();
            let result = await menuManageApi.deleteMenu(item.id);
            if (result["resp_code"] === 0) {
                ElMessage.success("删除成功")
                await getUserList();
            }
        }

        // 公共参数接口
        const commonParamsInterface = async function (params, tip) {
            let result = await menuManageApi.saveOrUpdateMenu(params)
            commonMethods.message(result, () => {
                getUserList();
                dialogCloseHandle();
            }, tip);
        }
        // 获取全部菜单列表
        const getAllMenu = async function () {
            let result = await menuManageApi.getAllMenuList();
            let menu = [];
            for (const data of result.data) {
                if (data.type === 1 && data.parentId === -1)
                    menu.push(data);
            }
            menuList.value = menu;
        }

        onMounted(function () {
            getUserList();
            getAllMenu();
        })

        return {
            filterForm,
            addMenuForm,
            addMenuFormRules,
            tableData,
            allRoleList,
            menuList,
            visibleDialog,
            dialogTitle,
            editHandle,
            deleteHandle,
            addHandle,
            watchConfirmHandle,
            dialogCloseHandle,
            getUserList,
        }
    }
})
</script>


<style lang="scss">
@import "../../../../src/scss/commonFunc";

.user-manage-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    .filter_section {
        display: flex;
        //width: 100%;

        .el-row {
            flex: 1;
            width: 0;

            .el-form-item {
                margin-bottom: 0;
            }
        }

        .el-button {
            margin-left: auto;
        }
    }

    .container_content_box {
        @include bgColor(white);
        margin-top: pxToRem(20);
        height: 100%;
        .add_role {
            margin-bottom: pxToRem(20);
            width: max-content;
        }

        .default_table_style {
            margin-bottom: pxToRem(30);

            .pass {
                @include color($defaultColor);
            }

            .no-pass {
                @include color(#D10000);
            }

            .await {
                @include color(#999999);
            }
        }
    }
}
</style>
