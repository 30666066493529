<template>
    <div class="module-head-component">
        <span v-text="title"></span>
        <span v-if="describe" v-html="describe"></span>
    </div>
    <slot/>
</template>

<script>
import {defineComponent} from "vue";


export default defineComponent({
    name: "module-head-component",
    props: {
        title: {
            type: String,
            default: null
        },
        describe: {
            type: String,
            default: null
        },
    },
    setup() {

    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.module-head-component {
    @include bgColor($bgOpacityColor);
    @include height(52);
    display: flex;
    align-items: center;
    padding: 0 pxToRem(30);

    span {
        @include fontSize(18);
        @include color($defaultColor);
        font-weight: bold;
    }
}
</style>