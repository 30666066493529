<template>
    <div class="coordinates-select-map-component">
        <el-autocomplete size="large"
                         clearable
                         v-model.trim="address"
                         :fetch-suggestions="querySearchAsync"
                         placeholder="请输入行政区划名称"
                         @select="selectDivisionEvent">
            <template #suffix>
                <el-icon>
                    <location-information/>
                </el-icon>
            </template>
        </el-autocomplete>
        <div id="map" class="map_container"></div>
        <p class="coords_tip">在地图上点击获取经纬度，坐标系为cgcs2000</p>
    </div>
</template>

<script>
import {nextTick, onMounted, ref} from "vue";
import mapConfig from "../../plugins/mapTool/map-config";
import {LocationInformation} from '@element-plus/icons-vue'
import {marker} from 'leaflet'
import commonMethods from "../../js/common-methods";
import {ElMessage} from "element-plus";

export default {
    name: "coordinates-select-map-component",
    props: {
        // 纬度
        latitude: {
            type: String,
            default: null
        },
        // 经度
        longitude: {
            type: String,
            default: null
        },
    },
    components: {
        LocationInformation
    },
    setup(props) {
        // 初始化地图
        let config = new mapConfig();

        let point = ref(null);
        let address = ref(null);
        let timeout = ref(null);

        let coordinates = ref([]);

        // 初始化地图
        const initMap = function () {
            // config.initMap("map", "image");
            config.initMap("map");
            config.setDrawCursorStyle();
            point.value = marker(config.map.getCenter(), {
                draggable: true
            }).addTo(config.map).on("move", function (e) {
                coordinatesHandle(e.latlng)
            });
            coordinatesHandle(config.map.getCenter());
            config.map.on("click", e => setPointCoords(e.latlng));

            if (props.latitude && props.longitude) {
                let splitLat = commonMethods.degreeMinuteSecondConvertCoordinatesToSplit(props.latitude);
                let splitLng = commonMethods.degreeMinuteSecondConvertCoordinatesToSplit(props.longitude);
                selectDivisionEvent({coordinates: [splitLng, splitLat]})
            }
        }

        // 设置marker的坐标
        const setPointCoords = function (latLng) {
            if (point.value) {
                point.value.setLatLng(latLng);
            }
            coordinatesHandle(latLng);
        }

        // 处理坐标
        const coordinatesHandle = function (latLng) {
            let longitude = commonMethods.coordinatesConvertDegreeMinuteSecond(latLng.lng);
            let latitude = commonMethods.coordinatesConvertDegreeMinuteSecond(latLng.lat);
            coordinates.value = [latitude, longitude];
        }

        // 选择位置
        const selectDivisionEvent = function (item) {
            const {coordinates} = item;
            const latLng = {lat: coordinates[1], lng: coordinates[0]};
            setPointCoords(latLng);
            config.map.flyTo(latLng, 16);
        }

        // 处理查询结果下拉列表
        const querySearchAsync = function (queryString, cb) {
            if (queryString === "null") {
                cb([]);
                return;
            }
            clearTimeout(timeout);
            timeout.value = setTimeout(async function () {
                let result = await queryAddressDataList(queryString).catch(cb([]));
                cb(result);
            }, 1000)
        }
        // 查询数据列表
        const queryAddressDataList = function (keywords) {
            return new Promise((resolve) => {
                fetch(`https://restapi.amap.com/v3/place/text?keywords=${keywords}&output=json&extensions=base&offset=10&key=${commonMethods.options.gaoDeKey}`)
                    .then(res => res.json())
                    .then(res => {
                        let dataArr = [];
                        if (res.info === "OK") {
                            for (const poi of res['pois']) {
                                let address = poi.address;
                                dataArr.push({
                                    value: address.length > 20 ? address.substr(0, 20) + "..." : address,
                                    coordinates: poi['location'].split(",")
                                })
                            }
                            if (res['pois'].length < 1) {
                                ElMessage.success("暂无数据，请输入精确的位置")
                            }
                        } else {
                            ElMessage.error(res.info)
                        }
                        resolve(dataArr);
                    })
            })
        }

        onMounted(function () {
            nextTick(function () {
                initMap();
            })
        })
        return {
            address,
            coordinates,
            selectDivisionEvent,
            querySearchAsync
        }
    }
}
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.coordinates-select-map-component {
    position: relative;

    .el-input {
        margin-bottom: pxToRem(20);

        .el-input__suffix {
            @include displayFlex;
        }
    }

    .map_container {
        width: 100%;
        height: pxToRem(250);
        @include bgColor;
    }

    .coords_tip {
        @include position;
        @include trbl('', 0, pxToRem(-30), 0);
        @include color($redColor);
    }
}
</style>
