import requestInterface from "../js/request-interface";

// 角色管理接口API
const linkageApi = {
    //获取动物/碳十四/DNA样本数据
    getTypeDataList(type, params) {
        return requestInterface.requestPost(`map-center/${type}/page`, params)
    },
    //获取植物数据量
    getPlantDataList(type, params) {
        return requestInterface.requestPost(`map-center/plant/${type}`, params)
    },
    ruinsUpdate(params){
        return requestInterface.requestPost(`ruins-arch/ruins/update`, params)
    }
};
export {
    linkageApi,
}