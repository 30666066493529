<template>
    <div class="ruins-mode-details">
        <breadcrumb-component max showBack/>
        <div class="default_page_style default_wrapper">
            <div class="background-info default_space_style">
                <div class="background-info-right">
                    <module-head-component title="背景信息"/>
                    <div class="background-content">
                        <info-text-ul-component :data="dataInfo" :span="8"/>
                    </div>
                </div>
            </div>
            <div class="experiment-basic-relevancy-info default_space_style">
                <div class="experiment-info">
                    <module-head-component title="数据信息"/>
                    <div class="background-content">
                        <info-text-ul-component class="default_table_style" :data="basicInfo" :span="8"/>
                    </div>
                </div>
                <div class="experiment-info">
                    <module-head-component title="图像信息"/>
                    <div class="detail-table-style default_table_style">
                        <image-info/>
                    </div>
                </div>
                <div class="experiment-info">
                    <module-head-component title="三维信息"/>
                    <div class="detail-table-style default_table_style">
                        <three-info/>
                    </div>
                </div>
                <div class="experiment-info" v-if="sampleType !== '4'">
                    <module-head-component title="测量信息"/>
                    <div class="detail-table-style default_table_style">
                        <measure-info/>
                    </div>
                </div>
                <div class="experiment-info" v-if="sampleType === '1'">
                    <module-head-component title="检测信息"/>
                    <div class="detail-table-style default_table_style">
                        <detection-info/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import InfoTextUlComponent from "../plant-details-component/component/info-text-ul-component";
import ImageInfo from "../plant-details-component/component/image-info/image-info";
import ThreeInfo from "../plant-details-component/component/three-info/three-info";
import MeasureInfo from "../plant-details-component/component/measure-info/measure-info";
import DetectionInfo from "../plant-details-component/component/detection-info/detection-info";
import {useRoute} from "vue-router";
import commonMethodsPlant from "./js/common-methods-plant";
import {plantDetailsApi} from "../../../api/data-linkage-details-api";

export default defineComponent({
    name: "plant-details-component",
    components: {
        InfoTextUlComponent,
        ImageInfo,
        ThreeInfo,
        MeasureInfo,
        DetectionInfo
    },
    setup() {
        const route = useRoute();
        let imgList = ref([
            "https://img0.baidu.com/it/u=1531531448,3410082874&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500",
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fp6.itc.cn%2Fq_70%2Fimages03%2F20201117%2Ffb26b0157e934c8d8c31239bd748eee7.jpeg&refer=http%3A%2F%2Fp6.itc.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651907560&t=0e553ed1e4f8ef72422fd96f0b744ae3",
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Finews.gtimg.com%2Fnewsapp_match%2F0%2F11729622002%2F0.jpg&refer=http%3A%2F%2Finews.gtimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651907560&t=ce84d9419a244dfc9488572f5e225581",
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Finews.gtimg.com%2Fnewsapp_match%2F0%2F11926801087%2F0.jpg&refer=http%3A%2F%2Finews.gtimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651907560&t=f8850c38239b4194472e35f9a5ef75a8",
            "https://img2.baidu.com/it/u=1299970167,1931826776&fm=253&fmt=auto&app=120&f=JPEG?w=1080&h=720",
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fnimg.ws.126.net%2F%3Furl%3Dhttp%3A%2F%2Fdingyue.ws.126.net%2F2021%2F0111%2Fc7e92961p00qmqysd00brc000hs00d8m.png%26thumbnail%3D650x2147483647%26quality%3D80%26type%3Djpg&refer=http%3A%2F%2Fnimg.ws.126.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651907790&t=e79c669256412babdfec9efb88c44c95",
            "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Finews.gtimg.com%2Fnewsapp_match%2F0%2F11704486893%2F0.jpg&refer=http%3A%2F%2Finews.gtimg.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1651907790&t=9174d90210381468688a7f365a5214a9"
        ])

        // 当前类型
        let headTitle = ref("");
        let currentBgType = ref(null);
        let sampleType = ref("1");
        let dataInfo = ref([]);

        let btnList = ref([
            {name: '样本关联', value: 'sample',type:1},
            {name: '关联扩展', value: 'expand',type:2},
        ])
        let btnListDefault = ref(btnList.value[0].value)
        let queryShow = ref(false) //切换展示关联数据查询条件

        // 单位信息
        const ruinsInfoList = ref([
            {title: "遗址名称", key: "ruinsName", value: null},
            {title: "出土单位号", key: "unitCode", value: null},
            {title: "发掘年份", key: "digYear", value: null},
            {title: "遗址分区", key: "ruinsSubarea", value: null},
            {title: "文化分期", key: "culturalPeriod", value: null},
            {title: "探方号", key: "exploreSquareNumber", value: null},
            {title: "层位", key: "stratumGradation", value: null},
            {title: "遗迹号", key: "relicNumber", value: null},
            {title: "堆积号", key: "relicGradation", value: null},
        ])
        // 剖面信息
        const profileInfoList = ref([
            {title: "遗址名称", key: "ruinsName", value: null},
            {title: "剖面位置", key: "sectionPosition", value: null},
            {title: "采样方法", key: "sampleProcessName", value: null},
            // {title: "海拔", key: "altitude", value: null},
            {title: "经度", key: "longitude", value: null},
            {title: "纬度", key: "latitude", value: null},
            {title: "间距", key: "spacing", value: null},
            {title: "所属层位", key: "stratumGradation", value: null},
            {title: "距地表深度", key: "surfaceDepth", value: null},
            {title: "样品数量", key: "sampleQuantity", value: null},
            {title: "采样人", key: "collector", value: null},
            {title: "采样时间", key: "collectTime", value: null},
            // {title: "取样方式", key: "sampleMethodName", value: null},
        ])

        let basicInfo = ref([]);

        let carbidePlantList = ref([
            {title: "实验室名称", key: "labNameId", value: null},
            {title: "实验室编号", key: "labCode", value: null},
            {title: "送检号", key: "sampleCode", value: null},
            {title: "样品来源", key: "sampleSourceName", value: null},
            {title: "浮选方法", key: "flotationMethodName", value: null},
            {title: "筛孔尺寸", key: "apertureSize", value: null},
            {title: "实验室分样筛尺寸", key: "labSieveSize", value: null},
            {title: "土样量（L）", key: "soilSampleQuantity", value: null},
            {title: "炭化物（g）", key: "carbide", value: null},
            {title: "炭化物体积（ml）", key: "volume", value: null},
            {title: "重量", key: "weight", value: null},
            {title: "科拉丁名", key: "familyId", value: null},
            {title: "属拉丁名", key: "genusId", value: null},
            {title: "种拉丁名", key: "speciesId", value: null},
            {title: "亚种", key: "subspeciesId", value: null},
            {title: "种属", key: "name", value: null},
            {title: "成熟度", key: "maturityName", value: null},
            {title: "保存部位", key: "preservationSiteName", value: null},
            {title: "保存状况", key: "saveStatusName", value: null},
            {title: "数量", key: "quantity", value: null},
        ])

        let phytolithList = ref([
            {title: "实验室名称", key: "labNameId", value: null},
            {title: "实验室编号", key: "labCode", value: null},
            {title: "土样量", key: "soilSampleQuantity", value: null},
            {title: "提取方法", key: "extractionMethodName", value: null},
            {title: "重液类型", key: "heavyLiquidTypeName", value: null},
            {title: "试剂比重", key: "reagentSpecificGravity", value: null},
            {title: "是否定量", key: "ration", value: null},
            {title: "参考物", key: "referenceName", value: null},
            {title: "参考数量", key: "referenceQuantity", value: null},
            {title: "计数参考物数量", key: "referenceCountQuantity", value: null},
            {title: "计数总量", key: "totalCount", value: null},
            {title: "植硅体类型", key: "phytolithTypeName", value: null},
            {title: "数量", key: "quantity", value: null},
            {title: "浓度", key: "concentration", value: null},
        ])

        let starchGrainList = ref([
            {title: "样品来源", key: "utensilType", value: null},
            {title: "取样部位", key: "utensilNumber", value: null},
            {title: "实验室名称", key: "labNameId", value: null},
            {title: "实验室编号", key: "labCode", value: null},
            {title: "重液类型", key: "heavyLiquidTypeName", value: null},
            {title: "试剂比重", key: "reagentSpecificGravity", value: null},
            {title: "科拉丁名", key: "familyId", value: null},
            {title: "属拉丁名", key: "genusId", value: null},
            {title: "种拉丁名", key: "speciesId", value: null},
            {title: "亚种", key: "subspeciesId", value: null},
            {title: "淀粉粒种类", key: "name", value: null},
            {title: "数量", key: "quantity", value: null},
            {title: "保存状况", key: "saveStatusName", value: null},
        ]);

        let charcoalList = ref([
            {title: "实验室名称", key: "labNameId", value: null},
            {title: "实验室编号", key: "labCode", value: null},
            {title: "送检号", key: "sampleCode", value: null},
            {title: "来源分类", key: "sourceTypeName", value: null},
            {title: "保存状况", key: "saveStatusName", value: null},
            {title: "取样部位", key: "samplePlace", value: null},
            {title: "科拉丁名", key: "familyId", value: null},
            {title: "属拉丁名", key: "genusId", value: null},
            {title: "种拉丁名", key: "speciesId", value: null},
            {title: "亚种", key: "subspeciesId", value: null},
            {title: "种属", key: "name", value: null},
            {title: "数量", key: "quantity", value: null},
        ]);

        const getUnitInfo = async function () {
            const {bgId} = route.query
            let current = currentBgType.value;
            let result = await plantDetailsApi.findByIdGetInfo(current.value, bgId);
            let dataInfoList
            let data = result["datas"];
            if (current.backgroundType === "1") {
                data = result
                dataInfoList = ruinsInfoList.value;
            } else {
                dataInfoList = profileInfoList.value;
            }
            for (const item of dataInfoList) {
                for (const key in data) {
                    if (item.key === key) {
                        item.value = data[key]
                    } else if (item.key === 'collectTime') {
                        item.value = data['collectTime'] ? data['collectTime'] : ''
                    }
                }
            }
            dataInfo.value = dataInfoList;
        }

        const getBasicInfo = async function () {
            const {sampleType, sampleId} = route.query
            let currentSample = getSampleType(sampleType)
            let result
            let dataInfoList
            result = await plantDetailsApi.getCurrentDataInfo(sampleId, currentSample.type);
            if (sampleType === "1") {
                dataInfoList = carbidePlantList.value;
            } else if (currentSample.sampleType === '2') {
                dataInfoList = phytolithList.value;
            } else if (currentSample.sampleType === '3') {
                dataInfoList = starchGrainList.value;
            } else {
                dataInfoList = charcoalList.value;
            }

            for (const item of dataInfoList) {
                for (const key in result['datas']) {
                    if (item.key === key) {
                        item.value = result['datas'][key]
                    }
                }
            }
            basicInfo.value = dataInfoList;
        }
        const getSampleType = function (val) {
            let list = [  {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", sampleType: "1"},
                {name: '植硅体', value: 'phytolith', type: "phytolith", sampleType: "2"},
                {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", sampleType: "3"},
                {name: '木炭', value: 'charcoal', type: "charcoal", sampleType: "4"},];
            for (const key of list) {
                if (key.sampleType === val) {
                    return key
                }
            }
        }
        // 管理数据查看按钮切换事件
        const handleEvent = function (val) {
            btnListDefault.value = val.value
            if (val.value === "expand"){
                queryShow.value = true
            }else {
                queryShow.value = false
            }
        }
        let form = ref({})
        //获取关联数据查询条件子组件值
        const getFormData = function (val) {
            form.value = val
        }

        onMounted(() => {
            const {bgType} = route.query;
            currentBgType.value = commonMethodsPlant.getBgInfoType(bgType)
            headTitle.value = bgType === "1" ? "单位采集信息" : "剖面采集信息";
            sampleType.value = route.query.sampleType;
            getUnitInfo();
            getBasicInfo();
        })
        return {
            imgList,
            dataInfo,
            headTitle,
            basicInfo,
            sampleType,

            btnList,
            btnListDefault,
            handleEvent,
            queryShow,
            form,
            getFormData
        }
    }
})
</script>

<style lang="scss">
@import "details";
</style>