<template>
    <div class="row-box">
        <el-row class="info-text-ul-component">
            <el-col v-for="(item, i) in data" :key="i" :span="span">
                <span class="info-titles">{{ item.title }}：</span>
                <p :class="{'latinName':['genusId', 'speciesId','subspeciesId'].includes(item.key)}">
                    {{ item.value }}</p>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {defineComponent, onMounted} from "vue";

export default defineComponent({
    name: "info-text-ul-component",

    props: {
        data: {
            type: Array,
            default: () => []
        },
        span: {
            type: String,
            default: null
        }
    },
    setup() {
        onMounted(function () {

        })
    }

})
</script>

<style lang="scss">
@import "../../../../scss/commonFunc";

.row-box {
    .info-text-ul-component {

        .el-col {
            align-items: center;

            &.el-col-6 {
                max-width: 100%;
            }

            display: flex;

            margin-bottom: pxToRem(25);
            font-size: pxToRem(14);
            font-weight: 400;

            .info-titles {
                //width: pxToRem(140);
                color: #666666;
            }

            p {
                color: #333333;
            }

            .el-checkbox-group {
                display: flex;
            }

        }
    }
}
</style>
