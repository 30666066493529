<template>
    <div class="dialog-component" v-if="visibleDialog">
        <el-dialog v-model="visibleDialog"
                   :title="title"
                   :width="width"
                   :before-close="dialogClose"
                   :close-on-click-modal="false"
                   :close-on-press-escape="false"
                   :draggable="draggable"
                   destroy-on-close>
            <div class="dialog_body">
                <slot/>
            </div>
            <template #footer v-if="visibleFooter">
                <div class="custom_btn_group_right" v-if="!dialogDnaShow">
                    <el-button type="primary" @click="confirmHandle">确定</el-button>
                    <el-button @click="dialogClose">取消</el-button>
                </div>
                <div class="custom_btn_group_right" v-if="dialogDnaShow">
                    <el-button type="primary" @click="confirmHandle">
                        <p>提交</p>
                        <p>Submit</p>
                    </el-button>
                    <el-button @click="dialogClose">
                        <p>取消</p>
                        <p>Cancel</p>
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {defineComponent, ref, watchEffect} from "vue";

export default defineComponent({
    name: "dialog-component",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        width: {
            type: String,
            default: "615px"
        },
        visibleFooter: {
            type: Boolean,
            default: true
        },
        dialogDnaShow:{
            type: Boolean,
            default: false
        },
    },
    emits: ['cancelHandle', 'confirmHandle'],
    setup(props, context) {
        // 控制弹窗显示隐藏
        let visibleDialog = ref(props.visible);

        watchEffect(() => {
            visibleDialog.value = props.visible;
        });

        // 关闭弹窗
        const dialogClose = () => {
            context.emit("cancelHandle");
        }

        // 确定事件
        const confirmHandle = () => {
            context.emit("confirmHandle");
        }

        return {
            visibleDialog,
            dialogClose,
            confirmHandle
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.dialog-component {
    .el-overlay-dialog {
        @include displayFlex;

        .el-dialog {
            margin: 0;

            .el-dialog__header {
                @include bgColor($defaultColor);
                margin-right: 0;
                height: pxToRem(62);
                padding: 0 pxToRem(20);
                display: flex;
                align-items: center;

                .el-dialog__title {
                    @include color(white);
                    @include fontSize(18);
                }
            }

            .el-dialog__headerbtn {
                @include widthHeight(62);
                @include displayFlex;
                top: 0;

                i {
                    @include fontSize(25);
                    @include color(white);

                    &:hover {
                        opacity: .85;
                    }
                }
            }

            .el-dialog__footer {
                padding: pxToRem(20) pxToRem(20);

                .el-button{

                    span{
                        flex-direction: column;
                    }
                }
            }
        }
    }
}
</style>
