<template>
    <div class="preview-file-pdf">
        <div id="preview_container" class="preview_container"></div>
    </div>
</template>

<script setup>
import {onMounted} from "vue";
import PDFObject from "pdfobject"

const props = defineProps({
    fileUrl: {
        type: String,
        default: null
    }
});
const initPdf = () => {
    PDFObject.embed(props.fileUrl, "#preview_container");
}
onMounted(() => {
    initPdf();
})
</script>

<style scoped lang="scss">

</style>
