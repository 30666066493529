<template>
    <dialog-component
        :visible="visibleDialog"
        title="提交审核"
        @cancel-handle="dialogClose"
        @confirm-handle="confirmHandle">
        <el-form ref="auditFormRef" :model="auditForm" :rules="auditRules" label-width="100px" size="large">
            <el-form-item label="审核人：" prop="examinerId">
                <el-select v-model="auditForm.examinerId" filterable placeholder="请选择审核人">
                    <el-option
                        v-for="item in administratorList"
                        :key="item.id"
                        :label="item.nickname+'('+item.username+')'"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="remark">
                <el-input v-model="auditForm.remark" rows="4" type="textarea"/>
            </el-form-item>
        </el-form>
    </dialog-component>
</template>

<script>
import {defineComponent, ref, reactive, watchEffect} from "vue";
import {userManageApi} from "@acc/api/database-devops-api";
import {sampleImportCommonApi} from "@acc/api/sample-manage-common-api";
import commonMethods from "@acc/js/common-methods";
import {ElMessage} from "element-plus";

export default defineComponent({
    name: "submit-audit-dialog-component",
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        auditList: {
            type: Array,
            default: () => []
        },
        sampleType: {
            type: Object,
            default: () => {
            }
        }
    },
    setup(props, context) {
        // 控制弹窗显示隐藏
        let visibleDialog = ref(props.visible);

        let database = ref("");
        let auditFormRef = ref(null)
        const auditForm = reactive({
            examinerId: null,
            remark: null,
        })
        const auditRules = reactive({
            examinerId: [{required: true, message: '请选择审核人', trigger: 'change'}],
        })
        let administratorList = ref([]);


        // 关闭弹窗
        const dialogClose = () => {
            context.emit("cancelHandle", false);
            auditFormRef.value.resetFields();
        }

        // 确定事件
        const confirmHandle = async () => {
            let ids = props.auditList.join(",")
            let params = {
                sampleIds: ids,
                ...auditForm,
                userId: commonMethods.getCookie('uId')
            }
            if (props.sampleType) {
                // 只有植物需要传该参数
                params.sampleType = props.sampleType
            }
            let result = await sampleImportCommonApi.batchAuditData(params, database.value);
            if (result["resp_code"] === 0) {
                ElMessage.success("数据提交审核成功");
                context.emit("cancelHandle", true);
                auditFormRef.value.resetFields();
            } else {
                ElMessage.warning(result["resp_msg"]);
            }
        }

        const getAdministratorList = async () => {
            let result = await userManageApi.getCurrentUserAuditList();
            administratorList.value = result["datas"];
        }

        watchEffect(() => {
            visibleDialog.value = props.visible;
            if (visibleDialog.value) {
                let dataIndex = process.env.VUE_APP_MARKING;
                database.value = commonMethods.getDatabaseType(dataIndex).database;
                getAdministratorList();
            }
        });

        return {
            visibleDialog,
            dialogClose,
            confirmHandle,

            auditFormRef,
            auditForm,
            auditRules,
            administratorList,
        }
    }
})
</script>

<style scoped>

</style>