import {CRS, divIcon, featureGroup, geoJSON, icon, map, Marker, marker, point} from "leaflet";
import 'leaflet/dist/leaflet.css'
import BaseLayers from "./base-layers";
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import 'leaflet-editable'
import {MarkerClusterGroup} from "leaflet.markercluster/src";
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import commonMethods from "../../js/common-methods";
import {sampleMapApi} from "@acc/api/sample-map-api";

Marker.prototype.options.icon = icon({
    iconUrl,
    iconAnchor: [13, 41]
})
const {VUE_APP_MARKING} = process.env

// 地图相关配置
class MapConfig {
    constructor() {
        this.options = {
            bounds: [[43.100982876188546, 123.31054687500001], [27.6251403350933, 93.42773437500001]]
        }
    }

    // 初始化地图
    initMap(id, layer = "高德影像",) {
        if (this.map) this.map.remove();
        let baseLayers = new BaseLayers();
        this.map = global.map = map(id, {
            crs: CRS.EPSG3857,
            center: {lat: 34.34131000461518, lng: 108.9459013938904},
            zoom: 2,
            minZoom: 2,
            maxZoom: 18,
            zoomControl: false,
            attributionControl: false,
            editable: true
            // layers: [baseLayers.options.gaoDeTileLayer[layer]]
        });
        this.tempLayer = featureGroup().addTo(this.map);
        this.divisionLayer = featureGroup().addTo(this.map);
        // this.baseLayers = global.baseLayers = baseLayers.loadTileLayers(this.map);
        baseLayers.loadBaseLayer(this.map, layer);
        this.baseLayers = baseLayers;
    }

    // 图层添加坐标点
    addMarker(map, str) {
        marker(str).addTo(map);
    }

    mapResize() {
        this.map.invalidateSize(true);
    }

    /*设置地图级别*/
    setMapZoom(state) {
        let zoom = this.map.getZoom();
        let maxZoom = this.map.getMaxZoom();
        let minZoom = this.map.getMinZoom();
        if (state) {
            if (maxZoom > zoom) {
                zoom++;
            }
        } else {
            if (minZoom < zoom) {
                zoom--;
            }
        }
        this.map.setZoom(zoom);
    }

    /*全图*/
    totalGraph() {
        this.map.fitBounds(this.options.bounds);
    }

    // 设置地图绘制手势
    setDrawCursorStyle() {
        this.map._container.style.cursor = "crosshair";
    }

    // 设置地图默认手势
    setDefaultDrawStyle() {
        this.map._container.style.cursor = "grab";
    }

    // 添加图层到地图
    addLayerToMap(parse) {
        this.tempLayer.clearLayers();
        geoJSON(parse, {
            onEachFeature: (feature, layer) => {
                this.tempLayer.addLayer(layer);
            }
        });
        if (this.tempLayer.getLayers().length > 0) {
            this.map.fitBounds(this.tempLayer.getBounds());
        }
    }

    // 添加点到图层
    addPointToMap(dataList, type) {
        for (const data of dataList) {
            if (type) {
                let icon = divIcon({
                    html: `<div>
                                <span class="marker_icon">
                                    <i>${data.count}</i>
                                </span>
                                <p>${data.key}</p>
                            </div>`,
                    className: 'custom_marker_province'
                });
                let point = marker(data['center'].split(",").reverse(), {
                    icon
                });
                this.tempLayer.addLayer(point);
            } else {
                const {ruins, children} = data;
                geoJSON(JSON.parse(ruins.geoJson), {
                    onEachFeature: (feature, layer) => {
                        let icon = divIcon({
                            html: `<div>
                                    <span></span>
                                    <p>${ruins.ruinsName}</p>
                                   </div>`,
                            className: 'custom_marker_city'
                        });
                        layer.setIcon(icon);
                        const {animalSampleCount, carbonSampleCount, dnaSampleCount, plantSampleCount} = children;
                        let html = `<div class="custom_popup">
                                <h3>数据量统计：</h3>
                                <p>出土单位：${ruins.ruinsUnitNumber}</p>
                                <p>动物标本：${animalSampleCount}</p>
                                <p>植物标本：${plantSampleCount}</p>
                                <p>碳十四测年：${carbonSampleCount}</p>
                                <p>东亚古DNA：${dnaSampleCount}</p>
                                <a class="detail_viewer" href="${location.origin + location.pathname}/#/sampleMap/detail?ruinsId=${ruins.id}&title=${ruins.ruinsName}">查看详情</a>
                            </div>`;
                        // <a class="detail_viewer" href="${location.origin+location.pathname+location.hash}/detail?ruinsId=${ruins.id}&title=${ruins.ruinsName}">查看详情</a>
                        layer.bindPopup(html, {
                            className: 'custom_popup_style',
                            title: '数据量统计：',
                            minWidth: 170,
                            offset: point(0, -15)
                        });
                        this.tempLayer.addLayer(layer);
                    }
                })
            }
        }
        this.map.fitBounds(this.tempLayer.getBounds());
    }

    // 门户地图
    doorMap(dataList) {
        this.tempLayer.clearLayers();
        let cluster = new MarkerClusterGroup();
        this.tempLayer.addLayer(cluster);
        if (dataList.length < 1) return;
        for (const data of dataList) {
            geoJSON(JSON.parse(data.geoJson), {
                onEachFeature: (feature, layer) => {
                    let icon = divIcon({
                        html: `<div>
                                    <i class="iconfont icon-dingwei"></i>
                                    <p>${data.ruinsName}</p>
                               </div>`,
                        className: 'custom_marker_door',
                        iconSize: point(30, 10)
                    });
                    layer.setIcon(icon)
                    layer.on('click', () => {
                        let proUrl = commonMethods.getCookie("proUrl");
                        window.location.href = proUrl['door'] + `/#/dataCenter/detail?title=${data.ruinsName}&ruinsId=${data.id}`
                    });
                    cluster.addLayer(layer);
                }
            });
        }
        this.map.fitBounds(this.tempLayer.getBounds());
    }

    // 添加聚类数据到地图
    addClusterDataToMap(dataList, plantAttr, callback, plantCallback) {
        this.tempLayer.clearLayers();
        let clusterGroup = new MarkerClusterGroup({
            showCoverageOnHover: false,
            iconCreateFunction: function (cluster) {
                let markers = cluster.getAllChildMarkers();
                let h = 0;
                for (let i = 0; i < markers.length; i++) {
                    let m = markers[i];
                    if (Number(VUE_APP_MARKING) === 2 || Number(VUE_APP_MARKING) === 1) {
                        h = markers.length;
                    } else {
                        h += Number(Number(VUE_APP_MARKING) === 4 ? m.feature.properties.allSampleCount : m.feature.properties.sampleCount || 1);
                    }
                }
                let className;
                if (h < 10) {
                    className = "marker-cluster-small";
                } else if (h >= 10 && h < 100) {
                    className = "marker-cluster-medium"
                } else {
                    className = "marker-cluster-large";
                }
                // return cluster;
                return divIcon({
                    html: `<div><span>${h}</span></div>`,
                    className: 'custom_cluster marker-cluster ' + className,
                    iconSize: point(40, 40)
                });
            }
        }).addTo(this.tempLayer);
        let typeList = [
            {name: "动物", className: 'animal', dataIndex: 1},
            {name: '植物', className: 'carbon', dataIndex: 2},
            {name: "DNA", className: 'dna', dataIndex: 3},
            {name: "碳十四", className: 'carbon', dataIndex: 4},
        ]
        for (const data of dataList) {
            geoJSON(JSON.parse(data['geomStr']), {
                onEachFeature: (feature, layer) => {
                    layer.feature.properties = data;
                    let box
                    for (const item of typeList) {
                        if (Number(VUE_APP_MARKING) === item.dataIndex && Number(VUE_APP_MARKING) !== 4 && Number(VUE_APP_MARKING) !== 2) {
                            box = `<div>
                                    <i class="${item.className}"></i>
                                    <p>${data.ruinsName}</p>
                                    </div>`
                        } else if (Number(VUE_APP_MARKING) === 4) {
                            box = `<div class="carbon-box">
                                    <span class="carbon">${data.allSampleCount ? data.allSampleCount : 0}</span>
                                    <p>${data.ruinsName}</p>
                                    </div>`
                        } else if (Number(VUE_APP_MARKING) === 2) {
                            if (plantAttr) {
                                box = `<div class="carbon-box ">
                                    <span class="carbon">${data.sampleCount ? data.sampleCount : 0} </span>
                                    <p>${data.ruinsName}</p>
                                    </div>`
                            } else {
                                box = `<div class="carbon-box plant-carbon-box plant-${data.id}">
                                            <span class="carbon iconfont icon-yezi"> </span>
                                            <p>${data.ruinsName}</p>
                                            <ul class="plant-custom_popup">
                                                <li class="menu-item">
                                                    <div><span>0</span> <span>炭化植物</span> </div>
                                                </li>
                                                <li class="menu-item">
                                                    <div><span>0</span> <span>植硅体</span> </div>
                                                </li>
                                                <li class="menu-item">
                                                    <div><span>0</span> <span>淀粉粒</span> </div>
                                                </li>
                                                <li class="menu-item">
                                                     <div><span>0</span> <span>木炭</</span> </div>
                                                </li>
                                            </ul>
                                    </div>`
                            }
                        }
                    }
                    let icon = divIcon({
                        html: box,
                        className: 'custom_marker_city'
                    });
                    layer.setIcon(icon);
                    clusterGroup.addLayer(layer);

                    if (Number(VUE_APP_MARKING) === 2 && !plantAttr) {
                        let id = ""
                        layer.on("mouseover", async function (e) {
                            let ruinsId = e.target.feature.properties.id;
                            if (id === ruinsId) return;
                            id = ruinsId;
                            let className = "plant-" + ruinsId;
                            let ruinsResult = await sampleMapApi.getPlantRuinsCount(ruinsId);
                            let count;
                            if (ruinsResult.resp_code === 0) {
                                const {carbonizedPlant, phytolith, starchGranule, charcoal} = ruinsResult.datas;
                                count = [carbonizedPlant || 0, phytolith || 0, starchGranule || 0, charcoal || 0]
                            }
                            const custom_popup = document.querySelectorAll(`.${className} .plant-custom_popup li`);
                            let num = 0;
                            custom_popup.forEach(el => {
                                if (ruinsResult.resp_code === 0) {
                                    el.children[0].children[0].innerText = count[num];
                                    num++;
                                }
                                el.onclick = evt => {
                                    let text = evt.currentTarget.children[0].children[1].innerText
                                    let plantTypeObj = {
                                        "炭化植物": "carbonPlant",
                                        "植硅体": "phytolith",
                                        "淀粉粒": "starchGranule",
                                        "木炭": "charcoal",
                                    }
                                    plantCallback(e, plantTypeObj[text])
                                }
                            })
                        })

                        layer.on("mouseleave", function (e) {
                            // let className = ".plant-" + e.target.feature.properties.id;
                            // const dom = document.querySelector(className);
                            // // 获取要移除的子元素
                            // let childElement = element.querySelector(".plant-custom_popup");
                            // // 移除子元素
                            // dom.removeChild(childElement);
                        })
                    } else {
                        layer.on('click', callback);
                    }
                    data["leaflet_id"] = layer._leaflet_id;
                    data["latLng"] = layer.getLatLng();
                }
            })
        }
        if (clusterGroup.getLayers().length > 0) {
            this.map.fitBounds(clusterGroup.getBounds());
        }
    }
}

export default MapConfig;
