import axios from "axios";
import {ElMessage} from "element-plus";
import commonMethods from "./common-methods";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import userApi from "../api/user-api";

const {VUE_APP_MARKING, VUE_APP_QUERY_URL} = process.env;

let baseURL

async function getProUrl() {
    let result
    if (!VUE_APP_QUERY_URL) {
        let url = window.location.origin + window.location.pathname + "url"
        result = await fetch(url).then(res => res.json());
    }

    let proUrl = {
        url: null,
        animal: null,
        plant: null,
        carbon: null,
        dna: null,
        door: null,
        webSocket: null,
        // url: "https://www.archaeometry-naca.cn/gateway",//测试环境后台接口
        // url: "http://192.168.0.203:9901",//测试环境后台接口
        // animal: "http://localhost:5001",
        // plant: "http://localhost:5002",
        // carbon: "http://localhost:5004",
        // dna: "http://localhost:5003",
        // door: "http://localhost:5000",
        // webSocket: "192.168.0.106:81",
    }
    if (result) {
        for (const data of result) {
            if (data.name === "gateway路由url") {
                proUrl.url = data.value;
            }
            if (data.name === "动物考古数据库前端url") {
                proUrl.animal = data.value;
            }
            if (data.name === "植物考古数据库前端url") {
                proUrl.plant = data.value;
            }
            if (data.name === "碳十四测年数据库前端url") {
                proUrl.carbon = data.value;
            }
            if (data.name === "东亚古DNA数据库前端url") {
                proUrl.dna = data.value;
            }
            if (data.name === "平台前端url") {
                proUrl.door = data.value;
            }
            if (data.name === "webSocket路径Url") {
                proUrl.webSocket = data.value
            }
        }
    }
    commonMethods.setCookies({proUrl});
    return proUrl.url || VUE_APP_QUERY_URL
}

axios.interceptors.request.use(async function (config) {
    config.baseURL = await requestInterface.getBaseUrl();
    NProgress['start']();
    return config;
})

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        userApi.logout();
    }
    // const {resp_code, resp_msg} = error.response.data;
    // if (resp_code === 1 && resp_msg === "Access Denied") {
    //     return ElMessage.warning("暂无该操作权限，如需进行操作请联系管理员分配权限")
    // }
    return Promise.reject(error);
})

const requestInterface = {
    index: VUE_APP_MARKING, options: {
        baseURL: baseURL,
        timeout: 10000
    }, requestConfig(url, data, method, responseType, Authorization, contentType, paramsType) {
        data = data || {};
        const {baseURL, timeout} = this.options;
        return new Promise((resolve, reject) => {
            axios({
                url, method, baseURL, [paramsType ? paramsType : "params"]: {
                    ...data, dataIndex: data && data.existIndex ? null : VUE_APP_MARKING, //数据库唯一标识
                }, timeout, responseType: responseType || "json", headers: {
                    'Content-Type': contentType || 'application/x-www-form-urlencoded',
                    'Authorization': Authorization || commonMethods.getCookie("safer")
                },
            }).then(res => {
                if (res.status === 200) resolve(res.data); else reject();
            }).catch((e) => {
                if (e.response) {
                    const {resp_code, resp_msg} = e.response.data;
                    if (resp_code === 1 && resp_msg === "Access Denied") {
                        // return ElMessage.warning("暂无该操作权限，如需进行操作请联系管理员分配权限")
                    } else {
                        ElMessage.error(resp_msg);
                    }
                } else {
                    ElMessage.error("服务器忙，请稍后再试");
                }
                // ElMessage.error(e.response ? e.response.data['resp_msg'] : "服务器忙，请稍后再试");
            }).finally(() => {
                NProgress['done'](true);
            });
        })
    }, /**
     * post请求
     * @param url
     * @param data
     * @param Authorization
     * @param responseType
     * @param Json
     * @returns {*}
     */
    requestPost(url, data, responseType, Authorization, Json) {
        return this.requestConfig(url, data, "POST", responseType, Authorization, Json ? "application/json" : null, Json ? "data" : null);
    }, /**
     * get请求
     * @param url
     * @param data
     * @param responseType
     * @param Authorization
     * @returns {*}
     */
    requestGet(url, data, responseType, Authorization) {
        return this.requestConfig(url, data, "GET", responseType, Authorization);
    }, /**
     * delete请求
     * @param url
     * @param data
     * @param responseType
     * @param Authorization
     * @returns {*}
     */
    requestDelete(url, data, responseType, Authorization) {
        return this.requestConfig(url, data, "DELETE", responseType, Authorization);
    }, /**
     * post请求上传文件
     * @param url
     * @param data
     * @param responseType
     * @param Authorization
     * @returns {*}
     */
    requestUploadFile(url, data, responseType, Authorization) {
        data.append("dataIndex", VUE_APP_MARKING)
        const {baseURL, timeout} = this.options;
        return new Promise((resolve, reject) => {
            axios({
                url, method: "POST", baseURL, data: data, timeout, responseType: responseType || "json", headers: {
                    'Content-Type': "multipart/form-data",
                    'Authorization': Authorization || commonMethods.getCookie("safer")
                },
            }).then(res => {
                if (res.status === 200) resolve(res.data); else reject();
            }).catch((e) => {
                ElMessage.error(e.response ? e.response.data['resp_msg'] : "服务器忙，请稍后再试");
            }).finally(() => {
                NProgress['done'](true);
            });
        })
    },

    //大文件上传
    bigfileUpload(params) {
        return requestInterface.requestPost("dna-arch/file/save", params)
    },

    //小文件上传
    minFileUpload(params) {
        return requestInterface.requestUploadFile("dna-arch/file/saves", params)
    },

    async getBaseUrl() {
        let proUrl = commonMethods.getCookie("proUrl")
        baseURL = proUrl && proUrl.url ? proUrl.url : await getProUrl();
        return baseURL;
    },
}

export default requestInterface;
