import requestInterface from "archaeometry-common-components/src/js/request-interface";

const regionApi = {
    /**
     * 获取全国行政区划-树状目录
     * @param params
     * @returns {Promise<void>}
     */
    async getThreeList(params = {}) {
        let result = await requestInterface.requestGet("geoyt-region/region/list", params);
        return result['datas'];
    },
    /**
     * 获取行政区划信息
     * @param adCode
     */
    async getRegionInfo(adCode) {
        let result = await requestInterface.requestGet("geoyt-region/region/find", {adcode: adCode});
        return result['datas'];
    },

    //获取用户操作权限
    async getUserAccess(dataIndex){
        let result = await requestInterface.requestGet(`api-user/menus/current/${dataIndex}`);
        return result;
    }
}
export default regionApi;
