<template>
    <div class="main-navigation-component">
        <div class="main_navigation_top default_page_style">
            <div :class="subhead ? 'logo_subhead_box' : 'logo_box'">
                <div class="logo_section" v-text="logo"/>
                <p class="logo_subhead" v-if="subhead">—“中华文明探源生业资源与技术”课题共建</p>
                <h2 v-show="DNADatabase" class="logo_section_compiling" v-text="logoVice"/>
            </div>
            <div class="login_section">
                <div>
                    <p v-if="username">
                        <span @click="userNameEvent()" class="user_info">
                            {{ username }}
                            <span class="message_info" @click="msgCountEvent">{{ msgCount }}</span>
                        </span>
                        <span @click="logoutHandle">{{ dataIndex ? "退出" : "返回平台" }}
                        {{ !dataIndex && DNADatabase ? 'RETURN' : null }}</span>
                    </p>
                    <p v-else>
                        <span @click="loginOrRegisterHandle('login')">登录</span>
                        <span @click="loginOrRegisterHandle('register')">注册</span>
                    </p>
                </div>
                <div class="message-box" :class="{'active':element}">
                    <div class="transparent"></div>
                    <div class="message-list">
                        <div class="title">
                            消息列表
                            <span class="iconfont icon-shanchu2" @click="element=false"/></div>
                        <ul>
                            <li v-for="(item, i) in messageList" :key="i"
                                @click="currentMsgEvent(item)">{{ changeTitle(item.title) }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="DNADatabase ? 'dna_main_navigation_content default_page_style' : 'main_navigation_content default_page_style'">
            <ul :class="DNADatabase ? 'dna_navigation_section' : 'navigation_section'">
                <li v-for="(nav,i) in navigationList" v-show="!DNADatabase"
                    :key="i"
                    :class="{'active':nav.path===currentNav}"
                    @click="navigationHandle(nav)"
                    v-text="nav.name"></li>
                <li v-for="(nav,i) in navigationList" v-show="DNADatabase"
                    :key="i"
                    :class="{'active':nav.path===currentNav}"
                    @click="navigationHandle(nav)">
                    <p>{{ nav.name }}</p>
                    <p class="name_en">{{ nav.vice }}</p>
                </li>
            </ul>
            <!--            <span v-if="username" class="control_btn" @click="controlHandle" v-text="controlText"></span>-->
            <el-input v-if="inputShow" v-model="input2" :suffix-icon="Search"/>
        </div>
        <teleport to="#cloudMap">
            <login-component :visible="visibleLogin"/>
        </teleport>
    </div>
</template>

<script>
import {defineComponent, onBeforeUnmount, onMounted, ref, watchEffect} from "vue";
import {useRoute, useRouter} from "vue-router";
import LoginComponent from "../login-component/login-component";
import commonMethods from "../../js/common-methods";
import userApi from "../../api/user-api";
import {Search} from '@element-plus/icons-vue'
import ReconnectingWebSocket from "reconnecting-websocket";

export default defineComponent({
    name: "main-navigation-component",
    components: {LoginComponent},
    props: {
        logo: {
            type: String,
            default: null
        },
        logoVice: {
            type: String,
            default: null
        },
        navigationList: {
            type: Array,
            default: () => []
        },
        username: {
            type: String,
            default: commonMethods.getCookie("uname")
        },
        controlText: {
            type: String,
            default: "控制台"
        },
        inputShow: {
            type: Boolean,
            default: false
        },
        DNADatabase: {
            type: Boolean,
            default: false,
        },
        dataIndex: {
            type: Boolean,
            default: false,
        }
    },
    setup(props, context) {
        // 当前路由
        let route = useRoute();
        let router = useRouter();
        // 当前选中导航
        let currentNav = ref(null);

        let rws; //消息提醒
        let messageList = ref([]) //消息列表

        //控制动物头部副标题的显示
        let subhead = ref(process.env.VUE_APP_MARKING === '1' || process.env.VUE_APP_MARKING === '2' ? true : false)


        let input2 = ref('')
        // 监听当前路由变化，默认会执行一次
        watchEffect(() => {
            currentNav.value = route.meta['mainNavigation'];
        })

        // 导航事件处理
        const navigationHandle = (item) => {
            context.emit("navigationHandle", item.path);
        }
        const changeTitle = function (val) {
            return commonMethods.fontSplit(val, 13)
        }
        let msgCount = ref(0);
        let element = ref(false);
        const currentMsgEvent = function (item) {
            element.value = false;
            router.push({name: 'personalCenterMessage', query: {id: item.id}})
        }
        const msgCountEvent = function (e) {
            e.stopPropagation()
            element.value = !element.value;
        }

        let visibleLogin = ref(false);
        // 登录、注册事件处理
        const loginOrRegisterHandle = (state) => {
            if (state === "login") {
                visibleLogin.value = !visibleLogin.value;
            } else {
                context.emit("registerHandle");
            }
        }

        // 退出登录事件处理
        const logoutHandle = async () => {
            let proUrl = commonMethods.getCookie("proUrl");
            window.location.href = proUrl.door + '/#/portalWebsite' + '?active=1';
            if (props.dataIndex) {
                userApi.logout();
                // context.emit("logoutHandle");
            }
        }

        // 个人中心事件处理
        const personalCenterHandle = () => {
            context.emit("personalCenterHandle");
        }

        // 控制台事件处理
        const controlHandle = () => {
            context.emit("controlHandle");
        }

        // 个人信息事件处理
        const userNameEvent = () => {
            context.emit("userHandle");
        }

        // 获取Minio地址
        const getMinioUrl = async function () {
            let result = await userApi.getMinioUrl();
            if (result["resp_code"] === 0) {
                commonMethods.setCookies({
                    moUrl: result["resp_msg"],
                });
            }
        }
        // 获取当前登录用户信息
        const getCurrentUser = async function () {
            let result = await userApi.getCurrentUser();
            if (result["resp_code"] === 0) {
                commonMethods.setCookies({
                    uId: result["datas"].id,
                    real: result['datas'].nickname,//真实姓名
                });
            }
        }
        onMounted(async function () {
            let token = commonMethods.getCookie("safer")
            if (!token && !props.dataIndex) {
                let proUrl = commonMethods.getCookie("proUrl");
                window.location.href = proUrl.door;
            }
            let stage = router.options.history.location !== "/register";
            if (token && stage && !commonMethods.getCookie("uId")) {
                await getCurrentUser();
            }
            if (token && stage && !commonMethods.getCookie("moUrl")) {
                await getMinioUrl();
            }

            if (token) {
                let marking = commonMethods.getDatabaseMarking();
                let webSocket = commonMethods.getCookie('proUrl').webSocket;
                let uId = commonMethods.getCookie('uId');
                let ws = location.protocol === "http:" ? "ws" : "wss";
                rws = new ReconnectingWebSocket(`${ws}://${webSocket}/${marking}/ws/${uId}`);
                rws.addEventListener('open', () => {
                    rws.send('西安云图信息技术有限公司');
                })
                rws.addEventListener('message', (e) => {
                    let data = JSON.parse(e.data);
                    messageList.value = data.msg;
                    msgCount.value = data.msgcount;
                })
            }
        })
        onBeforeUnmount(function () {
            // rws.close();
        })

        return {
            currentNav,
            navigationHandle,
            controlHandle,
            userNameEvent,
            logoutHandle,
            personalCenterHandle,
            loginOrRegisterHandle,
            Search,
            input2,
            visibleLogin,
            subhead,
            messageList,
            changeTitle,
            element,
            msgCount,
            currentMsgEvent,
            msgCountEvent,
        }
    }
})
</script>

<style lang="scss">
@import "./main-navigation-component";
</style>
