import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: {name: "portalWebsite"}
    },
    {
        path: '/portalWebsite',
        name: 'portalWebsite',
        meta: {
            title: "首页",
            mainNavigation: "portalWebsite"
        },
        component: () => import('../views/homepage-view/homepage-view'),
        // component: () => import('../views/portal-website/portal-website-homepage'),
    },
    {
        path: '/platformIntro',
        name: 'platformIntro',
        meta: {
            title: "平台简介",
            mainNavigation: "platformIntro"
        },
        component: () => import('../views/platform-intro/platform-intro'),
    },
    {
        path: '/academicFrontier',
        name: 'academicFrontier',
        meta: {
            title: "学术动态",
            mainNavigation: "academicFrontier",
        },
        redirect: {name: "academicFrontierInformation"},
        component: () => import('../views/academic-frontier/academic-frontier'),
        children: [
            {
                path: 'information',
                name: 'academicFrontierInformation',
                meta: {
                    title: "综合资讯",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierInformation',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'animal',
                name: 'academicFrontierAnimal',
                meta: {
                    title: "动物考古",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierAnimal',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'plant',
                name: 'academicFrontierPlant',
                meta: {
                    title: "植物考古",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierPlant',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'chronology',
                name: 'academicFrontierChronology',
                meta: {
                    title: "碳十四年代学",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierChronology',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'dna',
                name: 'academicFrontierDna',
                meta: {
                    title: "古DNA",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierDna',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'skeleton',
                name: 'academicFrontierSkeleton',
                meta: {
                    title: "人类骨骼考古",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierSkeleton',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'environmental',
                name: 'academicFrontierEnvironmental',
                meta: {
                    title: "环境考古",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierEnvironmental',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'materials',
                name: 'academicFrontierMaterials',
                meta: {
                    title: "材料考古",
                    mainNavigation: "academicFrontier",
                    frontierLeft: 'academicFrontierMaterials',
                },
                component: () => import('../components/academic-frontier-component'),
            },
            {
                path: 'academicFrontierDetails',
                name: 'academicFrontierDetails',
                meta: {
                    title: "学术详情",
                    mainNavigation: "academicFrontier",
                },
                component: () => import('../views/academic-frontier/academic-frontier-details'),
            },
        ]
    },
    {
        path: '/professionalStandard',
        name: 'professionalStandard',
        meta: {
            title: "行业标准",
            mainNavigation: "professionalStandard"
        },
        component: () => import('../views/professional-standard/professional-standard'),
    },
    {
        path: '/dataCenter',
        name: 'dataCenter',
        meta: {
            title: "数据中心",
            mainNavigation: "dataCenter"
        },
        redirect: {name: "dataCenterDna"},
        component: () => import('archaeometry-common-components/src/components/common-route-view'),
        children: [
            {
                path: "",
                name: "dataCenterDna",
                meta: {
                    title: "数据中心",
                    mainNavigation: "dataCenter"
                },
                component: () => import('../views/data-center/data-center'),
            },
            {
                path: "detail",
                name: "sampleMapDetail",
                meta: {
                    title: "遗址详情",
                    mainNavigation: "dataCenter"
                },
                component: () => import('../views/data-center/details/details-ruins/detail/detail'),
            },
            {
                path: "animalDataInfo",
                name: "dataCenterAnimalInfo",
                meta: {
                    title: "动物样本数据查看",
                    mainNavigation: "dataCenter"
                },
                component: () => import("../views/data-center/details/details-animal/details"),
            },
            {
                path: "carbonDetail",
                name: "dataCenterCarbonInfo",
                meta: {
                    title: "碳十四测年数据查看",
                    mainNavigation: "dataCenter"
                },
                component: () => import("../views/data-center/details/detail-carbon/detail"),
            },
            {
                path: 'dnaDataInfo',
                name: 'dataCenterDnaInfo',
                meta: {
                    title: "DNA数据查看",
                    mainNavigation: "dataCenter",
                },
                component: () => import('../views/data-center/details/data-details-dna'),
            },
            {
                path: "plantDetails",
                name: "dataCenterPlantInfo",
                meta: {
                    title: "样本详情",
                    mainNavigation: "dataCenter"
                },
                component: () => import("../views/data-center/details/details-plant/details-component"),
            },
            {
                path: "animalDetails",
                name: "dataCenterAnimalDetails",
                meta: {
                    title: "动物样本详情",
                    mainNavigation: "dataCenter"
                },
                component: () => import("../views/data-center/new-sample-details/animal-details"),
            },
            {
                path: "animalDetail",
                name: "dataCenterAnimalDetail",
                meta: {
                    title: "动物样本详情",
                    mainNavigation: "dataCenter"
                },
                component: () => import("archaeometry-common-components/src/components/data-linkage-details-component/animal-details-component/animal-details-component"),
            },
            {
                path: "plantDetail",
                name: "dataCenterPlantDetail",
                meta: {
                    title: "植物样本详情",
                    mainNavigation: "dataCenter"
                },
                component: () => import("archaeometry-common-components/src/components/data-linkage-details-component/plant-details-component/plant-details-component"),
            },
        ]
    },
    {
        path: '/websiteManagement',
        name: 'websiteManagement',
        meta: {
            title: "系统管理",
            mainNavigation: "websiteManagement"
        },
        redirect: {name: "websiteIntro"},
        component: () => import('../views/website-management/platform-brief'),
        children: [
            {
                path: "intro",
                name: "websiteIntro",
                meta: {
                    title: "平台简介",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteIntro"
                },
                component: () => import('../views/website-management/management-details/brief-introduction'),
            },
            {
                path: "unit",
                name: "websiteUnit",
                meta: {
                    title: "共建单位",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteUnit"
                },
                component: () => import('../views/website-management/management-details/build-unit'),
            },
            {
                path: "team",
                name: "websiteTeam",
                meta: {
                    title: "科研团队",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteTeam"
                },
                component: () => import('../views/website-management/management-details/research-team'),
            },
            {
                path: "front",
                name: "websiteFront",
                meta: {
                    title: "学术前沿",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteFront"
                },
                component: () => import('../views/website-management/management-details/academic-frontier-management'),
            },
            {
                path: "standard",
                name: "websiteStandard",
                meta: {
                    title: "行业标准",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteStandard"
                },
                component: () => import('../views/website-management/management-details/professional-standard-management'),
            },
            {
                path: "unitManagement",
                name: "websiteUnitManagement",
                meta: {
                    title: "单位管理",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteUnitManagement"
                },
                component: () => import('../views/website-management/management-details/unit-management'),
            },
            {
                path: "userManagement",
                name: "websiteUserManagement",
                meta: {
                    title: "用户管理",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteUserManagement"
                },
                component: () => import('../views/website-management/management-details/user-management'),
            },
            {
                path: "roleManagement",
                name: "websiteRoleManagement",
                meta: {
                    title: "角色管理",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteRoleManagement"
                },
                redirect: {name: "roleManageList"},
                component: () => import('archaeometry-common-components/src/components/common-route-view'),
                children: [
                    {
                        path: "",
                        name: "roleManageList",
                        meta: {
                            title: "角色列表",
                            mainNavigation: "websiteManagement",
                            currentNav: "websiteRoleManagement"
                        },
                        component: () => import('../views/website-management/management-details/role-management'),
                    },
                    {
                        path: "privileges",
                        name: "roleManagePrivileges",
                        meta: {
                            title: "分配权限",
                            mainNavigation: "websiteManagement",
                            currentNav: "websiteRoleManagement"
                        },
                        component: () => import('../views/website-management/management-details/privileges/privileges'),
                    }
                ]
            },
            {
                path: "systemManagement",
                name: "websiteSystemManagement",
                meta: {
                    title: "系统管理",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteSystemManagement"
                },
                component: () => import('../views/website-management/management-details/system-journal'),
            },
            {
                path: "menuManagement",
                name: "websiteMenuManagement",
                meta: {
                    title: "菜单管理",
                    mainNavigation: "websiteManagement",
                    currentNav: "websiteMenuManagement"
                },
                component: () => import('../views/website-management/management-details/menu-manage'),
            },
        ]
    },
    {
        path: '/businessManagement',
        name: 'businessManagement',
        meta: {
            title: "业务管理",
            mainNavigation: "businessManagement",
        },
        component: () => import('../views/website-management/platform-brief'),
    },
    {
        path: '/systemManagement',
        name: 'systemManagement',
        meta: {
            title: "系统管理",
            mainNavigation: "systemManagement",
        },
        component: () => import('../views/website-management/platform-brief'),
    },
    {
        path: '/preview',
        name: 'preview',
        meta: {
            title: "预览",
            mainNavigation: "preview"
        },
        component: () => import('archaeometry-common-components/src/components/common-route-view'),
        children: [
            {
                path: 'pdf',
                name: 'previewPDF',
                meta: {
                    title: "预览pdf",
                    mainNavigation: "preview"
                },
                component: () => import('archaeometry-common-components/src/components/pdf-viewer-component/pdf-viewer-component'),
            },
        ]
    },
    {
        path: '/preview-view',
        name: 'previewView',
        meta: {
            title: "预览",
            mainNavigation: "preview"
        },
        component: () => import('archaeometry-common-components/src/modules/preview-file-module/preview-file-module'),
    },
    {
        path: '/register',
        name: 'userRegister',
        meta: {
            title: "用户注册",
            mainNavigation: "register",
        },
        component: () => import('../views/register/register')
    },
    // {
    //     path: '/personalCenter',
    //     name: 'personalCenter',
    //     meta: {
    //         title: "个人中心",
    //         mainNavigation: "personalCenter",
    //     },
    //     redirect: {name: "personalCenterUserInfo"},
    //     component: () => import('../views/personal-center/personal-center'),
    //     children: [
    //         {
    //             path: 'userInfo',
    //             name: 'personalCenterUserInfo',
    //             meta: {
    //                 title: "个人中心",
    //                 mainNavigation: "personalCenter",
    //                 personalLeft: "userInfo",
    //             },
    //             component: () => import('../views/personal-center/user-info'),
    //         },
    //         {
    //             path: 'editPwd',
    //             name: 'personalCenterEditPwd',
    //             meta: {
    //                 title: "修改密码",
    //                 mainNavigation: "personalCenter",
    //                 personalLeft: "editPwd",
    //             },
    //             component: () => import('../views/personal-center/edit-password'),
    //         },
    //         {
    //             path: 'database',
    //             name: 'personalCenterDatabase',
    //             meta: {
    //                 title: "数据库申请",
    //                 mainNavigation: "personalCenter",
    //                 personalLeft: "database",
    //             },
    //             component: () => import('../views/personal-center/database-apply'),
    //         },
    //     ]
    // },
    {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: {
            title: "个人中心",
            mainNavigation: "personalCenter",
        },
        redirect: {name: "personalCenterUserInfo"},
        component: () => import('archaeometry-common-components/src/modules/personal-center/personal-center'),
        children: [
            {
                path: 'userInfo',
                name: 'personalCenterUserInfo',
                meta: {
                    title: "个人中心",
                    mainNavigation: "personalCenter",
                    personalLeft: "userInfo",
                },
                component: () => import('archaeometry-common-components/src/modules/personal-center/user-info'),
            },
            {
                path: 'editPwd',
                name: 'personalCenterEditPwd',
                meta: {
                    title: "修改密码",
                    mainNavigation: "personalCenter",
                    personalLeft: "editPwd",
                },
                component: () => import('archaeometry-common-components/src/modules/personal-center/edit-password'),
            },
            {
                path: 'database',
                name: 'personalCenterDatabase',
                meta: {
                    title: "数据库申请",
                    mainNavigation: "personalCenter",
                    personalLeft: "database",
                },
                component: () => import('archaeometry-common-components/src/modules/personal-center/database-apply'),
            },
        ]
    },
]

const router = createRouter({
    // 使用hash的路由模式
    history: createWebHashHistory(),
    routes,
    // 每次切换路由的时候滚动到页面顶部
    scrollBehavior() {
        // vue2.0  x  y  控制
        // vue3.0  left  top 控制
        return {left: 0, top: 0}
    }
})
export default router
