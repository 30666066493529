<template>
    <div class="map-viewer-component">
        <div id="map" class="default_map_style">
            <base-map-component v-if="visibleBaseMap"/>
        </div>
        <map-tool-component/>
        <map-catalog-component ref="mapCatalogRef" :ruinsList="ruinsListParams ? sampleQuantity : ruinsList"
                               :dataType="plantType"
                               @city-event="cityEvent"
                               @query-event="queryEvent">
            <slot slot="attr-query"/>
        </map-catalog-component>
        <!--        样本列表-->
        <div :class="{active:visibleSamplePanel}" class="sample_list_panel">
            <div class="sample_list_title">
                <h3 v-text="currentRuins.ruinsName"></h3>
                <span class="sample_list_close" @click="visibleSamplePanel=false">
                   <el-icon><Close/></el-icon>
                </span>
            </div>
            <div class="sample_list_body">
                <ul>
                    <li v-for="(item,i) in sampleList" :key="i" @click="redirectPage(item)">
                        <template v-if="markingName==='animal'">
                            <h3>鉴定编号：{{ item.appraisalNumber }}</h3>
                            <p>种属：{{ item.speciesName }}</p>
                        </template>
                        <template v-if="markingName==='plant'">
                            <h3>出土单位号：{{ item.unitCode }}</h3>
                            <template v-if="plantType ==='carbonPlant'">
                                <p>种属：{{ item.name }}</p>
                                <p>保存状况：{{ item.saveStatusName }}</p>
                                <p>成熟度：{{ item.maturityName }}</p>
                            </template>
                            <template v-if="plantType ==='phytolith'">
                                <p>植硅体类型：{{ item.phytolithTypeName }}</p>
                            </template>
                            <template v-if="plantType ==='starchGranule'">
                                <p>淀粉粒种类：{{ item.name }}</p>
                            </template>
                            <template v-if="plantType ==='charcoal'">
                                <p>种属：{{ item.name }}</p>
                            </template>
                            <p>数据类别：{{ item.dataTypeName }}</p>
                            <p>数据归属：{{ item.dataBelongName }}</p>
                        </template>
                        <template v-if="markingName==='dna'">
                            <h3>样品原始编号：{{ item.originalNumber }}</h3>
                            <p>物种：{{ item.speciesName }}</p>
                        </template>
                        <template v-if="markingName==='carbon'">
                            <h3>实验室编号：{{ item.labCode }}</h3>
                            <p>样品类型：{{ item.sampleTypeName }}</p>
                        </template>
                    </li>
                </ul>
                <el-empty v-if="sampleList.length<1"/>
            </div>
            <div class="sample_list_footer">
                <el-pagination
                    :page-size="pageSize"
                    :pager-count="pagerCount"
                    :total="total"
                    hide-on-single-page
                    layout="total, prev, pager, next"
                    small
                    @current-change="currentChange"/>
            </div>
        </div>
        <!--        遗址列表控制按钮-->
        <div class="ruins_control_btn" @click="visibleRuinsPanel=true">
            <span class="iconfont icon-liebiao"></span>
        </div>
        <!--        遗址列表-->
        <div :class="{active:visibleRuinsPanel}" class="sample_list_panel">
            <div class="sample_list_title">
                <h3>遗址列表</h3>
                <span class="sample_list_close" @click="visibleRuinsPanel=false">
                   <el-icon><Close/></el-icon>
                </span>
            </div>
            <div class="sample_list_body">
                <ul>
                    <li v-for="(item,i) in ruinsListSplice" :key="i" @click="locationRuins(item)">
                        <h3>名称：{{ item.ruinsName }}</h3>
                        <p>行政区划：{{ item.province }}{{ item.city }}{{ item.country }}</p>
                    </li>
                </ul>
                <el-empty v-if="ruinsListSplice.length<1"/>
            </div>
            <div class="sample_list_footer">
                <el-pagination
                    :page-size="pageSizeRuins"
                    :pager-count="pagerCountRuins"
                    :total="ruinsList.length"
                    hide-on-single-page
                    layout="total, prev, pager, next"
                    small
                    @current-change="currentChangeRuins"/>
            </div>
        </div>
    </div>
</template>

<script>
import {computed, h, inject, nextTick, onMounted, provide, ref, watch, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import MapConfig from "@acc/plugins/mapTool/map-config";
import {Close, Grid, Search} from '@element-plus/icons-vue'
import {sampleMapApi} from "@acc/api/sample-map-api";
import MapCatalogComponent from "@acc/components/map-catalog-component/map-catalog-component";
import regionApi from "@acc/api/region-api";
import {geoJSON} from "leaflet";
import commonMethods from "@acc/js/common-methods";
import {ElNotification} from "element-plus";
import {dataVisitApplyApi} from "../../api/data-visit-apply-api";

export default {
    name: "map-viewer-component",
    components: {
        MapCatalogComponent,
        Search,
        Close,
        Grid,
    },
    setup() {
        let router = useRouter();
        let mapConfig = new MapConfig();
        let mapCatalogRef = ref(false);
        let visibleBaseMap = ref(false);
        let visibleSamplePanel = ref(false);
        let visibleRuinsPanel = ref(false);
        let visibleAttrPanel = ref(false);
        let queryVal = ref(null);
        let geoJson = ref(null);
        let adCode = ref(null);
        let viewer = inject('viewer');
        let currentSwitch = inject('currentSwitch');
        let attrQueryForm = inject('attrQueryForm');
        let currentRuins = ref({});
        let total = ref(0);
        let pageSize = ref(20);
        let pagerCount = ref(1);
        let pageSizeRuins = ref(20);
        let pagerCountRuins = ref(1);
        let ruinsListParams = ref(false)
        let sampleQuantity = ref([])
        let ruinsList = ref([]);
        let ruinsListSplice = ref([]);
        let sampleList = ref([]);
        let markingName = ref(commonMethods.getMarkingByName()); // 数据库唯一标识，用于区分不同库展示详情字段
        // 植物类型，用于显示详情不同字段
        let plantType = ref('carbonPlant');

        watch(currentSwitch, function (val) {
            plantType.value = val.type;
            queryEvent();
        });
        // 监听是否属性查询
        let isAttr = ref(false);
        let isPlant = computed(() => process.env.VUE_APP_MARKING === "2");
        watchEffect(function () {
            if (isPlant.value) {
                isAttr.value = mapCatalogRef.value.currentNav === "attr";
            }
        })

        watch(visibleSamplePanel, function (val) {
            pagerCount.value = 1;
            if (val) visibleRuinsPanel.value = false;
        });

        watch(ruinsList, function (val) {
            pagerCountRuins.value = 1;
            visibleRuinsPanel.value = val.length > 0;
            if (val) visibleSamplePanel.value = false;
            spliceRuinsData();
        })

        watch(geoJson, function () {
            queryEvent();
        });
        watch(adCode, function () {
            getDivisionBounds();
            queryEvent();
        });
        watch(viewer, function (val) {
            if (val === 'map') {
                nextTick(function () {
                    mapConfig.mapResize();
                });
            }
        });

        // 城市选择事件
        const cityEvent = function (city) {
            if (city) {
                // mapConfig.map.flyTo([city.y, city.x], 15, {
                //     duration: 2
                // });
                adCode.value = city.adcode;
            } else {
                mapConfig.totalGraph();
                adCode.value = null;
            }
        }

        // 查询事件
        const queryEvent = function (val) {
            getDataList(val === "plant" && isAttr.value);
        }

        // 植物数据库，初始化时添加的遗址列表的样本类型
        let mapInitSampleType = ref("");
        // 获取数据列表
        const getDataList = async function (stage) {
            visibleSamplePanel.value = false;
            let queryForm;
            // 碳十四查询条件 ,接口写的不规范，如果后期看到提醒后端改，如果后端改了之后这一段代码删掉即可
            // 目前scopeOne只有三个选项，如果scopeOne有一百个选项，现在这种就会存在100个字段，这样是不对的
            if (process.env.VUE_APP_MARKING === "4") {
                // attrQueryForm.index 作为数据类型标识（区分：发布数据，自由数据+个人共享数据，自由数据，他人共享）
                const {
                    labNameId, sampleTypeId, carbonFourteenDatingMethod, dataSourcesType, carbonYearType,
                    errorSelect, error, scopeOne, calendarOne, scopeTwo, calendarTwo, userId, examineStatus, dataBelong
                } = attrQueryForm;
                queryForm = {
                    labNameId, sampleTypeId, carbonFourteenDatingMethod, userId, examineStatus, dataBelong,
                    carbonYearType, index: dataSourcesType ? "0" : null,
                }
                if (errorSelect && error) queryForm[errorSelect === "1" ? 'gtError' : "ltError"] = error

                if (scopeOne && calendarOne) queryForm[scopeOne === "1" ? 'gtAdCarbonYear' : (scopeOne === "2" ? 'gtBcCarbonYear' : "gtBpCarbonYear")] = calendarOne

                if (scopeTwo && calendarTwo) queryForm[scopeTwo === "1" ? 'ltAdCarbonYear' : (scopeTwo === "2" ? 'ltBcCarbonYear' : "ltBpCarbonYear")] = calendarTwo
                // if (queryForm.index === 0 && !queryForm.userId) {
                //     queryForm.userId = commonMethods.getCookie('uId');
                // }
                // ----------------------end
            } else {
                queryForm = {
                    ...attrQueryForm,
                    index: attrQueryForm.dataSourcesType ? "0" : null,
                    dataBelong: (isPlant.value && !isAttr.value) ? "" : attrQueryForm.dataBelong,
                    examineStatus: (isPlant.value && !isAttr.value) ? "" : attrQueryForm.examineStatus
                }
            }

            let params = {
                adcode: adCode.value,
                geoJson: geoJson.value ? encodeURIComponent(JSON.stringify(geoJson.value)) : null,
                plantType: plantType.value,
                ...queryForm,
                ruinsName: queryVal.value,
            }
            let result = await sampleMapApi.getRuinsFind(params, stage);
            let dataList = result['datas']
            // let res
            // if (isPlant.value) {
            // res = await sampleMapApi.getRuinsNum(params);
            // ruinsList.value = result['datas'];
            // sampleQuantity.value = [res['datas']];
            // ruinsListParams.value = true
            // } else if (process.env.VUE_APP_MARKING !== "2") {
            ruinsList.value = dataList;
            ruinsListParams.value = false
            // }

            mapConfig.addClusterDataToMap(dataList, isPlant.value && isAttr.value, function (e) {
                currentRuins.value = e.target.feature.properties;
                visibleSamplePanel.value = true;
                mapInitSampleType.value = "";
                getSampleList();
            }, function (e, type) {
                currentRuins.value = e.target.feature.properties;
                visibleSamplePanel.value = true;
                mapInitSampleType.value = type;
                getSampleList(true, type);
            });
        }
        // 分页切换事件
        const currentChange = function (num) {
            pagerCount.value = num;
            getSampleList();
        }
        // 获取样本列表
        const getSampleList = async function (stage, type) {
            let params = {
                limit: pageSize.value,
                page: pagerCount.value,
                ruinsId: currentRuins.value.id,
                // dataBelong: attrQueryForm.othersId ? '1' : attrQueryForm.userId ? '0' : '',
                ...attrQueryForm,
                plantType: stage ? type : plantType.value,
                index: attrQueryForm.dataSourcesType ? "0" : null,
                dataBelong: (isPlant.value && !isAttr.value) ? "" : attrQueryForm.dataBelong,
                examineStatus: (isPlant.value && !isAttr.value) ? "" : attrQueryForm.examineStatus
            }
            let result
            if (process.env.VUE_APP_MARKING === "4") {
                const {errorSelect, error, scopeOne, calendarOne, scopeTwo, calendarTwo,} = attrQueryForm;
                let queryForm = {}
                if (errorSelect && error) queryForm[errorSelect === "1" ? 'gtError' : "ltError"] = error

                if (scopeOne && calendarOne) queryForm[scopeOne === "1" ? 'gtAdCarbonYear' : (scopeOne === "2" ? 'gtBcCarbonYear' : "gtBpCarbonYear")] = calendarOne

                if (scopeTwo && calendarTwo) queryForm[scopeTwo === "1" ? 'ltAdCarbonYear' : (scopeTwo === "2" ? 'ltBcCarbonYear' : "ltBpCarbonYear")] = calendarTwo
                // ----------------------end
                // 根据数据来源类型区分
                result = await sampleMapApi.getSampleList({...params, ...queryForm})
                // if (attrQueryForm.userId || attrQueryForm.index) {
                //     params.userId = commonMethods.getCookie('uId'),
                //
                // } else if (!params.dataBelong) {
                //     result = await sampleMapApi.getMapSampleList({
                //         releaseStatus: 1,
                //         ruinsId: currentRuins.value.id, ...attrQueryForm, ...queryForm
                //     });
                // }
            } else {
                result = await sampleMapApi.getSampleList(params)
            }
            if (markingName.value === 'carbon') {
                sampleList.value = result.datas.data;
            } else {
                sampleList.value = result.data;
            }
            total.value = result.count;
        }

        // 跳转样本详情
        const redirectPage = async function (item) {
            let name
            let plantQuery = {}
            let plantTypeObj = {
                carbonPlant: 1,
                phytolith: 2,
                starchGranule: 3,
                charcoal: 4,
            }
            if (isPlant.value) {
                if (item.dataType === "1") {
                    name = "resourceDataDetail";
                    plantQuery = {
                        ruinsId: item.ruinsId,
                        sampleId: item.id,
                        sampleType: mapInitSampleType.value || plantType.value
                    }
                    let resolve = router.resolve({name, query: {...plantQuery}});
                    window.location.href = resolve.href;
                    return;
                } else {
                    name = "sampleCenterNewDetailList";
                    let bgType = getBgTypeName(item.backgroundType);
                    plantQuery = {
                        ruinsId: item.ruinsId,
                        sampleId: item.id,
                        [bgType.param]: item.backgroundId,
                        bgType: bgType.backgroundType,
                        examineStatus: attrQueryForm.examineStatus,
                        dataBelong: attrQueryForm.dataBelong,
                        sampleType: plantTypeObj[mapInitSampleType.value || plantType.value]
                    }
                }

            } else {
                name = "sampleMapModeDetails"
            }
            let query = {
                title: item.ruinsName, id: item.id,
                unitCode: item.unitCode,
                culturalPeriod: item.culturalPeriod,
                sampleCode: item.sampleCode,
                ruinsId: item.ruinsId,
                ...plantQuery,
            }
            if (process.env.VUE_APP_MARKING === "3") {
                if (item.dataPower === '1') {
                    let resolve = router.resolve({name, query});
                    window.location.href = resolve.href;
                } else {
                    ElNotification({
                        title: '数据访问受限',
                        message: h('i', {style: 'color: #2486D5'}, '该数据属于受限数据，请进入列表提交数据访问申请'),
                    })
                }
            } else {
                if (item.dataPower === '1') {
                    let resolve = router.resolve({name, query});
                    window.location.href = resolve.href;
                } else {
                    let params = {
                        sampleId: item.id,
                        userId: commonMethods.getCookie('uId'),
                        sampleType: ""
                    }
                    if (process.env.VUE_APP_MARKING === "2") {
                        params.sampleType = plantTypeObj[mapInitSampleType.value || plantType.value]
                    }
                    let res = await dataVisitApplyApi.getDataApplyVerify(params);
                    if (res['resp_code'] === 0) {
                        let resolve = router.resolve({name, query});
                        window.location.href = resolve.href;
                    } else {
                        ElNotification({
                            title: '数据访问受限',
                            message: h('i', {style: 'color: #2486D5'}, '该数据属于受限数据，请进入列表提交数据访问申请'),
                        })
                    }
                }
            }
        }
        // 获取背景类型名称
        const getBgTypeName = function (val) {
            let bgType
            let TypeList = [
                {param: "bgId", backgroundType: "1", key: '遗迹单位'},
                {param: "bgId", backgroundType: "2", key: "剖面采集"},
            ];
            for (const list of TypeList) {
                if (val === list.backgroundType) {
                    bgType = list
                }
            }
            return bgType
        }

        // 获取行政区划边界
        const getDivisionBounds = function () {
            mapConfig.divisionLayer.clearLayers();
            if (!adCode.value) return;
            regionApi.getRegionInfo(adCode.value).then(res => {
                let geometry = JSON.parse(res.bound);
                geoJSON(geometry).addTo(mapConfig.divisionLayer);
                if (mapConfig.divisionLayer.getLayers().length > 0) mapConfig.map.fitBounds(mapConfig.divisionLayer.getBounds());
            });
        }

        // 定位
        const locationRuins = function (item) {
            mapConfig.map.setView(item.latLng);
        }
        // 遗址分页
        const currentChangeRuins = function (num) {
            pagerCountRuins.value = num;
            spliceRuinsData();
        }
        // 切割数组，实现前端分页-遗址列表
        const spliceRuinsData = function () {
            let slice = commonMethods.arraySlice(ruinsList.value, pageSizeRuins.value);
            if (slice.length > 0)
                ruinsListSplice.value = slice[pagerCountRuins.value - 1];

        }

        provide("mapTool", mapConfig);
        provide("viewer", viewer);
        provide("queryVal", queryVal);
        provide("visibleBaseMap", visibleBaseMap);
        provide("visibleAttrPanel", visibleAttrPanel);
        provide("geoJson", geoJson);
        provide("adCode", adCode);
        provide("queryEvent", queryEvent);

        onMounted(() => {
            mapConfig.initMap("map");
            queryEvent('plant')
            // getDataList();
        });

        return {
            queryVal,
            visibleBaseMap,
            visibleAttrPanel,
            mapCatalogRef,
            total,
            viewer,
            pageSize,
            pagerCount,
            pageSizeRuins,
            pagerCountRuins,
            sampleList,
            ruinsList,
            cityEvent,
            queryEvent,
            visibleSamplePanel,
            visibleRuinsPanel,
            currentRuins,
            currentChange,
            redirectPage,
            markingName,
            attrQueryForm,
            plantType,
            ruinsListSplice,
            locationRuins,
            currentChangeRuins,
            currentSwitch,
            sampleQuantity,
            ruinsListParams
        }
    }
}
</script>

<style lang="scss">
@import "./map-viewer-component";
</style>
