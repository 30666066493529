<template>
    <div class="three-level-linkage">
        <el-select placeholder="请选择（省、自治区）" v-model="province" @change="provinceChange"
                   clearable filterable :disabled="disabled">
            <el-option v-for="(item,i) in provinceList" :key="i"
                       :label="item.name"
                       :value="item.name"/>
        </el-select>
        <el-select placeholder="请选择（市、州）" v-model="city" @change="cityChange"
                   clearable filterable :disabled="disabled">
            <el-option v-for="(item,i) in cityList" :key="i"
                       :label="item.name"
                       :value="item.name"/>
        </el-select>
        <el-select placeholder="请选择（区县）" v-model="area" @change="areaChange"
                   clearable filterable :disabled="disabled">
            <el-option v-for="(item,i) in areaList" :key="i"
                       :label="item.name"
                       :value="item.name"/>
        </el-select>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {ElMessage} from "element-plus";
import commonMethods from "../../js/common-methods";

export default defineComponent({
    name: "three-level-linkage",
    props: {
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    setup() {
        let key = commonMethods.options.gaoDeKey;
        // 省份
        let province = ref(null);
        // 城市
        let city = ref(null);
        // 区县
        let area = ref(null);
        // 省份列表
        let provinceList = ref([]);
        // 城市列表
        let cityList = ref([]);
        // 区县列表
        let areaList = ref([]);
        // 中心点
        let center = ref(null);

        // 查询行政区划
        let queryDistrictService = (code = 100000) => {
            let url = `https://restapi.amap.com/v3/config/district?key=${key}&keywords=${code}&subdistrict=1&output=json`;
            return new Promise(resolve => {
                fetch(url).then(res => res.json()).then(res => {
                    if (res.info === "OK") {
                        center.value = res['districts'][0].center;
                        resolve(res['districts'][0]['districts']);
                    } else {
                        resolve([]);
                        ElMessage.error({message: "服务器忙，请稍后再试"})
                    }
                })
            })
        }

        // 省份选择
        let provinceChange = async adCode => {
            province.value = adCode;
            city.value = null;
            area.value = null;
            areaList.value = null;
            cityList.value = await queryDistrictService(adCode);
        }
        // 城市选择
        let cityChange = async adCode => {
            city.value = adCode;
            area.value = null;
            areaList.value = await queryDistrictService(adCode);
        }
        // 区县选择
        let areaChange = async adCode => {
            for (const list of areaList.value) {
                if (list.name === adCode) center.value = list.center;
            }
            area.value = adCode;
        }

        onMounted(() => {
            queryDistrictService().then(districts => {
                provinceList.value = districts;
            });
        })

        return {
            province,
            city,
            area,
            provinceList,
            cityList,
            areaList,
            center,
            provinceChange,
            cityChange,
            areaChange
        }
    }
})
</script>

<style lang="scss">
@import "../../scss/commonFunc";

.three-level-linkage {
    display: flex;
    align-items: center;

    .el-select {
        margin-right: pxToRem(20);

        &:last-of-type {
            margin-right: 0;
        }
    }
}
</style>
