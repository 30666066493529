class DragMethods  {
    /*开启并绑定dom实现拖拽*/
    startDrag(dom) {
        this.dom = dom;
        this.boxDom = dom.parentElement;
        dom.addEventListener("mousedown", this.mousedownHandle.bind(this))
    }
    // 鼠标摁下事件处理
    mousedownHandle(e) {
        this.startX = e.clientX - this.boxDom.offsetLeft;
        this.startY = e.clientY - this.boxDom.offsetTop;
        document.onmousemove = this.mousemoveHandle.bind(this);
        document.onmouseup = this.onmouseupHandle.bind(this);
    }
    // 鼠标移动事件处理
    mousemoveHandle(e) {
        let boxDom = this.boxDom;
        let boxParentDom = boxDom.parentElement;
        let boxWidth = boxParentDom.offsetWidth - boxDom.offsetWidth;
        let boxHeight = boxParentDom.offsetHeight - boxDom.offsetHeight;
        let left = e.clientX - this.startX;
        let top = e.clientY - this.startY;
        left = left < 0 ? 0 : (left > boxWidth ? boxWidth : left);
        top = top < 0 ? 0 : (top > boxHeight ? boxHeight : top);

        boxDom.style.left = left + "px";
        boxDom.style.top = top + "px";
    }
    // 鼠标抬起事件处理
    onmouseupHandle() {
        document.onmousemove = null;
        document.onmouseup = null;
    }
}

export default DragMethods;