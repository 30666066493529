<template>
    <div class="ruins-mode-relic-image-info">
        <image-list-component :data-list="dataList" :describe="false"
                              :operation="false"/>
        <pagination-component :page-size="pageSize" :total="total" pageSizes
                              @current-change="currentChange"
                              @sizes-change="handleSizeChange"/>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref, watchEffect} from "vue";
import commonMethodsPlant from "../../js/common-methods-plant";
import {useRoute, useRouter} from "vue-router";
import {plantDetailsApi} from "../../../../../api/data-linkage-details-api";

export default defineComponent({
    name: "ruins-mode-relic-image-info",
    components: {},
    props: {
        navList: {
            type: Array,
            default: () => [],
        }
    },
    setup() {
        let route = useRoute();
        let router = useRouter();
        let navigationConfig = ref([
            {name: '炭化植物', value: 'carbidePlant', type: "carbonPlant", sampleType: "1"},
            {name: '植硅体', value: 'phytolith', type: "phytolith", sampleType: "2"},
            {name: '淀粉粒', value: 'starchGrain', type: "starchGranule", sampleType: "3"},
            {name: '木炭', value: 'charcoal', type: "charcoal", sampleType: "4"},
        ])
        const plantSampleSwitchRef = ref(null);
        // 当前选中的按钮，
        let currentSwitch = ref(null);
        // 数据列表
        let pageNumber = ref(1);
        let pageSize = ref(8);
        let total = ref(0);
        let dataList = ref([]);
        // 编辑事件
        const editImageHandle = function (item) {
            let {ruinsId, backgroundType, unitId, profileId, residueId} = route.query;
            let query = {
                ruinsId: ruinsId,
                type: backgroundType === "1" ? "unit" : (backgroundType === "2" ? "profile" : "residue"),
                unitId, profileId, residueId,
                isEdit: true,
                info: "imageInfo", sampleType: currentSwitch.value.value, id: item.id,
            }
            let routerUrl = router.resolve({
                name: "originalDataCollection",
                query
            })
             window.location.href = routerUrl.href;
            // window.open(routerUrl.href, "_blank")
        };
        // 删除事件
        const deleteImageHandle = function (item) {
            commonMethodsPlant.deleteHandle(item.id, currentSwitch.value, () => {
                getDataList();
            })
        };
        // 分页事件
        const currentChange = function (num) {
            pageNumber.value = num;
            getDataList();
        };
        const handleSizeChange = function (val) {
            pageSize.value = val;
            currentChange(1);
        }
        // 获取数据列表
        const getDataList = async function () {
            // let urlParams = commonMethodsPlant.getUrlBgParams(route.query)
            let param = {
                page: pageNumber.value,
                limit: pageSize.value,
                // sampleType: currentSwitch.value.sampleType,
                // ...urlParams,
                sampleId: route.query.sampleId
            }
            let result = await plantDetailsApi.getImageDataList(param);
            for (const item of result.data) {
                item.imgUrl = item["photoFileList"].length > 0 ? item["photoFileList"][0] : "";
                item.name = item.photoName;
            }
            dataList.value = result.data;
            total.value = result.count;
        }

        watchEffect(function () {
            if (plantSampleSwitchRef.value) {
                let current = plantSampleSwitchRef.value.currentSwitch
                if (currentSwitch.value !== current) {
                    currentSwitch.value = current
                    currentChange();
                }
            } else {
                currentSwitch.value = navigationConfig.value[0]
            }
        })
        onMounted(function () {
            getDataList()
        })

        return {
            plantSampleSwitchRef,
            currentSwitch,
            pageSize,
            total,
            dataList,
            currentChange,
            handleSizeChange,
            editImageHandle,
            deleteImageHandle
        }
    }
})
</script>

<style lang="scss">
@import "../../../../../scss/commonFunc";

.ruins-mode-relic-image-info {
    .image-list-component {
        margin-top: pxToRem(20);

        li {
            max-width: 23%;
            min-width: 23%;

            &:nth-child(3n) {
                margin-right: pxToRem(25);
            }

            &:nth-child(4n) {
                margin-right: 0;
            }
        }
    }
}
</style>
